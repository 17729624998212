import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import { Stats } from "../models/models";

export default class StatisticsStore {
    storeType: string = window.localStorage.getItem('store-type') ? window.localStorage.getItem('store-type')! : 'store-md';

    get factor() {
        switch (this.storeType) {
            case 'store-lg': return 1;
            case 'store-md': return 0.5;
            case 'store-sm': return 0.25;
        }
        return 1;
    }

    facebookLivePart = 0.35;
    facebookPostPart = 0.20;
    tiktokPart = 0.20;

    constructor() {
        makeAutoObservable(this);

        reaction(
            () => this.storeType,
            storeType => {
                if (!storeType) {
                    storeType = 'store-md'
                }
                window.localStorage.setItem('store-type', storeType)
            }
        )
    }

    setStoreType = (storeType: string) => {
        this.storeType = storeType;
    }

    getTotalIncome = () => {
        return Math.round(608253 * this.factor);
    }

    getTotalTransactions = () => {
        return Math.round(6845 * this.factor);
    }

    getAverageSalesByDayWidget = () => {
        switch (this.storeType) {
            case 'store-lg': return '[8524, 7934, 8155, 6812, 9989, 11341, 14987]';
            case 'store-md': return '[4346, 2865, 3423, 2895, 5875, 7618, 6526]';
            case 'store-sm': return '[2346, 1865, 1423, 1895, 2875, 3618, 3526]';
        }
        return '';
    }

    getSalesInDaysWidget = () => {
        switch (this.storeType) {
            case 'store-lg': return '[23687,30687,28687,27687,35687,42687,31687,40687,34687,47687,54687,59687]';
            case 'store-md': return '[13687,15687,14687,13687,16687,21687,16687,20687,17687,22687,28687,29687]';
            case 'store-sm': return '[6687,7687,7687,6687,8687,10687,7687,10687,8687,8287,10687,14687]';
        }
        return '';
    }

    getPopularityInDaysWidget = () => {
        switch (this.storeType) {
            case 'store-lg': return '[6391,6903,7798,6923,7975,8376,8135,9017,10451,11846,10967,11067]';
            case 'store-md': return '[3391,3903,3798,3923,4975,4376,4135,5017,6451,5846,5967,6067]';
            case 'store-sm': return '[1391,1903,2798,1923,2975,2376,2135,3017,3451,3846,3967,4067]';
        }
        return '';
    }

    getTotalForQuarter = () => {
        return Math.round(this.getTotalIncome() / 3.7)
    }

    getChannelsForQuarter = () => {
        const total = this.getTotalForQuarter();
        const facebookLive = Math.round(total * this.facebookLivePart);
        const facebookPost = Math.round(total * this.facebookPostPart);
        const tiktok = Math.round(total * this.tiktokPart);
        const instagram = Math.round(total - tiktok - facebookLive - facebookPost);
        return `[${facebookLive},${facebookPost},${tiktok},${instagram}]`
    }

    getFacebookForYear = () => {
        const total = this.getTotalIncome();
        const facebookLive = Math.round(total * this.facebookLivePart);
        const facebookPost = Math.round(total * this.facebookPostPart);
        return Math.round(facebookLive + facebookPost);
    }

    getTikTokForYear = () => {
        const total = this.getTotalIncome();
        return Math.round(total * this.tiktokPart)
    }

    getInstagramForYear = () => {
        const total = this.getTotalIncome();
        return Math.round(total - this.getFacebookForYear() - this.getTikTokForYear())
    }

    getTransactionsForQuarter = () => {
        return Math.round(this.getTotalTransactions() / 3.5)
    }

    getPaymentsChannelForQuarter = () => {
        const total = this.getTotalForQuarter();
        const przelewy24 = Math.round(total * 0.45);
        const transfer = Math.round(total * 0.33);
        const onSpot = Math.round(total - przelewy24 - transfer);
        return `[${przelewy24},${transfer},${onSpot}]`
    }

    getDeliveryForQuarter = () => {
        const total = this.getTransactionsForQuarter();
        const inpost = Math.round(total * 0.55);
        const dpd = Math.round(total * 0.25);
        const pickup = Math.round(total - inpost - dpd);
        return `[${inpost},${dpd},${pickup}]`
    }

    getTotalComments = () => {
        return Math.round(105849 * this.factor);
    }

    getTotalShares = () => {
        return Math.round(16775 * this.factor);
    }

    getSimpleStatsLastQuarter = () => {
        return {
            basketSize: Math.round((2.65 + this.factor / 2) * 100) / 100,
            basketValue: Math.round((98.3 + this.factor * 3) * 10) / 10,
            comments: Math.round((2829.9 * this.factor) * 10) / 10,
            shares: Math.round((331.4 * this.factor) * 10) / 10,
            orders: Math.round((137.8 * this.factor) * 10) / 10,

            basketSizeChange: Math.round((2.6 + this.factor / 3) * 10) / 10,
            basketValueChange: Math.round((4.1 + this.factor / 2) * 10) / 10,
            commentsChange: Math.round((8.2 + this.factor * 2) * 10) / 10,
            sharesChange: Math.round((0.4 + this.factor / 2) * 10) / 10,
            ordersChange: Math.round((8.3 - this.factor) * 10) / 10,
        }
    }

    getSalesLast5 = () => {
        switch (this.storeType) {
            case 'store-lg': return 88;
            case 'store-md': return 79;
            case 'store-sm': return 77;
        }
        return 0;
    }


    statistics: Stats | undefined = undefined;

    loadStatistics = async () => {
        let stats = await agent.Statistics.general();
        runInAction(() => {
            this.statistics = stats;
        })
    }
}