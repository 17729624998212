import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
export default observer(function SaleAndBasketSettings() {
    const { modalStore: {show: ShowModalDemoWarning} } = useStore();
    return (
        <>
            {/*begin::Connected Accounts*/}
            <div className="card card-flush h-xl-100">
                {/*begin::Card header*/}
                <div className="card-header border-0">
                    <div className="card-title m-0">
                        <h3 className="fw-bolder m-0">Sprzedaż i koszyki</h3>
                    </div>
                </div>
                {/*end::Card header*/}
                {/*begin::Content*/}
                <div>
                    {/*begin::Card body*/}
                    <div className="card-body border-top p-9">
                        <div className="fv-row">
                            <div className="mb-10">
                                {/*begin::Heading*/}
                                <div className="mb-3">
                                    {/*begin::Label*/}
                                    <label className="d-flex align-items-center fs-5 fw-bold">
                                        <span>Pozwalaj na sprzedaż po transmisji</span>
                                    </label>
                                    {/*end::Label*/}
                                    {/*begin::Description*/}
                                    <div className="fs-7 fw-bold text-muted">Po zakończonej transmisji użytkownicy mogą dalej wpisywać kody, tworzyć koszyki i zamówienia.
                                        Wszystkie takie zamówienia zobaczysz na liście zamówień</div>
                                    {/*end::Description*/}
                                </div>
                                {/*end::Heading*/}
                                {/*begin::Row*/}
                                <div className="fv-row">
                                    {/*begin::Radio group*/}
                                    <div className="btn-group w-100">
                                        {/*begin::Radio*/}
                                        <label className="btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-danger" data-kt-button="true" onClick={() => ShowModalDemoWarning()}>
                                            {/*begin::Input*/}
                                            <input className="btn-check" type="radio" name="method" defaultValue="1" />
                                            {/*end::Input*/}
                                            NIE</label>
                                        {/*end::Radio*/}
                                        {/*begin::Radio*/}
                                        <label className="btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-primary active" data-kt-button="true" onClick={() => ShowModalDemoWarning()}>
                                            {/*begin::Input*/}
                                            <input className="btn-check" type="radio" name="method" defaultValue="2" />
                                            {/*end::Input*/}
                                            TAK</label>
                                        {/*end::Radio*/}
                                    </div>
                                    {/*end::Radio group*/}
                                </div>
                                {/*end::Row*/}
                            </div>
                            {/*end::Input group*/}
                        </div>
                        <div className="fv-row">
                            <div className="mb-10">
                                {/*begin::Heading*/}
                                <div className="mb-3">
                                    {/*begin::Label*/}
                                    <label className="d-flex align-items-center fs-5 fw-bold">
                                        <span>Dodanie do koszyka blokuje towar</span>
                                    </label>
                                    {/*end::Label*/}
                                    {/*begin::Description*/}
                                    <div className="fs-7 fw-bold text-muted">Ustaw czy dodanie do koszyka blokuje towar czy dopiero płatność za towar go blokuje.</div>
                                    {/*end::Description*/}
                                </div>
                                {/*end::Heading*/}
                                {/*begin::Row*/}
                                <div className="fv-row">
                                    {/*begin::Radio group*/}
                                    <div className="btn-group w-100">
                                        {/*begin::Radio*/}
                                        <label className="btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-primary active" data-kt-button="true" onClick={() => ShowModalDemoWarning()}>
                                            {/*begin::Input*/}
                                            <input className="btn-check" type="radio" name="method" defaultValue="1" />
                                            {/*end::Input*/}
                                            Koszyk blokuje towar</label>
                                        {/*end::Radio*/}
                                        {/*begin::Radio*/}
                                        <label className="btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-primary" data-kt-button="true" onClick={() => ShowModalDemoWarning()}>
                                            {/*begin::Input*/}
                                            <input className="btn-check" type="radio" name="method" defaultValue="2" />
                                            {/*end::Input*/}
                                            Płatność blokuje towar</label>
                                        {/*end::Radio*/}
                                    </div>
                                    {/*end::Radio group*/}
                                </div>
                                {/*end::Row*/}
                            </div>
                            {/*end::Input group*/}
                        </div>
                        <div className="fv-row">
                            <div className="mb-10">
                                {/*begin::Heading*/}
                                <div className="mb-3">
                                    {/*begin::Label*/}
                                    <label className="d-flex align-items-center fs-5 fw-bold">
                                        <span>Automatycznie usuwaj stare koszyki</span>
                                    </label>
                                    {/*end::Label*/}
                                    {/*begin::Description*/}
                                    <div className="fs-7 fw-bold text-muted">Koszyki, które leżą dłuższy czas mogą blokować towar. Możesz włączyć ich automatyczne usuwanie po X dniach. Jeśli wyłączysz tą opcję
                                        możesz również kasować koszyki w zakładce "Koszyki"</div>
                                    {/*end::Description*/}
                                </div>
                                {/*end::Heading*/}
                                {/*begin::Row*/}
                                <div className="fv-row">
                                    {/*begin::Radio group*/}
                                    <div className="btn-group w-100">
                                        {/*begin::Radio*/}
                                        <label className="btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-danger" data-kt-button="true" onClick={() => ShowModalDemoWarning()}>
                                            {/*begin::Input*/}
                                            <input className="btn-check" type="radio" name="method" defaultValue="1" />
                                            {/*end::Input*/}
                                            NIE</label>
                                        {/*end::Radio*/}
                                        {/*begin::Radio*/}
                                        <label className="btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-primary" data-kt-button="true" onClick={() => ShowModalDemoWarning()}>
                                            {/*begin::Input*/}
                                            <input className="btn-check" type="radio" name="method" defaultValue="2" />
                                            {/*end::Input*/}
                                            Po 3 dniach</label>
                                        {/*end::Radio*/}
                                        {/*begin::Radio*/}
                                        <label className="btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-primary" data-kt-button="true" onClick={() => ShowModalDemoWarning()}>
                                            {/*begin::Input*/}
                                            <input className="btn-check" type="radio" name="method" defaultValue="3" />
                                            {/*end::Input*/}
                                            Po 7 dniach</label>
                                        {/*end::Radio*/}
                                        {/*begin::Radio*/}
                                        <label className="btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-primary active" data-kt-button="true" onClick={() => ShowModalDemoWarning()}>
                                            {/*begin::Input*/}
                                            <input className="btn-check" type="radio" name="method" defaultValue="4" />
                                            {/*end::Input*/}
                                            Po 14 dniach</label>
                                        {/*end::Radio*/}
                                    </div>
                                    {/*end::Radio group*/}
                                </div>
                                {/*end::Row*/}
                            </div>
                            {/*end::Input group*/}
                        </div>
                        <div className="fv-row">
                            <div className="">
                                {/*begin::Heading*/}
                                <div className="mb-3">
                                    {/*begin::Label*/}
                                    <label className="d-flex align-items-center fs-5 fw-bold">
                                        <span>Maksymala ilość przedmiotów w koszyku</span>
                                    </label>
                                    {/*end::Label*/}
                                    {/*begin::Description*/}
                                    <div className="fs-7 fw-bold text-muted">Ta opcja zabezpiecza Cię przed złośliwym dodawaniem duzej ilości rzeczy do koszyka i blokowaniem towaru
                                        przez użytkowników, którzy i tak ich potem nie realizują.</div>
                                    {/*end::Description*/}
                                </div>
                                {/*end::Heading*/}
                                {/*begin::Row*/}
                                <div className="fv-row">
                                    {/*begin::Radio group*/}
                                    <div className="btn-group w-100">                                       
                                        {/*begin::Radio*/}
                                        <label className="btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-primary" data-kt-button="true" onClick={() => ShowModalDemoWarning()}>
                                            {/*begin::Input*/}
                                            <input className="btn-check" type="radio" name="method" defaultValue="2" />
                                            {/*end::Input*/}
                                            10</label>
                                        {/*end::Radio*/}
                                        {/*begin::Radio*/}
                                        <label className="btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-primary" data-kt-button="true" onClick={() => ShowModalDemoWarning()}>
                                            {/*begin::Input*/}
                                            <input className="btn-check" type="radio" name="method" defaultValue="3" />
                                            {/*end::Input*/}
                                            20</label>
                                        {/*end::Radio*/}
                                        {/*begin::Radio*/}
                                        <label className="btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-primary active" data-kt-button="true" onClick={() => ShowModalDemoWarning()}>
                                            {/*begin::Input*/}
                                            <input className="btn-check" type="radio" name="method" defaultValue="4" />
                                            {/*end::Input*/}
                                            30</label>
                                        {/*end::Radio*/}
                                         {/*begin::Radio*/}
                                         <label className="btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-danger" data-kt-button="true" onClick={() => ShowModalDemoWarning()}>
                                            {/*begin::Input*/}
                                            <input className="btn-check" type="radio" name="method" defaultValue="1" />
                                            {/*end::Input*/}
                                            <i className="fa fa-infinity text-muted fs-2" /> Nieograniczone</label>
                                        {/*end::Radio*/}
                                    </div>
                                    {/*end::Radio group*/}
                                </div>
                                {/*end::Row*/}
                            </div>
                            {/*end::Input group*/}
                        </div>


                    </div>
                    {/*end::Card body*/}
                    {/*begin::Card footer*/}
                    {/* <div className="card-footer d-flex justify-content-end py-6 px-9">
                        <button className="btn btn-light btn-active-light-primary me-2">Discard</button>
                        <button className="btn btn-primary">Save Changes</button>
                    </div> */}
                    {/*end::Card footer*/}
                </div>
                {/*end::Content*/}
            </div>
            {/*end::Connected Accounts*/}
        </>
    )
})
