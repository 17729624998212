import React from 'react';
import { observer } from 'mobx-react-lite';
export default observer(function ChartTest4(){
    return(
        <>
    {/*begin::Row*/}
								<div className="row g-5 g-xl-8">
									{/*begin::Col*/}
									<div className="col-xl-4">
										{/*begin::Mixed Widget 5*/}
										<div className="card card-xxl-stretch mb-xl-8">
											{/*begin::Beader*/}
											<div className="card-header border-0 py-5">
												<h3 className="card-title align-items-start flex-column">
													<span className="card-label fw-bolder fs-3 mb-1">Trends</span>
													<span className="text-muted fw-bold fs-7">Latest trends</span>
												</h3>
												<div className="card-toolbar">
													{/*begin::Menu*/}
													<button type="button" className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
														{/*begin::Svg Icon | path: icons/duotune/general/gen024.svg*/}
														<span className="svg-icon svg-icon-2">
															<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
																<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
																	<rect x="5" y="5" width="5" height="5" rx="1" fill="#000000" />
																	<rect x="14" y="5" width="5" height="5" rx="1" fill="#000000" opacity="0.3" />
																	<rect x="5" y="14" width="5" height="5" rx="1" fill="#000000" opacity="0.3" />
																	<rect x="14" y="14" width="5" height="5" rx="1" fill="#000000" opacity="0.3" />
																</g>
															</svg>
														</span>
														{/*end::Svg Icon*/}
													</button>
													{/*begin::Menu 3*/}
													<div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3" data-kt-menu="true">
														{/*begin::Heading*/}
														<div className="menu-item px-3">
															<div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">Payments</div>
														</div>
														{/*end::Heading*/}
														{/*begin::Menu item*/}
														<div className="menu-item px-3">
															<a href="\" className="menu-link px-3">Create Invoice</a>
														</div>
														{/*end::Menu item*/}
														{/*begin::Menu item*/}
														<div className="menu-item px-3">
															<a href="\" className="menu-link flex-stack px-3">Create Payment
															<i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Specify a target name for future usage and reference"></i></a>
														</div>
														{/*end::Menu item*/}
														{/*begin::Menu item*/}
														<div className="menu-item px-3">
															<a href="\" className="menu-link px-3">Generate Bill</a>
														</div>
														{/*end::Menu item*/}
														{/*begin::Menu item*/}
														<div className="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-end">
															<a href="\" className="menu-link px-3">
																<span className="menu-title">Subscription</span>
																<span className="menu-arrow"></span>
															</a>
															{/*begin::Menu sub*/}
															<div className="menu-sub menu-sub-dropdown w-175px py-4">
																{/*begin::Menu item*/}
																<div className="menu-item px-3">
																	<a href="\" className="menu-link px-3">Plans</a>
																</div>
																{/*end::Menu item*/}
																{/*begin::Menu item*/}
																<div className="menu-item px-3">
																	<a href="\" className="menu-link px-3">Billing</a>
																</div>
																{/*end::Menu item*/}
																{/*begin::Menu item*/}
																<div className="menu-item px-3">
																	<a href="\" className="menu-link px-3">Statements</a>
																</div>
																{/*end::Menu item*/}
																{/*begin::Menu separator*/}
																<div className="separator my-2"></div>
																{/*end::Menu separator*/}
																{/*begin::Menu item*/}
																<div className="menu-item px-3">
																	<div className="menu-content px-3">
																		{/*begin::Switch*/}
																		<label className="form-check form-switch form-check-custom form-check-solid">
																			{/*begin::Input*/}
																			<input className="form-check-input w-30px h-20px" type="checkbox" defaultValue="1"  name="notifications" />
																			{/*end::Input*/}
																			{/*end::Label*/}
																			<span className="form-check-label text-muted fs-6">Recuring</span>
																			{/*end::Label*/}
																		</label>
																		{/*end::Switch*/}
																	</div>
																</div>
																{/*end::Menu item*/}
															</div>
															{/*end::Menu sub*/}
														</div>
														{/*end::Menu item*/}
														{/*begin::Menu item*/}
														<div className="menu-item px-3 my-1">
															<a href="\" className="menu-link px-3">Settings</a>
														</div>
														{/*end::Menu item*/}
													</div>
													{/*end::Menu 3*/}
													{/*end::Menu*/}
												</div>
											</div>
											{/*end::Header*/}
											{/*begin::Body*/}
											<div className="card-body d-flex flex-column">
												{/*begin::Chart*/}
												<div className="mixed-widget-5-chart card-rounded-top" data-kt-chart-color="success" style={{height: '150px'}}></div>
												{/*end::Chart*/}
												{/*begin::Items*/}
												<div className="mt-5">
													{/*begin::Item*/}
													<div className="d-flex flex-stack mb-5">
														{/*begin::Section*/}
														<div className="d-flex align-items-center me-2">
															{/*begin::Symbol*/}
															<div className="symbol symbol-50px me-3">
																<div className="symbol-label bg-light">
																	<img src="assets/media/svg/brand-logos/plurk.svg" className="h-50" alt="" />
																</div>
															</div>
															{/*end::Symbol*/}
															{/*begin::Title*/}
															<div>
																<a href="\" className="fs-6 text-gray-800 text-hover-primary fw-bolder">Top Authors</a>
																<div className="fs-7 text-muted fw-bold mt-1">Ricky Hunt, Sandra Trepp</div>
															</div>
															{/*end::Title*/}
														</div>
														{/*end::Section*/}
														{/*begin::Label*/}
														<div className="badge badge-light fw-bold py-4 px-3">+82$</div>
														{/*end::Label*/}
													</div>
													{/*end::Item*/}
													{/*begin::Item*/}
													<div className="d-flex flex-stack mb-5">
														{/*begin::Section*/}
														<div className="d-flex align-items-center me-2">
															{/*begin::Symbol*/}
															<div className="symbol symbol-50px me-3">
																<div className="symbol-label bg-light">
																	<img src="assets/media/svg/brand-logos/figma-1.svg" className="h-50" alt="" />
																</div>
															</div>
															{/*end::Symbol*/}
															{/*begin::Title*/}
															<div>
																<a href="\" className="fs-6 text-gray-800 text-hover-primary fw-bolder">Top Sales</a>
																<div className="fs-7 text-muted fw-bold mt-1">PitStop Emails</div>
															</div>
															{/*end::Title*/}
														</div>
														{/*end::Section*/}
														{/*begin::Label*/}
														<div className="badge badge-light fw-bold py-4 px-3">+82$</div>
														{/*end::Label*/}
													</div>
													{/*end::Item*/}
													{/*begin::Item*/}
													<div className="d-flex flex-stack">
														{/*begin::Section*/}
														<div className="d-flex align-items-center me-2">
															{/*begin::Symbol*/}
															<div className="symbol symbol-50px me-3">
																<div className="symbol-label bg-light">
																	<img src="assets/media/svg/brand-logos/vimeo.svg" className="h-50" alt="" />
																</div>
															</div>
															{/*end::Symbol*/}
															{/*begin::Title*/}
															<div className="py-1">
																<a href="\" className="fs-6 text-gray-800 text-hover-primary fw-bolder">Top Engagement</a>
																<div className="fs-7 text-muted fw-bold mt-1">KT.com</div>
															</div>
															{/*end::Title*/}
														</div>
														{/*end::Section*/}
														{/*begin::Label*/}
														<div className="badge badge-light fw-bold py-4 px-3">+82$</div>
														{/*end::Label*/}
													</div>
													{/*end::Item*/}
												</div>
												{/*end::Items*/}
											</div>
											{/*end::Body*/}
										</div>
										{/*end::Mixed Widget 5*/}
									</div>
									{/*end::Col*/}
									{/*begin::Col*/}
									<div className="col-xl-8">
										{/*begin::Tables Widget 5*/}
										<div className="card card-xxl-stretch mb-5 mb-xl-8">
											{/*begin::Header*/}
											<div className="card-header border-0 pt-5">
												<h3 className="card-title align-items-start flex-column">
													<span className="card-label fw-bolder fs-3 mb-1">Latest Products</span>
													<span className="text-muted mt-1 fw-bold fs-7">More than 400 new products</span>
												</h3>
												<div className="card-toolbar">
													<ul className="nav">
														<li className="nav-item">
															<a className="nav-link btn btn-sm btn-color-muted btn-active btn-active-secondary fw-bolder px-4 me-1 active" data-bs-toggle="tab" href="#kt_table_widget_5_tab_1">Month</a>
														</li>
														<li className="nav-item">
															<a className="nav-link btn btn-sm btn-color-muted btn-active btn-active-secondary fw-bolder px-4 me-1" data-bs-toggle="tab" href="#kt_table_widget_5_tab_2">Week</a>
														</li>
														<li className="nav-item">
															<a className="nav-link btn btn-sm btn-color-muted btn-active btn-active-secondary fw-bolder px-4" data-bs-toggle="tab" href="#kt_table_widget_5_tab_3">Day</a>
														</li>
													</ul>
												</div>
											</div>
											{/*end::Header*/}
											{/*begin::Body*/}
											<div className="card-body py-3">
												<div className="tab-content">
													{/*begin::Tap pane*/}
													<div className="tab-pane fade show active" id="kt_table_widget_5_tab_1">
														{/*begin::Table container*/}
														<div className="table-responsive">
															{/*begin::Table*/}
															<table className="table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4">
																{/*begin::Table head*/}
																<thead>
																	<tr className="border-0">
																		<th className="p-0 w-50px"></th>
																		<th className="p-0 min-w-150px"></th>
																		<th className="p-0 min-w-140px"></th>
																		<th className="p-0 min-w-110px"></th>
																		<th className="p-0 min-w-50px"></th>
																	</tr>
																</thead>
																{/*end::Table head*/}
																{/*begin::Table body*/}
																<tbody>
																	<tr>
																		<td>
																			<div className="symbol symbol-45px me-2">
																				<span className="symbol-label">
																					<img src="assets/media/svg/brand-logos/plurk.svg" className="h-50 align-self-center" alt="" />
																				</span>
																			</div>
																		</td>
																		<td>
																			<a href="\" className="text-dark fw-bolder text-hover-primary mb-1 fs-6">Brad Simmons</a>
																			<span className="text-muted fw-bold d-block">Movie Creator</span>
																		</td>
																		<td className="text-end text-muted fw-bold">React, HTML</td>
																		<td className="text-end">
																			<span className="badge badge-light-success">Approved</span>
																		</td>
																		<td className="text-end">
																			<a href="\" className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary">
																				{/*begin::Svg Icon | path: icons/duotune/arrows/arr064.svg*/}
																				<span className="svg-icon svg-icon-2">
																					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																						<rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="black" />
																						<path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="black" />
																					</svg>
																				</span>
																				{/*end::Svg Icon*/}
																			</a>
																		</td>
																	</tr>
																	<tr>
																		<td>
																			<div className="symbol symbol-45px me-2">
																				<span className="symbol-label">
																					<img src="assets/media/svg/brand-logos/telegram.svg" className="h-50 align-self-center" alt="" />
																				</span>
																			</div>
																		</td>
																		<td>
																			<a href="\" className="text-dark fw-bolder text-hover-primary mb-1 fs-6">Popular Authors</a>
																			<span className="text-muted fw-bold d-block">Most Successful</span>
																		</td>
																		<td className="text-end text-muted fw-bold">Python, MySQL</td>
																		<td className="text-end">
																			<span className="badge badge-light-warning">In Progress</span>
																		</td>
																		<td className="text-end">
																			<a href="\" className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary">
																				{/*begin::Svg Icon | path: icons/duotune/arrows/arr064.svg*/}
																				<span className="svg-icon svg-icon-2">
																					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																						<rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="black" />
																						<path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="black" />
																					</svg>
																				</span>
																				{/*end::Svg Icon*/}
																			</a>
																		</td>
																	</tr>
																	<tr>
																		<td>
																			<div className="symbol symbol-45px me-2">
																				<span className="symbol-label">
																					<img src="assets/media/svg/brand-logos/vimeo.svg" className="h-50 align-self-center" alt="" />
																				</span>
																			</div>
																		</td>
																		<td>
																			<a href="\" className="text-dark fw-bolder text-hover-primary mb-1 fs-6">New Users</a>
																			<span className="text-muted fw-bold d-block">Awesome Users</span>
																		</td>
																		<td className="text-end text-muted fw-bold">Laravel,Metronic</td>
																		<td className="text-end">
																			<span className="badge badge-light-primary">Success</span>
																		</td>
																		<td className="text-end">
																			<a href="\" className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary">
																				{/*begin::Svg Icon | path: icons/duotune/arrows/arr064.svg*/}
																				<span className="svg-icon svg-icon-2">
																					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																						<rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="black" />
																						<path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="black" />
																					</svg>
																				</span>
																				{/*end::Svg Icon*/}
																			</a>
																		</td>
																	</tr>
																	<tr>
																		<td>
																			<div className="symbol symbol-45px me-2">
																				<span className="symbol-label">
																					<img src="assets/media/svg/brand-logos/bebo.svg" className="h-50 align-self-center" alt="" />
																				</span>
																			</div>
																		</td>
																		<td>
																			<a href="\" className="text-dark fw-bolder text-hover-primary mb-1 fs-6">Active Customers</a>
																			<span className="text-muted fw-bold d-block">Movie Creator</span>
																		</td>
																		<td className="text-end text-muted fw-bold">AngularJS, C#</td>
																		<td className="text-end">
																			<span className="badge badge-light-danger">Rejected</span>
																		</td>
																		<td className="text-end">
																			<a href="\" className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary">
																				{/*begin::Svg Icon | path: icons/duotune/arrows/arr064.svg*/}
																				<span className="svg-icon svg-icon-2">
																					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																						<rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="black" />
																						<path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="black" />
																					</svg>
																				</span>
																				{/*end::Svg Icon*/}
																			</a>
																		</td>
																	</tr>
																	<tr>
																		<td>
																			<div className="symbol symbol-45px me-2">
																				<span className="symbol-label">
																					<img src="assets/media/svg/brand-logos/kickstarter.svg" className="h-50 align-self-center" alt="" />
																				</span>
																			</div>
																		</td>
																		<td>
																			<a href="\" className="text-dark fw-bolder text-hover-primary mb-1 fs-6">Bestseller Theme</a>
																			<span className="text-muted fw-bold d-block">Best Customers</span>
																		</td>
																		<td className="text-end text-muted fw-bold">ReactJS, Ruby</td>
																		<td className="text-end">
																			<span className="badge badge-light-warning">In Progress</span>
																		</td>
																		<td className="text-end">
																			<a href="\" className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary">
																				{/*begin::Svg Icon | path: icons/duotune/arrows/arr064.svg*/}
																				<span className="svg-icon svg-icon-2">
																					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																						<rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="black" />
																						<path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="black" />
																					</svg>
																				</span>
																				{/*end::Svg Icon*/}
																			</a>
																		</td>
																	</tr>
																</tbody>
																{/*end::Table body*/}
															</table>
														</div>
														{/*end::Table*/}
													</div>
													{/*end::Tap pane*/}
													{/*begin::Tap pane*/}
													<div className="tab-pane fade" id="kt_table_widget_5_tab_2">
														{/*begin::Table container*/}
														<div className="table-responsive">
															{/*begin::Table*/}
															<table className="table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4">
																{/*begin::Table head*/}
																<thead>
																	<tr className="border-0">
																		<th className="p-0 w-50px"></th>
																		<th className="p-0 min-w-150px"></th>
																		<th className="p-0 min-w-140px"></th>
																		<th className="p-0 min-w-110px"></th>
																		<th className="p-0 min-w-50px"></th>
																	</tr>
																</thead>
																{/*end::Table head*/}
																{/*begin::Table body*/}
																<tbody>
																	<tr>
																		<td>
																			<div className="symbol symbol-45px me-2">
																				<span className="symbol-label">
																					<img src="assets/media/svg/brand-logos/plurk.svg" className="h-50 align-self-center" alt="" />
																				</span>
																			</div>
																		</td>
																		<td>
																			<a href="\" className="text-dark fw-bolder text-hover-primary mb-1 fs-6">Brad Simmons</a>
																			<span className="text-muted fw-bold d-block">Movie Creator</span>
																		</td>
																		<td className="text-end text-muted fw-bold">React, HTML</td>
																		<td className="text-end">
																			<span className="badge badge-light-success">Approved</span>
																		</td>
																		<td className="text-end">
																			<a href="\" className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary">
																				{/*begin::Svg Icon | path: icons/duotune/arrows/arr064.svg*/}
																				<span className="svg-icon svg-icon-2">
																					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																						<rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="black" />
																						<path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="black" />
																					</svg>
																				</span>
																				{/*end::Svg Icon*/}
																			</a>
																		</td>
																	</tr>
																	<tr>
																		<td>
																			<div className="symbol symbol-45px me-2">
																				<span className="symbol-label">
																					<img src="assets/media/svg/brand-logos/telegram.svg" className="h-50 align-self-center" alt="" />
																				</span>
																			</div>
																		</td>
																		<td>
																			<a href="\" className="text-dark fw-bolder text-hover-primary mb-1 fs-6">Popular Authors</a>
																			<span className="text-muted fw-bold d-block">Most Successful</span>
																		</td>
																		<td className="text-end text-muted fw-bold">Python, MySQL</td>
																		<td className="text-end">
																			<span className="badge badge-light-warning">In Progress</span>
																		</td>
																		<td className="text-end">
																			<a href="\" className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary">
																				{/*begin::Svg Icon | path: icons/duotune/arrows/arr064.svg*/}
																				<span className="svg-icon svg-icon-2">
																					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																						<rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="black" />
																						<path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="black" />
																					</svg>
																				</span>
																				{/*end::Svg Icon*/}
																			</a>
																		</td>
																	</tr>
																	<tr>
																		<td>
																			<div className="symbol symbol-45px me-2">
																				<span className="symbol-label">
																					<img src="assets/media/svg/brand-logos/bebo.svg" className="h-50 align-self-center" alt="" />
																				</span>
																			</div>
																		</td>
																		<td>
																			<a href="\" className="text-dark fw-bolder text-hover-primary mb-1 fs-6">Active Customers</a>
																			<span className="text-muted fw-bold d-block">Movie Creator</span>
																		</td>
																		<td className="text-end text-muted fw-bold">AngularJS, C#</td>
																		<td className="text-end">
																			<span className="badge badge-light-danger">Rejected</span>
																		</td>
																		<td className="text-end">
																			<a href="\" className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary">
																				{/*begin::Svg Icon | path: icons/duotune/arrows/arr064.svg*/}
																				<span className="svg-icon svg-icon-2">
																					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																						<rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="black" />
																						<path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="black" />
																					</svg>
																				</span>
																				{/*end::Svg Icon*/}
																			</a>
																		</td>
																	</tr>
																</tbody>
																{/*end::Table body*/}
															</table>
														</div>
														{/*end::Table*/}
													</div>
													{/*end::Tap pane*/}
													{/*begin::Tap pane*/}
													<div className="tab-pane fade" id="kt_table_widget_5_tab_3">
														{/*begin::Table container*/}
														<div className="table-responsive">
															{/*begin::Table*/}
															<table className="table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4">
																{/*begin::Table head*/}
																<thead>
																	<tr className="border-0">
																		<th className="p-0 w-50px"></th>
																		<th className="p-0 min-w-150px"></th>
																		<th className="p-0 min-w-140px"></th>
																		<th className="p-0 min-w-110px"></th>
																		<th className="p-0 min-w-50px"></th>
																	</tr>
																</thead>
																{/*end::Table head*/}
																{/*begin::Table body*/}
																<tbody>
																	<tr>
																		<td>
																			<div className="symbol symbol-45px me-2">
																				<span className="symbol-label">
																					<img src="assets/media/svg/brand-logos/kickstarter.svg" className="h-50 align-self-center" alt="" />
																				</span>
																			</div>
																		</td>
																		<td>
																			<a href="\" className="text-dark fw-bolder text-hover-primary mb-1 fs-6">Bestseller Theme</a>
																			<span className="text-muted fw-bold d-block">Best Customers</span>
																		</td>
																		<td className="text-end text-muted fw-bold">ReactJS, Ruby</td>
																		<td className="text-end">
																			<span className="badge badge-light-warning">In Progress</span>
																		</td>
																		<td className="text-end">
																			<a href="\" className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary">
																				{/*begin::Svg Icon | path: icons/duotune/arrows/arr064.svg*/}
																				<span className="svg-icon svg-icon-2">
																					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																						<rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="black" />
																						<path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="black" />
																					</svg>
																				</span>
																				{/*end::Svg Icon*/}
																			</a>
																		</td>
																	</tr>
																	<tr>
																		<td>
																			<div className="symbol symbol-45px me-2">
																				<span className="symbol-label">
																					<img src="assets/media/svg/brand-logos/bebo.svg" className="h-50 align-self-center" alt="" />
																				</span>
																			</div>
																		</td>
																		<td>
																			<a href="\" className="text-dark fw-bolder text-hover-primary mb-1 fs-6">Active Customers</a>
																			<span className="text-muted fw-bold d-block">Movie Creator</span>
																		</td>
																		<td className="text-end text-muted fw-bold">AngularJS, C#</td>
																		<td className="text-end">
																			<span className="badge badge-light-danger">Rejected</span>
																		</td>
																		<td className="text-end">
																			<a href="\" className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary">
																				{/*begin::Svg Icon | path: icons/duotune/arrows/arr064.svg*/}
																				<span className="svg-icon svg-icon-2">
																					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																						<rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="black" />
																						<path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="black" />
																					</svg>
																				</span>
																				{/*end::Svg Icon*/}
																			</a>
																		</td>
																	</tr>
																	<tr>
																		<td>
																			<div className="symbol symbol-45px me-2">
																				<span className="symbol-label">
																					<img src="assets/media/svg/brand-logos/vimeo.svg" className="h-50 align-self-center" alt="" />
																				</span>
																			</div>
																		</td>
																		<td>
																			<a href="\" className="text-dark fw-bolder text-hover-primary mb-1 fs-6">New Users</a>
																			<span className="text-muted fw-bold d-block">Awesome Users</span>
																		</td>
																		<td className="text-end text-muted fw-bold">Laravel,Metronic</td>
																		<td className="text-end">
																			<span className="badge badge-light-primary">Success</span>
																		</td>
																		<td className="text-end">
																			<a href="\" className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary">
																				{/*begin::Svg Icon | path: icons/duotune/arrows/arr064.svg*/}
																				<span className="svg-icon svg-icon-2">
																					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																						<rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="black" />
																						<path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="black" />
																					</svg>
																				</span>
																				{/*end::Svg Icon*/}
																			</a>
																		</td>
																	</tr>
																	<tr>
																		<td>
																			<div className="symbol symbol-45px me-2">
																				<span className="symbol-label">
																					<img src="assets/media/svg/brand-logos/telegram.svg" className="h-50 align-self-center" alt="" />
																				</span>
																			</div>
																		</td>
																		<td>
																			<a href="\" className="text-dark fw-bolder text-hover-primary mb-1 fs-6">Popular Authors</a>
																			<span className="text-muted fw-bold d-block">Most Successful</span>
																		</td>
																		<td className="text-end text-muted fw-bold">Python, MySQL</td>
																		<td className="text-end">
																			<span className="badge badge-light-warning">In Progress</span>
																		</td>
																		<td className="text-end">
																			<a href="\" className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary">
																				{/*begin::Svg Icon | path: icons/duotune/arrows/arr064.svg*/}
																				<span className="svg-icon svg-icon-2">
																					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																						<rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="black" />
																						<path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="black" />
																					</svg>
																				</span>
																				{/*end::Svg Icon*/}
																			</a>
																		</td>
																	</tr>
																</tbody>
																{/*end::Table body*/}
															</table>
														</div>
														{/*end::Table*/}
													</div>
													{/*end::Tap pane*/}
												</div>
											</div>
											{/*end::Body*/}
										</div>
										{/*end::Tables Widget 5*/}
									</div>
									{/*end::Col*/}
								</div>
								{/*end::Row*/}
        </>
    )
})
