import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Invoice } from "../models/models";
import FilteringAndPaginationController, { StoreWithLoadItems } from "../common/filteringAndPaginationController";

export default class InvoiceStore implements StoreWithLoadItems{
    registry = new Map<string, Invoice>();
    selectedItem: Invoice | undefined = undefined;
    filteringAndPaginationController = new FilteringAndPaginationController(this);

    constructor() {
        makeAutoObservable(this);
    }

    loadItems = async () => {
        const result = await agent.Invoices.list(this.filteringAndPaginationController.axiosParams);
        this.filteringAndPaginationController.setPagination(result.pagination);
        runInAction(() => {
            this.registry.clear();
            result.data.forEach(b => {
                this.registry.set(b.id, b);
            });
        })
    }

    loadItem = async (id: string) => {
        this.selectedItem = await agent.Invoices.details(id);
        return this.selectedItem;
    }
    
    markAsPaid = async(id: string) => {
        await agent.Invoices.markAsPaid(id);
    }
    
    get items() {
        return Array.from(this.registry.values());
    }
}