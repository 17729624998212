import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
export default observer(function PaymentsWidget() {
    const { modalStore: {show: ShowModalDemoWarning} } = useStore();
    return (
        <>
            {/*begin::Connected Accounts*/}
            <div className="card card-flush h-xl-100">
                {/*begin::Card header*/}
                <div className="card-header border-0">
                    <div className="card-title m-0">
                        <h3 className="fw-bolder m-0">Metody płatności</h3>
                    </div>
                </div>
                {/*end::Card header*/}
                {/*begin::Content*/}
                <div id="kt_account_settings_connected_accounts" className="collapse show">
                    {/*begin::Card body*/}
                    <div className="card-body border-top p-9">
                        {/*begin::Notice*/}
                        <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6">
                            {/*begin::Icon*/}
                            {/*begin::Svg Icon | path: icons/duotune/art/art006.svg*/}
                            <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path opacity="0.3" d="M15.8 11.4H6C5.4 11.4 5 11 5 10.4C5 9.80002 5.4 9.40002 6 9.40002H15.8C16.4 9.40002 16.8 9.80002 16.8 10.4C16.8 11 16.3 11.4 15.8 11.4ZM15.7 13.7999C15.7 13.1999 15.3 12.7999 14.7 12.7999H6C5.4 12.7999 5 13.1999 5 13.7999C5 14.3999 5.4 14.7999 6 14.7999H14.8C15.3 14.7999 15.7 14.2999 15.7 13.7999Z" fill="black" />
                                    <path d="M18.8 15.5C18.9 15.7 19 15.9 19.1 16.1C19.2 16.7 18.7 17.2 18.4 17.6C17.9 18.1 17.3 18.4999 16.6 18.7999C15.9 19.0999 15 19.2999 14.1 19.2999C13.4 19.2999 12.7 19.2 12.1 19.1C11.5 19 11 18.7 10.5 18.5C10 18.2 9.60001 17.7999 9.20001 17.2999C8.80001 16.8999 8.49999 16.3999 8.29999 15.7999C8.09999 15.1999 7.80001 14.7 7.70001 14.1C7.60001 13.5 7.5 12.8 7.5 12.2C7.5 11.1 7.7 10.1 8 9.19995C8.3 8.29995 8.79999 7.60002 9.39999 6.90002C9.99999 6.30002 10.7 5.8 11.5 5.5C12.3 5.2 13.2 5 14.1 5C15.2 5 16.2 5.19995 17.1 5.69995C17.8 6.09995 18.7 6.6 18.8 7.5C18.8 7.9 18.6 8.29998 18.3 8.59998C18.2 8.69998 18.1 8.69993 18 8.79993C17.7 8.89993 17.4 8.79995 17.2 8.69995C16.7 8.49995 16.5 7.99995 16 7.69995C15.5 7.39995 14.9 7.19995 14.2 7.19995C13.1 7.19995 12.1 7.6 11.5 8.5C10.9 9.4 10.5 10.6 10.5 12.2C10.5 13.3 10.7 14.2 11 14.9C11.3 15.6 11.7 16.1 12.3 16.5C12.9 16.9 13.5 17 14.2 17C15 17 15.7 16.8 16.2 16.4C16.8 16 17.2 15.2 17.9 15.1C18 15 18.5 15.2 18.8 15.5Z" fill="black" />
                                </svg>
                            </span>
                            {/*end::Svg Icon*/}
                            {/*end::Icon*/}
                            {/*begin::Wrapper*/}
                            <div className="d-flex flex-stack flex-grow-1">
                                {/*begin::Content*/}
                                <div className="fw-bold">
                                    <div className="fs-6 text-gray-700">Jeśli wśród tych metod płatności nie ma takiej, której chcielibyście używać - dajcie nam znać. Chętnie ją dodamy!
                                    </div>
                                </div>
                                {/*end::Content*/}
                            </div>
                            {/*end::Wrapper*/}
                        </div>
                        {/*end::Notice*/}
                        {/*begin::Items*/}
                        <div className="py-2">
                            {/*begin::Item*/}
                            <div className="d-flex flex-stack">
                                <div className="d-flex">
                                    <img src="/partner-custom/logos/Przelewy24_logo.svg" className="w-75px me-6" alt="" />
                                    <div className="d-flex flex-column justify-content-center">
                                        <span className="fs-5 text-dark fw-bolder">Przelewy 24</span>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end">
                                    <div className="form-check form-check-solid form-switch">
                                        <input className="form-check-input w-45px h-30px" type="checkbox" readOnly checked onClick={() => ShowModalDemoWarning()} />
                                        <label className="form-check-label" ></label>
                                    </div>
                                </div>
                            </div>
                            {/*end::Item*/}
                            <div className="separator separator-dashed my-5"></div>
                            {/*begin::Item*/}
                            <div className="d-flex flex-stack">
                                <div className="d-flex">
                                    <img src="/partner-custom/logos/Blik_logo.svg" className="w-60px ms-3 me-8" alt="" />
                                    <div className="d-flex flex-column justify-content-center">
                                        <span className="fs-5 text-dark fw-bolder">Blik</span>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end">
                                    <div className="form-check form-check-solid form-switch">
                                        <input className="form-check-input w-45px h-30px" type="checkbox" readOnly checked onClick={() => ShowModalDemoWarning()} />
                                        <label className="form-check-label" ></label>
                                    </div>
                                </div>
                            </div>
                            {/*end::Item*/}
                            <div className="separator separator-dashed my-5"></div>
                            {/*begin::Item*/}
                            <div className="d-flex flex-stack">
                                <div className="d-flex">
                                    <span className="svg-icon svg-icon-3x svg-icon-primary w-75px ms-6 me-0 ">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M20 19.725V18.725C20 18.125 19.6 17.725 19 17.725H5C4.4 17.725 4 18.125 4 18.725V19.725H3C2.4 19.725 2 20.125 2 20.725V21.725H22V20.725C22 20.125 21.6 19.725 21 19.725H20Z" fill="black" />
                                            <path opacity="0.3" d="M22 6.725V7.725C22 8.325 21.6 8.725 21 8.725H18C18.6 8.725 19 9.125 19 9.725C19 10.325 18.6 10.725 18 10.725V15.725C18.6 15.725 19 16.125 19 16.725V17.725H15V16.725C15 16.125 15.4 15.725 16 15.725V10.725C15.4 10.725 15 10.325 15 9.725C15 9.125 15.4 8.725 16 8.725H13C13.6 8.725 14 9.125 14 9.725C14 10.325 13.6 10.725 13 10.725V15.725C13.6 15.725 14 16.125 14 16.725V17.725H10V16.725C10 16.125 10.4 15.725 11 15.725V10.725C10.4 10.725 10 10.325 10 9.725C10 9.125 10.4 8.725 11 8.725H8C8.6 8.725 9 9.125 9 9.725C9 10.325 8.6 10.725 8 10.725V15.725C8.6 15.725 9 16.125 9 16.725V17.725H5V16.725C5 16.125 5.4 15.725 6 15.725V10.725C5.4 10.725 5 10.325 5 9.725C5 9.125 5.4 8.725 6 8.725H3C2.4 8.725 2 8.325 2 7.725V6.725L11 2.225C11.6 1.925 12.4 1.925 13.1 2.225L22 6.725ZM12 3.725C11.2 3.725 10.5 4.425 10.5 5.225C10.5 6.025 11.2 6.725 12 6.725C12.8 6.725 13.5 6.025 13.5 5.225C13.5 4.425 12.8 3.725 12 3.725Z" fill="black" />
                                        </svg>
                                    </span>
                                    <div className="d-flex flex-column justify-content-center">
                                        <span className="fs-5 text-dark fw-bolder">Przelewy tradycyjne</span>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end">
                                    <div className="form-check form-check-solid form-switch">
                                        <input className="form-check-input w-45px h-30px" type="checkbox" readOnly checked onClick={() => ShowModalDemoWarning()} />
                                        <label className="form-check-label" ></label>
                                    </div>
                                </div>
                            </div>
                            {/*end::Item*/}
                            <div className="separator separator-dashed my-5"></div>
                            {/*begin::Item*/}
                            <div className="d-flex flex-stack">
                                <div className="d-flex">
                                    <span className="svg-icon svg-icon-3x svg-icon-danger w-75px ms-6 ">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 26 26" fill="none">
                                            <path opacity="0.3" d="M18 10V20C18 20.6 18.4 21 19 21C19.6 21 20 20.6 20 20V10H18Z" fill="black" />
                                            <path opacity="0.3" d="M11 10V17H6V10H4V20C4 20.6 4.4 21 5 21H12C12.6 21 13 20.6 13 20V10H11Z" fill="black" />
                                            <path opacity="0.3" d="M10 10C10 11.1 9.1 12 8 12C6.9 12 6 11.1 6 10H10Z" fill="black" />
                                            <path opacity="0.3" d="M18 10C18 11.1 17.1 12 16 12C14.9 12 14 11.1 14 10H18Z" fill="black" />
                                            <path opacity="0.3" d="M14 4H10V10H14V4Z" fill="black" />
                                            <path opacity="0.3" d="M17 4H20L22 10H18L17 4Z" fill="black" />
                                            <path opacity="0.3" d="M7 4H4L2 10H6L7 4Z" fill="black" />
                                            <path d="M6 10C6 11.1 5.1 12 4 12C2.9 12 2 11.1 2 10H6ZM10 10C10 11.1 10.9 12 12 12C13.1 12 14 11.1 14 10H10ZM18 10C18 11.1 18.9 12 20 12C21.1 12 22 11.1 22 10H18ZM19 2H5C4.4 2 4 2.4 4 3V4H20V3C20 2.4 19.6 2 19 2ZM12 17C12 16.4 11.6 16 11 16H6C5.4 16 5 16.4 5 17C5 17.6 5.4 18 6 18H11C11.6 18 12 17.6 12 17Z" fill="black" />
                                        </svg>
                                    </span>
                                    <div className="d-flex flex-column justify-content-center">
                                        <span className="fs-5 text-dark fw-bolder">Płatność na miejscu</span>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end">
                                    <div className="form-check form-check-solid form-switch">
                                        <input className="form-check-input w-45px h-30px" type="checkbox" readOnly checked onClick={() => ShowModalDemoWarning()} />
                                        <label className="form-check-label" ></label>
                                    </div>
                                </div>
                            </div>
                            {/*end::Item*/}
                            <div className="separator separator-dashed my-5"></div>
                            {/*begin::Item*/}
                            <div className="d-flex flex-stack">
                                <div className="d-flex">
                                    <div className="d-flex flex-column">
                                        <span className="fs-5 text-dark fw-bolder">Doliczaj prowizję 2% dla przelewów automatycznych</span>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end">
                                    <div className="form-check form-check-solid form-switch">
                                        <input className="form-check-input w-45px h-30px" type="checkbox" readOnly checked onClick={() => ShowModalDemoWarning()} />
                                        <label className="form-check-label" ></label>
                                    </div>
                                </div>
                            </div>
                            {/*end::Item*/}
                        </div>
                        {/*end::Items*/}
                    </div>
                    {/*end::Card body*/}
                    {/*begin::Card footer*/}
                    {/* <div className="card-footer d-flex justify-content-end py-6 px-9">
                        <button className="btn btn-light btn-active-light-primary me-2">Discard</button>
                        <button className="btn btn-primary">Save Changes</button>
                    </div> */}
                    {/*end::Card footer*/}
                </div>
                {/*end::Content*/}
            </div>
            {/*end::Connected Accounts*/}
        </>
    )
})
