import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Box, BoxCalendar, BoxRent, BoxStatistics, BoxUpdate } from "../models/models";
import FilteringAndPaginationController, { StoreWithLoadItems } from "../common/filteringAndPaginationController";

export class BoxStore implements StoreWithLoadItems {
    registry = new Map<string, Box>();
    selectedBox: Box | undefined = undefined;
    selectedBoxStatistics: BoxStatistics | undefined = undefined;
    selectedBoxCalendar: BoxCalendar | undefined = undefined;
    filteringAndPaginationController = new FilteringAndPaginationController(this);

    constructor() {
        makeAutoObservable(this);
    }

    loadItems = async () => {
        const result = await agent.Boxes.list(this.filteringAndPaginationController.axiosParams);
        this.filteringAndPaginationController.setPagination(result.pagination);
        runInAction(() => {
            this.registry.clear();
            result.data.forEach(b => {
                this.registry.set(b.id, b);
            });
        })
    }

    loadItem = async (id: string) => {
        let box = await agent.Boxes.details(id);
        let stats = await agent.Boxes.stats(id);
        let calendar = await agent.Boxes.calendar(id);
        runInAction(() => {
            this.selectedBox = box;
            this.selectedBoxStatistics = stats;
            this.selectedBoxCalendar = calendar;
        })
        return this.selectedBox;
    }

    update = async (box: BoxUpdate) => {
        return agent.Boxes.update(box);
    }

    rent = async (boxRent: BoxRent) => {
        return agent.Boxes.rent(boxRent);
    }

    checkAvailabilty = async (id: string, from: Date, to: Date) => {
        return agent.Boxes.checkAvailability(id, from, to);
    }

    get items() {
        return Array.from(this.registry.values()).sort((a, b) => (a.number > b.number) ? 1 : ((b.number > a.number) ? -1 : 0));
    }
}