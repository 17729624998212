import React, { Suspense } from 'react';
import { Route } from 'react-router-dom';
import HomePage from '../../features/home/HomePage';
import CustomersPage, { CustomerMode } from '../../features/customers/CustomersPage';
import OrdersPage from '../../features/orders/OrdersPage';
import urls from '../common/urls';
import Aside from './Aside';
import Footer from './Footer';
import Header from './Header';
import BasketsPage from '../../features/baskets/BasketsPage';
import ProductsPage from '../../features/products/ProductsPage';
import HistoryPage from '../../features/history/HistoryPage';
import AllDash from '../../features/playground/AllDash';
import StatisticsPage from '../../features/statistics/StatisticsPage';
import SettingsPage from '../../features/settings/SettingsPage';
import BuildYourReachPage from '../../features/buildYourReach/BuildYourReachPage';
import LiveViewPage from '../../features/liveView/LiveViewPage';
import CustomerDetailsPage from '../../features/customers/customer-details/CustomerDetailsPage';
import OrderDetailsPage from '../../features/orders/order-details/OrderDetailsPage';
import ProductDetailsPage from '../../features/products/product-details/ProductDetailsPage';
import BasketDetailsPage from '../../features/baskets/basket-details/BasketDetailsPage';
import HistoryDetailsPage from '../../features/history/history-details/HistoryDetailsPage';
import { OrdersMode } from '../common/OrdersListWidget';
import ModalDemoWarning from '../elements/ModalDemoWarning';
import ModalReloadingStoreSize from '../elements/ModalReloadingStoreSize';

function App() {
  return (
    <Suspense fallback="loading">
      {/*begin::Page*/}
      <div className="page d-flex flex-row flex-column-fluid">
        <Aside />
        {/*begin::Wrapper*/}
        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
          <Header />
          {/*begin::Content*/}
          <div className="content d-flex flex-column flex-column-fluid" id="kt_content" style={{ paddingTop: '50px' }}>
            {/*begin::Post*/}
            <div className="post d-flex flex-column-fluid" id="kt_post">
              {/*begin::Container*/}
              <div id="kt_content_container" className="container-xxl">
                <Route exact path={urls.customers()} render={(props) => (<CustomersPage {...props} mode={CustomerMode.All} />)} />
                <Route exact path={urls.blackList()} render={(props) => (<CustomersPage {...props} mode={CustomerMode.Blacklist} />)} />
                <Route exact path={urls.orders()} render={(props) => (<OrdersPage {...props} mode={OrdersMode.All} />)} />
                <Route exact path={urls.openParcels()} render={(props) => (<OrdersPage {...props} mode={OrdersMode.OpenParcels} />)} />
                <Route exact path={urls.parcelsToSend()} render={(props) => (<OrdersPage {...props} mode={OrdersMode.ParcelsToSend} />)} />
                <Route exact path={urls.pickups()} render={(props) => (<OrdersPage {...props} mode={OrdersMode.Pickups} />)} />
                <Route exact path={urls.baskets()} component={BasketsPage} />
                <Route exact path={urls.products()} component={ProductsPage} />
                <Route exact path={urls.history()} component={HistoryPage} />
                <Route exact path={urls.dashboard()} component={HomePage} />
                <Route exact path={urls.root()} component={HomePage} />
                <Route exact path={urls.statistics()} component={StatisticsPage} />
                <Route exact path={urls.settings()} component={SettingsPage} />
                <Route exact path={urls.buildYourReach()} component={BuildYourReachPage} />
                <Route exact path={urls.liveView()} component={LiveViewPage} />
                <Route exact path={'/alldash'} component={AllDash} />

                <Route exact path={urls.customerTemplate()} component={CustomerDetailsPage} />
                <Route exact path={urls.orderTemplate()} component={OrderDetailsPage} />
                <Route exact path={urls.productTemplate()} component={ProductDetailsPage} />
                <Route exact path={urls.basketTemplate()} component={BasketDetailsPage} />
                <Route exact path={urls.historyTemplate()} component={HistoryDetailsPage} />
                <ModalDemoWarning />
                <ModalReloadingStoreSize />
              </div>
              {/*end::Container*/}
            </div>
            {/*end::Post*/}
          </div>
          {/*end::Content*/}
          <Footer />
        </div>
        {/*end::Wrapper*/}
      </div>
      {/*end::Page*/}
    </Suspense >
  );
}
export default App;
