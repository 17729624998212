import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
export default observer(function RemindersWidget() {
    const { modalStore: {show: ShowModalDemoWarning} } = useStore();
    return (
        <>
            {/*begin::Connected Accounts*/}
            <div className="card card-flush h-xl-100">
                {/*begin::Card header*/}
                <div className="card-header border-0">
                    <div className="card-title m-0">
                        <h3 className="fw-bolder m-0">Przypomnienia i notyfikacje dla klientów</h3>
                    </div>
                </div>
                {/*end::Card header*/}
                {/*begin::Content*/}
                <div id="kt_account_settings_connected_accounts" className="collapse show">
                    {/*begin::Card body*/}
                    <div className="card-body border-top p-9">
                        {/*begin::Notice*/}
                        {/* <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6">
                            <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path opacity="0.3" d="M15.8 11.4H6C5.4 11.4 5 11 5 10.4C5 9.80002 5.4 9.40002 6 9.40002H15.8C16.4 9.40002 16.8 9.80002 16.8 10.4C16.8 11 16.3 11.4 15.8 11.4ZM15.7 13.7999C15.7 13.1999 15.3 12.7999 14.7 12.7999H6C5.4 12.7999 5 13.1999 5 13.7999C5 14.3999 5.4 14.7999 6 14.7999H14.8C15.3 14.7999 15.7 14.2999 15.7 13.7999Z" fill="black" />
                                    <path d="M18.8 15.5C18.9 15.7 19 15.9 19.1 16.1C19.2 16.7 18.7 17.2 18.4 17.6C17.9 18.1 17.3 18.4999 16.6 18.7999C15.9 19.0999 15 19.2999 14.1 19.2999C13.4 19.2999 12.7 19.2 12.1 19.1C11.5 19 11 18.7 10.5 18.5C10 18.2 9.60001 17.7999 9.20001 17.2999C8.80001 16.8999 8.49999 16.3999 8.29999 15.7999C8.09999 15.1999 7.80001 14.7 7.70001 14.1C7.60001 13.5 7.5 12.8 7.5 12.2C7.5 11.1 7.7 10.1 8 9.19995C8.3 8.29995 8.79999 7.60002 9.39999 6.90002C9.99999 6.30002 10.7 5.8 11.5 5.5C12.3 5.2 13.2 5 14.1 5C15.2 5 16.2 5.19995 17.1 5.69995C17.8 6.09995 18.7 6.6 18.8 7.5C18.8 7.9 18.6 8.29998 18.3 8.59998C18.2 8.69998 18.1 8.69993 18 8.79993C17.7 8.89993 17.4 8.79995 17.2 8.69995C16.7 8.49995 16.5 7.99995 16 7.69995C15.5 7.39995 14.9 7.19995 14.2 7.19995C13.1 7.19995 12.1 7.6 11.5 8.5C10.9 9.4 10.5 10.6 10.5 12.2C10.5 13.3 10.7 14.2 11 14.9C11.3 15.6 11.7 16.1 12.3 16.5C12.9 16.9 13.5 17 14.2 17C15 17 15.7 16.8 16.2 16.4C16.8 16 17.2 15.2 17.9 15.1C18 15 18.5 15.2 18.8 15.5Z" fill="black" />
                                </svg>
                            </span>
                            <div className="d-flex flex-stack flex-grow-1">
                                <div className="fw-bold">
                                    <div className="fs-6 text-gray-700">Jeśli wśród tych metod płatności nie ma takiej, której chcielibyście używać - dajcie nam znać. Chętnie ją dodamy!
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/*end::Notice*/}
                        {/*begin::Items*/}
                        <div className="py-2">
                            {/*begin::Item*/}
                            <div className="d-flex flex-stack">
                                <div className="d-flex">
                                    <span className="symbol symbol-50px me-6">
                                        <span className="symbol-label bg-light-primary">
                                            <i className="fa fa-shopping-basket me-1 text-primary fs-2 ms-2"></i>
                                        </span>
                                    </span>
                                    <div className="d-flex flex-column">
                                        <span className="fs-5 text-dark fw-bolder">Automatycznie przypominaj użytkownikom o ich koszykach</span>
                                        <div className="fs-6 fw-bold text-gray-400">Klienci otrzymają powiadomienia o "zapomnianym" koszyku po 3 dniach od jego utworzenia</div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end ps-5">
                                    <div className="form-check form-check-solid form-switch">
                                        <input className="form-check-input w-45px h-30px" type="checkbox" readOnly checked onClick={() => ShowModalDemoWarning()} />
                                        <label className="form-check-label" ></label>
                                    </div>
                                </div>
                            </div>
                            {/*end::Item*/}
                            <div className="separator separator-dashed my-5"></div>
                            {/*begin::Item*/}
                            <div className="d-flex flex-stack">
                                <div className="d-flex">
                                    <span className="symbol symbol-50px me-6">
                                        <span className="symbol-label bg-light-primary">
                                            <i className="fa fa-store me-1 text-primary fs-2 ms-2"></i>
                                        </span>
                                    </span>
                                    <div className="d-flex flex-column">
                                        <span className="fs-5 text-dark fw-bolder">Automatycznie przypominaj użytkownikom o odbiorach osobistych</span>
                                        <div className="fs-6 fw-bold text-gray-400">Klienci otrzymają powiadomienia, że czeka na nich zamówienie w sklepie po 3 dniach od jego złożenia</div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end ps-5">
                                    <div className="form-check form-check-solid form-switch">
                                        <input className="form-check-input w-45px h-30px" type="checkbox" readOnly checked onClick={() => ShowModalDemoWarning()} />
                                        <label className="form-check-label" ></label>
                                    </div>
                                </div>
                            </div>
                            {/*end:Item */}
                            <div className="separator separator-dashed my-5"></div>
                            {/*begin::Item*/}
                            <div className="d-flex flex-stack">
                                <div className="d-flex">
                                    <span className="symbol symbol-50px me-6">
                                        <span className="symbol-label bg-light-primary">
                                            <i className="fa fa-money-check-alt me-1 text-primary fs-2 ms-2"></i>
                                        </span>
                                    </span>
                                    <div className="d-flex flex-column">
                                        <span className="fs-5 text-dark fw-bolder">Automatycznie przypominaj użytkownikom o oczekujących płatnościach</span>
                                        <div className="fs-6 fw-bold text-gray-400">Klienci otrzymają powiadomienia, że ich zamówienie czeka na płatność po 3 dniach (dotyczy tylko zwykłego przelewu tradycyjnego)</div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end ps-5">
                                    <div className="form-check form-check-solid form-switch">
                                        <input className="form-check-input w-45px h-30px" type="checkbox" readOnly checked onClick={() => ShowModalDemoWarning()} />
                                        <label className="form-check-label" ></label>
                                    </div>
                                </div>
                            </div>
                            {/*end:Item */}
                        </div>
                        {/*end::Items*/}
                    </div>
                    {/*end::Card body*/}
                    {/*begin::Card footer*/}
                    {/* <div className="card-footer d-flex justify-content-end py-6 px-9">
                        <button className="btn btn-light btn-active-light-primary me-2">Discard</button>
                        <button className="btn btn-primary">Save Changes</button>
                    </div> */}
                    {/*end::Card footer*/}
                </div>
                {/*end::Content*/}
            </div>
            {/*end::Connected Accounts*/}
        </>
    )
})
