import React from 'react';
import { observer } from 'mobx-react-lite';
import { Order } from '../models/models';

interface Props {
    order: Order
}

export default observer(function OrderStatus({ order }: Props) {
    switch (order.status) {
        case 'finished': return <><span className="badge badge-light-success fw-bolder px-4 py-3">Zrealizowano</span></>
        case 'open-parcel': return <><span className="badge badge-light-info fw-bolder px-4 py-3">Otwarta paczka</span></>
        case 'to-ship': return (<><span className="badge badge-light-danger fw-bolder px-4 py-3">Do wysłania</span></>);
        case 'to-pay': return (<><span className="badge badge-light-warning fw-bolder px-4 py-3">Czekamy na płatność</span></>);
        case 'shipped': return (<><span className="badge badge-light-success fw-bolder px-4 py-3">Paczka wysłana</span></>);
        case 'pickup': return (<><span className="badge badge-light-warning fw-bolder px-4 py-3">Odbiór osobisty</span></>);
        default: return (<>{order.status}</>)
    }
})