import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
export default observer(function NumbersWidget() {
	const { statisticsStore } = useStore();
	const simpleStats = statisticsStore.getSimpleStatsLastQuarter();
	return (
		<>
			<div className="card card-flush h-xl-100">
				{/*begin::Header*/}
				<div className="card-header pt-7">
					{/*begin::Title*/}
					<h3 className="card-title align-items-start flex-column">
						<span className="card-label fw-bolder text-gray-800">Sklep w liczbach</span>
						<span className="text-gray-400 mt-1 fw-bold fs-6">Ostatni kwartał</span>
					</h3>
					{/*end::Title*/}
				</div>
				{/*end::Header*/}
				{/*begin::Body*/}
				<div className="card-body">
					{/*begin::Item*/}
					<div className="d-flex flex-stack">
						{/*begin::Section*/}
						<div className="d-flex align-items-center me-5">
							{/*begin::Symbol*/}
							<div className="symbol symbol-30px me-5">
								<span className="symbol-label">
									{/*begin::Svg Icon | path: icons/duotune/general/gen002.svg*/}
									<span className="svg-icon svg-icon-3 svg-icon-gray-600">
										<i className="fa fa-shopping-basket me-1 text-primary fs-5 ms-2" />
									</span>
									{/*end::Svg Icon*/}
								</span>
							</div>
							{/*end::Symbol*/}
							{/*begin::Content*/}
							<div className="me-5">
								{/*begin::Title*/}
								<span className="text-gray-800 fw-bolder fs-6">Koszyk - wielkość</span>
								{/*end::Title*/}
								{/*begin::Desc*/}
								<span className="text-gray-400 fw-bold fs-7 d-block text-start ps-0">Średnia ilość rzeczy</span>
								{/*end::Desc*/}
							</div>
							{/*end::Content*/}
						</div>
						{/*end::Section*/}
						{/*begin::Wrapper*/}
						<div className="d-flex align-items-center">
							{/*begin::Number*/}
							<span className="text-gray-800 fw-bolder fs-6 me-3">{simpleStats.basketSize}</span>
							{/*end::Number*/}
							{/*begin::Info*/}
							<div className="d-flex flex-center">
								{/*begin::label*/}
								<span className="badge badge-success px-2">
									{/*begin::Svg Icon | path: icons/duotune/arrows/arr067.svg*/}
									<span className="svg-icon svg-icon-7 svg-icon-white ms-n1">
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
											<path opacity="0.5" d="M13 9.59998V21C13 21.6 12.6 22 12 22C11.4 22 11 21.6 11 21V9.59998H13Z" fill="black" />
											<path d="M5.7071 7.89291C5.07714 8.52288 5.52331 9.60002 6.41421 9.60002H17.5858C18.4767 9.60002 18.9229 8.52288 18.2929 7.89291L12.7 2.3C12.3 1.9 11.7 1.9 11.3 2.3L5.7071 7.89291Z" fill="black" />
										</svg>
									</span>
									{/*end::Svg Icon*/}{simpleStats.basketSizeChange} %</span>
								{/*end::label*/}
							</div>
							{/*end::Info*/}
						</div>
						{/*end::Wrapper*/}
					</div>
					{/*end::Item*/}
					{/*begin::Separator*/}
					<div className="separator separator-dashed my-4"></div>
					{/*end::Separator*/}
					{/*begin::Item*/}
					<div className="d-flex flex-stack">
						{/*begin::Section*/}
						<div className="d-flex align-items-center me-5">
							{/*begin::Symbol*/}
							<div className="symbol symbol-30px me-5">
								<span className="symbol-label">
									{/*begin::Svg Icon | path: icons/duotune/social/soc008.svg*/}
									<span className="svg-icon svg-icon-3 svg-icon-gray-600">
										<i className="fa fa-shopping-basket me-1 text-primary fs-5 ms-2" />
									</span>
									{/*end::Svg Icon*/}
								</span>
							</div>
							{/*end::Symbol*/}
							{/*begin::Content*/}
							<div className="me-5">
								{/*begin::Title*/}
								<span className="text-gray-800 fw-bolder fs-6">Koszyk - wartość</span>
								{/*end::Title*/}
								{/*begin::Desc*/}
								<span className="text-gray-400 fw-bold fs-7 d-block text-start ps-0">Średnia wartość</span>
								{/*end::Desc*/}
							</div>
							{/*end::Content*/}
						</div>
						{/*end::Section*/}
						{/*begin::Wrapper*/}
						<div className="d-flex align-items-center">
							{/*begin::Number*/}
							<span className="text-gray-800 fw-bolder fs-6 me-3">{simpleStats.basketValue}zł</span>
							{/*end::Number*/}
							{/*begin::Info*/}
							<div className="d-flex flex-center">
								{/*begin::label*/}
								<span className="badge badge-success px-2">
									{/*begin::Svg Icon | path: icons/duotune/arrows/arr067.svg*/}
									<span className="svg-icon svg-icon-7 svg-icon-white ms-n1">
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
											<path opacity="0.5" d="M13 9.59998V21C13 21.6 12.6 22 12 22C11.4 22 11 21.6 11 21V9.59998H13Z" fill="black" />
											<path d="M5.7071 7.89291C5.07714 8.52288 5.52331 9.60002 6.41421 9.60002H17.5858C18.4767 9.60002 18.9229 8.52288 18.2929 7.89291L12.7 2.3C12.3 1.9 11.7 1.9 11.3 2.3L5.7071 7.89291Z" fill="black" />
										</svg>
									</span>
									{/*end::Svg Icon*/}{simpleStats.basketValueChange} %</span>
								{/*end::label*/}
							</div>
							{/*end::Info*/}
						</div>
						{/*end::Wrapper*/}
					</div>
					{/*end::Item*/}
					{/*begin::Separator*/}
					<div className="separator separator-dashed my-4"></div>
					{/*end::Separator*/}
					{/*begin::Item*/}
					<div className="d-flex flex-stack">
						{/*begin::Section*/}
						<div className="d-flex align-items-center me-5">
							{/*begin::Symbol*/}
							<div className="symbol symbol-30px me-5">
								<span className="symbol-label">
									{/*begin::Svg Icon | path: icons/duotune/communication/com011.svg*/}
									<span className="svg-icon svg-icon-3 svg-icon-gray-600">
										<i className="fa fa-comment-dots me-1 text-primary fs-5 ms-2" />
									</span>
									{/*end::Svg Icon*/}
								</span>
							</div>
							{/*end::Symbol*/}
							{/*begin::Content*/}
							<div className="me-5">
								{/*begin::Title*/}
								<span className="text-gray-800 fw-bolder fs-6">Komentarze</span>
								{/*end::Title*/}
								{/*begin::Desc*/}
								<span className="text-gray-400 fw-bold fs-7 d-block text-start ps-0">Średnia ilość na live</span>
								{/*end::Desc*/}
							</div>
							{/*end::Content*/}
						</div>
						{/*end::Section*/}
						{/*begin::Wrapper*/}
						<div className="d-flex align-items-center">
							{/*begin::Number*/}
							<span className="text-gray-800 fw-bolder fs-6 me-3">{simpleStats.comments}</span>
							{/*end::Number*/}
							{/*begin::Info*/}
							<div className="d-flex flex-center">
								{/*begin::label*/}
								<span className="badge badge-success px-2">
									{/*begin::Svg Icon | path: icons/duotune/arrows/arr067.svg*/}
									<span className="svg-icon svg-icon-7 svg-icon-white ms-n1">
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
											<path opacity="0.5" d="M13 9.59998V21C13 21.6 12.6 22 12 22C11.4 22 11 21.6 11 21V9.59998H13Z" fill="black" />
											<path d="M5.7071 7.89291C5.07714 8.52288 5.52331 9.60002 6.41421 9.60002H17.5858C18.4767 9.60002 18.9229 8.52288 18.2929 7.89291L12.7 2.3C12.3 1.9 11.7 1.9 11.3 2.3L5.7071 7.89291Z" fill="black" />
										</svg>
									</span>
									{/*end::Svg Icon*/}{simpleStats.commentsChange} %</span>
								{/*end::label*/}
							</div>
							{/*end::Info*/}
						</div>
						{/*end::Wrapper*/}
					</div>
					{/*end::Item*/}
					{/*begin::Separator*/}
					<div className="separator separator-dashed my-4"></div>
					{/*end::Separator*/}
					{/*begin::Item*/}
					<div className="d-flex flex-stack">
						{/*begin::Section*/}
						<div className="d-flex align-items-center me-5">
							{/*begin::Symbol*/}
							<div className="symbol symbol-30px me-5">
								<span className="symbol-label">
									{/*begin::Svg Icon | path: icons/duotune/communication/com001.svg*/}
									<span className="svg-icon svg-icon-3 svg-icon-gray-600">
										<i className="fa fa-share me-1 text-primary fs-5 ms-2" />
									</span>
									{/*end::Svg Icon*/}
								</span>
							</div>
							{/*end::Symbol*/}
							{/*begin::Content*/}
							<div className="me-5">
								{/*begin::Title*/}
								<span className="text-gray-800 fw-bolder fs-6">Udostępnienia</span>
								{/*end::Title*/}
								{/*begin::Desc*/}
								<span className="text-gray-400 fw-bold fs-7 d-block text-start ps-0">Średnia ilość udost. live</span>
								{/*end::Desc*/}
							</div>
							{/*end::Content*/}
						</div>
						{/*end::Section*/}
						{/*begin::Wrapper*/}
						<div className="d-flex align-items-center">
							{/*begin::Number*/}
							<span className="text-gray-800 fw-bolder fs-6 me-3">{simpleStats.shares}</span>
							{/*end::Number*/}
							{/*begin::Info*/}
							<div className="d-flex flex-center">
								{/*begin::label*/}
								<span className="badge badge-danger px-2">
									{/*begin::Svg Icon | path: icons/duotune/arrows/arr068.svg*/}
									<span className="svg-icon svg-icon-7 svg-icon-white ms-n1">
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
											<path opacity="0.5" d="M13 14.4V3.00003C13 2.40003 12.6 2.00003 12 2.00003C11.4 2.00003 11 2.40003 11 3.00003V14.4H13Z" fill="black" />
											<path d="M5.7071 16.1071C5.07714 15.4771 5.52331 14.4 6.41421 14.4H17.5858C18.4767 14.4 18.9229 15.4771 18.2929 16.1071L12.7 21.7C12.3 22.1 11.7 22.1 11.3 21.7L5.7071 16.1071Z" fill="black" />
										</svg>
									</span>
									{/*end::Svg Icon*/}{simpleStats.sharesChange} %</span>
								{/*end::label*/}
							</div>
							{/*end::Info*/}
						</div>
						{/*end::Wrapper*/}
					</div>
					{/*end::Item*/}
					{/*begin::Separator*/}
					<div className="separator separator-dashed my-4"></div>
					{/*end::Separator*/}
					{/*begin::Item*/}
					<div className="d-flex flex-stack">
						{/*begin::Section*/}
						<div className="d-flex align-items-center me-5">
							{/*begin::Symbol*/}
							<div className="symbol symbol-30px me-5">
								<span className="symbol-label">
									{/*begin::Svg Icon | path: icons/duotune/abstract/abs026.svg*/}
									<span className="svg-icon svg-icon-3 svg-icon-gray-600">
										<i className="fa fa-truck me-1 text-primary fs-5 ms-2" />
									</span>
									{/*end::Svg Icon*/}
								</span>
							</div>
							{/*end::Symbol*/}
							{/*begin::Content*/}
							<div className="me-5">
								{/*begin::Title*/}
								<span className="text-gray-800 fw-bolder fs-6">Zamówienia</span>
								{/*end::Title*/}
								{/*begin::Desc*/}
								<span className="text-gray-400 fw-bold fs-7 d-block text-start ps-0">Średnia ilość zamówień na live</span>
								{/*end::Desc*/}
							</div>
							{/*end::Content*/}
						</div>
						{/*end::Section*/}
						{/*begin::Wrapper*/}
						<div className="d-flex align-items-center">
							{/*begin::Number*/}
							<span className="text-gray-800 fw-bolder fs-6 me-3">{simpleStats.orders}</span>
							{/*end::Number*/}
							{/*begin::Info*/}
							<div className="d-flex flex-center">
								{/*begin::label*/}
								<span className="badge badge-success px-2">
									{/*begin::Svg Icon | path: icons/duotune/arrows/arr067.svg*/}
									<span className="svg-icon svg-icon-7 svg-icon-white ms-n1">
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
											<path opacity="0.5" d="M13 9.59998V21C13 21.6 12.6 22 12 22C11.4 22 11 21.6 11 21V9.59998H13Z" fill="black" />
											<path d="M5.7071 7.89291C5.07714 8.52288 5.52331 9.60002 6.41421 9.60002H17.5858C18.4767 9.60002 18.9229 8.52288 18.2929 7.89291L12.7 2.3C12.3 1.9 11.7 1.9 11.3 2.3L5.7071 7.89291Z" fill="black" />
										</svg>
									</span>
									{/*end::Svg Icon*/}{simpleStats.ordersChange} %</span>
								{/*end::label*/}
							</div>
							{/*end::Info*/}
						</div>
						{/*end::Wrapper*/}
					</div>
					{/*end::Item*/}
				</div>
				{/*end::Body*/}
			</div>
		</>
	)
})
