import React from 'react';
import { observer } from 'mobx-react-lite';
import DeliveryWidget from './DeliveryWidget';
import PaymentsWidget from './PaymentsWidget';
import RemindersWidget from './RemindersWidget';
import BlackListWidget from './BlackListWidget';
import SaleAndBasketSettings from './SaleAndBasketSettings';

export default observer(function SettingsPage() {
    return (
        <>
            <div className="row g-5 g-xl-10 mb-xl-10" style={{ paddingTop: '50px' }}>
                <div className="col-lg-12 col-xl-6 col-xxl-6 mb-5 mb-xl-0">
                    <DeliveryWidget />
                </div>
                <div className="col-lg-12 col-xl-6 col-xxl-6 mb-5 mb-xl-0">
                    <PaymentsWidget />
                </div>
            </div>
            <div className="row g-5 g-xl-10 mb-xl-10">
                <div className="col-lg-12 col-xl-6 col-xxl-6 mb-5 mb-xl-0">
                    <RemindersWidget />
                </div>
                <div className="col-lg-12 col-xl-6 col-xxl-6 mb-5 mb-xl-0">
                    <BlackListWidget />
                </div>
            </div>
            <div className="row g-5 g-xl-10 mb-xl-10">
                <div className="col-lg-12 col-xl-6 col-xxl-6 mb-5 mb-xl-0">
                    <SaleAndBasketSettings />
                </div>
                <div className="col-lg-12 col-xl-6 col-xxl-8 mb-5 mb-xl-0">
                </div>
            </div>
        </>
    )
})