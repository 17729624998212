import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import SalesPlatformWidget from './SalesPlatformWidget';
import TopPaymentsWidget from './TopPaymentsWidget';
import TopDeliveryWidget from './TopDeliveryWidget';
import AverageSalesByDayWidget from './AverageSalesByDayWidget';
import SalesInDaysWidget from './SalesInDaysWidget';
import NumbersWidget from './NumbersWidget';
import PopularityInDaysWidget from './PopularityInDaysWidget';

export default observer(function StatisticsPage() {

    useEffect(() => {
        const script1 = loadScript('/partner-custom/widgets/SalesLastMonth.js');
        const script2 = loadScript('/partner-custom/widgets/CommentsLastXTransmissions.js');
        const script3 = loadScript('/partner-custom/widgets/SalesChannelsSummary.js');

        document.body.appendChild(script1);
        document.body.appendChild(script2);
        document.body.appendChild(script3);

        return () => {
            document.body.removeChild(script1);
            document.body.removeChild(script2);
            document.body.removeChild(script3);
        }
    }, []);

    const loadScript = (src: string) => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        return script;
    }


    return (
        <>
            <div className="row g-5 g-xl-10 mb-xl-10" style={{ paddingTop: '50px' }}>
                <div className="col-lg-12 col-xl-12 col-xxl-4 mb-5 mb-xl-0">
                    <SalesPlatformWidget />
                </div>
                <div className="col-lg-12 col-xl-12 col-xxl-4 mb-5 mb-xl-0">
                    <TopPaymentsWidget />
                </div>
                <div className="col-lg-12 col-xl-12 col-xxl-4 mb-5 mb-xl-0">
                    <TopDeliveryWidget />
                </div>
            </div>
            <div className="row g-5 g-xl-10 mb-xl-10">
                <div className="col-lg-12 col-xl-12 col-xxl-4 mb-5 mb-xl-0">
                    <AverageSalesByDayWidget />
                </div>
                <div className="col-lg-12 col-xl-12 col-xxl-8 mb-5 mb-xl-0">
                    <SalesInDaysWidget />
                    
                </div>               
            </div>
            <div className="row g-5 g-xl-10 mb-xl-10">
                <div className="col-lg-12 col-xl-12 col-xxl-4 mb-5 mb-xl-0">
                    <NumbersWidget />
                </div>
                <div className="col-lg-12 col-xl-12 col-xxl-8 mb-5 mb-xl-0">
                    <PopularityInDaysWidget />
                </div>               
            </div>
        </>
    )
})