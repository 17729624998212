import axios, { AxiosError, AxiosResponse } from 'axios'
import { Box, BoxStatistics, Contract, GeneralForBoxesStatistics, Invoice, Reservation, Customer, BoxRent, ReservationAdd, UserFormValues, User, BoxUpdate, PasswordUpdate, PasswordSet, ResetPassword, DiscountPlan, DiscountPlanCreateOrUpdate, BoxCalendar, BoxAvailabilityStatus, TypeOfPayment, Order, Basket, Product, HistoryItem, Stats } from '../models/models';
import { PaginatedResult } from '../models/pagination';
import { store } from '../stores/store';
import Result from './result';
import i18next from "i18next";

const responseBody = <T>(response: AxiosResponse<T>) => response.data;
const responseResult = (response: AxiosResponse<Result>) => response.data;

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(config => {
    const token = store.commonStore.token;
    const storeType = store.statisticsStore.storeType;
    if (storeType) {
        if (config.params === undefined) config.params = new URLSearchParams();
        config.params.append('tennantId', storeType);
    }
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    if (i18next.language) {
        config.headers["accept-language"] = i18next.language;
    }
    return config;
})

axios.interceptors.response.use(
    async response => {
        const pagination = response.headers['pagination'];
        if (pagination) {
            response.data = new PaginatedResult(response.data, JSON.parse(pagination));
            return response as AxiosResponse<PaginatedResult<any>>;
        }
        return response;
    },
    (error: AxiosError) => {
        return Promise.reject(error);
        // const { status } = error.response!;
        // switch (status) {
        //     case 400: return error.response as AxiosResponse<Result>;
        //     case 401: break;
        //     case 404: break;
        //     case 500: break;
        // }

    })

const requests = {
    get: <T>(url: string) => axios.get<T>(url).then(responseBody),
    post: <T>(url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
    put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
    delete: <T>(url: string) => axios.delete<T>(url).then(responseBody),
}

const Boxes = {
    list: (params: URLSearchParams) => axios.get<PaginatedResult<Box[]>>('/boxes', { params }).then(responseBody),
    details: (id: string) => requests.get<Box>(`/boxes/${id}`),
    stats: (id: string) => requests.get<BoxStatistics>(`/boxes/${id}/stats`),
    rent: (boxRent: BoxRent) => axios.post<Result>(`/boxes/${boxRent.boxId}/rent`, boxRent).then(responseResult),
    update: (box: BoxUpdate) => axios.post<Result>(`/boxes/${box.id}`, box).then(responseResult),
    calendar: (id: string) => requests.get<BoxCalendar>(`/boxes/${id}/calendar`),
    checkAvailability: (id: string, from: Date, to: Date) => axios.get<BoxAvailabilityStatus>(`/boxes/${id}/check-availability?from=${from.toISOString()}&to=${to.toISOString()}`),
}

const Contracts = {
    list: (params: URLSearchParams) => axios.get<PaginatedResult<Contract[]>>('/contracts', { params }).then(responseBody),
    details: (id: string) => requests.get<Contract>(`/contracts/${id}`),
    invoiceEntireRemainingPeriod: (id: string, typeOfPayment: TypeOfPayment) => requests.post(`/contracts/${id}/invoice-entire-remaining-period/${TypeOfPayment[typeOfPayment]}`, {}),
    invoiceNextUninvoicedPeriod: (id: string, typeOfPayment: TypeOfPayment) => requests.post(`/contracts/${id}/invoice-next-uninvoiced-period/${TypeOfPayment[typeOfPayment]}`, {}),
    delete: (id: string) => requests.delete(`/contracts/${id}`),
    finish: (id: string) => requests.post(`/contracts/${id}/finish`, {})
}

const Invoices = {
    list: (params: URLSearchParams) => axios.get<PaginatedResult<Invoice[]>>('/invoices', { params }).then(responseBody),
    details: (id: string) => requests.get<Invoice>(`/invoices/${id}`),
    markAsPaid: (id: string) => requests.post(`/invoices/${id}/mark-as-paid`, {})
}

const Customers = {
    list: (params: URLSearchParams) => axios.get<PaginatedResult<Customer[]>>('/customers', { params }).then(responseBody),
    details: (id: string) => requests.get<Customer>(`/customers/${id}`),
    //create: (customer: CustomerAddOrUpdate) => axios.post<Result>('/customers', customer).then(responseResult),
    //update: (customer: CustomerAddOrUpdate) => axios.put<Result>(`/customers/${customer.id}`, customer).then(responseResult)
}

const Orders = {
    list: (params: URLSearchParams) => axios.get<PaginatedResult<Order[]>>('/orders', { params }).then(responseBody),
    details: (id: string) => requests.get<Order>(`/orders/${id}`),
    //create: (customer: CustomerAddOrUpdate) => axios.post<Result>('/customers', customer).then(responseResult),
    //update: (customer: CustomerAddOrUpdate) => axios.put<Result>(`/customers/${customer.id}`, customer).then(responseResult)
}

const Baskets = {
    list: (params: URLSearchParams) => axios.get<PaginatedResult<Basket[]>>('/baskets', { params }).then(responseBody),
    details: (id: string) => requests.get<Basket>(`/baskets/${id}`),
    //create: (customer: CustomerAddOrUpdate) => axios.post<Result>('/customers', customer).then(responseResult),
    //update: (customer: CustomerAddOrUpdate) => axios.put<Result>(`/customers/${customer.id}`, customer).then(responseResult)
}

const Products = {
    list: (params: URLSearchParams) => axios.get<PaginatedResult<Product[]>>('/products', { params }).then(responseBody),
    details: (id: string) => requests.get<Product>(`/products/${id}`),
    //create: (customer: CustomerAddOrUpdate) => axios.post<Result>('/customers', customer).then(responseResult),
    //update: (customer: CustomerAddOrUpdate) => axios.put<Result>(`/customers/${customer.id}`, customer).then(responseResult)
}

const History = {
    list: (params: URLSearchParams) => axios.get<PaginatedResult<HistoryItem[]>>('/history', { params }).then(responseBody),
    details: (id: string) => requests.get<HistoryItem>(`/history/${id}`),
    //create: (customer: CustomerAddOrUpdate) => axios.post<Result>('/customers', customer).then(responseResult),
    //update: (customer: CustomerAddOrUpdate) => axios.put<Result>(`/customers/${customer.id}`, customer).then(responseResult)
}

const Reservations = {
    list: (params: URLSearchParams) => axios.get<PaginatedResult<Reservation[]>>('/reservations', { params }).then(responseBody),
    details: (id: string) => requests.get<Reservation>(`/reservations/${id}`),
    create: (reservation: ReservationAdd) => axios.post<Result>(`/reservations`, reservation).then(responseResult),
    delete: (id: string) => requests.delete(`/reservations/${id}`)
}

const Statistics = {
    generalForBoxes: () => requests.get<GeneralForBoxesStatistics>('/statistics'),
    general: () => requests.get<Stats>('/statistics'),
}

const Account = {
    current: () => requests.get<User>('/account'),
    login: (user: UserFormValues) => requests.post<User>('/account/login', user),
    register: (user: UserFormValues) => requests.post<User>('/account/register', user),
    changePassword: (passwordUpdate: PasswordUpdate) => axios.post<Result>('/account/change-password', passwordUpdate).then(responseResult),
    setNewPassword: (passwordSet: PasswordSet) => axios.post<Result>('/account/set-new-password', passwordSet).then(responseResult),
    resetPassword: (resetPassword: ResetPassword) => axios.post<Result>('/account/reset-password', resetPassword).then(responseResult),
}

const DiscountPlans = {
    list: (params: URLSearchParams) => axios.get<PaginatedResult<DiscountPlan[]>>('/discountplans', { params }).then(responseBody),
    details: (id: string) => requests.get<DiscountPlan>(`/discountplans/${id}`),
    create: (discountPlan: DiscountPlanCreateOrUpdate) => axios.post<Result>('/discountplans', discountPlan).then(responseResult),
    update: (discountPlan: DiscountPlanCreateOrUpdate) => axios.put<Result>(`/discountplans/${discountPlan.id}`, discountPlan).then(responseResult),
    activate: (id: string) => requests.post(`/discountplans/${id}/activate`, {}),
    deactivate: (id: string) => requests.post(`/discountplans/${id}/deactivate`, {})
}


const agent = {
    Boxes,
    Contracts,
    Invoices,
    Customers,
    Orders,
    Baskets,
    History,
    Products,
    Reservations,
    Statistics,
    Account,
    DiscountPlans
}

export default agent;