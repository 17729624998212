import React from 'react';
import { observer } from 'mobx-react-lite';
import FilteredQuestions from './FilteredQuestions';
import PurchasesTable from './PurchasesTable';

export default observer(function LiveViewPage() {
    return (
        <>
            <div className="row g-5 g-xl-10 mb-xl-10" style={{ paddingTop: '50px' }}>
                <div className="col-lg-12 col-xl-8 col-xxl-6 mb-5 mb-xl-0">
                    <PurchasesTable />
                </div>
                <div className="col-lg-12 col-xl-4 col-xxl-6 mb-5 mb-xl-0">
                    <FilteredQuestions />
                </div>
            </div>
            <div className="row g-5 g-xl-10 mb-xl-10">
                <div className="col-lg-12 col-xl-6 col-xxl-6 mb-5 mb-xl-0">
                </div>
                <div className="col-lg-12 col-xl-6 col-xxl-6 mb-5 mb-xl-0">
                </div>
            </div>
        </>
    )
})