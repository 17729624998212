import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { loadScript, unloadScript } from '../../app/common/helpers';
import { useStore } from '../../app/stores/store';
export default observer(function PopularityInDaysWidget() {
    const { statisticsStore } = useStore();

    useEffect(() => {
        const script1 = loadScript('/partner-custom/widgets/PopularityInDaysWidget.js');
        return () => {
            unloadScript(script1);
        }
    }, []);
    
    return (
        <>
            {/*begin::Charts Widget 1*/}
            <div className="card card-flush h-xl-100">
                {/*begin::Header*/}
                <div className="card-header border-0 pt-5">
                    {/*begin::Title*/}
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bolder fs-3 mb-1">Popularność kanału - ostatnie 12 miesięcy</span>
                        <span className="text-gray-400 mt-1 fw-bold fs-6">Łącznie {statisticsStore.getTotalComments()} komentarzy i {statisticsStore.getTotalShares()} udostępnień</span>
                    </h3>
                    {/*end::Title*/}
                    {/*begin::Toolbar*/}
                    <div className="card-toolbar">
                        {/*begin::Menu*/}
                      
                        {/*end::Menu*/}
                    </div>
                    {/*end::Toolbar*/}
                </div>
                {/*end::Header*/}
                {/*begin::Body*/}
                <div className="card-body">
                    {/*begin::Chart*/}
                    <div id="PopularityInDaysWidget" style={{ height: '300px' }} data-attr={statisticsStore.getPopularityInDaysWidget()}></div>
                    {/*end::Chart*/}
                </div>
                {/*end::Body*/}
            </div>
            {/*end::Charts Widget 1*/}
        </>
    )
})
