import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import urls from '../../app/common/urls';
import SourceToLogoImage from '../../app/elements/SourceToLogoImage';
import ToolTipWrapper, { ToolTipWrapperMode } from '../../app/elements/ToolTipWrapper';
import PaginationBar from '../../app/layout/PaginationBar';
import { HistoryItem } from '../../app/models/models';
import { PagingParams } from '../../app/models/pagination';
import { useStore } from '../../app/stores/store';

export default observer(function HistoryPage() {
    const { historyStore: store } = useStore();
    const { loadItems } = store;
    const { setPredicate, setPagingParams, pagination, clearPredicateAndPaging } = store.filteringAndPaginationController;

    function handleGetNext() {
        setPagingParams(new PagingParams(pagination!.currentPage + 1));
        loadItems();
    }

    function handleGetPrev() {
        setPagingParams(new PagingParams(pagination!.currentPage - 1));
        loadItems();
    }

    const handleSortChange = (event: any) => {
        setPredicate('sort', event.target.value);
    }

    const handleFromChange = (event: any) => {
        setPredicate('from', event.target.value);
    }

    const handleSearchChange = (event: any) => {
        const text = event.target.value;
        if (text === '') {
            setPredicate('search', event.target.value)
        }
        else if (text.length >= 3) {
            setPredicate('search', event.target.value)
        }
    }

    useEffect(() => {
        clearPredicateAndPaging();
        // switch (mode) {
        //     case OrdersMode.OpenParcels: setPredicate('filter', 'open-parcel'); break;
        //     case OrdersMode.ParcelsToSend: setPredicate('filter', 'to-ship'); break;
        // }
        store.loadItems();
    }, [store, clearPredicateAndPaging])

    const renderTitle = () => {
        // switch (mode) {
        //     case OrdersMode.OpenParcels: return 'Otwarte Paczki'
        //     case OrdersMode.ParcelsToSend: return 'Paczki do wysłania'
        // }
        return 'Historia sprzedaży'
    }

    const renderTotalRevenue = (value: HistoryItem) => {
        const text = `Na tym wydarzeniu Twój sklep zarobił łącznie ${value.totalRevenueReadable} zł`;
        if (value.isMostProfitable) {
            return <>
                <ToolTipWrapper notification={text} >
                    <span className="badge py-3 px-4 fs-7 badge-success">{value.totalRevenueReadable} zł
                    </span>
                </ToolTipWrapper>
            </>
        } else {
            return <>
                <ToolTipWrapper notification={text} >
                    <span className="py-3 px-4 fs-7 text-gray-700">{value.totalRevenueReadable} zł</span>
                </ToolTipWrapper>
            </>
        }
    }

    return (
        <>
            {/*begin::Table*/}
            <div className="card card-flush mt-6 mt-xl-9">
                {/*begin::Card header*/}
                <div className="card-header mt-5">
                    {/*begin::Card title*/}
                    <div className="card-title flex-column">
                        <h3 className="fw-bolder mb-1">{renderTitle()}</h3>
                        <div className="fs-6 text-gray-400">Łącznie {pagination?.totalItems}</div>
                    </div>
                    {/*begin::Card title*/}
                    {/*begin::Card toolbar*/}
                    <div className="card-toolbar my-1">
                        {/*begin::Select*/}
                        <div className="me-6 my-1">
                            <select name="sort" onChange={handleSortChange} className="w-275px form-select form-select-solid form-select-sm">
                                <option value="All" >Sortuj: Po dacie</option>
                                <option value="total-revenue-desc">Przychód: dochodowe</option>
                                <option value="total-revenue-asc">Przychód: słabe</option>
                                <option value="comments-desc">Komentarze: od bardzo popularnych</option>
                                <option value="comments-asc">Komentarze: od mało popularnych</option>
                                <option value="shares-desc">Udostępnienia: od często udostępnianych</option>
                                <option value="shares-asc">Udostępnienia: od mało udostępnianych</option>
                                <option value="date-desc">Data: od najnowszych</option>
                                <option value="date-asc">Data: od najstarszych</option>
                            </select>
                        </div>
                        {/*end::Select*/}
                        {/*begin::Select*/}
                        <div className="me-6 my-1">
                            <select name="from" onChange={handleFromChange} className="w-150px form-select form-select-solid form-select-sm">
                                <option value="All" >Wszystko</option>
                                <option value="facebook">Facebook</option>
                                <option value="tiktok">TikTok</option>
                                <option value="instagram">Instagam</option>
                            </select>
                        </div>
                        {/*end::Select*/}
                        {/*begin::Select*/}
                        {/* <div className="me-4 my-1">
                            <select name="filter" onChange={handleFilterChange} className="w-275px form-select form-select-solid form-select-sm">
                                <option value="all">Filtruj: Wszyscy</option>
                                <option value="finished">Dostarczono</option>
                                <option value="open-parcel">Otwarta Paczka</option>
                                <option value="to-ship">Do wysłania</option>
                                <option value="to-pay">Czekamy na płatność</option>
                                <option value="shipped">Paczka wysłana</option>
                                <option value="pickup">Odbiór osobisty</option>
                            </select>
                        </div> */}
                        {/*end::Select*/}
                        {/*begin::Search*/}
                        <div className="d-flex align-items-center position-relative my-1 me-4">
                            {/*begin::Svg Icon | path: icons/duotone/General/Search.svg*/}
                            <span className="svg-icon svg-icon-3 position-absolute ms-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <rect x="0" y="0" width="24" height="24" />
                                        <path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                        <path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z" fill="#000000" fillRule="nonzero" />
                                    </g>
                                </svg>
                            </span>
                            {/*end::Svg Icon*/}
                            <input type="text" onChange={handleSearchChange} className="form-control form-control-solid form-select-sm w-250px ps-9" placeholder={'Szukaj (minimum 3 litery)'} />
                        </div>
                        {/*end::Search*/}
                        {/* <div className="d-flex align-items-center position-relative my-1">
                            <button type="button" className="btn btn-primary btn-sm" onClick={() => handleNewCustomer()}>
                                <span className="svg-icon svg-icon-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <rect fill="#000000" x="4" y="11" width="16" height="2" rx="1"></rect>
                                        <rect fill="#000000" opacity="0.5" transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000)" x="4" y="11" width="16" height="2" rx="1"></rect>
                                    </svg>
                                </span>
                                {t('--Customers.Add customer')}</button>
                        </div> */}
                    </div>
                    {/*begin::Card toolbar*/}
                </div>
                {/*end::Card header*/}
                {/*begin::Card body*/}
                <div className="card-body pt-0">
                    {/*begin::Table container*/}
                    <div className="table-responsive">
                        {/*begin::Table*/}
                        <table className="table table-row-bordered table-row-dashed gy-3 align-middle fw-bolder">
                            {/*begin::Head*/}
                            <thead className="fs-7 text-gray-400 text-uppercase">
                                <tr>
                                    <th colSpan={2} className="min-w-50px">Rodzaj wydarzenia</th>
                                    <th className="min-w-50px">Data</th>
                                    <th className="min-w-50px">Przychód</th>
                                    <th colSpan={5} className="min-w-50px text-center">Statystyka</th>
                                    <th className="min-w-50px"></th>
                                </tr>
                            </thead>
                            {/*end::Head*/}
                            {/*begin::Body*/}
                            <tbody className="fs-6">
                                {store.items.map((i) => (
                                    <tr key={i.id}>
                                        <td className='w-50px'>
                                        <SourceToLogoImage source={i.source} big />
                                        </td>
                                        <td>
                                            {i.kind}
                                            {i.isMostProfitable &&
                                                <ToolTipWrapper notification={`To wydarzenie  należy do najbardziej dochodowych`} >
                                                    <i className="fa fa-rocket me-1 text-danger fs-5 ms-2" />
                                                </ToolTipWrapper>}
                                            {i.isMostPopular &&
                                                <ToolTipWrapper notification={`To wydarzenie nalezy do najbardziej komentowanych`} >
                                                    <i className="fa fa-comment-dots me-1 text-danger fs-5 ms-2" />
                                                </ToolTipWrapper>}
                                        </td>
                                        <td>
                                            {i.dateReadable}
                                        </td>
                                        <td>
                                            {renderTotalRevenue(i)}
                                        </td>
                                        <td className='text-center w-90px'>
                                            <ToolTipWrapper notification={`Sprzedanych przedmiotów: ${i.totalItemsSold}`} >
                                                {i.totalItemsSold} <i className="fa fa-box me-1 text-primary fs-5 ms-2" />
                                            </ToolTipWrapper>
                                        </td>
                                        <td className='text-center w-90px'>
                                            <ToolTipWrapper notification={`Liczba zamówień: ${i.orders}`} >
                                                {i.orders} <i className="fa fa-truck me-1 text-primary fs-5 ms-2" />
                                            </ToolTipWrapper>
                                        </td>
                                        <td className='text-center w-90px'>
                                            <ToolTipWrapper notification={`Pozostało koszyków: ${i.baskets}`} >
                                                {i.baskets} <i className="fa fa-shopping-basket me-1 text-primary fs-5 ms-2" />
                                            </ToolTipWrapper>
                                        </td>
                                        <td className='text-center w-90px'>
                                            <ToolTipWrapper notification={`Komentarzy: ${i.comments}`} >
                                                {i.comments} <i className="fa fa-comment-dots me-1 text-primary fs-5 ms-2" />
                                            </ToolTipWrapper>
                                        </td>
                                        <td className='text-center w-90px'>
                                            <ToolTipWrapper notification={`Udostępnień: ${i.shares}`} mode={ToolTipWrapperMode.Medium} >
                                                {i.shares} <i className="fa fa-share me-1 text-primary fs-5 ms-2" />
                                            </ToolTipWrapper>
                                        </td>
                                        <td className="text-end">
                                            <NavLink to={urls.historyDetails(i.id)} className="btn btn-light btn-sm me-3">Szczegóły</NavLink>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                            {/*end::Body*/}
                        </table>
                        {/*end::Table*/}
                    </div>
                    {/*end::Table container*/}
                    {pagination &&
                        <PaginationBar pagination={pagination!} handleGetNext={handleGetNext} handleGetPrev={handleGetPrev} />}
                </div>
                {/*end::Card body*/}
            </div>
            {/*end::Card*/}
        </>
    )
})