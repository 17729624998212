import { observer } from 'mobx-react-lite';
import React from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import ProductsListWidget, { ProductsMode } from '../../../app/common/ProductsListWidget';
import { useStore } from '../../../app/stores/store';
import BasketDetailsData from './BasketDetailsData';

export default observer(function BasketDetailsPage() {
    var { customerStore, productStore, basketStore } = useStore();
    const { id } = useParams<{ id: string }>();
    const { selectedItem } = basketStore;

    useEffect(() => {
        basketStore.loadItem(id);
        productStore.filteringAndPaginationController.clearPredicateAndPaging();
        productStore.filteringAndPaginationController.setPredicate('basketId', id);
    }, [
        id,
        customerStore.loadItem,
        productStore, productStore.filteringAndPaginationController.setPredicate,
        basketStore, basketStore.filteringAndPaginationController.setPredicate])

    return (
        <>
            {selectedItem &&
                <>
                    <div className="row g-5 g-xl-10 mb-xl-10" style={{ paddingTop: '50px' }}>
                        <div className="col-lg-12 col-xl-6 col-xxl-4 mb-5 mb-xl-0">
                            <BasketDetailsData basket={selectedItem!} />
                        </div>
                        <div className="col-lg-12 col-xl-6 col-xxl-8 mb-5 mb-xl-0">
                            <ProductsListWidget mode={ProductsMode.BasketWidget} />
                        </div>
                    </div>
                    <div className="row g-5 g-xl-10 mb-xl-10">
                        <div className="col-lg-12 col-xl-6 col-xxl-6 mb-5 mb-xl-0">
                        </div>
                        <div className="col-lg-12 col-xl-6 col-xxl-6 mb-5 mb-xl-0">
                        </div>
                    </div>
                    <div className="row g-5 g-xl-10 mb-xl-10">
                        <div className="col-lg-12 col-xl-6 col-xxl-6 mb-5 mb-xl-0">
                        </div>
                        <div className="col-lg-12 col-xl-6 col-xxl-8 mb-5 mb-xl-0">
                        </div>
                    </div>
                </>
            }
        </>
    )
})
