import React from 'react';
import { observer } from 'mobx-react-lite';
export default observer(function DashEcommerce() {
    return (
        <>
            {/*begin::Row*/}
            <div className="row g-5 g-xl-10 mb-xl-10">
                <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10">
                    {/*begin::Card widget 4*/}
                    <div className="card card-flush h-md-50 mb-5 mb-xl-10">
                        {/*begin::Header*/}
                        <div className="card-header pt-5">
                            {/*begin::Title*/}
                            <div className="card-title d-flex flex-column">
                                {/*begin::Info*/}
                                <div className="d-flex align-items-center">
                                    {/*begin::Currency*/}
                                    <span className="fs-4 fw-bold text-gray-400 me-1 align-self-start">$</span>
                                    {/*end::Currency*/}
                                    {/*begin::Amount*/}
                                    <span className="fs-2hx fw-bolder text-dark me-2 lh-1">69,700</span>
                                    {/*end::Amount*/}
                                    {/*begin::Badge*/}
                                    <span className="badge badge-success fs-6 lh-1 py-1 px-2 d-flex flex-center" style={{ height: '22px' }}>
                                        {/*begin::Svg Icon | path: icons/duotune/arrows/arr067.svg*/}
                                        <span className="svg-icon svg-icon-7 svg-icon-white ms-n1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path opacity="0.5" d="M13 9.59998V21C13 21.6 12.6 22 12 22C11.4 22 11 21.6 11 21V9.59998H13Z" fill="black" />
                                                <path d="M5.7071 7.89291C5.07714 8.52288 5.52331 9.60002 6.41421 9.60002H17.5858C18.4767 9.60002 18.9229 8.52288 18.2929 7.89291L12.7 2.3C12.3 1.9 11.7 1.9 11.3 2.3L5.7071 7.89291Z" fill="black" />
                                            </svg>
                                        </span>
                                        {/*end::Svg Icon*/}2.2%</span>
                                    {/*end::Badge*/}
                                </div>
                                {/*end::Info*/}
                                {/*begin::Subtitle*/}
                                <span className="text-gray-400 pt-1 fw-bold fs-6">Expected Earnings</span>
                                {/*end::Subtitle*/}
                            </div>
                            {/*end::Title*/}
                        </div>
                        {/*end::Header*/}
                        {/*begin::Card body*/}
                        <div className="card-body pt-2 pb-4 d-flex align-items-center">
                            {/*begin::Chart*/}
                            <div className="d-flex flex-center me-5 pt-2">
                                <div id="kt_card_widget_4_chart" style={{ minWidth: '70px', minHeight: '70px' }} data-kt-size="70" data-kt-line="11"></div>
                            </div>
                            {/*end::Chart*/}
                            {/*begin::Labels*/}
                            <div className="d-flex flex-column content-justify-center w-100">
                                {/*begin::Label*/}
                                <div className="d-flex fs-6 fw-bold align-items-center">
                                    {/*begin::Bullet*/}
                                    <div className="bullet w-8px h-6px rounded-2 bg-danger me-3"></div>
                                    {/*end::Bullet*/}
                                    {/*begin::Label*/}
                                    <div className="text-gray-500 flex-grow-1 me-4">Shoes</div>
                                    {/*end::Label*/}
                                    {/*begin::Stats*/}
                                    <div className="fw-boldest text-gray-700 text-xxl-end">$7,660</div>
                                    {/*end::Stats*/}
                                </div>
                                {/*end::Label*/}
                                {/*begin::Label*/}
                                <div className="d-flex fs-6 fw-bold align-items-center my-3">
                                    {/*begin::Bullet*/}
                                    <div className="bullet w-8px h-6px rounded-2 bg-primary me-3"></div>
                                    {/*end::Bullet*/}
                                    {/*begin::Label*/}
                                    <div className="text-gray-500 flex-grow-1 me-4">Gaming</div>
                                    {/*end::Label*/}
                                    {/*begin::Stats*/}
                                    <div className="fw-boldest text-gray-700 text-xxl-end">$2,820</div>
                                    {/*end::Stats*/}
                                </div>
                                {/*end::Label*/}
                                {/*begin::Label*/}
                                <div className="d-flex fs-6 fw-bold align-items-center">
                                    {/*begin::Bullet*/}
                                    <div className="bullet w-8px h-6px rounded-2 me-3" style={{ backgroundColor: '#E4E6EF' }}></div>
                                    {/*end::Bullet*/}
                                    {/*begin::Label*/}
                                    <div className="text-gray-500 flex-grow-1 me-4">Others</div>
                                    {/*end::Label*/}
                                    {/*begin::Stats*/}
                                    <div className="fw-boldest text-gray-700 text-xxl-end">$45,257</div>
                                    {/*end::Stats*/}
                                </div>
                                {/*end::Label*/}
                            </div>
                            {/*end::Labels*/}
                        </div>
                        {/*end::Card body*/}
                    </div>
                    {/*end::Card widget 4*/}
                    {/*begin::Card widget 5*/}
                    <div className="card card-flush h-md-50 mb-xl-10">
                        {/*begin::Header*/}
                        <div className="card-header pt-5">
                            {/*begin::Title*/}
                            <div className="card-title d-flex flex-column">
                                {/*begin::Info*/}
                                <div className="d-flex align-items-center">
                                    {/*begin::Amount*/}
                                    <span className="fs-2hx fw-bolder text-dark me-2 lh-1">1,836</span>
                                    {/*end::Amount*/}
                                    {/*begin::Badge*/}
                                    <span className="badge badge-danger fs-6 lh-1 py-1 px-2 d-flex flex-center" style={{ height: '22px' }}>
                                        {/*begin::Svg Icon | path: icons/duotune/arrows/arr068.svg*/}
                                        <span className="svg-icon svg-icon-7 svg-icon-white ms-n1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path opacity="0.5" d="M13 14.4V3.00003C13 2.40003 12.6 2.00003 12 2.00003C11.4 2.00003 11 2.40003 11 3.00003V14.4H13Z" fill="black" />
                                                <path d="M5.7071 16.1071C5.07714 15.4771 5.52331 14.4 6.41421 14.4H17.5858C18.4767 14.4 18.9229 15.4771 18.2929 16.1071L12.7 21.7C12.3 22.1 11.7 22.1 11.3 21.7L5.7071 16.1071Z" fill="black" />
                                            </svg>
                                        </span>
                                        {/*end::Svg Icon*/}2.2%</span>
                                    {/*end::Badge*/}
                                </div>
                                {/*end::Info*/}
                                {/*begin::Subtitle*/}
                                <span className="text-gray-400 pt-1 fw-bold fs-6">Orders This Month</span>
                                {/*end::Subtitle*/}
                            </div>
                            {/*end::Title*/}
                        </div>
                        {/*end::Header*/}
                        {/*begin::Card body*/}
                        <div className="card-body d-flex align-items-end pt-0">
                            {/*begin::Progress*/}
                            <div className="d-flex align-items-center flex-column mt-3 w-100">
                                <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                                    <span className="fw-boldest fs-6 text-dark">1,048 to Goal</span>
                                    <span className="fw-bolder fs-6 text-gray-400">62%</span>
                                </div>
                                <div className="h-8px mx-3 w-100 bg-light-success rounded">
                                    <div className="bg-success rounded h-8px" role="progressbar" style={{ width: '62%' }} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100}></div>
                                </div>
                            </div>
                            {/*end::Progress*/}
                        </div>
                        {/*end::Card body*/}
                    </div>
                    {/*end::Card widget 5*/}
                </div>
                <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10">
                    {/*begin::Card widget 6*/}
                    <div className="card card-flush h-md-50 mb-5 mb-xl-10">
                        {/*begin::Header*/}
                        <div className="card-header pt-5">
                            {/*begin::Title*/}
                            <div className="card-title d-flex flex-column">
                                {/*begin::Info*/}
                                <div className="d-flex align-items-center">
                                    {/*begin::Currency*/}
                                    <span className="fs-4 fw-bold text-gray-400 me-1 align-self-start">$</span>
                                    {/*end::Currency*/}
                                    {/*begin::Amount*/}
                                    <span className="fs-2hx fw-bolder text-dark me-2 lh-1">2,420</span>
                                    {/*end::Amount*/}
                                    {/*begin::Badge*/}
                                    <span className="badge badge-success fs-6 lh-1 py-1 px-2 d-flex flex-center" style={{ height: '22px' }}>
                                        {/*begin::Svg Icon | path: icons/duotune/arrows/arr067.svg*/}
                                        <span className="svg-icon svg-icon-7 svg-icon-white ms-n1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path opacity="0.5" d="M13 9.59998V21C13 21.6 12.6 22 12 22C11.4 22 11 21.6 11 21V9.59998H13Z" fill="black" />
                                                <path d="M5.7071 7.89291C5.07714 8.52288 5.52331 9.60002 6.41421 9.60002H17.5858C18.4767 9.60002 18.9229 8.52288 18.2929 7.89291L12.7 2.3C12.3 1.9 11.7 1.9 11.3 2.3L5.7071 7.89291Z" fill="black" />
                                            </svg>
                                        </span>
                                        {/*end::Svg Icon*/}2.6%</span>
                                    {/*end::Badge*/}
                                </div>
                                {/*end::Info*/}
                                {/*begin::Subtitle*/}
                                <span className="text-gray-400 pt-1 fw-bold fs-6">Average Daily Sales</span>
                                {/*end::Subtitle*/}
                            </div>
                            {/*end::Title*/}
                        </div>
                        {/*end::Header*/}
                        {/*begin::Card body*/}
                        <div className="card-body d-flex align-items-end px-0 pb-0">
                            {/*begin::Chart*/}
                            <div id="kt_card_widget_6_chart" className="w-100" style={{ height: '80px' }}></div>
                            {/*end::Chart*/}
                        </div>
                        {/*end::Card body*/}
                    </div>
                    {/*end::Card widget 6*/}
                    {/*begin::Card widget 7*/}
                    <div className="card card-flush h-md-50 mb-xl-10">
                        {/*begin::Header*/}
                        <div className="card-header pt-5">
                            {/*begin::Title*/}
                            <div className="card-title d-flex flex-column">
                                {/*begin::Amount*/}
                                <span className="fs-2hx fw-bolder text-dark me-2 lh-1">6.3k</span>
                                {/*end::Amount*/}
                                {/*begin::Subtitle*/}
                                <span className="text-gray-400 pt-1 fw-bold fs-6">New Customers This Month</span>
                                {/*end::Subtitle*/}
                            </div>
                            {/*end::Title*/}
                        </div>
                        {/*end::Header*/}
                        {/*begin::Card body*/}
                        <div className="card-body d-flex flex-column justify-content-end pe-0">
                            {/*begin::Title*/}
                            <span className="fs-6 fw-boldest text-gray-800 d-block mb-2">Today’s Heroes</span>
                            {/*end::Title*/}
                            {/*begin::Users group*/}
                            <div className="symbol-group symbol-hover flex-nowrap">
                                <div className="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Alan Warden">
                                    <span className="symbol-label bg-warning text-inverse-warning fw-bolder">A</span>
                                </div>
                                <div className="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Michael Eberon">
                                    <img alt="Pic" src="assets/media/avatars/300-11.jpg" />
                                </div>
                                <div className="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Susan Redwood">
                                    <span className="symbol-label bg-primary text-inverse-primary fw-bolder">S</span>
                                </div>
                                <div className="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Melody Macy">
                                    <img alt="Pic" src="assets/media/avatars/300-2.jpg" />
                                </div>
                                <div className="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Perry Matthew">
                                    <span className="symbol-label bg-danger text-inverse-danger fw-bolder">P</span>
                                </div>
                                <div className="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Barry Walter">
                                    <img alt="Pic" src="assets/media/avatars/300-12.jpg" />
                                </div>
                                <a href="\" className="symbol symbol-35px symbol-circle" data-bs-toggle="modal" data-bs-target="#kt_modal_view_users">
                                    <span className="symbol-label bg-light text-gray-400 fs-8 fw-bolder">+42</span>
                                </a>
                            </div>
                            {/*end::Users group*/}
                        </div>
                        {/*end::Card body*/}
                    </div>
                    {/*end::Card widget 7*/}
                </div>
                {/*begin::Col*/}
                <div className="col-lg-12 col-xl-12 col-xxl-6 mb-5 mb-xl-0">
                    {/*begin::Chart widget 3*/}
                    <div className="card card-flush overflow-hidden h-md-100">
                        {/*begin::Header*/}
                        <div className="card-header py-5">
                            {/*begin::Title*/}
                            <h3 className="card-title align-items-start flex-column">
                                <span className="card-label fw-bolder text-dark">Sales This Months</span>
                                <span className="text-gray-400 mt-1 fw-bold fs-6">Users from all channels</span>
                            </h3>
                            {/*end::Title*/}
                            {/*begin::Toolbar*/}
                            <div className="card-toolbar">
                                <a href="../../demo1/dist/apps/ecommerce/catalog/products.html" className="btn btn-sm btn-light-primary fw-bolder">Launch eCommerce App</a>
                            </div>
                            {/*end::Toolbar*/}
                        </div>
                        {/*end::Header*/}
                        {/*begin::Card body*/}
                        <div className="card-body d-flex justify-content-between flex-column pb-1 px-0">
                            {/*begin::Statistics*/}
                            <div className="px-9 mb-5">
                                {/*begin::Statistics*/}
                                <div className="d-flex mb-2">
                                    <span className="fs-4 fw-bold text-gray-400 me-1">$</span>
                                    <span className="fs-2hx fw-bolder text-gray-800 me-2 lh-1">14,094</span>
                                </div>
                                {/*end::Statistics*/}
                                {/*begin::Description*/}
                                <span className="fs-6 fw-bold text-gray-400">Another $48,346 to Goal</span>
                                {/*end::Description*/}
                            </div>
                            {/*end::Statistics*/}
                            {/*begin::Chart*/}
                            <div id="kt_charts_widget_3" className="min-h-auto ps-4 pe-6" style={{ height: '300px' }}></div>
                            {/*end::Chart*/}
                        </div>
                        {/*end::Card body*/}
                    </div>
                    {/*end::Chart widget 3*/}
                </div>
                {/*end::Col*/}
            </div>
            {/*end::Row*/}
            {/*begin::Row*/}
            <div className="row gy-5 g-xl-10">
                {/*begin::Col*/}
                <div className="col-xl-6 mb-xl-10">
                    {/*begin::Tables widget 2*/}
                    <div className="card h-md-100">
                        {/*begin::Header*/}
                        <div className="card-header align-items-center border-0">
                            {/*begin::Title*/}
                            <h3 className="fw-bolder text-gray-900 m-0">Recent Orders</h3>
                            {/*end::Title*/}
                            {/*begin::Menu*/}
                            <button className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-overflow="true">
                                {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
                                <span className="svg-icon svg-icon-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="4" fill="black" />
                                        <rect x="11" y="11" width="2.6" height="2.6" rx="1.3" fill="black" />
                                        <rect x="15" y="11" width="2.6" height="2.6" rx="1.3" fill="black" />
                                        <rect x="7" y="11" width="2.6" height="2.6" rx="1.3" fill="black" />
                                    </svg>
                                </span>
                                {/*end::Svg Icon*/}
                            </button>
                            {/*begin::Menu 2*/}
                            <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px" data-kt-menu="true">
                                {/*begin::Menu item*/}
                                <div className="menu-item px-3">
                                    <div className="menu-content fs-6 text-dark fw-bolder px-3 py-4">Quick Actions</div>
                                </div>
                                {/*end::Menu item*/}
                                {/*begin::Menu separator*/}
                                <div className="separator mb-3 opacity-75"></div>
                                {/*end::Menu separator*/}
                                {/*begin::Menu item*/}
                                <div className="menu-item px-3">
                                    <a href="\" className="menu-link px-3">New Ticket</a>
                                </div>
                                {/*end::Menu item*/}
                                {/*begin::Menu item*/}
                                <div className="menu-item px-3">
                                    <a href="\" className="menu-link px-3">New Customer</a>
                                </div>
                                {/*end::Menu item*/}
                                {/*begin::Menu item*/}
                                <div className="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-start">
                                    {/*begin::Menu item*/}
                                    <a href="\" className="menu-link px-3">
                                        <span className="menu-title">New Group</span>
                                        <span className="menu-arrow"></span>
                                    </a>
                                    {/*end::Menu item*/}
                                    {/*begin::Menu sub*/}
                                    <div className="menu-sub menu-sub-dropdown w-175px py-4">
                                        {/*begin::Menu item*/}
                                        <div className="menu-item px-3">
                                            <a href="\" className="menu-link px-3">Admin Group</a>
                                        </div>
                                        {/*end::Menu item*/}
                                        {/*begin::Menu item*/}
                                        <div className="menu-item px-3">
                                            <a href="\" className="menu-link px-3">Staff Group</a>
                                        </div>
                                        {/*end::Menu item*/}
                                        {/*begin::Menu item*/}
                                        <div className="menu-item px-3">
                                            <a href="\" className="menu-link px-3">Member Group</a>
                                        </div>
                                        {/*end::Menu item*/}
                                    </div>
                                    {/*end::Menu sub*/}
                                </div>
                                {/*end::Menu item*/}
                                {/*begin::Menu item*/}
                                <div className="menu-item px-3">
                                    <a href="\" className="menu-link px-3">New Contact</a>
                                </div>
                                {/*end::Menu item*/}
                                {/*begin::Menu separator*/}
                                <div className="separator mt-3 opacity-75"></div>
                                {/*end::Menu separator*/}
                                {/*begin::Menu item*/}
                                <div className="menu-item px-3">
                                    <div className="menu-content px-3 py-3">
                                        <a className="btn btn-primary btn-sm px-4" href="\">Generate Reports</a>
                                    </div>
                                </div>
                                {/*end::Menu item*/}
                            </div>
                            {/*end::Menu 2*/}
                            {/*end::Menu*/}
                        </div>
                        {/*end::Header*/}
                        {/*begin::Body*/}
                        <div className="card-body pt-2">
                            {/*begin::Nav*/}
                            <ul className="nav nav-pills nav-pills-custom mb-3">
                                {/*begin::Item*/}
                                <li className="nav-item mb-3 me-3 me-lg-6">
                                    {/*begin::Link*/}
                                    <a className="nav-link d-flex justify-content-between flex-column flex-center overflow-hidden active w-80px h-85px py-4" data-bs-toggle="pill" href="#kt_stats_widget_2_tab_1">
                                        {/*begin::Icon*/}
                                        <div className="nav-icon">
                                            <img alt="" src="assets/media/svg/products-categories/t-shirt.svg" className="" />
                                        </div>
                                        {/*end::Icon*/}
                                        {/*begin::Subtitle*/}
                                        <span className="nav-text text-gray-700 fw-bolder fs-6 lh-1">T-shirt</span>
                                        {/*end::Subtitle*/}
                                        {/*begin::Bullet*/}
                                        <span className="bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary"></span>
                                        {/*end::Bullet*/}
                                    </a>
                                    {/*end::Link*/}
                                </li>
                                {/*end::Item*/}
                                {/*begin::Item*/}
                                <li className="nav-item mb-3 me-3 me-lg-6">
                                    {/*begin::Link*/}
                                    <a className="nav-link d-flex justify-content-between flex-column flex-center overflow-hidden w-80px h-85px py-4" data-bs-toggle="pill" href="#kt_stats_widget_2_tab_2">
                                        {/*begin::Icon*/}
                                        <div className="nav-icon">
                                            <img alt="" src="assets/media/svg/products-categories/gaming.svg" className="" />
                                        </div>
                                        {/*end::Icon*/}
                                        {/*begin::Subtitle*/}
                                        <span className="nav-text text-gray-700 fw-bolder fs-6 lh-1">Gaming</span>
                                        {/*end::Subtitle*/}
                                        {/*begin::Bullet*/}
                                        <span className="bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary"></span>
                                        {/*end::Bullet*/}
                                    </a>
                                    {/*end::Link*/}
                                </li>
                                {/*end::Item*/}
                                {/*begin::Item*/}
                                <li className="nav-item mb-3 me-3 me-lg-6">
                                    {/*begin::Link*/}
                                    <a className="nav-link d-flex justify-content-between flex-column flex-center overflow-hidden w-80px h-85px py-4" data-bs-toggle="pill" href="#kt_stats_widget_2_tab_3">
                                        {/*begin::Icon*/}
                                        <div className="nav-icon">
                                            <img alt="" src="assets/media/svg/products-categories/watch.svg" className="" />
                                        </div>
                                        {/*end::Icon*/}
                                        {/*begin::Subtitle*/}
                                        <span className="nav-text text-gray-600 fw-bolder fs-6 lh-1">Watch</span>
                                        {/*end::Subtitle*/}
                                        {/*begin::Bullet*/}
                                        <span className="bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary"></span>
                                        {/*end::Bullet*/}
                                    </a>
                                    {/*end::Link*/}
                                </li>
                                {/*end::Item*/}
                                {/*begin::Item*/}
                                <li className="nav-item mb-3 me-3 me-lg-6">
                                    {/*begin::Link*/}
                                    <a className="nav-link d-flex justify-content-between flex-column flex-center overflow-hidden w-80px h-85px py-4" data-bs-toggle="pill" href="#kt_stats_widget_2_tab_4">
                                        {/*begin::Icon*/}
                                        <div className="nav-icon">
                                            <img alt="" src="assets/media/svg/products-categories/gloves.svg" className="nav-icon" />
                                        </div>
                                        {/*end::Icon*/}
                                        {/*begin::Subtitle*/}
                                        <span className="nav-text text-gray-600 fw-bolder fs-6 lh-1">Gloves</span>
                                        {/*end::Subtitle*/}
                                        {/*begin::Bullet*/}
                                        <span className="bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary"></span>
                                        {/*end::Bullet*/}
                                    </a>
                                    {/*end::Link*/}
                                </li>
                                {/*end::Item*/}
                                {/*begin::Item*/}
                                <li className="nav-item mb-3">
                                    {/*begin::Link*/}
                                    <a className="nav-link d-flex justify-content-between flex-column flex-center overflow-hidden w-80px h-85px py-4" data-bs-toggle="pill" href="#kt_stats_widget_2_tab_5">
                                        {/*begin::Icon*/}
                                        <div className="nav-icon">
                                            <img alt="" src="assets/media/svg/products-categories/shoes.svg" className="nav-icon" />
                                        </div>
                                        {/*end::Icon*/}
                                        {/*begin::Subtitle*/}
                                        <span className="nav-text text-gray-600 fw-bolder fs-6 lh-1">Shoes</span>
                                        {/*end::Subtitle*/}
                                        {/*begin::Bullet*/}
                                        <span className="bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary"></span>
                                        {/*end::Bullet*/}
                                    </a>
                                    {/*end::Link*/}
                                </li>
                                {/*end::Item*/}
                            </ul>
                            {/*end::Nav*/}
                            {/*begin::Tab Content*/}
                            <div className="tab-content">
                                {/*begin::Tap pane*/}
                                <div className="tab-pane fade show active" id="kt_stats_widget_2_tab_1">
                                    {/*begin::Table container*/}
                                    <div className="table-responsive">
                                        {/*begin::Table*/}
                                        <table className="table table-row-dashed align-middle gs-0 gy-4 my-0">
                                            {/*begin::Table head*/}
                                            <thead>
                                                <tr className="fs-7 fw-bolder text-gray-500 border-bottom-0">
                                                    <th className="ps-0 w-50px">ITEM</th>
                                                    <th className="min-w-140px"></th>
                                                    <th className="text-end min-w-140px">QTY</th>
                                                    <th className="pe-0 text-end min-w-120px">PRICE</th>
                                                    <th className="pe-0 text-end min-w-120px">TOTAL PRICE</th>
                                                </tr>
                                            </thead>
                                            {/*end::Table head*/}
                                            {/*begin::Table body*/}
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <img src="assets/media/stock/ecommerce/210.gif" className="w-50px ms-n1" alt="" />
                                                    </td>
                                                    <td className="ps-0">
                                                        <a href="../../demo1/dist/apps/ecommerce/catalog/edit-product.html" className="text-gray-800 fw-bolder text-hover-primary mb-1 fs-6 text-start pe-0">Elephant 1802</a>
                                                        <span className="text-gray-400 fw-bold fs-7 d-block text-start ps-0">Item: #XDG-2347</span>
                                                    </td>
                                                    <td>
                                                        <span className="text-gray-800 fw-bolder d-block fs-6 ps-0 text-end">x1</span>
                                                    </td>
                                                    <td className="text-end pe-0">
                                                        <span className="text-gray-800 fw-bolder d-block fs-6">$72.00</span>
                                                    </td>
                                                    <td className="text-end pe-0">
                                                        <span className="text-gray-800 fw-bolder d-block fs-6">$126.00</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <img src="assets/media/stock/ecommerce/215.gif" className="w-50px ms-n1" alt="" />
                                                    </td>
                                                    <td className="ps-0">
                                                        <a href="../../demo1/dist/apps/ecommerce/catalog/edit-product.html" className="text-gray-800 fw-bolder text-hover-primary mb-1 fs-6 text-start pe-0">Red Laga</a>
                                                        <span className="text-gray-400 fw-bold fs-7 d-block text-start ps-0">Item: #XDG-1321</span>
                                                    </td>
                                                    <td>
                                                        <span className="text-gray-800 fw-bolder d-block fs-6 ps-0 text-end">x2</span>
                                                    </td>
                                                    <td className="text-end pe-0">
                                                        <span className="text-gray-800 fw-bolder d-block fs-6">$45.00</span>
                                                    </td>
                                                    <td className="text-end pe-0">
                                                        <span className="text-gray-800 fw-bolder d-block fs-6">$76.00</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <img src="assets/media/stock/ecommerce/209.gif" className="w-50px ms-n1" alt="" />
                                                    </td>
                                                    <td className="ps-0">
                                                        <a href="../../demo1/dist/apps/ecommerce/catalog/edit-product.html" className="text-gray-800 fw-bolder text-hover-primary mb-1 fs-6 text-start pe-0">RiseUP</a>
                                                        <span className="text-gray-400 fw-bold fs-7 d-block text-start ps-0">Item: #XDG-4312</span>
                                                    </td>
                                                    <td>
                                                        <span className="text-gray-800 fw-bolder d-block fs-6 ps-0 text-end">x3</span>
                                                    </td>
                                                    <td className="text-end pe-0">
                                                        <span className="text-gray-800 fw-bolder d-block fs-6">$84.00</span>
                                                    </td>
                                                    <td className="text-end pe-0">
                                                        <span className="text-gray-800 fw-bolder d-block fs-6">$168.00</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            {/*end::Table body*/}
                                        </table>
                                    </div>
                                    {/*end::Table*/}
                                </div>
                                {/*end::Tap pane*/}
                                {/*begin::Tap pane*/}
                                <div className="tab-pane fade" id="kt_stats_widget_2_tab_2">
                                    {/*begin::Table container*/}
                                    <div className="table-responsive">
                                        {/*begin::Table*/}
                                        <table className="table table-row-dashed align-middle gs-0 gy-4 my-0">
                                            {/*begin::Table head*/}
                                            <thead>
                                                <tr className="fs-7 fw-bolder text-gray-500 border-bottom-0">
                                                    <th className="ps-0 w-50px">ITEM</th>
                                                    <th className="min-w-140px"></th>
                                                    <th className="text-end min-w-140px">QTY</th>
                                                    <th className="pe-0 text-end min-w-120px">PRICE</th>
                                                    <th className="pe-0 text-end min-w-120px">TOTAL PRICE</th>
                                                </tr>
                                            </thead>
                                            {/*end::Table head*/}
                                            {/*begin::Table body*/}
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <img src="assets/media/stock/ecommerce/197.gif" className="w-50px ms-n1" alt="" />
                                                    </td>
                                                    <td className="ps-0">
                                                        <a href="../../demo1/dist/apps/ecommerce/catalog/edit-product.html" className="text-gray-800 fw-bolder text-hover-primary mb-1 fs-6 text-start pe-0">Elephant 1802</a>
                                                        <span className="text-gray-400 fw-bold fs-7 d-block text-start ps-0">Item: #XDG-4312</span>
                                                    </td>
                                                    <td>
                                                        <span className="text-gray-800 fw-bolder d-block fs-6 ps-0 text-end">x1</span>
                                                    </td>
                                                    <td className="text-end pe-0">
                                                        <span className="text-gray-800 fw-bolder d-block fs-6">$32.00</span>
                                                    </td>
                                                    <td className="text-end pe-0">
                                                        <span className="text-gray-800 fw-bolder d-block fs-6">$312.00</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <img src="assets/media/stock/ecommerce/178.gif" className="w-50px ms-n1" alt="" />
                                                    </td>
                                                    <td className="ps-0">
                                                        <a href="../../demo1/dist/apps/ecommerce/catalog/edit-product.html" className="text-gray-800 fw-bolder text-hover-primary mb-1 fs-6 text-start pe-0">Red Laga</a>
                                                        <span className="text-gray-400 fw-bold fs-7 d-block text-start ps-0">Item: #XDG-3122</span>
                                                    </td>
                                                    <td>
                                                        <span className="text-gray-800 fw-bolder d-block fs-6 ps-0 text-end">x2</span>
                                                    </td>
                                                    <td className="text-end pe-0">
                                                        <span className="text-gray-800 fw-bolder d-block fs-6">$53.00</span>
                                                    </td>
                                                    <td className="text-end pe-0">
                                                        <span className="text-gray-800 fw-bolder d-block fs-6">$62.00</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <img src="assets/media/stock/ecommerce/22.gif" className="w-50px ms-n1" alt="" />
                                                    </td>
                                                    <td className="ps-0">
                                                        <a href="../../demo1/dist/apps/ecommerce/catalog/edit-product.html" className="text-gray-800 fw-bolder text-hover-primary mb-1 fs-6 text-start pe-0">RiseUP</a>
                                                        <span className="text-gray-400 fw-bold fs-7 d-block text-start ps-0">Item: #XDG-1142</span>
                                                    </td>
                                                    <td>
                                                        <span className="text-gray-800 fw-bolder d-block fs-6 ps-0 text-end">x3</span>
                                                    </td>
                                                    <td className="text-end pe-0">
                                                        <span className="text-gray-800 fw-bolder d-block fs-6">$74.00</span>
                                                    </td>
                                                    <td className="text-end pe-0">
                                                        <span className="text-gray-800 fw-bolder d-block fs-6">$139.00</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            {/*end::Table body*/}
                                        </table>
                                    </div>
                                    {/*end::Table*/}
                                </div>
                                {/*end::Tap pane*/}
                                {/*begin::Tap pane*/}
                                <div className="tab-pane fade" id="kt_stats_widget_2_tab_3">
                                    {/*begin::Table container*/}
                                    <div className="table-responsive">
                                        {/*begin::Table*/}
                                        <table className="table table-row-dashed align-middle gs-0 gy-4 my-0">
                                            {/*begin::Table head*/}
                                            <thead>
                                                <tr className="fs-7 fw-bolder text-gray-500 border-bottom-0">
                                                    <th className="ps-0 w-50px">ITEM</th>
                                                    <th className="min-w-140px"></th>
                                                    <th className="text-end min-w-140px">QTY</th>
                                                    <th className="pe-0 text-end min-w-120px">PRICE</th>
                                                    <th className="pe-0 text-end min-w-120px">TOTAL PRICE</th>
                                                </tr>
                                            </thead>
                                            {/*end::Table head*/}
                                            {/*begin::Table body*/}
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <img src="assets/media/stock/ecommerce/1.gif" className="w-50px ms-n1" alt="" />
                                                    </td>
                                                    <td className="ps-0">
                                                        <a href="../../demo1/dist/apps/ecommerce/catalog/edit-product.html" className="text-gray-800 fw-bolder text-hover-primary mb-1 fs-6 text-start pe-0">Elephant 1324</a>
                                                        <span className="text-gray-400 fw-bold fs-7 d-block text-start ps-0">Item: #XDG-1523</span>
                                                    </td>
                                                    <td>
                                                        <span className="text-gray-800 fw-bolder d-block fs-6 ps-0 text-end">x1</span>
                                                    </td>
                                                    <td className="text-end pe-0">
                                                        <span className="text-gray-800 fw-bolder d-block fs-6">$43.00</span>
                                                    </td>
                                                    <td className="text-end pe-0">
                                                        <span className="text-gray-800 fw-bolder d-block fs-6">$231.00</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <img src="assets/media/stock/ecommerce/24.gif" className="w-50px ms-n1" alt="" />
                                                    </td>
                                                    <td className="ps-0">
                                                        <a href="../../demo1/dist/apps/ecommerce/catalog/edit-product.html" className="text-gray-800 fw-bolder text-hover-primary mb-1 fs-6 text-start pe-0">Red Laga</a>
                                                        <span className="text-gray-400 fw-bold fs-7 d-block text-start ps-0">Item: #XDG-5314</span>
                                                    </td>
                                                    <td>
                                                        <span className="text-gray-800 fw-bolder d-block fs-6 ps-0 text-end">x2</span>
                                                    </td>
                                                    <td className="text-end pe-0">
                                                        <span className="text-gray-800 fw-bolder d-block fs-6">$71.00</span>
                                                    </td>
                                                    <td className="text-end pe-0">
                                                        <span className="text-gray-800 fw-bolder d-block fs-6">$53.00</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <img src="assets/media/stock/ecommerce/71.gif" className="w-50px ms-n1" alt="" />
                                                    </td>
                                                    <td className="ps-0">
                                                        <a href="../../demo1/dist/apps/ecommerce/catalog/edit-product.html" className="text-gray-800 fw-bolder text-hover-primary mb-1 fs-6 text-start pe-0">RiseUP</a>
                                                        <span className="text-gray-400 fw-bold fs-7 d-block text-start ps-0">Item: #XDG-4222</span>
                                                    </td>
                                                    <td>
                                                        <span className="text-gray-800 fw-bolder d-block fs-6 ps-0 text-end">x3</span>
                                                    </td>
                                                    <td className="text-end pe-0">
                                                        <span className="text-gray-800 fw-bolder d-block fs-6">$23.00</span>
                                                    </td>
                                                    <td className="text-end pe-0">
                                                        <span className="text-gray-800 fw-bolder d-block fs-6">$213.00</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            {/*end::Table body*/}
                                        </table>
                                    </div>
                                    {/*end::Table*/}
                                </div>
                                {/*end::Tap pane*/}
                                {/*begin::Tap pane*/}
                                <div className="tab-pane fade" id="kt_stats_widget_2_tab_4">
                                    {/*begin::Table container*/}
                                    <div className="table-responsive">
                                        {/*begin::Table*/}
                                        <table className="table table-row-dashed align-middle gs-0 gy-4 my-0">
                                            {/*begin::Table head*/}
                                            <thead>
                                                <tr className="fs-7 fw-bolder text-gray-500 border-bottom-0">
                                                    <th className="ps-0 w-50px">ITEM</th>
                                                    <th className="min-w-140px"></th>
                                                    <th className="text-end min-w-140px">QTY</th>
                                                    <th className="pe-0 text-end min-w-120px">PRICE</th>
                                                    <th className="pe-0 text-end min-w-120px">TOTAL PRICE</th>
                                                </tr>
                                            </thead>
                                            {/*end::Table head*/}
                                            {/*begin::Table body*/}
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <img src="assets/media/stock/ecommerce/41.gif" className="w-50px ms-n1" alt="" />
                                                    </td>
                                                    <td className="ps-0">
                                                        <a href="../../demo1/dist/apps/ecommerce/catalog/edit-product.html" className="text-gray-800 fw-bolder text-hover-primary mb-1 fs-6 text-start pe-0">Elephant 2635</a>
                                                        <span className="text-gray-400 fw-bold fs-7 d-block text-start ps-0">Item: #XDG-1523</span>
                                                    </td>
                                                    <td>
                                                        <span className="text-gray-800 fw-bolder d-block fs-6 ps-0 text-end">x1</span>
                                                    </td>
                                                    <td className="text-end pe-0">
                                                        <span className="text-gray-800 fw-bolder d-block fs-6">$65.00</span>
                                                    </td>
                                                    <td className="text-end pe-0">
                                                        <span className="text-gray-800 fw-bolder d-block fs-6">$163.00</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <img src="assets/media/stock/ecommerce/63.gif" className="w-50px ms-n1" alt="" />
                                                    </td>
                                                    <td className="ps-0">
                                                        <a href="../../demo1/dist/apps/ecommerce/catalog/edit-product.html" className="text-gray-800 fw-bolder text-hover-primary mb-1 fs-6 text-start pe-0">Red Laga</a>
                                                        <span className="text-gray-400 fw-bold fs-7 d-block text-start ps-0">Item: #XDG-2745</span>
                                                    </td>
                                                    <td>
                                                        <span className="text-gray-800 fw-bolder d-block fs-6 ps-0 text-end">x2</span>
                                                    </td>
                                                    <td className="text-end pe-0">
                                                        <span className="text-gray-800 fw-bolder d-block fs-6">$64.00</span>
                                                    </td>
                                                    <td className="text-end pe-0">
                                                        <span className="text-gray-800 fw-bolder d-block fs-6">$73.00</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <img src="assets/media/stock/ecommerce/59.gif" className="w-50px ms-n1" alt="" />
                                                    </td>
                                                    <td className="ps-0">
                                                        <a href="../../demo1/dist/apps/ecommerce/catalog/edit-product.html" className="text-gray-800 fw-bolder text-hover-primary mb-1 fs-6 text-start pe-0">RiseUP</a>
                                                        <span className="text-gray-400 fw-bold fs-7 d-block text-start ps-0">Item: #XDG-5173</span>
                                                    </td>
                                                    <td>
                                                        <span className="text-gray-800 fw-bolder d-block fs-6 ps-0 text-end">x3</span>
                                                    </td>
                                                    <td className="text-end pe-0">
                                                        <span className="text-gray-800 fw-bolder d-block fs-6">$54.00</span>
                                                    </td>
                                                    <td className="text-end pe-0">
                                                        <span className="text-gray-800 fw-bolder d-block fs-6">$173.00</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            {/*end::Table body*/}
                                        </table>
                                    </div>
                                    {/*end::Table*/}
                                </div>
                                {/*end::Tap pane*/}
                                {/*begin::Tap pane*/}
                                <div className="tab-pane fade" id="kt_stats_widget_2_tab_5">
                                    {/*begin::Table container*/}
                                    <div className="table-responsive">
                                        {/*begin::Table*/}
                                        <table className="table table-row-dashed align-middle gs-0 gy-4 my-0">
                                            {/*begin::Table head*/}
                                            <thead>
                                                <tr className="fs-7 fw-bolder text-gray-500 border-bottom-0">
                                                    <th className="ps-0 w-50px">ITEM</th>
                                                    <th className="min-w-140px"></th>
                                                    <th className="text-end min-w-140px">QTY</th>
                                                    <th className="pe-0 text-end min-w-120px">PRICE</th>
                                                    <th className="pe-0 text-end min-w-120px">TOTAL PRICE</th>
                                                </tr>
                                            </thead>
                                            {/*end::Table head*/}
                                            {/*begin::Table body*/}
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <img src="assets/media/stock/ecommerce/10.gif" className="w-50px ms-n1" alt="" />
                                                    </td>
                                                    <td className="ps-0">
                                                        <a href="../../demo1/dist/apps/ecommerce/catalog/edit-product.html" className="text-gray-800 fw-bolder text-hover-primary mb-1 fs-6 text-start pe-0">Nike</a>
                                                        <span className="text-gray-400 fw-bold fs-7 d-block text-start ps-0">Item: #XDG-2163</span>
                                                    </td>
                                                    <td>
                                                        <span className="text-gray-800 fw-bolder d-block fs-6 ps-0 text-end">x1</span>
                                                    </td>
                                                    <td className="text-end pe-0">
                                                        <span className="text-gray-800 fw-bolder d-block fs-6">$64.00</span>
                                                    </td>
                                                    <td className="text-end pe-0">
                                                        <span className="text-gray-800 fw-bolder d-block fs-6">$287.00</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <img src="assets/media/stock/ecommerce/96.gif" className="w-50px ms-n1" alt="" />
                                                    </td>
                                                    <td className="ps-0">
                                                        <a href="../../demo1/dist/apps/ecommerce/catalog/edit-product.html" className="text-gray-800 fw-bolder text-hover-primary mb-1 fs-6 text-start pe-0">Adidas</a>
                                                        <span className="text-gray-400 fw-bold fs-7 d-block text-start ps-0">Item: #XDG-2162</span>
                                                    </td>
                                                    <td>
                                                        <span className="text-gray-800 fw-bolder d-block fs-6 ps-0 text-end">x2</span>
                                                    </td>
                                                    <td className="text-end pe-0">
                                                        <span className="text-gray-800 fw-bolder d-block fs-6">$76.00</span>
                                                    </td>
                                                    <td className="text-end pe-0">
                                                        <span className="text-gray-800 fw-bolder d-block fs-6">$51.00</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <img src="assets/media/stock/ecommerce/13.gif" className="w-50px ms-n1" alt="" />
                                                    </td>
                                                    <td className="ps-0">
                                                        <a href="../../demo1/dist/apps/ecommerce/catalog/edit-product.html" className="text-gray-800 fw-bolder text-hover-primary mb-1 fs-6 text-start pe-0">Puma</a>
                                                        <span className="text-gray-400 fw-bold fs-7 d-block text-start ps-0">Item: #XDG-1537</span>
                                                    </td>
                                                    <td>
                                                        <span className="text-gray-800 fw-bolder d-block fs-6 ps-0 text-end">x3</span>
                                                    </td>
                                                    <td className="text-end pe-0">
                                                        <span className="text-gray-800 fw-bolder d-block fs-6">$27.00</span>
                                                    </td>
                                                    <td className="text-end pe-0">
                                                        <span className="text-gray-800 fw-bolder d-block fs-6">$167.00</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            {/*end::Table body*/}
                                        </table>
                                    </div>
                                    {/*end::Table*/}
                                </div>
                                {/*end::Tap pane*/}
                            </div>
                            {/*end::Tab Content*/}
                        </div>
                        {/*end: Card Body*/}
                    </div>
                    {/*end::Tables widget 2*/}
                </div>
                {/*end::Col*/}
                {/*begin::Col*/}
                <div className="col-xl-6 mb-5 mb-xl-10">
                    {/*begin::Chart widget 4*/}
                    <div className="card card-flush overflow-hidden h-md-100">
                        {/*begin::Header*/}
                        <div className="card-header py-5">
                            {/*begin::Title*/}
                            <h3 className="card-title align-items-start flex-column">
                                <span className="card-label fw-bolder text-dark">Discounted Product Sales</span>
                                <span className="text-gray-400 mt-1 fw-bold fs-6">Users from all channels</span>
                            </h3>
                            {/*end::Title*/}
                            {/*begin::Toolbar*/}
                            <div className="card-toolbar">
                                {/*begin::Menu*/}
                                <button className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-overflow="true">
                                    {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="4" fill="black" />
                                            <rect x="11" y="11" width="2.6" height="2.6" rx="1.3" fill="black" />
                                            <rect x="15" y="11" width="2.6" height="2.6" rx="1.3" fill="black" />
                                            <rect x="7" y="11" width="2.6" height="2.6" rx="1.3" fill="black" />
                                        </svg>
                                    </span>
                                    {/*end::Svg Icon*/}
                                </button>
                                {/*begin::Menu 2*/}
                                <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px" data-kt-menu="true">
                                    {/*begin::Menu item*/}
                                    <div className="menu-item px-3">
                                        <div className="menu-content fs-6 text-dark fw-bolder px-3 py-4">Quick Actions</div>
                                    </div>
                                    {/*end::Menu item*/}
                                    {/*begin::Menu separator*/}
                                    <div className="separator mb-3 opacity-75"></div>
                                    {/*end::Menu separator*/}
                                    {/*begin::Menu item*/}
                                    <div className="menu-item px-3">
                                        <a href="\" className="menu-link px-3">New Ticket</a>
                                    </div>
                                    {/*end::Menu item*/}
                                    {/*begin::Menu item*/}
                                    <div className="menu-item px-3">
                                        <a href="\" className="menu-link px-3">New Customer</a>
                                    </div>
                                    {/*end::Menu item*/}
                                    {/*begin::Menu item*/}
                                    <div className="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-start">
                                        {/*begin::Menu item*/}
                                        <a href="\" className="menu-link px-3">
                                            <span className="menu-title">New Group</span>
                                            <span className="menu-arrow"></span>
                                        </a>
                                        {/*end::Menu item*/}
                                        {/*begin::Menu sub*/}
                                        <div className="menu-sub menu-sub-dropdown w-175px py-4">
                                            {/*begin::Menu item*/}
                                            <div className="menu-item px-3">
                                                <a href="\" className="menu-link px-3">Admin Group</a>
                                            </div>
                                            {/*end::Menu item*/}
                                            {/*begin::Menu item*/}
                                            <div className="menu-item px-3">
                                                <a href="\" className="menu-link px-3">Staff Group</a>
                                            </div>
                                            {/*end::Menu item*/}
                                            {/*begin::Menu item*/}
                                            <div className="menu-item px-3">
                                                <a href="\" className="menu-link px-3">Member Group</a>
                                            </div>
                                            {/*end::Menu item*/}
                                        </div>
                                        {/*end::Menu sub*/}
                                    </div>
                                    {/*end::Menu item*/}
                                    {/*begin::Menu item*/}
                                    <div className="menu-item px-3">
                                        <a href="\" className="menu-link px-3">New Contact</a>
                                    </div>
                                    {/*end::Menu item*/}
                                    {/*begin::Menu separator*/}
                                    <div className="separator mt-3 opacity-75"></div>
                                    {/*end::Menu separator*/}
                                    {/*begin::Menu item*/}
                                    <div className="menu-item px-3">
                                        <div className="menu-content px-3 py-3">
                                            <a className="btn btn-primary btn-sm px-4" href="\">Generate Reports</a>
                                        </div>
                                    </div>
                                    {/*end::Menu item*/}
                                </div>
                                {/*end::Menu 2*/}
                                {/*end::Menu*/}
                            </div>
                            {/*end::Toolbar*/}
                        </div>
                        {/*end::Header*/}
                        {/*begin::Card body*/}
                        <div className="card-body d-flex justify-content-between flex-column pb-1 px-0">
                            {/*begin::Info*/}
                            <div className="px-9 mb-5">
                                {/*begin::Statistics*/}
                                <div className="d-flex align-items-center mb-2">
                                    {/*begin::Currency*/}
                                    <span className="fs-4 fw-bold text-gray-400 align-self-start me-1">$</span>
                                    {/*end::Currency*/}
                                    {/*begin::Value*/}
                                    <span className="fs-2hx fw-bolder text-gray-800 me-2 lh-1">3,706</span>
                                    {/*end::Value*/}
                                    {/*begin::Label*/}
                                    <span className="badge badge-success fs-6 lh-1 py-1 px-2 d-flex flex-center">
                                        {/*begin::Svg Icon | path: icons/duotune/arrows/arr067.svg*/}
                                        <span className="svg-icon svg-icon-7 svg-icon-white">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path opacity="0.5" d="M13 9.59998V21C13 21.6 12.6 22 12 22C11.4 22 11 21.6 11 21V9.59998H13Z" fill="black" />
                                                <path d="M5.7071 7.89291C5.07714 8.52288 5.52331 9.60002 6.41421 9.60002H17.5858C18.4767 9.60002 18.9229 8.52288 18.2929 7.89291L12.7 2.3C12.3 1.9 11.7 1.9 11.3 2.3L5.7071 7.89291Z" fill="black" />
                                            </svg>
                                        </span>
                                        {/*end::Svg Icon*/}2.2%</span>
                                    {/*end::Label*/}
                                </div>
                                {/*end::Statistics*/}
                                {/*begin::Description*/}
                                <span className="fs-6 fw-bold text-gray-400">Total Discounted Sales This Month</span>
                                {/*end::Description*/}
                            </div>
                            {/*end::Info*/}
                            {/*begin::Chart*/}
                            <div id="kt_charts_widget_4" className="min-h-auto ps-4 pe-6" style={{ height: '300px' }}></div>
                            {/*end::Chart*/}
                        </div>
                        {/*end::Card body*/}
                    </div>
                    {/*end::Chart widget 4*/}
                </div>
                {/*end::Col*/}
            </div>
            {/*end::Row*/}
            {/*begin::Row*/}
            <div className="row gy-5 g-xl-10">
                {/*begin::Col*/}
                <div className="col-xl-4 mb-xl-10">
                    {/*begin::Engage widget 1*/}
                    <div className="card h-md-100">
                        {/*begin::Body*/}
                        <div className="card-body d-flex flex-column flex-center">
                            {/*begin::Heading*/}
                            <div className="mb-2">
                                {/*begin::Title*/}
                                <h1 className="fw-bold text-gray-800 text-center lh-lg">Have you tried
                                    <br />new
                                    <span className="fw-boldest">eCommerce App ?</span></h1>
                                {/*end::Title*/}
                                {/*begin::Illustration*/}
                                <div className="flex-grow-1 bgi-no-repeat bgi-size-contain bgi-position-x-center card-rounded-bottom h-200px mh-200px my-5 my-lg-12" style={{ backgroundImage: "url('assets/media/svg/illustrations/easy/2.svg')" }}></div>
                                {/*end::Illustration*/}
                            </div>
                            {/*end::Heading*/}
                            {/*begin::Links*/}
                            <div className="text-center">
                                {/*begin::Link*/}
                                <a className="btn btn-sm btn-primary me-2" href="../../demo1/dist/apps/ecommerce/sales/listing.html">View App</a>
                                {/*end::Link*/}
                                {/*begin::Link*/}
                                <a className="btn btn-sm btn-light" href="../../demo1/dist/apps/ecommerce/catalog/add-product.html">New Product</a>
                                {/*end::Link*/}
                            </div>
                            {/*end::Links*/}
                        </div>
                        {/*end::Body*/}
                    </div>
                    {/*end::Engage widget 1*/}
                </div>
                {/*end::Col*/}
                {/*begin::Col*/}
                <div className="col-xl-8 mb-5 mb-xl-10">
                    {/*begin::Table Widget 4*/}
                    <div className="card card-flush h-xl-100">
                        {/*begin::Card header*/}
                        <div className="card-header pt-7">
                            {/*begin::Title*/}
                            <h3 className="card-title align-items-start flex-column">
                                <span className="card-label fw-bolder text-dark">Product Orders</span>
                                <span className="text-gray-400 mt-1 fw-bold fs-6">Avg. 57 orders per day</span>
                            </h3>
                            {/*end::Title*/}
                            {/*begin::Actions*/}
                            <div className="card-toolbar">
                                {/*begin::Filters*/}
                                <div className="d-flex flex-stack flex-wrap gap-4">
                                    {/*begin::Destination*/}
                                    <div className="d-flex align-items-center fw-bolder">
                                        {/*begin::Label*/}
                                        <div className="text-muted fs-7 me-2">Cateogry</div>
                                        {/*end::Label*/}
                                        {/*begin::Select*/}
                                        <select className="form-select form-select-transparent text-dark fs-7 lh-1 fw-bolder py-0 ps-3 w-auto" data-control="select2" data-hide-search="true" data-dropdown-css-className="w-150px" data-placeholder="Select an option">
                                            <option></option>
                                            <option defaultValue="Show All" >Show All</option>
                                            <option defaultValue="a">Category A</option>
                                            <option defaultValue="b">Category A</option>
                                        </select>
                                        {/*end::Select*/}
                                    </div>
                                    {/*end::Destination*/}
                                    {/*begin::Status*/}
                                    <div className="d-flex align-items-center fw-bolder">
                                        {/*begin::Label*/}
                                        <div className="text-muted fs-7 me-2">Status</div>
                                        {/*end::Label*/}
                                        {/*begin::Select*/}
                                        <select className="form-select form-select-transparent text-dark fs-7 lh-1 fw-bolder py-0 ps-3 w-auto" data-control="select2" data-hide-search="true" data-dropdown-css-className="w-150px" data-placeholder="Select an option" data-kt-table-widget-4="filter_status">
                                            <option></option>
                                            <option defaultValue="Show All" >Show All</option>
                                            <option defaultValue="Shipped">Shipped</option>
                                            <option defaultValue="Confirmed">Confirmed</option>
                                            <option defaultValue="Rejected">Rejected</option>
                                            <option defaultValue="Pending">Pending</option>
                                        </select>
                                        {/*end::Select*/}
                                    </div>
                                    {/*end::Status*/}
                                    {/*begin::Search*/}
                                    <div className="position-relative my-1">
                                        {/*begin::Svg Icon | path: icons/duotune/general/gen021.svg*/}
                                        <span className="svg-icon svg-icon-2 position-absolute top-50 translate-middle-y ms-4">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black" />
                                                <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black" />
                                            </svg>
                                        </span>
                                        {/*end::Svg Icon*/}
                                        <input type="text" data-kt-table-widget-4="search" className="form-control w-150px fs-7 ps-12" placeholder="Search" />
                                    </div>
                                    {/*end::Search*/}
                                </div>
                                {/*begin::Filters*/}
                            </div>
                            {/*end::Actions*/}
                        </div>
                        {/*end::Card header*/}
                        {/*begin::Card body*/}
                        <div className="card-body">
                            {/*begin::Table*/}
                            <table className="table align-middle table-row-dashed fs-6 gy-3" id="kt_table_widget_4_table">
                                {/*begin::Table head*/}
                                <thead>
                                    {/*begin::Table row*/}
                                    <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                                        <th className="min-w-100px">Order ID</th>
                                        <th className="text-end min-w-100px">Created</th>
                                        <th className="text-end min-w-125px">Customer</th>
                                        <th className="text-end min-w-100px">Total</th>
                                        <th className="text-end min-w-100px">Profit</th>
                                        <th className="text-end min-w-50px">Status</th>
                                        <th className="text-end"></th>
                                    </tr>
                                    {/*end::Table row*/}
                                </thead>
                                {/*end::Table head*/}
                                {/*begin::Table body*/}
                                <tbody className="fw-bolder text-gray-600">
                                    <tr data-kt-table-widget-4="subtable_template" className="d-none">
                                        <td colSpan={2}>
                                            <div className="d-flex align-items-center gap-3">
                                                <a href="\" className="symbol symbol-50px bg-secondary bg-opacity-25 rounded">
                                                    <img src="" data-kt-src-path="assets/media/stock/ecommerce/" alt="" data-kt-table-widget-4="template_image" />
                                                </a>
                                                <div className="d-flex flex-column text-muted">
                                                    <a href="\" className="text-dark text-hover-primary fw-bolder" data-kt-table-widget-4="template_name">Product name</a>
                                                    <div className="fs-7" data-kt-table-widget-4="template_description">Product description</div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="text-end">
                                            <div className="text-dark fs-7">Cost</div>
                                            <div className="text-muted fs-7 fw-bolder" data-kt-table-widget-4="template_cost">1</div>
                                        </td>
                                        <td className="text-end">
                                            <div className="text-dark fs-7">Qty</div>
                                            <div className="text-muted fs-7 fw-bolder" data-kt-table-widget-4="template_qty">1</div>
                                        </td>
                                        <td className="text-end">
                                            <div className="text-dark fs-7">Total</div>
                                            <div className="text-muted fs-7 fw-bolder" data-kt-table-widget-4="template_total">name</div>
                                        </td>
                                        <td className="text-end">
                                            <div className="text-dark fs-7 me-3">On hand</div>
                                            <div className="text-muted fs-7 fw-bolder" data-kt-table-widget-4="template_stock">32</div>
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="../../demo1/dist/apps/ecommerce/catalog/edit-product.html" className="text-dark text-hover-primary">#XGT-346</a>
                                        </td>
                                        <td className="text-end">11 January 2022, 11:00 pm</td>
                                        <td className="text-end">
                                            <a href="\" className="text-dark text-hover-primary">Emma Smith</a>
                                        </td>
                                        <td className="text-end">$630.00</td>
                                        <td className="text-end">
                                            <span className="text-dark fw-bolder">$86.70</span>
                                        </td>
                                        <td className="text-end">
                                            <span className="badge py-3 px-4 fs-7 badge-light-warning">Pending</span>
                                        </td>
                                        <td className="text-end">
                                            <button type="button" className="btn btn-sm btn-icon btn-light btn-active-light-primary toggle h-25px w-25px" data-kt-table-widget-4="expand_row">
                                                {/*begin::Svg Icon | path: icons/duotune/arrows/arr087.svg*/}
                                                <span className="svg-icon svg-icon-3 m-0 toggle-off">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <rect opacity="0.5" x="11" y="18" width="12" height="2" rx="1" transform="rotate(-90 11 18)" fill="black" />
                                                        <rect x="6" y="11" width="12" height="2" rx="1" fill="black" />
                                                    </svg>
                                                </span>
                                                {/*end::Svg Icon*/}
                                                {/*begin::Svg Icon | path: icons/duotune/arrows/arr089.svg*/}
                                                <span className="svg-icon svg-icon-3 m-0 toggle-on">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <rect x="6" y="11" width="12" height="2" rx="1" fill="black" />
                                                    </svg>
                                                </span>
                                                {/*end::Svg Icon*/}
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="../../demo1/dist/apps/ecommerce/catalog/edit-product.html" className="text-dark text-hover-primary">#YHD-047</a>
                                        </td>
                                        <td className="text-end">11 January 2022, 10:08 pm</td>
                                        <td className="text-end">
                                            <a href="\" className="text-dark text-hover-primary">Melody Macy</a>
                                        </td>
                                        <td className="text-end">$25.00</td>
                                        <td className="text-end">
                                            <span className="text-dark fw-bolder">$4.20</span>
                                        </td>
                                        <td className="text-end">
                                            <span className="badge py-3 px-4 fs-7 badge-light-primary">Confirmed</span>
                                        </td>
                                        <td className="text-end">
                                            <button type="button" className="btn btn-sm btn-icon btn-light btn-active-light-primary toggle h-25px w-25px" data-kt-table-widget-4="expand_row">
                                                {/*begin::Svg Icon | path: icons/duotune/arrows/arr087.svg*/}
                                                <span className="svg-icon svg-icon-3 m-0 toggle-off">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <rect opacity="0.5" x="11" y="18" width="12" height="2" rx="1" transform="rotate(-90 11 18)" fill="black" />
                                                        <rect x="6" y="11" width="12" height="2" rx="1" fill="black" />
                                                    </svg>
                                                </span>
                                                {/*end::Svg Icon*/}
                                                {/*begin::Svg Icon | path: icons/duotune/arrows/arr089.svg*/}
                                                <span className="svg-icon svg-icon-3 m-0 toggle-on">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <rect x="6" y="11" width="12" height="2" rx="1" fill="black" />
                                                    </svg>
                                                </span>
                                                {/*end::Svg Icon*/}
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="../../demo1/dist/apps/ecommerce/catalog/edit-product.html" className="text-dark text-hover-primary">#SRR-678</a>
                                        </td>
                                        <td className="text-end">11 January 2022, 7:00 pm</td>
                                        <td className="text-end">
                                            <a href="\" className="text-dark text-hover-primary">Max Smith</a>
                                        </td>
                                        <td className="text-end">$1,630.00</td>
                                        <td className="text-end">
                                            <span className="text-dark fw-bolder">$203.90</span>
                                        </td>
                                        <td className="text-end">
                                            <span className="badge py-3 px-4 fs-7 badge-light-warning">Pending</span>
                                        </td>
                                        <td className="text-end">
                                            <button type="button" className="btn btn-sm btn-icon btn-light btn-active-light-primary toggle h-25px w-25px" data-kt-table-widget-4="expand_row">
                                                {/*begin::Svg Icon | path: icons/duotune/arrows/arr087.svg*/}
                                                <span className="svg-icon svg-icon-3 m-0 toggle-off">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <rect opacity="0.5" x="11" y="18" width="12" height="2" rx="1" transform="rotate(-90 11 18)" fill="black" />
                                                        <rect x="6" y="11" width="12" height="2" rx="1" fill="black" />
                                                    </svg>
                                                </span>
                                                {/*end::Svg Icon*/}
                                                {/*begin::Svg Icon | path: icons/duotune/arrows/arr089.svg*/}
                                                <span className="svg-icon svg-icon-3 m-0 toggle-on">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <rect x="6" y="11" width="12" height="2" rx="1" fill="black" />
                                                    </svg>
                                                </span>
                                                {/*end::Svg Icon*/}
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="../../demo1/dist/apps/ecommerce/catalog/edit-product.html" className="text-dark text-hover-primary">#PXF-534</a>
                                        </td>
                                        <td className="text-end">10 January 2022, 11:00 pm</td>
                                        <td className="text-end">
                                            <a href="\" className="text-dark text-hover-primary">Sean Bean</a>
                                        </td>
                                        <td className="text-end">$119.00</td>
                                        <td className="text-end">
                                            <span className="text-dark fw-bolder">$12.00</span>
                                        </td>
                                        <td className="text-end">
                                            <span className="badge py-3 px-4 fs-7 badge-light-success">Shipped</span>
                                        </td>
                                        <td className="text-end">
                                            <button type="button" className="btn btn-sm btn-icon btn-light btn-active-light-primary toggle h-25px w-25px" data-kt-table-widget-4="expand_row">
                                                {/*begin::Svg Icon | path: icons/duotune/arrows/arr087.svg*/}
                                                <span className="svg-icon svg-icon-3 m-0 toggle-off">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <rect opacity="0.5" x="11" y="18" width="12" height="2" rx="1" transform="rotate(-90 11 18)" fill="black" />
                                                        <rect x="6" y="11" width="12" height="2" rx="1" fill="black" />
                                                    </svg>
                                                </span>
                                                {/*end::Svg Icon*/}
                                                {/*begin::Svg Icon | path: icons/duotune/arrows/arr089.svg*/}
                                                <span className="svg-icon svg-icon-3 m-0 toggle-on">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <rect x="6" y="11" width="12" height="2" rx="1" fill="black" />
                                                    </svg>
                                                </span>
                                                {/*end::Svg Icon*/}
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="../../demo1/dist/apps/ecommerce/catalog/edit-product.html" className="text-dark text-hover-primary">#XGD-249</a>
                                        </td>
                                        <td className="text-end">9 January 2022, 11:00 pm</td>
                                        <td className="text-end">
                                            <a href="\" className="text-dark text-hover-primary">Brian Cox</a>
                                        </td>
                                        <td className="text-end">$660.00</td>
                                        <td className="text-end">
                                            <span className="text-dark fw-bolder">$52.26</span>
                                        </td>
                                        <td className="text-end">
                                            <span className="badge py-3 px-4 fs-7 badge-light-success">Shipped</span>
                                        </td>
                                        <td className="text-end">
                                            <button type="button" className="btn btn-sm btn-icon btn-light btn-active-light-primary toggle h-25px w-25px" data-kt-table-widget-4="expand_row">
                                                {/*begin::Svg Icon | path: icons/duotune/arrows/arr087.svg*/}
                                                <span className="svg-icon svg-icon-3 m-0 toggle-off">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <rect opacity="0.5" x="11" y="18" width="12" height="2" rx="1" transform="rotate(-90 11 18)" fill="black" />
                                                        <rect x="6" y="11" width="12" height="2" rx="1" fill="black" />
                                                    </svg>
                                                </span>
                                                {/*end::Svg Icon*/}
                                                {/*begin::Svg Icon | path: icons/duotune/arrows/arr089.svg*/}
                                                <span className="svg-icon svg-icon-3 m-0 toggle-on">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <rect x="6" y="11" width="12" height="2" rx="1" fill="black" />
                                                    </svg>
                                                </span>
                                                {/*end::Svg Icon*/}
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="../../demo1/dist/apps/ecommerce/catalog/edit-product.html" className="text-dark text-hover-primary">#SKP-035</a>
                                        </td>
                                        <td className="text-end">8 January 2022, 11:00 pm</td>
                                        <td className="text-end">
                                            <a href="\" className="text-dark text-hover-primary">Brian Cox</a>
                                        </td>
                                        <td className="text-end">$290.00</td>
                                        <td className="text-end">
                                            <span className="text-dark fw-bolder">$29.00</span>
                                        </td>
                                        <td className="text-end">
                                            <span className="badge py-3 px-4 fs-7 badge-light-danger">Rejected</span>
                                        </td>
                                        <td className="text-end">
                                            <button type="button" className="btn btn-sm btn-icon btn-light btn-active-light-primary toggle h-25px w-25px" data-kt-table-widget-4="expand_row">
                                                {/*begin::Svg Icon | path: icons/duotune/arrows/arr087.svg*/}
                                                <span className="svg-icon svg-icon-3 m-0 toggle-off">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <rect opacity="0.5" x="11" y="18" width="12" height="2" rx="1" transform="rotate(-90 11 18)" fill="black" />
                                                        <rect x="6" y="11" width="12" height="2" rx="1" fill="black" />
                                                    </svg>
                                                </span>
                                                {/*end::Svg Icon*/}
                                                {/*begin::Svg Icon | path: icons/duotune/arrows/arr089.svg*/}
                                                <span className="svg-icon svg-icon-3 m-0 toggle-on">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <rect x="6" y="11" width="12" height="2" rx="1" fill="black" />
                                                    </svg>
                                                </span>
                                                {/*end::Svg Icon*/}
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="../../demo1/dist/apps/ecommerce/catalog/edit-product.html" className="text-dark text-hover-primary">#SKP-567</a>
                                        </td>
                                        <td className="text-end">5 January 2022, 11:00 pm</td>
                                        <td className="text-end">
                                            <a href="\" className="text-dark text-hover-primary">Mikaela Collins</a>
                                        </td>
                                        <td className="text-end">$590.00</td>
                                        <td className="text-end">
                                            <span className="text-dark fw-bolder">$50.00</span>
                                        </td>
                                        <td className="text-end">
                                            <span className="badge py-3 px-4 fs-7 badge-light-success">Shipped</span>
                                        </td>
                                        <td className="text-end">
                                            <button type="button" className="btn btn-sm btn-icon btn-light btn-active-light-primary toggle h-25px w-25px" data-kt-table-widget-4="expand_row">
                                                {/*begin::Svg Icon | path: icons/duotune/arrows/arr087.svg*/}
                                                <span className="svg-icon svg-icon-3 m-0 toggle-off">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <rect opacity="0.5" x="11" y="18" width="12" height="2" rx="1" transform="rotate(-90 11 18)" fill="black" />
                                                        <rect x="6" y="11" width="12" height="2" rx="1" fill="black" />
                                                    </svg>
                                                </span>
                                                {/*end::Svg Icon*/}
                                                {/*begin::Svg Icon | path: icons/duotune/arrows/arr089.svg*/}
                                                <span className="svg-icon svg-icon-3 m-0 toggle-on">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <rect x="6" y="11" width="12" height="2" rx="1" fill="black" />
                                                    </svg>
                                                </span>
                                                {/*end::Svg Icon*/}
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                                {/*end::Table body*/}
                            </table>
                            {/*end::Table*/}
                        </div>
                        {/*end::Card body*/}
                    </div>
                    {/*end::Table Widget 4*/}
                </div>
                {/*end::Col*/}
            </div>
            {/*end::Row*/}
            {/*begin::Row*/}
            <div className="row gy-5 g-xl-10">
                {/*begin::Col*/}
                <div className="col-xl-4">
                    {/*begin::List widget 5*/}
                    <div className="card card-flush h-xl-100">
                        {/*begin::Header*/}
                        <div className="card-header pt-7">
                            {/*begin::Title*/}
                            <h3 className="card-title align-items-start flex-column">
                                <span className="card-label fw-bolder text-dark">Product Delivery</span>
                                <span className="text-gray-400 mt-1 fw-bold fs-6">1M Products Shipped so far</span>
                            </h3>
                            {/*end::Title*/}
                            {/*begin::Toolbar*/}
                            <div className="card-toolbar">
                                <a href="../../demo1/dist/apps/ecommerce/sales/details.html" className="btn btn-sm btn-light">Order Details</a>
                            </div>
                            {/*end::Toolbar*/}
                        </div>
                        {/*end::Header*/}
                        {/*begin::Body*/}
                        <div className="card-body">
                            {/*begin::Scroll*/}
                            <div className="hover-scroll-overlay-y pe-6 me-n6" style={{ height: '415px' }}>
                                {/*begin::Item*/}
                                <div className="rounded border-gray-300 border-1 border-gray-300 border-dashed px-7 py-3 mb-6">
                                    {/*begin::Info*/}
                                    <div className="d-flex flex-stack mb-3">
                                        {/*begin::Wrapper*/}
                                        <div className="me-3">
                                            {/*begin::Icon*/}
                                            <img src="assets/media/stock/ecommerce/210.gif" className="w-50px ms-n1 me-1" alt="" />
                                            {/*end::Icon*/}
                                            {/*begin::Title*/}
                                            <a href="../../demo1/dist/apps/ecommerce/catalog/edit-product.html" className="text-gray-800 text-hover-primary fw-bolder">Elephant 1802</a>
                                            {/*end::Title*/}
                                        </div>
                                        {/*end::Wrapper*/}
                                        {/*begin::Action*/}
                                        <div className="m-0">
                                            {/*begin::Menu*/}
                                            <button className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-overflow="true">
                                                {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
                                                <span className="svg-icon svg-icon-1">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="4" fill="black" />
                                                        <rect x="11" y="11" width="2.6" height="2.6" rx="1.3" fill="black" />
                                                        <rect x="15" y="11" width="2.6" height="2.6" rx="1.3" fill="black" />
                                                        <rect x="7" y="11" width="2.6" height="2.6" rx="1.3" fill="black" />
                                                    </svg>
                                                </span>
                                                {/*end::Svg Icon*/}
                                            </button>
                                            {/*begin::Menu 2*/}
                                            <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px" data-kt-menu="true">
                                                {/*begin::Menu item*/}
                                                <div className="menu-item px-3">
                                                    <div className="menu-content fs-6 text-dark fw-bolder px-3 py-4">Quick Actions</div>
                                                </div>
                                                {/*end::Menu item*/}
                                                {/*begin::Menu separator*/}
                                                <div className="separator mb-3 opacity-75"></div>
                                                {/*end::Menu separator*/}
                                                {/*begin::Menu item*/}
                                                <div className="menu-item px-3">
                                                    <a href="\" className="menu-link px-3">New Ticket</a>
                                                </div>
                                                {/*end::Menu item*/}
                                                {/*begin::Menu item*/}
                                                <div className="menu-item px-3">
                                                    <a href="\" className="menu-link px-3">New Customer</a>
                                                </div>
                                                {/*end::Menu item*/}
                                                {/*begin::Menu item*/}
                                                <div className="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-start">
                                                    {/*begin::Menu item*/}
                                                    <a href="\" className="menu-link px-3">
                                                        <span className="menu-title">New Group</span>
                                                        <span className="menu-arrow"></span>
                                                    </a>
                                                    {/*end::Menu item*/}
                                                    {/*begin::Menu sub*/}
                                                    <div className="menu-sub menu-sub-dropdown w-175px py-4">
                                                        {/*begin::Menu item*/}
                                                        <div className="menu-item px-3">
                                                            <a href="\" className="menu-link px-3">Admin Group</a>
                                                        </div>
                                                        {/*end::Menu item*/}
                                                        {/*begin::Menu item*/}
                                                        <div className="menu-item px-3">
                                                            <a href="\" className="menu-link px-3">Staff Group</a>
                                                        </div>
                                                        {/*end::Menu item*/}
                                                        {/*begin::Menu item*/}
                                                        <div className="menu-item px-3">
                                                            <a href="\" className="menu-link px-3">Member Group</a>
                                                        </div>
                                                        {/*end::Menu item*/}
                                                    </div>
                                                    {/*end::Menu sub*/}
                                                </div>
                                                {/*end::Menu item*/}
                                                {/*begin::Menu item*/}
                                                <div className="menu-item px-3">
                                                    <a href="\" className="menu-link px-3">New Contact</a>
                                                </div>
                                                {/*end::Menu item*/}
                                                {/*begin::Menu separator*/}
                                                <div className="separator mt-3 opacity-75"></div>
                                                {/*end::Menu separator*/}
                                                {/*begin::Menu item*/}
                                                <div className="menu-item px-3">
                                                    <div className="menu-content px-3 py-3">
                                                        <a className="btn btn-primary btn-sm px-4" href="\">Generate Reports</a>
                                                    </div>
                                                </div>
                                                {/*end::Menu item*/}
                                            </div>
                                            {/*end::Menu 2*/}
                                            {/*end::Menu*/}
                                        </div>
                                        {/*end::Action*/}
                                    </div>
                                    {/*end::Info*/}
                                    {/*begin::Customer*/}
                                    <div className="d-flex flex-stack">
                                        {/*begin::Name*/}
                                        <span className="text-gray-400 fw-bolder">To:
                                            <a href="../../demo1/dist/apps/ecommerce/sales/details.html" className="text-gray-800 text-hover-primary fw-bolder">Jason Bourne</a></span>
                                        {/*end::Name*/}
                                        {/*begin::Label*/}
                                        <span className="badge badge-light-success">Delivered</span>
                                        {/*end::Label*/}
                                    </div>
                                    {/*end::Customer*/}
                                </div>
                                {/*end::Item*/}
                                {/*begin::Item*/}
                                <div className="rounded border-gray-300 border-1 border-gray-300 border-dashed px-7 py-3 mb-6">
                                    {/*begin::Info*/}
                                    <div className="d-flex flex-stack mb-3">
                                        {/*begin::Wrapper*/}
                                        <div className="me-3">
                                            {/*begin::Icon*/}
                                            <img src="assets/media/stock/ecommerce/209.gif" className="w-50px ms-n1 me-1" alt="" />
                                            {/*end::Icon*/}
                                            {/*begin::Title*/}
                                            <a href="../../demo1/dist/apps/ecommerce/catalog/edit-product.html" className="text-gray-800 text-hover-primary fw-bolder">RiseUP</a>
                                            {/*end::Title*/}
                                        </div>
                                        {/*end::Wrapper*/}
                                        {/*begin::Action*/}
                                        <div className="m-0">
                                            {/*begin::Menu*/}
                                            <button className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-overflow="true">
                                                {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
                                                <span className="svg-icon svg-icon-1">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="4" fill="black" />
                                                        <rect x="11" y="11" width="2.6" height="2.6" rx="1.3" fill="black" />
                                                        <rect x="15" y="11" width="2.6" height="2.6" rx="1.3" fill="black" />
                                                        <rect x="7" y="11" width="2.6" height="2.6" rx="1.3" fill="black" />
                                                    </svg>
                                                </span>
                                                {/*end::Svg Icon*/}
                                            </button>
                                            {/*begin::Menu 2*/}
                                            <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px" data-kt-menu="true">
                                                {/*begin::Menu item*/}
                                                <div className="menu-item px-3">
                                                    <div className="menu-content fs-6 text-dark fw-bolder px-3 py-4">Quick Actions</div>
                                                </div>
                                                {/*end::Menu item*/}
                                                {/*begin::Menu separator*/}
                                                <div className="separator mb-3 opacity-75"></div>
                                                {/*end::Menu separator*/}
                                                {/*begin::Menu item*/}
                                                <div className="menu-item px-3">
                                                    <a href="\" className="menu-link px-3">New Ticket</a>
                                                </div>
                                                {/*end::Menu item*/}
                                                {/*begin::Menu item*/}
                                                <div className="menu-item px-3">
                                                    <a href="\" className="menu-link px-3">New Customer</a>
                                                </div>
                                                {/*end::Menu item*/}
                                                {/*begin::Menu item*/}
                                                <div className="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-start">
                                                    {/*begin::Menu item*/}
                                                    <a href="\" className="menu-link px-3">
                                                        <span className="menu-title">New Group</span>
                                                        <span className="menu-arrow"></span>
                                                    </a>
                                                    {/*end::Menu item*/}
                                                    {/*begin::Menu sub*/}
                                                    <div className="menu-sub menu-sub-dropdown w-175px py-4">
                                                        {/*begin::Menu item*/}
                                                        <div className="menu-item px-3">
                                                            <a href="\" className="menu-link px-3">Admin Group</a>
                                                        </div>
                                                        {/*end::Menu item*/}
                                                        {/*begin::Menu item*/}
                                                        <div className="menu-item px-3">
                                                            <a href="\" className="menu-link px-3">Staff Group</a>
                                                        </div>
                                                        {/*end::Menu item*/}
                                                        {/*begin::Menu item*/}
                                                        <div className="menu-item px-3">
                                                            <a href="\" className="menu-link px-3">Member Group</a>
                                                        </div>
                                                        {/*end::Menu item*/}
                                                    </div>
                                                    {/*end::Menu sub*/}
                                                </div>
                                                {/*end::Menu item*/}
                                                {/*begin::Menu item*/}
                                                <div className="menu-item px-3">
                                                    <a href="\" className="menu-link px-3">New Contact</a>
                                                </div>
                                                {/*end::Menu item*/}
                                                {/*begin::Menu separator*/}
                                                <div className="separator mt-3 opacity-75"></div>
                                                {/*end::Menu separator*/}
                                                {/*begin::Menu item*/}
                                                <div className="menu-item px-3">
                                                    <div className="menu-content px-3 py-3">
                                                        <a className="btn btn-primary btn-sm px-4" href="\">Generate Reports</a>
                                                    </div>
                                                </div>
                                                {/*end::Menu item*/}
                                            </div>
                                            {/*end::Menu 2*/}
                                            {/*end::Menu*/}
                                        </div>
                                        {/*end::Action*/}
                                    </div>
                                    {/*end::Info*/}
                                    {/*begin::Customer*/}
                                    <div className="d-flex flex-stack">
                                        {/*begin::Name*/}
                                        <span className="text-gray-400 fw-bolder">To:
                                            <a href="../../demo1/dist/apps/ecommerce/sales/details.html" className="text-gray-800 text-hover-primary fw-bolder">Marie Durant</a></span>
                                        {/*end::Name*/}
                                        {/*begin::Label*/}
                                        <span className="badge badge-light-primary">Shipping</span>
                                        {/*end::Label*/}
                                    </div>
                                    {/*end::Customer*/}
                                </div>
                                {/*end::Item*/}
                                {/*begin::Item*/}
                                <div className="rounded border-gray-300 border-1 border-gray-300 border-dashed px-7 py-3 mb-6">
                                    {/*begin::Info*/}
                                    <div className="d-flex flex-stack mb-3">
                                        {/*begin::Wrapper*/}
                                        <div className="me-3">
                                            {/*begin::Icon*/}
                                            <img src="assets/media/stock/ecommerce/214.gif" className="w-50px ms-n1 me-1" alt="" />
                                            {/*end::Icon*/}
                                            {/*begin::Title*/}
                                            <a href="../../demo1/dist/apps/ecommerce/catalog/edit-product.html" className="text-gray-800 text-hover-primary fw-bolder">Yellow Stone</a>
                                            {/*end::Title*/}
                                        </div>
                                        {/*end::Wrapper*/}
                                        {/*begin::Action*/}
                                        <div className="m-0">
                                            {/*begin::Menu*/}
                                            <button className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-overflow="true">
                                                {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
                                                <span className="svg-icon svg-icon-1">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="4" fill="black" />
                                                        <rect x="11" y="11" width="2.6" height="2.6" rx="1.3" fill="black" />
                                                        <rect x="15" y="11" width="2.6" height="2.6" rx="1.3" fill="black" />
                                                        <rect x="7" y="11" width="2.6" height="2.6" rx="1.3" fill="black" />
                                                    </svg>
                                                </span>
                                                {/*end::Svg Icon*/}
                                            </button>
                                            {/*begin::Menu 2*/}
                                            <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px" data-kt-menu="true">
                                                {/*begin::Menu item*/}
                                                <div className="menu-item px-3">
                                                    <div className="menu-content fs-6 text-dark fw-bolder px-3 py-4">Quick Actions</div>
                                                </div>
                                                {/*end::Menu item*/}
                                                {/*begin::Menu separator*/}
                                                <div className="separator mb-3 opacity-75"></div>
                                                {/*end::Menu separator*/}
                                                {/*begin::Menu item*/}
                                                <div className="menu-item px-3">
                                                    <a href="\" className="menu-link px-3">New Ticket</a>
                                                </div>
                                                {/*end::Menu item*/}
                                                {/*begin::Menu item*/}
                                                <div className="menu-item px-3">
                                                    <a href="\" className="menu-link px-3">New Customer</a>
                                                </div>
                                                {/*end::Menu item*/}
                                                {/*begin::Menu item*/}
                                                <div className="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-start">
                                                    {/*begin::Menu item*/}
                                                    <a href="\" className="menu-link px-3">
                                                        <span className="menu-title">New Group</span>
                                                        <span className="menu-arrow"></span>
                                                    </a>
                                                    {/*end::Menu item*/}
                                                    {/*begin::Menu sub*/}
                                                    <div className="menu-sub menu-sub-dropdown w-175px py-4">
                                                        {/*begin::Menu item*/}
                                                        <div className="menu-item px-3">
                                                            <a href="\" className="menu-link px-3">Admin Group</a>
                                                        </div>
                                                        {/*end::Menu item*/}
                                                        {/*begin::Menu item*/}
                                                        <div className="menu-item px-3">
                                                            <a href="\" className="menu-link px-3">Staff Group</a>
                                                        </div>
                                                        {/*end::Menu item*/}
                                                        {/*begin::Menu item*/}
                                                        <div className="menu-item px-3">
                                                            <a href="\" className="menu-link px-3">Member Group</a>
                                                        </div>
                                                        {/*end::Menu item*/}
                                                    </div>
                                                    {/*end::Menu sub*/}
                                                </div>
                                                {/*end::Menu item*/}
                                                {/*begin::Menu item*/}
                                                <div className="menu-item px-3">
                                                    <a href="\" className="menu-link px-3">New Contact</a>
                                                </div>
                                                {/*end::Menu item*/}
                                                {/*begin::Menu separator*/}
                                                <div className="separator mt-3 opacity-75"></div>
                                                {/*end::Menu separator*/}
                                                {/*begin::Menu item*/}
                                                <div className="menu-item px-3">
                                                    <div className="menu-content px-3 py-3">
                                                        <a className="btn btn-primary btn-sm px-4" href="\">Generate Reports</a>
                                                    </div>
                                                </div>
                                                {/*end::Menu item*/}
                                            </div>
                                            {/*end::Menu 2*/}
                                            {/*end::Menu*/}
                                        </div>
                                        {/*end::Action*/}
                                    </div>
                                    {/*end::Info*/}
                                    {/*begin::Customer*/}
                                    <div className="d-flex flex-stack">
                                        {/*begin::Name*/}
                                        <span className="text-gray-400 fw-bolder">To:
                                            <a href="../../demo1/dist/apps/ecommerce/sales/details.html" className="text-gray-800 text-hover-primary fw-bolder">Dan Wilson</a></span>
                                        {/*end::Name*/}
                                        {/*begin::Label*/}
                                        <span className="badge badge-light-danger">Confirmed</span>
                                        {/*end::Label*/}
                                    </div>
                                    {/*end::Customer*/}
                                </div>
                                {/*end::Item*/}
                                {/*begin::Item*/}
                                <div className="rounded border-gray-300 border-1 border-gray-300 border-dashed px-7 py-3 mb-6">
                                    {/*begin::Info*/}
                                    <div className="d-flex flex-stack mb-3">
                                        {/*begin::Wrapper*/}
                                        <div className="me-3">
                                            {/*begin::Icon*/}
                                            <img src="assets/media/stock/ecommerce/211.gif" className="w-50px ms-n1 me-1" alt="" />
                                            {/*end::Icon*/}
                                            {/*begin::Title*/}
                                            <a href="../../demo1/dist/apps/ecommerce/catalog/edit-product.html" className="text-gray-800 text-hover-primary fw-bolder">Elephant 1802</a>
                                            {/*end::Title*/}
                                        </div>
                                        {/*end::Wrapper*/}
                                        {/*begin::Action*/}
                                        <div className="m-0">
                                            {/*begin::Menu*/}
                                            <button className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-overflow="true">
                                                {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
                                                <span className="svg-icon svg-icon-1">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="4" fill="black" />
                                                        <rect x="11" y="11" width="2.6" height="2.6" rx="1.3" fill="black" />
                                                        <rect x="15" y="11" width="2.6" height="2.6" rx="1.3" fill="black" />
                                                        <rect x="7" y="11" width="2.6" height="2.6" rx="1.3" fill="black" />
                                                    </svg>
                                                </span>
                                                {/*end::Svg Icon*/}
                                            </button>
                                            {/*begin::Menu 2*/}
                                            <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px" data-kt-menu="true">
                                                {/*begin::Menu item*/}
                                                <div className="menu-item px-3">
                                                    <div className="menu-content fs-6 text-dark fw-bolder px-3 py-4">Quick Actions</div>
                                                </div>
                                                {/*end::Menu item*/}
                                                {/*begin::Menu separator*/}
                                                <div className="separator mb-3 opacity-75"></div>
                                                {/*end::Menu separator*/}
                                                {/*begin::Menu item*/}
                                                <div className="menu-item px-3">
                                                    <a href="\" className="menu-link px-3">New Ticket</a>
                                                </div>
                                                {/*end::Menu item*/}
                                                {/*begin::Menu item*/}
                                                <div className="menu-item px-3">
                                                    <a href="\" className="menu-link px-3">New Customer</a>
                                                </div>
                                                {/*end::Menu item*/}
                                                {/*begin::Menu item*/}
                                                <div className="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-start">
                                                    {/*begin::Menu item*/}
                                                    <a href="\" className="menu-link px-3">
                                                        <span className="menu-title">New Group</span>
                                                        <span className="menu-arrow"></span>
                                                    </a>
                                                    {/*end::Menu item*/}
                                                    {/*begin::Menu sub*/}
                                                    <div className="menu-sub menu-sub-dropdown w-175px py-4">
                                                        {/*begin::Menu item*/}
                                                        <div className="menu-item px-3">
                                                            <a href="\" className="menu-link px-3">Admin Group</a>
                                                        </div>
                                                        {/*end::Menu item*/}
                                                        {/*begin::Menu item*/}
                                                        <div className="menu-item px-3">
                                                            <a href="\" className="menu-link px-3">Staff Group</a>
                                                        </div>
                                                        {/*end::Menu item*/}
                                                        {/*begin::Menu item*/}
                                                        <div className="menu-item px-3">
                                                            <a href="\" className="menu-link px-3">Member Group</a>
                                                        </div>
                                                        {/*end::Menu item*/}
                                                    </div>
                                                    {/*end::Menu sub*/}
                                                </div>
                                                {/*end::Menu item*/}
                                                {/*begin::Menu item*/}
                                                <div className="menu-item px-3">
                                                    <a href="\" className="menu-link px-3">New Contact</a>
                                                </div>
                                                {/*end::Menu item*/}
                                                {/*begin::Menu separator*/}
                                                <div className="separator mt-3 opacity-75"></div>
                                                {/*end::Menu separator*/}
                                                {/*begin::Menu item*/}
                                                <div className="menu-item px-3">
                                                    <div className="menu-content px-3 py-3">
                                                        <a className="btn btn-primary btn-sm px-4" href="\">Generate Reports</a>
                                                    </div>
                                                </div>
                                                {/*end::Menu item*/}
                                            </div>
                                            {/*end::Menu 2*/}
                                            {/*end::Menu*/}
                                        </div>
                                        {/*end::Action*/}
                                    </div>
                                    {/*end::Info*/}
                                    {/*begin::Customer*/}
                                    <div className="d-flex flex-stack">
                                        {/*begin::Name*/}
                                        <span className="text-gray-400 fw-bolder">To:
                                            <a href="../../demo1/dist/apps/ecommerce/sales/details.html" className="text-gray-800 text-hover-primary fw-bolder">Lebron Wayde</a></span>
                                        {/*end::Name*/}
                                        {/*begin::Label*/}
                                        <span className="badge badge-light-success">Delivered</span>
                                        {/*end::Label*/}
                                    </div>
                                    {/*end::Customer*/}
                                </div>
                                {/*end::Item*/}
                                {/*begin::Item*/}
                                <div className="rounded border-gray-300 border-1 border-gray-300 border-dashed px-7 py-3 mb-6">
                                    {/*begin::Info*/}
                                    <div className="d-flex flex-stack mb-3">
                                        {/*begin::Wrapper*/}
                                        <div className="me-3">
                                            {/*begin::Icon*/}
                                            <img src="assets/media/stock/ecommerce/215.gif" className="w-50px ms-n1 me-1" alt="" />
                                            {/*end::Icon*/}
                                            {/*begin::Title*/}
                                            <a href="../../demo1/dist/apps/ecommerce/catalog/edit-product.html" className="text-gray-800 text-hover-primary fw-bolder">RiseUP</a>
                                            {/*end::Title*/}
                                        </div>
                                        {/*end::Wrapper*/}
                                        {/*begin::Action*/}
                                        <div className="m-0">
                                            {/*begin::Menu*/}
                                            <button className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-overflow="true">
                                                {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
                                                <span className="svg-icon svg-icon-1">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="4" fill="black" />
                                                        <rect x="11" y="11" width="2.6" height="2.6" rx="1.3" fill="black" />
                                                        <rect x="15" y="11" width="2.6" height="2.6" rx="1.3" fill="black" />
                                                        <rect x="7" y="11" width="2.6" height="2.6" rx="1.3" fill="black" />
                                                    </svg>
                                                </span>
                                                {/*end::Svg Icon*/}
                                            </button>
                                            {/*begin::Menu 2*/}
                                            <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px" data-kt-menu="true">
                                                {/*begin::Menu item*/}
                                                <div className="menu-item px-3">
                                                    <div className="menu-content fs-6 text-dark fw-bolder px-3 py-4">Quick Actions</div>
                                                </div>
                                                {/*end::Menu item*/}
                                                {/*begin::Menu separator*/}
                                                <div className="separator mb-3 opacity-75"></div>
                                                {/*end::Menu separator*/}
                                                {/*begin::Menu item*/}
                                                <div className="menu-item px-3">
                                                    <a href="\" className="menu-link px-3">New Ticket</a>
                                                </div>
                                                {/*end::Menu item*/}
                                                {/*begin::Menu item*/}
                                                <div className="menu-item px-3">
                                                    <a href="\" className="menu-link px-3">New Customer</a>
                                                </div>
                                                {/*end::Menu item*/}
                                                {/*begin::Menu item*/}
                                                <div className="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-start">
                                                    {/*begin::Menu item*/}
                                                    <a href="\" className="menu-link px-3">
                                                        <span className="menu-title">New Group</span>
                                                        <span className="menu-arrow"></span>
                                                    </a>
                                                    {/*end::Menu item*/}
                                                    {/*begin::Menu sub*/}
                                                    <div className="menu-sub menu-sub-dropdown w-175px py-4">
                                                        {/*begin::Menu item*/}
                                                        <div className="menu-item px-3">
                                                            <a href="\" className="menu-link px-3">Admin Group</a>
                                                        </div>
                                                        {/*end::Menu item*/}
                                                        {/*begin::Menu item*/}
                                                        <div className="menu-item px-3">
                                                            <a href="\" className="menu-link px-3">Staff Group</a>
                                                        </div>
                                                        {/*end::Menu item*/}
                                                        {/*begin::Menu item*/}
                                                        <div className="menu-item px-3">
                                                            <a href="\" className="menu-link px-3">Member Group</a>
                                                        </div>
                                                        {/*end::Menu item*/}
                                                    </div>
                                                    {/*end::Menu sub*/}
                                                </div>
                                                {/*end::Menu item*/}
                                                {/*begin::Menu item*/}
                                                <div className="menu-item px-3">
                                                    <a href="\" className="menu-link px-3">New Contact</a>
                                                </div>
                                                {/*end::Menu item*/}
                                                {/*begin::Menu separator*/}
                                                <div className="separator mt-3 opacity-75"></div>
                                                {/*end::Menu separator*/}
                                                {/*begin::Menu item*/}
                                                <div className="menu-item px-3">
                                                    <div className="menu-content px-3 py-3">
                                                        <a className="btn btn-primary btn-sm px-4" href="\">Generate Reports</a>
                                                    </div>
                                                </div>
                                                {/*end::Menu item*/}
                                            </div>
                                            {/*end::Menu 2*/}
                                            {/*end::Menu*/}
                                        </div>
                                        {/*end::Action*/}
                                    </div>
                                    {/*end::Info*/}
                                    {/*begin::Customer*/}
                                    <div className="d-flex flex-stack">
                                        {/*begin::Name*/}
                                        <span className="text-gray-400 fw-bolder">To:
                                            <a href="../../demo1/dist/apps/ecommerce/sales/details.html" className="text-gray-800 text-hover-primary fw-bolder">Ana Simmons</a></span>
                                        {/*end::Name*/}
                                        {/*begin::Label*/}
                                        <span className="badge badge-light-primary">Shipping</span>
                                        {/*end::Label*/}
                                    </div>
                                    {/*end::Customer*/}
                                </div>
                                {/*end::Item*/}
                                {/*begin::Item*/}
                                <div className="rounded border-gray-300 border-1 border-gray-300 border-dashed px-7 py-3">
                                    {/*begin::Info*/}
                                    <div className="d-flex flex-stack mb-3">
                                        {/*begin::Wrapper*/}
                                        <div className="me-3">
                                            {/*begin::Icon*/}
                                            <img src="assets/media/stock/ecommerce/192.gif" className="w-50px ms-n1 me-1" alt="" />
                                            {/*end::Icon*/}
                                            {/*begin::Title*/}
                                            <a href="../../demo1/dist/apps/ecommerce/catalog/edit-product.html" className="text-gray-800 text-hover-primary fw-bolder">Yellow Stone</a>
                                            {/*end::Title*/}
                                        </div>
                                        {/*end::Wrapper*/}
                                        {/*begin::Action*/}
                                        <div className="m-0">
                                            {/*begin::Menu*/}
                                            <button className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-overflow="true">
                                                {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
                                                <span className="svg-icon svg-icon-1">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="4" fill="black" />
                                                        <rect x="11" y="11" width="2.6" height="2.6" rx="1.3" fill="black" />
                                                        <rect x="15" y="11" width="2.6" height="2.6" rx="1.3" fill="black" />
                                                        <rect x="7" y="11" width="2.6" height="2.6" rx="1.3" fill="black" />
                                                    </svg>
                                                </span>
                                                {/*end::Svg Icon*/}
                                            </button>
                                            {/*begin::Menu 2*/}
                                            <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px" data-kt-menu="true">
                                                {/*begin::Menu item*/}
                                                <div className="menu-item px-3">
                                                    <div className="menu-content fs-6 text-dark fw-bolder px-3 py-4">Quick Actions</div>
                                                </div>
                                                {/*end::Menu item*/}
                                                {/*begin::Menu separator*/}
                                                <div className="separator mb-3 opacity-75"></div>
                                                {/*end::Menu separator*/}
                                                {/*begin::Menu item*/}
                                                <div className="menu-item px-3">
                                                    <a href="\" className="menu-link px-3">New Ticket</a>
                                                </div>
                                                {/*end::Menu item*/}
                                                {/*begin::Menu item*/}
                                                <div className="menu-item px-3">
                                                    <a href="\" className="menu-link px-3">New Customer</a>
                                                </div>
                                                {/*end::Menu item*/}
                                                {/*begin::Menu item*/}
                                                <div className="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-start">
                                                    {/*begin::Menu item*/}
                                                    <a href="\" className="menu-link px-3">
                                                        <span className="menu-title">New Group</span>
                                                        <span className="menu-arrow"></span>
                                                    </a>
                                                    {/*end::Menu item*/}
                                                    {/*begin::Menu sub*/}
                                                    <div className="menu-sub menu-sub-dropdown w-175px py-4">
                                                        {/*begin::Menu item*/}
                                                        <div className="menu-item px-3">
                                                            <a href="\" className="menu-link px-3">Admin Group</a>
                                                        </div>
                                                        {/*end::Menu item*/}
                                                        {/*begin::Menu item*/}
                                                        <div className="menu-item px-3">
                                                            <a href="\" className="menu-link px-3">Staff Group</a>
                                                        </div>
                                                        {/*end::Menu item*/}
                                                        {/*begin::Menu item*/}
                                                        <div className="menu-item px-3">
                                                            <a href="\" className="menu-link px-3">Member Group</a>
                                                        </div>
                                                        {/*end::Menu item*/}
                                                    </div>
                                                    {/*end::Menu sub*/}
                                                </div>
                                                {/*end::Menu item*/}
                                                {/*begin::Menu item*/}
                                                <div className="menu-item px-3">
                                                    <a href="\" className="menu-link px-3">New Contact</a>
                                                </div>
                                                {/*end::Menu item*/}
                                                {/*begin::Menu separator*/}
                                                <div className="separator mt-3 opacity-75"></div>
                                                {/*end::Menu separator*/}
                                                {/*begin::Menu item*/}
                                                <div className="menu-item px-3">
                                                    <div className="menu-content px-3 py-3">
                                                        <a className="btn btn-primary btn-sm px-4" href="\">Generate Reports</a>
                                                    </div>
                                                </div>
                                                {/*end::Menu item*/}
                                            </div>
                                            {/*end::Menu 2*/}
                                            {/*end::Menu*/}
                                        </div>
                                        {/*end::Action*/}
                                    </div>
                                    {/*end::Info*/}
                                    {/*begin::Customer*/}
                                    <div className="d-flex flex-stack">
                                        {/*begin::Name*/}
                                        <span className="text-gray-400 fw-bolder">To:
                                            <a href="../../demo1/dist/apps/ecommerce/sales/details.html" className="text-gray-800 text-hover-primary fw-bolder">Kevin Leonard</a></span>
                                        {/*end::Name*/}
                                        {/*begin::Label*/}
                                        <span className="badge badge-light-danger">Confirmed</span>
                                        {/*end::Label*/}
                                    </div>
                                    {/*end::Customer*/}
                                </div>
                                {/*end::Item*/}
                            </div>
                            {/*end::Scroll*/}
                        </div>
                        {/*end::Body*/}
                    </div>
                    {/*end::List widget 5*/}
                </div>
                {/*end::Col*/}
                {/*begin::Col*/}
                <div className="col-xl-8">
                    {/*begin::Table Widget 5*/}
                    <div className="card card-flush h-xl-100">
                        {/*begin::Card header*/}
                        <div className="card-header pt-7">
                            {/*begin::Title*/}
                            <h3 className="card-title align-items-start flex-column">
                                <span className="card-label fw-bolder text-dark">Stock Report</span>
                                <span className="text-gray-400 mt-1 fw-bold fs-6">Total 2,356 Items in the Stock</span>
                            </h3>
                            {/*end::Title*/}
                            {/*begin::Actions*/}
                            <div className="card-toolbar">
                                {/*begin::Filters*/}
                                <div className="d-flex flex-stack flex-wrap gap-4">
                                    {/*begin::Destination*/}
                                    <div className="d-flex align-items-center fw-bolder">
                                        {/*begin::Label*/}
                                        <div className="text-muted fs-7 me-2">Cateogry</div>
                                        {/*end::Label*/}
                                        {/*begin::Select*/}
                                        <select className="form-select form-select-transparent text-dark fs-7 lh-1 fw-bolder py-0 ps-3 w-auto" data-control="select2" data-hide-search="true" data-dropdown-css-className="w-150px" data-placeholder="Select an option">
                                            <option></option>
                                            <option defaultValue="Show All" >Show All</option>
                                            <option defaultValue="a">Category A</option>
                                            <option defaultValue="b">Category B</option>
                                        </select>
                                        {/*end::Select*/}
                                    </div>
                                    {/*end::Destination*/}
                                    {/*begin::Status*/}
                                    <div className="d-flex align-items-center fw-bolder">
                                        {/*begin::Label*/}
                                        <div className="text-muted fs-7 me-2">Status</div>
                                        {/*end::Label*/}
                                        {/*begin::Select*/}
                                        <select className="form-select form-select-transparent text-dark fs-7 lh-1 fw-bolder py-0 ps-3 w-auto" data-control="select2" data-hide-search="true" data-dropdown-css-className="w-150px" data-placeholder="Select an option" data-kt-table-widget-5="filter_status">
                                            <option></option>
                                            <option defaultValue="Show All" >Show All</option>
                                            <option defaultValue="In Stock">In Stock</option>
                                            <option defaultValue="Out of Stock">Out of Stock</option>
                                            <option defaultValue="Low Stock">Low Stock</option>
                                        </select>
                                        {/*end::Select*/}
                                    </div>
                                    {/*end::Status*/}
                                    {/*begin::Search*/}
                                    <a href="../../demo1/dist/apps/ecommerce/catalog/products.html" className="btn btn-light btn-sm">View Stock</a>
                                    {/*end::Search*/}
                                </div>
                                {/*begin::Filters*/}
                            </div>
                            {/*end::Actions*/}
                        </div>
                        {/*end::Card header*/}
                        {/*begin::Card body*/}
                        <div className="card-body">
                            {/*begin::Table*/}
                            <table className="table align-middle table-row-dashed fs-6 gy-3" id="kt_table_widget_5_table">
                                {/*begin::Table head*/}
                                <thead>
                                    {/*begin::Table row*/}
                                    <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                                        <th className="min-w-100px">Item</th>
                                        <th className="text-end pe-3 min-w-100px">Product ID</th>
                                        <th className="text-end pe-3 min-w-150px">Date Added</th>
                                        <th className="text-end pe-3 min-w-100px">Price</th>
                                        <th className="text-end pe-3 min-w-50px">Status</th>
                                        <th className="text-end pe-0 min-w-25px">Qty</th>
                                    </tr>
                                    {/*end::Table row*/}
                                </thead>
                                {/*end::Table head*/}
                                {/*begin::Table body*/}
                                <tbody className="fw-bolder text-gray-600">
                                    <tr>
                                        {/*begin::Item*/}
                                        <td>
                                            <a href="../../demo1/dist/apps/ecommerce/catalog/edit-product.html" className="text-dark text-hover-primary">Macbook Air M1</a>
                                        </td>
                                        {/*end::Item*/}
                                        {/*begin::Product ID*/}
                                        <td className="text-end">#XGY-356</td>
                                        {/*end::Product ID*/}
                                        {/*begin::Date added*/}
                                        <td className="text-end">Mar 10, 2022</td>
                                        {/*end::Date added*/}
                                        {/*begin::Price*/}
                                        <td className="text-end">$1,230</td>
                                        {/*end::Price*/}
                                        {/*begin::Status*/}
                                        <td className="text-end">
                                            <span className="badge py-3 px-4 fs-7 badge-light-primary">In Stock</span>
                                        </td>
                                        {/*end::Status*/}
                                        {/*begin::Qty*/}
                                        <td className="text-end" data-order="58">
                                            <span className="text-dark fw-bolder">58 PCS</span>
                                        </td>
                                        {/*end::Qty*/}
                                    </tr>
                                    <tr>
                                        {/*begin::Item*/}
                                        <td>
                                            <a href="../../demo1/dist/apps/ecommerce/catalog/edit-product.html" className="text-dark text-hover-primary">Surface Laptop 4</a>
                                        </td>
                                        {/*end::Item*/}
                                        {/*begin::Product ID*/}
                                        <td className="text-end">#YHD-047</td>
                                        {/*end::Product ID*/}
                                        {/*begin::Date added*/}
                                        <td className="text-end">Apr 15, 2022</td>
                                        {/*end::Date added*/}
                                        {/*begin::Price*/}
                                        <td className="text-end">$1,060</td>
                                        {/*end::Price*/}
                                        {/*begin::Status*/}
                                        <td className="text-end">
                                            <span className="badge py-3 px-4 fs-7 badge-light-danger">Out of Stock</span>
                                        </td>
                                        {/*end::Status*/}
                                        {/*begin::Qty*/}
                                        <td className="text-end" data-order="0">
                                            <span className="text-dark fw-bolder">0 PCS</span>
                                        </td>
                                        {/*end::Qty*/}
                                    </tr>
                                    <tr>
                                        {/*begin::Item*/}
                                        <td>
                                            <a href="../../demo1/dist/apps/ecommerce/catalog/edit-product.html" className="text-dark text-hover-primary">Logitech MX 250</a>
                                        </td>
                                        {/*end::Item*/}
                                        {/*begin::Product ID*/}
                                        <td className="text-end">#SRR-678</td>
                                        {/*end::Product ID*/}
                                        {/*begin::Date added*/}
                                        <td className="text-end">Dec 20, 2022</td>
                                        {/*end::Date added*/}
                                        {/*begin::Price*/}
                                        <td className="text-end">$64</td>
                                        {/*end::Price*/}
                                        {/*begin::Status*/}
                                        <td className="text-end">
                                            <span className="badge py-3 px-4 fs-7 badge-light-primary">In Stock</span>
                                        </td>
                                        {/*end::Status*/}
                                        {/*begin::Qty*/}
                                        <td className="text-end" data-order="290">
                                            <span className="text-dark fw-bolder">290 PCS</span>
                                        </td>
                                        {/*end::Qty*/}
                                    </tr>
                                    <tr>
                                        {/*begin::Item*/}
                                        <td>
                                            <a href="../../demo1/dist/apps/ecommerce/catalog/edit-product.html" className="text-dark text-hover-primary">AudioEngine HD3</a>
                                        </td>
                                        {/*end::Item*/}
                                        {/*begin::Product ID*/}
                                        <td className="text-end">#PXF-578</td>
                                        {/*end::Product ID*/}
                                        {/*begin::Date added*/}
                                        <td className="text-end">Feb 21, 2022</td>
                                        {/*end::Date added*/}
                                        {/*begin::Price*/}
                                        <td className="text-end">$1,060</td>
                                        {/*end::Price*/}
                                        {/*begin::Status*/}
                                        <td className="text-end">
                                            <span className="badge py-3 px-4 fs-7 badge-light-danger">Out of Stock</span>
                                        </td>
                                        {/*end::Status*/}
                                        {/*begin::Qty*/}
                                        <td className="text-end" data-order="46">
                                            <span className="text-dark fw-bolder">46 PCS</span>
                                        </td>
                                        {/*end::Qty*/}
                                    </tr>
                                    <tr>
                                        {/*begin::Item*/}
                                        <td>
                                            <a href="../../demo1/dist/apps/ecommerce/catalog/edit-product.html" className="text-dark text-hover-primary">HP Hyper LTR</a>
                                        </td>
                                        {/*end::Item*/}
                                        {/*begin::Product ID*/}
                                        <td className="text-end">#PXF-778</td>
                                        {/*end::Product ID*/}
                                        {/*begin::Date added*/}
                                        <td className="text-end">Mar 10, 2022</td>
                                        {/*end::Date added*/}
                                        {/*begin::Price*/}
                                        <td className="text-end">$4500</td>
                                        {/*end::Price*/}
                                        {/*begin::Status*/}
                                        <td className="text-end">
                                            <span className="badge py-3 px-4 fs-7 badge-light-primary">In Stock</span>
                                        </td>
                                        {/*end::Status*/}
                                        {/*begin::Qty*/}
                                        <td className="text-end" data-order="78">
                                            <span className="text-dark fw-bolder">78 PCS</span>
                                        </td>
                                        {/*end::Qty*/}
                                    </tr>
                                    <tr>
                                        {/*begin::Item*/}
                                        <td>
                                            <a href="../../demo1/dist/apps/ecommerce/catalog/edit-product.html" className="text-dark text-hover-primary">Dell 32 UltraSharp</a>
                                        </td>
                                        {/*end::Item*/}
                                        {/*begin::Product ID*/}
                                        <td className="text-end">#XGY-356</td>
                                        {/*end::Product ID*/}
                                        {/*begin::Date added*/}
                                        <td className="text-end">Apr 15, 2022</td>
                                        {/*end::Date added*/}
                                        {/*begin::Price*/}
                                        <td className="text-end">$1,060</td>
                                        {/*end::Price*/}
                                        {/*begin::Status*/}
                                        <td className="text-end">
                                            <span className="badge py-3 px-4 fs-7 badge-light-warning">Low Stock</span>
                                        </td>
                                        {/*end::Status*/}
                                        {/*begin::Qty*/}
                                        <td className="text-end" data-order="8">
                                            <span className="text-dark fw-bolder">8 PCS</span>
                                        </td>
                                        {/*end::Qty*/}
                                    </tr>
                                    <tr>
                                        {/*begin::Item*/}
                                        <td>
                                            <a href="../../demo1/dist/apps/ecommerce/catalog/edit-product.html" className="text-dark text-hover-primary">Google Pixel 6 Pro</a>
                                        </td>
                                        {/*end::Item*/}
                                        {/*begin::Product ID*/}
                                        <td className="text-end">#XVR-425</td>
                                        {/*end::Product ID*/}
                                        {/*begin::Date added*/}
                                        <td className="text-end">Nov 10, 2022</td>
                                        {/*end::Date added*/}
                                        {/*begin::Price*/}
                                        <td className="text-end">$1,060</td>
                                        {/*end::Price*/}
                                        {/*begin::Status*/}
                                        <td className="text-end">
                                            <span className="badge py-3 px-4 fs-7 badge-light-primary">In Stock</span>
                                        </td>
                                        {/*end::Status*/}
                                        {/*begin::Qty*/}
                                        <td className="text-end" data-order="124">
                                            <span className="text-dark fw-bolder">124 PCS</span>
                                        </td>
                                        {/*end::Qty*/}
                                    </tr>
                                </tbody>
                                {/*end::Table body*/}
                            </table>
                            {/*end::Table*/}
                        </div>
                        {/*end::Card body*/}
                    </div>
                    {/*end::Table Widget 5*/}
                </div>
                {/*end::Col*/}
            </div>
            {/*end::Row*/}
        </>
    )
})
