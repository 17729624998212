import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import urls from '../common/urls';
import { NavLink } from 'react-router-dom';
import { IconParcelsToSend, IconPlay } from './Icons';
import { useStore } from '../stores/store';
import { runInAction } from 'mobx';
import OutsideClickDetector from '../common/OutsideClickDetector';

export default observer(function Header() {
    const [showParcels, setShowParcels] = useState(false);

    const { headerOrderStore: store, statisticsStore, modalStore } = useStore();
    const { setPredicate, clearPredicateAndPaging } = store.filteringAndPaginationController;
    const { modalStore: { show: ShowModalDemoWarning } } = useStore();

    useEffect(() => {
        clearPredicateAndPaging();
        setPredicate('filter', 'to-ship', 1000);
        runInAction(() => {
            store.loadItems();
        })
    }, [store, clearPredicateAndPaging, setPredicate])

    const clickElswhere = () => {
        setShowParcels(false);
    }

    const handleStoreTypeChange = (storeType: string) => {
        statisticsStore.setStoreType(storeType);
        modalStore.showReload();
        setTimeout(() => {
            window.location.reload();
        },
            100);
    }

    const storeTypeToActive = (wantedStoreType: string) => {
        if (statisticsStore.storeType === wantedStoreType) return 'show';
        return '';
    }

    return (
        <>
            {/*begin::Header*/}
            <div id="kt_header" className="header align-items-stretch" >
                {/*begin::Container*/}
                <div className="container-fluid d-flex align-items-stretch justify-content-between">
                    {/*begin::Aside mobile toggle*/}
                    <div className="d-flex align-items-center d-lg-none ms-n2 me-2" title="Show aside menu">
                        <div className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px" id="kt_aside_mobile_toggle">
                            {/*begin::Svg Icon | path: icons/duotune/abstract/abs015.svg*/}
                            <span className="svg-icon svg-icon-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z" fill="black" />
                                    <path opacity="0.3" d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z" fill="black" />
                                </svg>
                            </span>
                            {/*end::Svg Icon*/}
                        </div>
                    </div>
                    {/*end::Aside mobile toggle*/}
                    {/*begin::Mobile logo*/}
                    <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
                        <img alt="Logo" src="/partner-custom/logo.svg" className="d-lg-none h-30px" />
                    </div>
                    {/*end::Mobile logo*/}
                    {/*begin::Wrapper*/}
                    <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
                        {/*begin::Navbar*/}
                        <div className="d-flex align-items-stretch" id="kt_header_nav">
                            {/*begin::Menu wrapper*/}
                            <div className="header-menu align-items-stretch" data-kt-drawer="true" data-kt-drawer-name="header-menu" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'200px', '300px': '250px'}" data-kt-drawer-direction="end" data-kt-drawer-toggle="#kt_header_menu_mobile_toggle" data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}">
                                {/*begin::Menu*/}
                                <div className="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-info menu-state-icon-info menu-state-bullet-info menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch" id="#kt_header_menu" data-kt-menu="true">
                                    <div className={`menu-item ${storeTypeToActive('store-lg')} me-lg-1`} onClick={() => handleStoreTypeChange('store-lg')}>
                                        <span className="py-3">
                                            <span className="menu-title">Kliknij, aby wybrać wielkość sklepu <i className="fa fa-arrow-right me-1 text-info fs-6 ms-2" /></span>
                                        </span>
                                    </div>
                                    <div className={`menu-item ${storeTypeToActive('store-lg')} me-lg-1`} onClick={() => handleStoreTypeChange('store-lg')}>
                                        <span className="menu-link py-3">
                                            <span className="menu-title">Duży sklep</span>
                                        </span>
                                    </div>
                                    <div className={`menu-item ${storeTypeToActive('store-md')} me-lg-1`} onClick={() => handleStoreTypeChange('store-md')}>
                                        <span className="menu-link py-3">
                                            <span className="menu-title">Średni sklep</span>
                                        </span>
                                    </div>
                                    <div className={`menu-item ${storeTypeToActive('store-sm')} me-lg-1`} onClick={() => handleStoreTypeChange('store-sm')}>
                                        <span className="menu-link py-3">
                                            <span className="menu-title">Mały sklep</span>
                                        </span>
                                    </div>
                                </div>
                                {/*end::Menu*/}
                            </div>
                            {/*end::Menu wrapper*/}
                        </div>
                        {/*end::Navbar*/}
                        {/*begin::Toolbar wrapper*/}
                        <div className="d-flex align-items-stretch flex-shrink-0">
                            {/*begin::Search*/}
                            <div className="d-flex align-items-stretch ms-1 ms-lg-3">
                                {/*begin::Search*/}
                                <div id="" className="d-flex align-items-stretch" onClick={() => setShowParcels(!showParcels)}>
                                    {/*begin::Search toggle*/}
                                    <div className="d-flex align-items-center" data-kt-search-element="toggle" id="kt_header_search_toggle">
                                        <div className="btn btn-icon btn-icon-muted btn-active-light btn-active-color-info w-30px h-30px w-md-40px h-md-40px">
                                            {/*begin::Svg Icon | path: icons/duotune/general/gen021.svg*/}
                                            <IconParcelsToSend size='1' />
                                            {/*end::Svg Icon*/}
                                        </div>
                                    </div>
                                    {/*end::Search toggle*/}
                                    {/*begin::Menu*/}
                                    <div data-kt-search-element="content" className={`menu menu-sub menu-sub-dropdown p-7 w-325px w-md-375px ${showParcels ? 'show' : ''}`}
                                        style={{ position: 'fixed', inset: '0px 0px auto auto', margin: '0px', transform: 'translate3d(-129px, 65px, 0px)' }}>
                                        {/*begin::Wrapper*/}
                                        <div data-kt-search-element="wrapper">
                                            {/*begin::Recently viewed*/}
                                            <div className="mb-5" data-kt-search-element="main">
                                                {/*begin::Heading*/}
                                                <div className="d-flex flex-stack fw-bold mb-4">
                                                    {/*begin::Label*/}
                                                    <span className="text-muted fs-6 me-2">Paczki do wysłania ({store.items.length}):</span>
                                                    {/*end::Label*/}
                                                </div>
                                                {/*end::Heading*/}
                                                {/*begin::Items*/}
                                                <OutsideClickDetector onClickOutside={() => clickElswhere()}>
                                                    <div className="scroll-y mh-200px mh-lg-325px">
                                                        {store.items.map((i) => (
                                                            <div key={i.id} className="d-flex align-items-center mb-5">
                                                                {/*begin::Symbol*/}
                                                                <div className="symbol symbol-40px me-4">
                                                                    <span className="symbol-label bg-light">
                                                                        <IconParcelsToSend size='1' />
                                                                    </span>
                                                                </div>
                                                                {/*end::Symbol*/}
                                                                {/*begin::Title*/}
                                                                <div className="d-flex flex-column">
                                                                    <NavLink to={urls.orderDetails(i.id)} className="fs-6 text-gray-800 fw-bold">{i.customerFullName} {i.customerIsPremium && <i className="fa fa-star me-1 text-warning fs-6 ms-2" />}</NavLink>
                                                                    <NavLink to={urls.orderDetails(i.id)} className="fs-7 text-muted fw-bold">Przedmiotów: {i.totalItems} ({i.totalValue}zł), {i.shippmentProvider}</NavLink>
                                                                </div>
                                                                {/*end::Title*/}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </OutsideClickDetector>
                                                {/*end::Items*/}
                                            </div>
                                            {/*end::Recently viewed*/}
                                            {/*begin::Empty*/}
                                            <div data-kt-search-element="empty" className="text-center d-none">
                                                {/*begin::Icon*/}
                                                <div className="pt-10 pb-10">
                                                    {/*begin::Svg Icon | path: icons/duotune/files/fil024.svg*/}
                                                    <span className="svg-icon svg-icon-4x opacity-50">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path opacity="0.3" d="M14 2H6C4.89543 2 4 2.89543 4 4V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V8L14 2Z" fill="black" />
                                                            <path d="M20 8L14 2V6C14 7.10457 14.8954 8 16 8H20Z" fill="black" />
                                                            <rect x="13.6993" y="13.6656" width="4.42828" height="1.73089" rx="0.865447" transform="rotate(45 13.6993 13.6656)" fill="black" />
                                                            <path d="M15 12C15 14.2 13.2 16 11 16C8.8 16 7 14.2 7 12C7 9.8 8.8 8 11 8C13.2 8 15 9.8 15 12ZM11 9.6C9.68 9.6 8.6 10.68 8.6 12C8.6 13.32 9.68 14.4 11 14.4C12.32 14.4 13.4 13.32 13.4 12C13.4 10.68 12.32 9.6 11 9.6Z" fill="black" />
                                                        </svg>
                                                    </span>
                                                    {/*end::Svg Icon*/}
                                                </div>
                                                {/*end::Icon*/}
                                                {/*begin::Message*/}
                                                <div className="pb-15 fw-bold">
                                                    <h3 className="text-gray-600 fs-5 mb-2">No result found</h3>
                                                    <div className="text-muted fs-7">Please try again with a different query</div>
                                                </div>
                                                {/*end::Message*/}
                                            </div>
                                            {/*end::Empty*/}
                                        </div>
                                        {/*end::Wrapper*/}
                                    </div>
                                    {/*end::Menu*/}
                                </div>
                                {/*end::Search*/}
                            </div>
                            {/*end::Search*/}
                            {/*begin::Chat*/}
                            <div className="d-flex align-items-center ms-1 ms-lg-3">
                                {/*begin::Menu wrapper*/}
                                <NavLink to={urls.liveView()}>
                                    <div className="btn btn-icon btn-icon-muted btn-active-light btn-active-color-danger w-30px h-30px w-md-40px h-md-40px position-relative">
                                        {/*begin::Svg Icon | path: icons/duotune/communication/com012.svg*/}
                                        <IconPlay size='1' />
                                        {/*end::Svg Icon*/}
                                        <span className="bullet bullet-dot bg-danger h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink"></span>
                                    </div>
                                </NavLink>
                                {/*end::Menu wrapper*/}
                            </div>
                            {/*end::Chat*/}
                            {/*begin::User menu*/}
                            <div className="d-flex align-items-center ms-1 ms-lg-3">
                                {/*begin::Menu wrapper*/}
                                <div className="">
                                    <button className="btn btn-sm btn-light-info" onClick={() => ShowModalDemoWarning()}>Admin - DEMO</button>
                                </div>
                                {/*end::Menu wrapper*/}
                            </div>
                            {/*end::User menu*/}
                        </div>
                        {/*end::Toolbar wrapper*/}
                    </div>
                    {/*end::Wrapper*/}
                </div>
                {/*end::Container*/}
            </div>
            {/*end::Header*/}
        </>
    )
})

