import React from 'react';
import { observer } from 'mobx-react-lite';
import { NavLink } from 'react-router-dom';

interface Props {
    caption: string,
    link: string,
    isSmall: boolean
}

export default observer(function NavButton({ caption, link, isSmall }: Props) {
    if (isSmall) return (<>
        <NavLink to={link} className="btn btn-icon btn-bg-light btn-active-color-primary text-hover-primary btn-sm me-3">
            <i className="fa fa-arrow-right me-1 text-muted fs-8 text-hover-primary" />
        </NavLink>
    </>)
    return (
        <>
            <NavLink to={link} className="btn btn-light btn-sm me-3">{caption}</NavLink>
        </>
    )
})