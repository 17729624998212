import React from 'react';
import { observer } from 'mobx-react-lite';
import { HistoryItem } from '../../../app/models/models';
import SourceToLogoImage from '../../../app/elements/SourceToLogoImage';
import ToolTipWrapper from '../../../app/elements/ToolTipWrapper';
import { useStore } from '../../../app/stores/store';
interface Props {
    historyItem: HistoryItem
}
export default observer(function HistoryDetailsData({ historyItem }: Props) {
    const { modalStore: {show: ShowModalDemoWarning} } = useStore();
    return (
        <>
            {/*begin::Card*/}
            <div className="card mb-5 mb-xl-8">
                {/*begin::Card body*/}
                <div className="card-body pt-15">
                    {/*begin::Summary*/}
                    <div className="d-flex flex-center flex-column mb-5">
                        <span className="fs-3 text-gray-800 fw-bolder mb-1"><SourceToLogoImage className='me-2' source={historyItem.source} />{historyItem.kind} na {historyItem.source}
                            {historyItem.isMostProfitable &&
                                <ToolTipWrapper notification={`To wydarzenie należy do najbardziej dochodowych`} >
                                    <i className="fa fa-rocket me-1 text-danger fs-5 ms-2" />
                                </ToolTipWrapper>}
                            {historyItem.isMostPopular &&
                                <ToolTipWrapper notification={`To wydarzenie należy do najbardziej komentowanych`} >
                                    <i className="fa fa-comment-dots me-1 text-danger fs-5 ms-2" />
                                </ToolTipWrapper>}
                        </span>
                        {/*end::Name*/}
                        {/*begin::Position*/}
                        <div className="fs-5 fw-bold text-muted mb-5">{historyItem.dateReadable}</div>
                        {/*end::Position*/}
                    </div>
                    {/*end::Summary*/}
                    {/*begin::Details toggle*/}
                    <div className="d-flex flex-stack fs-4 py-3">
                        <div className="fw-bolder">Szczegóły
                        </div>
                        <span data-bs-toggle="tooltip" data-bs-trigger="hover" title="Edit customer details">
                            <button onClick={() => ShowModalDemoWarning()} className="btn btn-sm btn-light-primary">Edytuj</button>
                        </span>
                    </div>
                    {/*end::Details toggle*/}
                    <div className="separator separator-dashed my-3"></div>
                    {/*begin::Details content*/}
                    <div>
                        <div className="pb-5 fs-6">
                            <div className='row'>
                                <div className='col-6'>
                                    <div className="fw-bolder mt-5">Łączny przychód</div>
                                    <div className="text-gray-600">{historyItem.totalRevenueReadable} zł</div>
                                </div>
                                <div className='col-6'>
                                    <div className="fw-bolder mt-5">Sprzedano rzeczy</div>
                                    <div className="text-gray-600">{historyItem.totalItemsSold} szt.</div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-6'>
                                    <div className="fw-bolder mt-5">Pozostało koszyków</div>
                                    <div className="text-gray-600">{historyItem.baskets}</div>
                                </div>
                                <div className='col-6'>
                                    <div className="fw-bolder mt-5">Łącznie zamówień</div>
                                    <div className="text-gray-600">{historyItem.orders} szt.</div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-6'>
                                    <div className="fw-bolder mt-5">Komentarzy</div>
                                    <div className="text-gray-600">{historyItem.comments}</div>
                                </div>
                                <div className='col-6'>
                                    <div className="fw-bolder mt-5">Udostępnień</div>
                                    <div className="text-gray-600">{historyItem.shares}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*end::Details content*/}
                </div>
                {/*end::Card body*/}
            </div>
            {/*end::Card*/}
        </>
    )
})
