import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import urls from './urls';
import ProgressBar from '../elements/ProgressBar';
import ToolTipWrapper from '../elements/ToolTipWrapper';
import PaginationBar from '../layout/PaginationBar';
import { Product } from '../models/models';
import { PagingParams } from '../models/pagination';
import { useStore } from '../stores/store';

export enum ProductsMode {
    All,
    OrderWidget,
    BasketWidget,
}

interface Props {
    mode?: ProductsMode
}


export default observer(function ProductsListWidget({ mode = ProductsMode.All }: Props) {
    const { productStore: store } = useStore();
    const { loadItems } = store;
    const { setPredicate, setPagingParams, pagination, clearPredicateAndPaging } = store.filteringAndPaginationController;
    const { modalStore: {show: ShowModalDemoWarning} } = useStore();

    function handleGetNext() {
        setPagingParams(new PagingParams(pagination!.currentPage + 1));
        loadItems();
    }

    function handleGetPrev() {
        setPagingParams(new PagingParams(pagination!.currentPage - 1));
        loadItems();
    }

    const handleFilterChange = (event: any) => {
        setPredicate('filter', event.target.value);
    }

    const handleSortChange = (event: any) => {
        setPredicate('sort', event.target.value);
    }

    const handleSearchChange = (event: any) => {
        const text = event.target.value;
        if (text === '') {
            setPredicate('search', event.target.value)
        }
        else if (text.length >= 3) {
            setPredicate('search', event.target.value)
        }
    }

    const renderTitle = () => {
        switch (mode) {
            case ProductsMode.OrderWidget: return 'Produkty w zamówieniu'
            case ProductsMode.BasketWidget: return 'Produkty w koszyku'
        }
        return 'Produkty'
    }

    useEffect(() => {
        switch (mode) {
            case ProductsMode.All: clearPredicateAndPaging(); break;
        }

        store.loadItems();
    }, [store, clearPredicateAndPaging, mode])

    const renderTotalRevenue = (value: Product) => {
        const text = `To produkt o większym przychodzie. Twój sklep zarobił na nim łącznie ${value.totalRevenueReadable} zł`;
        if (value.isMostProfitable) {
            return <>
                <ToolTipWrapper notification={text} isLeft>
                    <span className="badge py-3 px-4 fs-7 badge-success">{value.totalRevenueReadable} zł
                    </span>
                </ToolTipWrapper>
            </>
        } else {
            return <>
                <ToolTipWrapper notification={text} isLeft>
                    <span className="py-3 px-4 fs-7 text-gray-700">{value.totalRevenueReadable} zł</span>
                </ToolTipWrapper>
            </>
        }
    }

    const renderBigRevenue = (value: Product) => {
        const text = `Na tym produkcie Twój sklep zarobił łącznie ${value.totalRevenueReadable} zł`;
        if (value.isMostProfitable) {
            return <>
                <ToolTipWrapper notification={text} >
                    <i className="fa fa-money-bill-wave me-1 text-success fs-3 px-3" ></i>
                </ToolTipWrapper>
            </>
        }
    }

    return (
        <>
            {/*begin::Table*/}
            <div className={`card card-flush ${(mode === ProductsMode.OrderWidget || mode === ProductsMode.BasketWidget) ? '' : 'mt-15'} `}>
                {/*begin::Card header*/}
                <div className="card-header mt-5">
                    {/*begin::Card title*/}
                    <div className="card-title flex-column">
                        <h3 className="fw-bolder mb-1">{renderTitle()}</h3>
                        <div className="fs-6 text-gray-400">Łącznie {pagination?.totalItems}</div>
                    </div>
                    {/*begin::Card title*/}
                    {/*begin::Card toolbar*/}
                    {mode === ProductsMode.All &&
                        <div className="card-toolbar my-1">
                            {/*begin::Select*/}
                            <div className="me-6 my-1">
                                <select name="sort" onChange={handleSortChange} className="w-275px form-select form-select-solid form-select-sm">
                                    <option value="All" >Sortuj: Po nazwie</option>
                                    <option value="available-desc">Dostępność: od największej</option>
                                    <option value="available-asc">Dostępność: od najmniejszej</option>
                                    <option value="sold-desc">Sprzedanych: najwięcej</option>
                                    <option value="sold-asc">Sprzedanych: najmniej </option>
                                    <option value="revenue-desc">Zysk ze sprzedaży: najwięcej</option>
                                    <option value="revenue-asc">Zysk ze sprzedaży: najmniej</option>
                                </select>
                            </div>
                            {/*end::Select*/}
                            {/*begin::Select*/}
                            {/* <div className="me-6 my-1">
                            <select name="from" onChange={handleFromChange} className="w-150px form-select form-select-solid form-select-sm">
                                <option value="All" >{t('--Customers.All')}</option>
                                <option value="facebook">Facebook</option>
                                <option value="tiktok">TikTok</option>
                            </select>
                        </div> */}
                            {/*end::Select*/}
                            {/*begin::Select*/}
                            <div className="me-4 my-1">
                                <select name="filter" onChange={handleFilterChange} className="w-275px form-select form-select-solid form-select-sm">
                                    <option value="all">Filtruj: Wszystko</option>
                                    <option value="is-hot">Najgorętsze produkty</option>
                                    <option value="is-sold-out">Wyprzedane</option>
                                    <option value="is-available">Dostępne</option>
                                    <option value="is-not-available">Niedostępne</option>
                                </select>
                            </div>
                            {/*end::Select*/}
                            {/*begin::Search*/}
                            <div className="d-flex align-items-center position-relative my-1 me-4">
                                {/*begin::Svg Icon | path: icons/duotone/General/Search.svg*/}
                                <span className="svg-icon svg-icon-3 position-absolute ms-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                            <rect x="0" y="0" width="24" height="24" />
                                            <path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                            <path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z" fill="#000000" fillRule="nonzero" />
                                        </g>
                                    </svg>
                                </span>
                                {/*end::Svg Icon*/}
                                <input type="text" onChange={handleSearchChange} className="form-control form-control-solid form-select-sm w-250px ps-9" placeholder={'Szukaj (minimum 3 litery)'} />
                            </div>
                            {/*end::Search*/}
                            <div className="d-flex align-items-center position-relative my-1">
                                <button onClick={() => ShowModalDemoWarning()} type="button" className="btn btn-primary btn-sm">
                                    <span className="svg-icon svg-icon-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <rect fill="#000000" x="4" y="11" width="16" height="2" rx="1"></rect>
                                            <rect fill="#000000" opacity="0.5" transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000)" x="4" y="11" width="16" height="2" rx="1"></rect>
                                        </svg>
                                    </span>
                                    {'Dodaj produkt'}</button>
                            </div>
                        </div>}
                    {/*begin::Card toolbar*/}
                </div>
                {/*end::Card header*/}
                {/*begin::Card body*/}
                <div className="card-body pt-0">
                    {/*begin::Table container*/}
                    <div className="table-responsive">
                        {/*begin::Table*/}
                        <table className="table table-row-bordered table-row-dashed gy-2 align-middle fw-bolder">
                            {/*begin::Head*/}
                            <thead className="fs-7 text-gray-400 text-uppercase">
                                <tr>
                                    <th className="min-w-200px">Dane produktu</th>
                                    <th className="min-w-50px">Kod</th>
                                    {mode === ProductsMode.All &&
                                        <>
                                            <th className="min-w-50px text-end">Cena</th>
                                            <th className="min-w-50px text-center">Dostępnych</th>
                                            <th className="min-w-50px text-center">Sprzedanych</th>
                                            <th className="min-w-50px text-center">W koszykach</th>
                                            <th className="min-w-50px">Wyprzedano</th>
                                            <th className="min-w-50px px-7 text-end">Zysk</th>
                                        </>}
                                    {(mode === ProductsMode.OrderWidget || mode === ProductsMode.BasketWidget) &&
                                        <>
                                            <th className="min-w-50px text-end">Cena</th>
                                            <th className="min-w-50px text-center">Szt.</th>
                                            <th className="min-w-50px text-center">Wartość</th>
                                        </>}
                                    <th className="min-w-260px text-end"></th>
                                </tr>
                            </thead>
                            {/*end::Head*/}
                            {/*begin::Body*/}
                            <tbody className="fs-6">
                                {store.items.map((i) => (
                                    <tr key={i.id}>
                                        <td>
                                            {/*begin::User*/}
                                            <div className="d-flex align-items-center">
                                                {/*begin::Info*/}
                                                <div className="position-relative ps-6 pe-3 py-2">
                                                    {i.isHot &&
                                                        <div className="position-absolute start-0 top-0 w-4px h-100 rounded-2 bg-danger"></div>}
                                                    {i.available !== 0 &&
                                                        <div className="position-absolute start-0 top-0 w-4px h-100 rounded-2 bg-success"></div>}
                                                    <NavLink to={urls.productDetails(i.id)} className="fs-6 text-gray-800 text-hover-primary">
                                                        {i.name}
                                                        {i.isHot &&
                                                            <ToolTipWrapper notification={i.isHotReason} >
                                                                <i className="fa fa-fire-alt me-1 text-danger fs-3 ps-3 pe-0" ></i>
                                                            </ToolTipWrapper>
                                                        }
                                                        {renderBigRevenue(i)}
                                                    </NavLink>
                                                    <div className="fw-bold text-gray-400">{i.description}</div>
                                                    {/* <div className="fw-bold text-gray-400">{i.email}</div> */}
                                                </div>
                                                {/*end::Info*/}
                                            </div>
                                            {/*end::User*/}
                                        </td>
                                        <td>
                                            {i.code}
                                        </td>
                                        {mode === ProductsMode.All &&
                                            <>
                                                <td className='text-end'>
                                                    {i.priceReadable}zł
                                                </td>
                                                <td className='text-center'>
                                                    {i.available}
                                                </td>
                                                <td className='text-center'>
                                                    {i.sold}
                                                </td>
                                                <td className='text-center'>
                                                    {i.inBaskets}
                                                </td>
                                                <td>
                                                    <ProgressBar percentage={i.soldPercentage} />
                                                </td>

                                                <td className='text-end'>
                                                    {renderTotalRevenue(i)}
                                                </td>
                                            </>}
                                        {(mode === ProductsMode.OrderWidget || mode === ProductsMode.BasketWidget) &&
                                            <>
                                                <td className='text-end'>
                                                    {i.priceReadable}zł
                                                </td>
                                                <td className='text-end'>
                                                    {i.quantityHere}
                                                </td>
                                                <td className='text-end'>
                                                    {i.priceReadable}zł
                                                </td>
                                            </>}
                                        <td className="text-end">
                                            <NavLink to={urls.productDetails(i.id)} className="btn btn-light btn-sm me-3">Szczegóły</NavLink>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                            {/*end::Body*/}
                        </table>
                        {/*end::Table*/}
                    </div>
                    {/*end::Table container*/}
                    {pagination &&
                        <PaginationBar pagination={pagination!} handleGetNext={handleGetNext} handleGetPrev={handleGetPrev} />}
                </div>
                {/*end::Card body*/}
            </div>
            {/*end::Card*/}
        </>
    )
})