import React, { useRef } from "react";

export enum ToolTipWrapperMode{
    Small,
    Medium,
    Normal,
    Large
}

type ButtonProps = {
    notification: string
    mode?: ToolTipWrapperMode
    isLeft?: boolean

} & React.ComponentProps<'div'>

function ToolTipWrapper({ notification, mode, children, isLeft = false }: ButtonProps) {
    const wrapperRef = useRef(null);
    var style = {};
    switch (mode){
        case ToolTipWrapperMode.Small :  style = { width: '100px' }; break;
        case ToolTipWrapperMode.Medium :  style = { width: '150px' }; break;
        case ToolTipWrapperMode.Normal :  style = { width: '225px' }; break;
        case ToolTipWrapperMode.Large :  style = { width: '400px' }; break;
    }
    return (
        <div ref={wrapperRef} className='shc_tooltip' style={{ display: 'inline-block' }}>
            {children}
            <span className={`shc_tooltiptext ${isLeft? 'shc_tooltip-left': 'shc_tooltip-right'}`} style={style}>{notification}</span>
        </div>

    );
}


export default ToolTipWrapper;
