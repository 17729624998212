import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import ProductsListWidget, { ProductsMode } from '../../app/common/ProductsListWidget';
import { useStore } from '../../app/stores/store';

interface Props {
    mode?: ProductsMode
}

export default observer(function ProductsPage({ mode = ProductsMode.All }: Props) {
    const { productStore: store } = useStore();
    const { clearPredicateAndPaging } = store.filteringAndPaginationController;
    
    useEffect(() => {
        console.log('Products page use effect');
        clearPredicateAndPaging();
        store.loadItems();
    }, [clearPredicateAndPaging, store])

    return (
        <>
            <ProductsListWidget mode={mode} />
        </>
    )
})