import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import ProductsListWidget, { ProductsMode } from '../../../app/common/ProductsListWidget';
import { useStore } from '../../../app/stores/store';
import OrderDetailsData from './OrderDetailsData';

export default observer(function OrderDetailsPage() {
    var { productStore, orderStore } = useStore();
    const { id } = useParams<{ id: string }>();
    const { selectedItem } = orderStore;

    useEffect(() => {
        runInAction(() => {
            orderStore.loadItem(id);
            productStore.filteringAndPaginationController.clearPredicateAndPaging();
            productStore.filteringAndPaginationController.setPredicate('orderId', id);
        })
    }, [
        id,
        orderStore.loadItem,
        productStore, productStore.filteringAndPaginationController.setPredicate,
        orderStore, orderStore.filteringAndPaginationController.setPredicate])

    return (
        <>
            {selectedItem &&
                <>
                    <div className="row g-5 g-xl-10 mb-xl-10" style={{ paddingTop: '50px' }}>
                        <div className="col-lg-12 col-xl-6 col-xxl-4 mb-5 mb-xl-0">
                            <OrderDetailsData order={selectedItem} />
                        </div>
                        <div className="col-lg-12 col-xl-6 col-xxl-8 mb-5 mb-xl-0">
                            <ProductsListWidget mode={ProductsMode.OrderWidget} />
                        </div>
                    </div>
                    <div className="row g-5 g-xl-10 mb-xl-10">
                        <div className="col-lg-12 col-xl-6 col-xxl-6 mb-5 mb-xl-0">
                        </div>
                        <div className="col-lg-12 col-xl-6 col-xxl-6 mb-5 mb-xl-0">
                        </div>
                    </div>
                    <div className="row g-5 g-xl-10 mb-xl-10">
                        <div className="col-lg-12 col-xl-6 col-xxl-6 mb-5 mb-xl-0">
                        </div>
                        <div className="col-lg-12 col-xl-6 col-xxl-8 mb-5 mb-xl-0">
                        </div>
                    </div>
                </>
            }
        </>
    )
})
