import { observer } from 'mobx-react-lite';
import React from 'react';
import OrdersListWidget, { OrdersMode } from '../../app/common/OrdersListWidget';


interface Props {
    mode?: OrdersMode
}

export default observer(function OrdersPage({ mode }: Props) {
    return (
        <>
            <OrdersListWidget mode={mode} />
        </>
    )
})