import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
export default observer(function DeliveryWidget() {
    const { modalStore: {show: ShowModalDemoWarning} } = useStore();
    return (
        <>
            {/*begin::Connected Accounts*/}
            <div className="card card-flush h-xl-100">
                {/*begin::Card header*/}
                <div className="card-header border-0">
                    <div className="card-title m-0">
                        <h3 className="fw-bolder m-0">Metody dostawy</h3>
                    </div>
                </div>
                {/*end::Card header*/}
                {/*begin::Content*/}
                <div className="collapse show">
                    {/*begin::Card body*/}
                    <div className="card-body border-top p-9">
                        {/*begin::Items*/}
                        <div className="py-2">
                            {/*begin::Item*/}
                            <div className="d-flex flex-stack">
                                <div className="d-flex">
                                    <span className="svg-icon svg-icon-3x svg-icon-danger w-75px ms-6 ">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 26 26" fill="none">
                                            <path opacity="0.3" d="M18 10V20C18 20.6 18.4 21 19 21C19.6 21 20 20.6 20 20V10H18Z" fill="black" />
                                            <path opacity="0.3" d="M11 10V17H6V10H4V20C4 20.6 4.4 21 5 21H12C12.6 21 13 20.6 13 20V10H11Z" fill="black" />
                                            <path opacity="0.3" d="M10 10C10 11.1 9.1 12 8 12C6.9 12 6 11.1 6 10H10Z" fill="black" />
                                            <path opacity="0.3" d="M18 10C18 11.1 17.1 12 16 12C14.9 12 14 11.1 14 10H18Z" fill="black" />
                                            <path opacity="0.3" d="M14 4H10V10H14V4Z" fill="black" />
                                            <path opacity="0.3" d="M17 4H20L22 10H18L17 4Z" fill="black" />
                                            <path opacity="0.3" d="M7 4H4L2 10H6L7 4Z" fill="black" />
                                            <path d="M6 10C6 11.1 5.1 12 4 12C2.9 12 2 11.1 2 10H6ZM10 10C10 11.1 10.9 12 12 12C13.1 12 14 11.1 14 10H10ZM18 10C18 11.1 18.9 12 20 12C21.1 12 22 11.1 22 10H18ZM19 2H5C4.4 2 4 2.4 4 3V4H20V3C20 2.4 19.6 2 19 2ZM12 17C12 16.4 11.6 16 11 16H6C5.4 16 5 16.4 5 17C5 17.6 5.4 18 6 18H11C11.6 18 12 17.6 12 17Z" fill="black" />
                                        </svg>
                                    </span>
                                    <div className="d-flex flex-column">
                                        <span className="fs-5 text-dark fw-bolder">Odbiór osobisty</span>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end">
                                    <div className="form-check form-check-solid form-switch">
                                        <input className="form-check-input w-45px h-30px" type="checkbox" readOnly checked onClick={() => ShowModalDemoWarning()}/>
                                        <label className="form-check-label" ></label>
                                    </div>
                                </div>
                            </div>
                            {/*end::Item*/}
                            <div className="separator separator-dashed my-5"></div>
                            {/*begin::Item*/}
                            <div className="d-flex flex-stack">
                                <div className="d-flex">
                                    <img src="/partner-custom/logos/inpost_logo.svg" className="w-75px me-6" alt="" />
                                    <div className="d-flex flex-column justify-content-center">
                                        <span className="fs-5 text-dark fw-bolder">Kurier InPost</span>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end">
                                    <div className="form-check form-check-solid form-switch">
                                        <input className="form-check-input w-45px h-30px" type="checkbox" readOnly checked onClick={() => ShowModalDemoWarning()} />
                                        <label className="form-check-label" ></label>
                                    </div>
                                </div>
                            </div>
                            {/*end::Item*/}
                            <div className="separator separator-dashed my-5"></div>
                            {/*begin::Item*/}
                            <div className="d-flex flex-stack">
                                <div className="d-flex">
                                    <img src="/partner-custom/logos/inpost_logo.svg" className="w-75px me-6" alt="" />
                                    <div className="d-flex flex-column justify-content-center">
                                        <span className="fs-5 text-dark fw-bolder">Paczkomat InPost</span>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end">
                                    <div className="form-check form-check-solid form-switch">
                                        <input className="form-check-input w-45px h-30px" type="checkbox" readOnly checked onClick={() => ShowModalDemoWarning()} />
                                        <label className="form-check-label" ></label>
                                    </div>
                                </div>
                            </div>
                            {/*end::Item*/}
                            <div className="separator separator-dashed my-5"></div>
                            {/*begin::Item*/}
                            <div className="d-flex flex-stack">
                                <div className="d-flex">
                                    <img src="/partner-custom/logos/dpd_logo.svg" className="w-65px me-7 ms-2" alt="" />
                                    <div className="d-flex flex-column justify-content-center">
                                        <span className="fs-5 text-dark fw-bolder">Kurier DPD</span>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end">
                                    <div className="form-check form-check-solid form-switch">
                                        <input className="form-check-input w-45px h-30px" type="checkbox" readOnly checked onClick={() => ShowModalDemoWarning()} />
                                        <label className="form-check-label" ></label>
                                    </div>
                                </div>
                            </div>
                            {/*end::Item*/}
                            <div className="separator separator-dashed my-5"></div>
                            {/*begin::Item*/}
                            <div className="d-flex flex-stack">
                                <div className="d-flex">
                                    <img src="/partner-custom/logos/dhl_logo.svg" className="w-75px me-6" alt="" />
                                    <div className="d-flex flex-column justify-content-center">
                                        <span className="fs-5 text-dark fw-bolder">Kurier DHL</span>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end">
                                    <div className="form-check form-check-solid form-switch">
                                        <input className="form-check-input w-45px h-30px" type="checkbox" readOnly checked onClick={() => ShowModalDemoWarning()} />
                                        <label className="form-check-label" ></label>
                                    </div>
                                </div>
                            </div>
                            {/*end::Item*/}
                            <div className="separator separator-dashed my-5"></div>
                            {/*begin::Item*/}
                            <div className="d-flex flex-stack">
                                <div className="d-flex">
                                    <img src="/partner-custom/logos/poczta_polska.svg" className="w-75px me-6" alt="" />
                                    <div className="d-flex flex-column justify-content-center">
                                        <span className="fs-5 text-dark fw-bolder">Poczta Polska</span>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end">
                                    <div className="form-check form-check-solid form-switch">
                                        <input className="form-check-input w-45px h-30px" type="checkbox" readOnly checked onClick={() => ShowModalDemoWarning()} />
                                        <label className="form-check-label" ></label>
                                    </div>
                                </div>
                            </div>
                            {/*end::Item*/}
                        </div>
                        {/*end::Items*/}
                    </div>
                    {/*end::Card body*/}
                    {/*begin::Card footer*/}
                    {/* <div className="card-footer d-flex justify-content-end py-6 px-9">
                        <button className="btn btn-light btn-active-light-primary me-2">Discard</button>
                        <button className="btn btn-primary">Save Changes</button>
                    </div> */}
                    {/*end::Card footer*/}
                </div>
                {/*end::Content*/}
            </div>
            {/*end::Connected Accounts*/}
        </>
    )
})
