import React from 'react';
import { observer } from 'mobx-react-lite';
import { NavLink } from 'react-router-dom';

type Props = {
    url: string,
    caption: string,
    isSub?: boolean,
} & React.ComponentProps<'div'>

export default observer(function MenuItem({ caption, url, children, isSub = false }: Props) {
    var style = {};
    if (isSub) {
        style = { marginLeft: '20px' };
    }

    return (
        <>
            <div className="menu-item" style={style}>
                <NavLink className="menu-link" to={url}>
                    <span className="menu-icon">
                        {children}
                    </span>
                    <span className='menu-title'>{caption}</span>
                </NavLink>
            </div>
        </>
    )
})