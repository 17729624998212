import React from 'react';
import { observer } from 'mobx-react-lite';

export default observer(function MarketingDiscounts() {
    return (
        <>
            {/*begin::Connected Accounts*/}
            <div className="card">
                <div className="card-header border-0">
                    <div className="card-title m-0">
                        <h3 className="fw-bolder m-0">Buduj odsłony</h3>
                    </div>
                </div>
                {/*begin::Content*/}
                <div>
                    {/*begin::Card body*/}
                    <div className="card-body border-top p-9">
                        <div className="fv-row">
                            <div className="col-12">
                                {/*begin::Heading*/}
                                <div className="mb-3">
                                    {/*begin::Label*/}
                                    <label className="d-flex align-items-center fs-5 fw-bold">
                                        <span>Włącz rabat na pierwsze zakupy za zgody maketingowe</span>
                                    </label>
                                    {/*end::Label*/}      
                                    {/*begin::Description*/}
                                    <div className='py-5'>
                                        <div className="timeline-label">
                                            {/*begin::Item*/}
                                            <div className="timeline-item">
                                                {/*begin::Label*/}
                                                {/*end::Label*/}
                                                {/*begin::Badge*/}
                                                <div className="timeline-badge">
                                                    <i className="fa fa-genderless text-success fs-1"></i>
                                                </div>
                                                {/*end::Badge*/}
                                                {/*begin::Text*/}
                                                <div className="timeline-content fw-bolder text-gray-800 ps-3">Najlepiej zacząć od wysyłania przypomnień o nadchodzących transmisjach klientkom które wyraziły na to zgodę.</div>
                                                {/*end::Text*/}
                                            </div>
                                            {/*end::Item*/}
                                            {/*begin::Item*/}
                                            <div className="timeline-item">
                                                {/*begin::Label*/}
                                                {/*end::Label*/}
                                                {/*begin::Badge*/}
                                                <div className="timeline-badge">
                                                    <i className="fa fa-genderless text-success fs-1"></i>
                                                </div>
                                                {/*end::Badge*/}
                                                {/*begin::Content*/}
                                                <div className="timeline-content d-flex">
                                                    <span className="timeline-content fw-bolder text-gray-800 ps-3">Możesz włączyć automatyczne udzielanie rabatu na pierwsze zakupy jeśli klientka taką zgodę wyrazi.</span>
                                                </div>
                                                {/*end::Content*/}
                                            </div>
                                            {/*end::Item*/}
                                            {/*begin::Item*/}
                                            <div className="timeline-item">
                                                {/*begin::Label*/}
                                                {/*end::Label*/}
                                                {/*begin::Badge*/}
                                                <div className="timeline-badge">
                                                    <i className="fa fa-genderless text-success fs-1"></i>
                                                </div>
                                                {/*end::Badge*/}
                                                {/*begin::Content*/}
                                                <div className="timeline-content d-flex">
                                                    <span className="timeline-content fw-bolder text-gray-800 ps-3">W ten sposób budujesz bazę klientów, których możesz (zgodnie z RODO) powiadamiać o swoich transmisjach.</span>
                                                </div>
                                                {/*end::Content*/}
                                            </div>
                                            {/*end::Item*/}
                                            {/*begin::Item*/}
                                            <div className="timeline-item">
                                                {/*begin::Label*/}
                                                {/*end::Label*/}
                                                {/*begin::Badge*/}
                                                <div className="timeline-badge">
                                                    <i className="fa fa-genderless text-success fs-1"></i>
                                                </div>
                                                {/*end::Badge*/}
                                                {/*begin::Desc*/}
                                                <div className="timeline-content fw-bolder text-gray-800 ps-3">Badania pokazują, że rabat zwiększa prawdopodobieństwo zapisania się na powiadomienia nawet kilkukrotnie.</div>
                                                {/*end::Desc*/}
                                            </div>
                                            {/*end::Item*/}
                                        </div>
                                    </div>
                                    {/*end::Description*/}
                                </div>
                                {/*end::Heading*/}
                                {/*begin::Row*/}
                                <div className="fv-row">
                                    {/*begin::Radio group*/}
                                    <div className="btn-group w-100">
                                        {/*begin::Radio*/}
                                        <label className="btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-danger" data-kt-button="true">
                                            {/*begin::Input*/}
                                            <input className="btn-check" type="radio" name="method" defaultValue="1" />
                                            {/*end::Input*/}
                                            0%</label>
                                        {/*end::Radio*/}
                                        {/*begin::Radio*/}
                                        <label className="btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-primary" data-kt-button="true">
                                            {/*begin::Input*/}
                                            <input className="btn-check" type="radio" name="method" defaultValue="2" />
                                            {/*end::Input*/}
                                            -5%</label>
                                        {/*end::Radio*/}
                                        {/*begin::Radio*/}
                                        <label className="btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-primary active" data-kt-button="true">
                                            {/*begin::Input*/}
                                            <input className="btn-check" type="radio" name="method" defaultValue="3" />
                                            {/*end::Input*/}
                                            -10%</label>
                                        {/*end::Radio*/}
                                        {/*begin::Radio*/}
                                        <label className="btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-primary" data-kt-button="true">
                                            {/*begin::Input*/}
                                            <input className="btn-check" type="radio" name="method" defaultValue="4" />
                                            {/*end::Input*/}
                                            -15%</label>
                                        {/*end::Radio*/}
                                    </div>
                                    {/*end::Radio group*/}
                                </div>
                                {/*end::Row*/}
                            </div>
                            {/*end::Input group*/}
                        </div>
                    </div>
                    {/*end::Card body*/}
                    {/*begin::Card footer*/}
                    {/* <div className="card-footer d-flex justify-content-end py-6 px-9">
                        <button className="btn btn-light btn-active-light-primary me-2">Discard</button>
                        <button className="btn btn-primary">Save Changes</button>
                    </div> */}
                    {/*end::Card footer*/}
                </div>
                {/*end::Content*/}
            </div>
            {/*end::Connected Accounts*/}
        </>
    )
})