import React from 'react';
import { observer } from 'mobx-react-lite';
import { Customer } from '../models/models';
import ToolTipWrapper from './ToolTipWrapper';

interface Props {
    customer: Customer
    isLeft?: boolean
}


export default observer(function CustomerProblematic({ customer, isLeft = false }: Props) {
    return (
        <>
            {customer.troubleComment?.length !== 0 &&
                <ToolTipWrapper notification={'Ten klient jest problematyczny. ' + customer.troubleComment} isLeft={isLeft} >
                    <i className="fa fa-exclamation-triangle me-1 text-danger fs-3 px-5" ></i>
                </ToolTipWrapper>
            }
        </>
    )
})