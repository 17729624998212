import React from 'react';
import { observer } from 'mobx-react-lite';
import { formatDate } from '../../app/common/helpers';
import { useStore } from '../../app/stores/store';
export default observer(function EmailMessageWidget() {
    const { modalStore: {show: ShowModalDemoWarning} } = useStore();
    var tomorrow = new Date();
    tomorrow.setDate(new Date().getDate() + 1);
    var dateToDisplay = formatDate(tomorrow);
    var text =
        'Hej Kochani!\n\n' +
        'Zapraszamy Was wszystkich na nasz live jutro o 18:00 na nasz profil na Facebooku. Na nim oczywiście jeszcze więcej super produktów!🔥\n' +
        'www.facebook.com/NajlepszyButikSwiata\n\n' +
        'Pojawi się kilka rzeczy na wyprzedaży - zniżki na ostatnie sztuki to -50%.\n\n' +
        'Na końcu live jak zwykle konkurs dla udostępniających :) Co będzie nagrodą? Tego dowiecie już jutro o 18:00 na naszej transmisji!😊\n\n' +
        'Zapraszamy i do zobaczenia!\n' +
        'Wasz Najlepszy Butik Świata';
    return (
        <>
            {/*begin::Card*/}
            <div className="card mt-10">
                <div className="card-header border-0">
                    <div className="card-title m-0">
                        <h3 className="fw-bolder m-0">Wyślij powiadomienie email</h3>
                    </div>
                </div>
                <div className="card-body p-0">
                    {/*begin::Form*/}
                    <form>
                        {/*begin::Body*/}
                        <div className="d-block">
                            {/*begin::To*/}
                            <div className="d-flex align-items-center border-bottom px-8 min-h-50px">
                                {/*begin::Label*/}
                                <div className="text-dark fw-bolder w-60px">Do:</div>
                                {/*end::Label*/}
                                {/*begin::Input*/}
                                <input type="text" className="form-control form-control-transparent border-0" value='Wszyscy klienci ze zgodą na emailing (356)' />
                                {/*end::Input*/}
                            </div>
                            <div className="d-flex align-items-center border-bottom px-8 min-h-50px">
                                {/*begin::Label*/}
                                <div className="text-dark fw-bolder w-60px">Temat:</div>
                                {/*end::Label*/}
                                {/*begin::Input*/}
                                <input type="text" className="form-control form-control-transparent border-0" value={`Zapraszamy na live już ${dateToDisplay} (jutro) o 18:00`} />
                                {/*end::Input*/}
                            </div>
                            {/*begin::Message*/}
                            <div className="bg-transparent border-0 h-330px px-8 py-8">
                                <textarea className='w-100 h-275px p-2' value={text} onChange={() => ShowModalDemoWarning()}>

                                </textarea>
                            </div>
                            {/*end::Message*/}
                        </div>
                        {/*end::Body*/}
                        {/*begin::Footer*/}
                        <div className="py-5 ps-8 pe-5 border-top">
                            {/*begin::Actions*/}
                            <div className="me-3 text-end">
                                {/*begin::Send*/}
                                <div className="btn-group me-4">
                                    {/*begin::Submit*/}
                                    <span className="btn btn-primary fs-bold px-6 w-250px" onClick={() => ShowModalDemoWarning()}>
                                        <span className="indicator-label">Wyślij</span>
                                    </span>
                                    {/*end::Submit*/}
                                </div>
                                {/*end::Send*/}
                            </div>
                            {/*end::Actions*/}
                        </div>
                        {/*end::Footer*/}
                    </form>
                    {/*end::Form*/}
                </div>
            </div>
            {/*end::Card*/}
        </>
    )
})
