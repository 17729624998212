import React from 'react';
import { observer } from 'mobx-react-lite';
import { Pagination } from '../models/pagination';

interface Props {
    pagination: Pagination
    handleGetNext(): any
    handleGetPrev(): any
}

export default observer(function PaginationBar({ pagination, handleGetNext, handleGetPrev }: Props) {
    return (
        <>
            <div className="row">

                <div className="col-sm-12 col-md-6 d-flex align-items-center justify-content-center justify-content-md-end">
                    <div>
                        {/* <label>
                            <select name="kt_customers_table_length" aria-controls="kt_customers_table" className="form-select form-select-sm form-select-solid">
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </label> */}
                        <span>{pagination.currentPage} / {pagination.totalPages}</span>
                    </div>
                </div>

                <div className="col-sm-12 col-md-6 d-flex align-items-center justify-content-center justify-content-md-start">
                    <div className="dataTables_paginate paging_simple_numbers">
                        <ul className="pagination">
                            {pagination?.currentPage !== 1 ?
                                <li className="paginate_button page-item active">
                                    <button onClick={handleGetPrev} className="page-link">
                                        <i className="previous">
                                        </i>
                                    </button>
                                </li>
                                :
                                <li className="paginate_button page-item disabled">
                                    <button className="page-link" disabled>
                                        <i className="previous">
                                        </i>
                                    </button>
                                </li>}

                            {pagination?.currentPage !== pagination?.totalPages ?
                                <li className="paginate_button page-item active">
                                    <button onClick={handleGetNext} className="page-link">
                                        <i className="next">
                                        </i>
                                    </button>
                                </li>
                                :
                                <li className="paginate_button page-item disabled">
                                    <button className="page-link" disabled>
                                        <i className="next">
                                        </i>
                                    </button>
                                </li>}

                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
})