import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import urls from '../../app/common/urls';
import { NavLink } from 'react-router-dom';
import ToolTipWrapper from '../../app/elements/ToolTipWrapper';
import { IconBaskets } from '../../app/layout/Icons';
import { useStore } from '../../app/stores/store';
import { PagingParams } from '../../app/models/pagination';
import { runInAction } from 'mobx';
import PaginationBar from '../../app/layout/PaginationBar';
import SourceToLogoImage from '../../app/elements/SourceToLogoImage';

export default observer(function OldBasketsWidget() {
    const { basketStore: store } = useStore();
    const { setPredicate, setPagingParams, pagination, clearPredicateAndPaging } = store.filteringAndPaginationController;

    useEffect(() => {
        clearPredicateAndPaging();
        setPredicate('filter', 'is-old', 5);
        runInAction(() => {
            store.loadItems();
        })
    }, [store, clearPredicateAndPaging, setPredicate])

    function handleGetNext() {
        setPagingParams(new PagingParams(pagination!.currentPage + 1, 5));
        store.loadItems();
    }

    function handleGetPrev() {
        setPagingParams(new PagingParams(pagination!.currentPage - 1, 5));
        store.loadItems();
    }

    return (
        <>
            {/*begin::Tables Widget 9*/}
            <div className="card card-flush h-md-100">
                {/*begin::Header*/}
                <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bolder fs-3 mb-1">Przeterminowane koszyki (blokują towar)</span>
                        <span className="text-muted mt-1 fw-bold fs-7">Łącznie {pagination?.totalItems}</span>
                    </h3>
                    <div className="card-toolbar">
                        <NavLink to={urls.baskets()} className="btn btn-sm btn-light btn-active-primary" >
                            {/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
                            <IconBaskets size="1" />
                            {/*end::Svg Icon*/}Zobacz wszystkie</NavLink>
                    </div>
                </div>
                {/*end::Header*/}
                {/*begin::Body*/}
                <div className="card-body py-3">
                    {/*begin::Table container*/}
                    <div className="table-responsive">
                        {/*begin::Table*/}
                        <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2 mb-0">
                            {/*begin::Table body*/}
                            <tbody>
                                {store.items.map((i) => (
                                    <tr key={i.id}>
                                        <td>
                                            {/*begin::User*/}
                                            <div className="d-flex align-items-center">
                                                {/*begin::Info*/}
                                                {i.troubleComment?.length !== 0 &&
                                                    <ToolTipWrapper notification={'Klient problematyczny. ' + i.troubleComment}>
                                                        <NavLink to={urls.customerDetails(i.customerId)} className="position-relative pe-3 py-2">
                                                            <span className="text-dark fw-bolder text-hover-primary fs-6">
                                                                {i.customerFirstName}<i className="fa fa-exclamation-triangle me-1 text-danger fs-5 ms-2" /><br />{i.customerLastName}
                                                            </span>
                                                            <div className="text-muted fw-bold text-muted d-block fs-7">{i.customerPhone}</div>
                                                        </NavLink>
                                                    </ToolTipWrapper>}
                                                {(i.troubleComment === undefined || i.troubleComment?.length === 0) &&
                                                    <NavLink to={urls.customerDetails(i.customerId)} className="position-relative pe-3 py-2">
                                                        <span className="text-dark fw-bolder text-hover-primary fs-6">
                                                            {i.customerFirstName}<br />{i.customerLastName}
                                                        </span>
                                                        <div className="text-muted fw-bold text-muted d-block fs-7">{i.customerPhone}</div>
                                                    </NavLink>}
                                                {/*end::Info*/}
                                            </div>
                                            {/*end::User*/}
                                        </td>
                                        <td>
                                            <SourceToLogoImage source={i.source} />
                                            {`${i.createdDaysAgo} d. temu`}
                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                {/*begin::Info*/}
                                                <div className="position-relative pe-3 py-2">
                                                    <ToolTipWrapper notification={`Wartość koszyka to ${i.totalValue} zł. Liczba przedmiotów w koszyku: ${i.totalItems}`}>
                                                        <span className="text-dark fw-bolder fs-6">{i.totalValue} zł</span>
                                                        <div className="fw-bold fs-7 text-gray-400">{i.totalItems} <i className="fa fa-arrow-right me-1 text-muted fs-8" /><i className="fa fa-shopping-basket me-1 text-muted fs-7" /></div>
                                                        {/* <div className="fw-bold text-gray-400">{i.email}</div> */}
                                                    </ToolTipWrapper>
                                                </div>
                                                {/*end::Info*/}
                                            </div>
                                        </td>
                                        <td>
                                            {i.items.map((item) =>
                                            (
                                                <span key={item}>
                                                    <span className='fs-7 text-gray-600'>{item}</span><br />
                                                </span>
                                            ))}
                                        </td>
                                        <td>
                                            <div className="">
                                                <NavLink to={urls.basketDetails(i.id)} className="btn btn-icon btn-bg-light btn-active-color-primary text-hover-primary btn-sm">
                                                    {/*begin::Svg Icon | path: icons/duotune/general/gen027.svg*/}
                                                    <i className="fa fa-arrow-right me-1 text-muted fs-8 text-hover-primary" />
                                                    {/*end::Svg Icon*/}
                                                </NavLink>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                            {/*end::Table body*/}
                        </table>
                        {/*end::Table*/}
                        {pagination &&
                            <PaginationBar pagination={pagination!} handleGetNext={handleGetNext} handleGetPrev={handleGetPrev} />}
                    </div>
                    {/*end::Table container*/}
                </div>
                {/*begin::Body*/}
            </div>
            {/*end::Tables Widget 9*/}
        </>
    )
})