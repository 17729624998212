import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../stores/store';
import OutsideClickDetector from '../common/OutsideClickDetector';


export default observer(function ModalDemoWarning() {

    const { modalStore } = useStore();

    return (
        <>
            <div className={`modal fade ${modalStore.visible ? 'show' : ''}`} tabIndex={-1} style={{ display: modalStore.visible ? 'block' : 'none' }}>
                {/*begin::Modal dialog*/}
                <div className="modal-dialog modal-dialog-centered mw-650px">
                    <OutsideClickDetector onClickOutside={() => modalStore.hide()}>
                        {/*begin::Modal content*/}
                        <div className="modal-content rounded">
                            {/*begin::Modal header*/}
                            <div className="modal-header pb-0 border-0 justify-content-end">
                                {/*begin::Close*/}
                                <div onClick={() => modalStore.hide()} className="btn btn-sm btn-icon btn-active-color-primary">
                                    {/*begin::Svg Icon | path: icons/duotone/Navigation/Close.svg*/}
                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1"></rect>
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1"></rect>
                                            </g>
                                        </svg>
                                    </span>
                                    {/*end::Svg Icon*/}
                                </div>
                                {/*end::Close*/}
                            </div>
                            {/*begin::Modal header*/}
                            {/*begin::Modal body*/}
                            <div className="modal-body scroll-y px-10 px-lg-15 pt-0 pb-15" data-select2-id="select2-data-72-z5wg">
                                <div className="mb-13 text-center">
                                    {/*begin::Title*/}
                                    <h1 className="mb-3">To konto demo</h1>
                                    {/*end::Title*/}
                                    {/*begin::Description*/}
                                    <div className="text-gray-400 fw-bold fs-5">Przeglądając konto demo nie możesz niczego zmianiać, edytować itp.</div>
                                    {/*end::Description*/}
                                </div>
                                {/*end::Heading*/}

                                {/*begin::Actions*/}
                                <div className="text-center">
                                    <button onClick={() => modalStore.hide()} className="btn btn-success">Ok, rozumiem</button>
                                </div>
                                {/*end::Actions*/}
                            </div>
                            {/*end::Modal body*/}
                        </div>
                        {/*end::Modal content*/}
                    </OutsideClickDetector>
                </div>
                {/*end::Modal dialog*/}
            </div>
            {modalStore.visible &&
                <div className="modal-backdrop fade show" style={{opacity: '0.9'}}></div>}
        </>
    )
})