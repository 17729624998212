import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { loadScript, unloadScript } from '../../app/common/helpers';
export default observer(function FilteredQuestions() {
    
    useEffect(() => {
        const script0 = loadScript('/assets/js/scripts.bundle.js');
        const script1 = loadScript('/partner-custom/demo/filteredquestions.js');

        return () => {
            unloadScript(script0);
            unloadScript(script1);
        }
    }, []);

    return (
        <>
            {/*begin::Messenger*/}
            <div className="card h-100 min-h-500px" id="kt_chat_messenger">
                {/*begin::Card header*/}
                <div className="card-header" id="kt_chat_messenger_header">
                    {/*begin::Title*/}
                    <div className="card-title">
                        {/*begin::User*/}
                        <div className="d-flex justify-content-center flex-column me-3">
                            <span className="card-label fw-bolder fs-3 mb-1 mt-5">Odfiltrowane pytania <span className="badge badge-danger badge-circle w-10px h-10px me-1 animation-blink"></span></span>
                            <div className="mb-1 lh-1">
                                
                                <span className="fs-7 fw-bold text-muted">Tutaj kopiujemy odfiltrowane pytania o towary zadawane na live np. na facebooku przez Twoich widzów. Dzięki temu nic Ci nie zginie w zalewie innych wiadomości. Teraz widzisz troszkę przyśpieszone demo :)</span>
                            </div>
                        </div>
                        {/*end::User*/}
                    </div>
                    {/*end::Title*/}
                </div>
                {/*end::Card header*/}
                {/*begin::Card body*/}
                <div className="card-body" id="kt_chat_messenger_body">
                    {/*begin::Messages*/}
                    <div className="scroll-y me-n5 pe-5 h-300px h-lg-auto" data-kt-element="messages" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer" data-kt-scroll-wrappers="#kt_content, #kt_chat_messenger_body" data-kt-scroll-offset="5px">
                        {/*begin::Message(template for out)*/}
                        <div className="d-flex justify-content-end mb-10 d-none" data-kt-element="template-out">
                            {/*begin::Wrapper*/}
                            <div className="d-flex flex-column align-items-end">
                                {/*begin::User*/}
                                <div className="d-flex align-items-center mb-2">
                                    {/*begin::Details*/}
                                    <div className="me-3">
                                        <span className="text-muted fs-7 mb-1" data-kt-element="message-time">Teraz</span>
                                        <span className="fs-5 fw-bolder text-gray-900 ms-1 me-2" data-kt-element="message-name"></span>
                                    </div>
                                    {/*end::Details*/}
                                    {/*begin::Avatar*/}
                                    {/* <div className="symbol symbol-35px symbol-circle">
                                        <img alt="Pic" src="assets/media/avatars/300-1.jpg" />
                                    </div> */}
                                    {/*end::Avatar*/}
                                </div>
                                {/*end::User*/}
                                {/*begin::Text*/}
                                <div className="p-5 rounded bg-light-primary text-dark fw-bold mw-lg-400px text-end" data-kt-element="message-text"></div>
                                {/*end::Text*/}
                            </div>
                            {/*end::Wrapper*/}
                        </div>
                        {/*end::Message(template for out)*/}
                    </div>
                    {/*end::Messages*/}
                </div>
                {/*end::Card body*/}
            </div>
            {/*end::Messenger*/}
        </>
    )
})
