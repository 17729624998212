import React from 'react';
import { observer } from 'mobx-react-lite';
import ToolTipWrapper, { ToolTipWrapperMode } from './ToolTipWrapper';

interface Props {
    name: string
}

export default observer(function ShipmentProviderLogo({ name }: Props){
    return <>
            {name === 'dpd' &&
                <img src="/partner-custom/logos/dpd_logo.svg" className="w-50px mx-3" alt="" />}
            {name === 'inpost' &&
                <img src="/partner-custom/logos/inpost_logo.svg" className="w-55px mx-2" alt="" style={{ width: '27px !important' }} />}
            {name === 'pickup' &&
                <ToolTipWrapper notification='Odbiór osobisty' mode={ToolTipWrapperMode.Small}>
                    <span className="svg-icon svg-icon-3x svg-icon-danger">
                        <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 26 26" fill="none">
                            <path opacity="0.3" d="M18 10V20C18 20.6 18.4 21 19 21C19.6 21 20 20.6 20 20V10H18Z" fill="black" />
                            <path opacity="0.3" d="M11 10V17H6V10H4V20C4 20.6 4.4 21 5 21H12C12.6 21 13 20.6 13 20V10H11Z" fill="black" />
                            <path opacity="0.3" d="M10 10C10 11.1 9.1 12 8 12C6.9 12 6 11.1 6 10H10Z" fill="black" />
                            <path opacity="0.3" d="M18 10C18 11.1 17.1 12 16 12C14.9 12 14 11.1 14 10H18Z" fill="black" />
                            <path opacity="0.3" d="M14 4H10V10H14V4Z" fill="black" />
                            <path opacity="0.3" d="M17 4H20L22 10H18L17 4Z" fill="black" />
                            <path opacity="0.3" d="M7 4H4L2 10H6L7 4Z" fill="black" />
                            <path d="M6 10C6 11.1 5.1 12 4 12C2.9 12 2 11.1 2 10H6ZM10 10C10 11.1 10.9 12 12 12C13.1 12 14 11.1 14 10H10ZM18 10C18 11.1 18.9 12 20 12C21.1 12 22 11.1 22 10H18ZM19 2H5C4.4 2 4 2.4 4 3V4H20V3C20 2.4 19.6 2 19 2ZM12 17C12 16.4 11.6 16 11 16H6C5.4 16 5 16.4 5 17C5 17.6 5.4 18 6 18H11C11.6 18 12 17.6 12 17Z" fill="black" />
                        </svg>
                    </span>
                </ToolTipWrapper>
            }
        </>
})