const formatDate = (date?: Date) => {
    if (!date) return '';
    return `${pad(date.getDate())}.${pad(date.getMonth() + 1)}.${date!.getFullYear()}`
};

const pad = (i: number) => {
    return String(i).padStart(2, '0');
};

const formatMoney = (money: number) => {
    return new Intl.NumberFormat().format(money);
}

export { formatDate, pad, formatMoney };

export function loadScript(src: string) {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    document.body.appendChild(script)
    return script;
}

export function unloadScript(script: HTMLScriptElement) {
    document.body.removeChild(script);
}

export function paymentMethodReadable(paymentMethod: string) {
    switch (paymentMethod) {
        case "transfer": return "Przelew";
        case "przelewy24": return "Przelewy 24";
        case "on-spot": return "Płatność na miejscu";
    }
    return "";
}

export function paymentStatusReadable(paymentMethod: string) {
    switch (paymentMethod) {
        case "paid": return "Opłacono";
        case "to-pay": return "Czekamy na płatność";
    }
    return "";
}