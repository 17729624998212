import React from 'react';
import { observer } from 'mobx-react-lite';
import { Customer } from '../../../app/models/models';
import SourceToLogoImage from '../../../app/elements/SourceToLogoImage';
import CustomerEventStatus from '../../../app/elements/CustomerEventStatus';
import CustomerProblematic from '../../../app/elements/CustomerProblematic';
import CustomerActivity from '../../../app/elements/CustomerActivity';
import { NavLink } from 'react-router-dom';
import urls from '../../../app/common/urls';
import { useStore } from '../../../app/stores/store';
import ToolTipWrapper from '../../../app/elements/ToolTipWrapper';
interface Props {
    customer: Customer
}
export default observer(function CustomerDetailsData({ customer }: Props) {
    const { modalStore: { show: ShowModalDemoWarning } } = useStore();

    return (
        <>
            {/*begin::Card*/}
            <div className="card mb-5 mb-xl-8">
                {/*begin::Card body*/}
                <div className="card-body pt-15">
                    {/*begin::Summary*/}
                    <div className="d-flex flex-center flex-column mb-5">
                        <div className="fs-5 fw-bold text-muted mb-3"><CustomerProblematic customer={customer} /></div>
                        <span className="fs-3 text-gray-800 fw-bolder mb-1"><SourceToLogoImage className='me-2' source={customer.source} />{customer.firstName} {customer.lastName}
                            {customer.isPremium &&
                                <ToolTipWrapper notification={`To klient premium. Wydał u Ciebie łącznie ${customer.totalSpendings} zł`}>
                                    <i className="fa fa-star me-1 text-warning fs-5 ms-2" />
                                </ToolTipWrapper>}
                        </span>
                        {/*end::Name*/}
                        {/*begin::Position*/}
                        <div className="fs-5 fw-bold text-muted mb-5">Numer klienta: #{customer.number}</div>
                        <div className="fs-5 fw-bold text-muted mb-3"><CustomerEventStatus customer={customer} /></div>
                        {/*end::Position*/}
                    </div>
                    {/*end::Summary*/}
                    {/*begin::Details toggle*/}
                    <div className="d-flex flex-stack fs-4 py-3">
                        <div className="fw-bolder">Szczegóły
                        </div>
                        <span data-bs-toggle="tooltip" data-bs-trigger="hover" title="Edit customer details">
                            <button onClick={() => ShowModalDemoWarning()} className="btn btn-sm btn-light-primary">Edytuj</button>
                        </span>
                    </div>
                    {/*end::Details toggle*/}
                    <div className="separator separator-dashed my-3"></div>
                    {/*begin::Details content*/}
                    <div>
                        <div className="pb-0 fs-6">
                            {/*begin::Details item*/}
                            <div className='row'>
                                <div className='col-xxl-6'>
                                    <div className="fw-bolder mt-5">Wydała u Ciebie</div>
                                    <div className="text-gray-600">{customer.totalSpendingsFormatted} zł</div>
                                </div>
                                <div className='col-xxl-6'>
                                    <div className="fw-bolder mt-5">Złożyła zamówień</div>
                                    <div className="text-gray-600">{customer.totalOrders}</div>
                                </div>
                            </div>
                            {/*begin::Details item*/}
                            <div className="fw-bolder mt-5">Kontakt</div>
                            <div className="text-gray-600">
                                {customer.email}
                                <br />
                                {customer.phone}
                            </div>
                            {/*begin::Details item*/}
                            {/*begin::Details item*/}
                            <div className="fw-bolder mt-5">Adres</div>
                            <div className="text-gray-600">
                                {customer.streetAndNumber}
                                <br />{customer.postCode} {customer.city}
                                <br />Polska</div>
                            {/*begin::Details item*/}
                            {/*begin::Details item*/}
                            <div className="fw-bolder mt-5 mb-2">Zgody marketingowe:</div>
                            <div className="text-gray-600">
                                {customer.agreeEmail &&
                                    <div className="badge badge-light-success d-inline me-2">Zgoda na email</div>
                                }
                                {!customer.agreeEmail &&
                                    <div className="badge badge-light-danger d-inline me-2">Brak zgody na email</div>
                                }
                                {customer.agreeSms &&
                                    <div className="badge badge-light-success d-inline me-2">Zgoda na sms</div>
                                }
                                {!customer.agreeSms &&
                                    <div className="badge badge-light-danger d-inline me-2">Brak zgody na sms</div>
                                }
                            </div>
                            {/*begin::Details item*/}
                            {/*begin::Details item*/}
                            <div className="fw-bolder mt-5">Dołączyła</div>
                            <div className="text-gray-600">
                                <span>{customer.joined} na {customer.source}</span>
                            </div>
                            {/*begin::Details item*/}
                            {/*begin::Details item*/}
                            <div className="fw-bolder mt-5">Aktywność</div>
                            <div className="text-gray-600">
                                <CustomerActivity customer={customer} inline />
                            </div>
                            {/*begin::Details item*/}
                            {/* {customer.basketId &&
                                <NavLink to={urls.basketDetails(customer.basketId)} className="btn btn-success w-100 mb-0 mt-5"><i className="fa fa-shopping-basket me-1 text-white fs-5"></i> Klient ma przedmioty w koszyku <br /> Kliknij i zobacz ten koszyk</NavLink>} */}
                            {customer.eventStatus === 'open-parcel' && customer.openParcelId &&
                                <NavLink to={urls.orderDetails(customer.openParcelId)} className="btn btn-success w-100 mb-0 mt-5"><i className="fa fa-shopping-bag me-1 text-white fs-5"></i> Klient ma otwartą paczkę <br /> Kliknij i zobacz jej zawartość</NavLink>}
                            {customer.eventStatus === 'parcel-to-send' && customer.openParcelId &&
                                <NavLink to={urls.orderDetails(customer.openParcelId)} className="btn btn-success w-100 mb-0 mt-5"><i className="fa fa-shopping-bag me-1 text-white fs-5"></i> Klient ma paczkę do wysłania <br /> Kliknij i zobacz jej zawartość</NavLink>}
                            {customer.isBlackList &&
                                <button onClick={() => ShowModalDemoWarning()} className="btn btn-success w-100 mb-0 mt-5"><i className="fa fa-smile-wink me-1 text-white fs-5"></i> Usuń klienta z czarnej listy</button>}
                            {!customer.isBlackList &&
                                <button onClick={() => ShowModalDemoWarning()} className="btn btn-danger w-100 mb-0 mt-5"><i className="fa fa-ban me-1 text-white fs-5"></i> Dodaj klienta do czarnej listy</button>}
                        </div>
                    </div>
                    {/*end::Details content*/}
                </div>
                {/*end::Card body*/}
            </div>
            {/*end::Card*/}
        </>
    )
})
