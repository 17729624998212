import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import urls from '../../app/common/urls';
import CustomerActivity from '../../app/elements/CustomerActivity';
import CustomerEventStatus from '../../app/elements/CustomerEventStatus';
import CustomerProblematic from '../../app/elements/CustomerProblematic';
import SourceToLogoImage from '../../app/elements/SourceToLogoImage';
import ToolTipWrapper from '../../app/elements/ToolTipWrapper';
import PaginationBar from '../../app/layout/PaginationBar';
import { Customer } from '../../app/models/models';
import { PagingParams } from '../../app/models/pagination';
import { useStore } from '../../app/stores/store';

export enum CustomerMode {
    All,
    Blacklist,
    ParcelsToSend
}

interface Props {
    mode?: CustomerMode
}

export default observer(function CustomersPage({ mode }: Props) {
    const [t] = useTranslation('common');
    const { customerStore: store } = useStore();
    const { loadItems } = store;
    const { setPredicate, setPagingParams, pagination, clearPredicateAndPaging } = store.filteringAndPaginationController;
    const { modalStore: { show: ShowModalDemoWarning } } = useStore();

    function handleGetNext() {
        setPagingParams(new PagingParams(pagination!.currentPage + 1));
        loadItems();
    }

    function handleGetPrev() {
        setPagingParams(new PagingParams(pagination!.currentPage - 1));
        loadItems();
    }

    const handleFilterChange = (event: any) => {
        setPredicate('filter', event.target.value);
    }

    const handleSortChange = (event: any) => {
        setPredicate('sort', event.target.value);
    }

    const handleSearchChange = (event: any) => {
        const text = event.target.value;
        if (text === '') {
            setPredicate('search', event.target.value)
        }
        else if (text.length >= 3) {
            setPredicate('search', event.target.value)
        }
    }

    const renderTitle = () => {
        switch (mode) {
            case CustomerMode.Blacklist: return 'Czarna lista'
        }
        return 'Klienci'
    }

    useEffect(() => {
        clearPredicateAndPaging();
        switch (mode) {
            case CustomerMode.Blacklist: setPredicate('filter', 'blacklist'); break;
        }
        store.loadItems();
    }, [store, clearPredicateAndPaging, mode, setPredicate])


    const renderSpending = (value: Customer) => {
        const text = `Ten klient wydał u Ciebie łącznie ${value.totalSpendingsFormatted} zł`;
        if (value.isPremium) {
            return <>
                <ToolTipWrapper notification={text} >
                    <span className="badge py-3 px-4 fs-7 badge-success">{value.totalSpendingsFormatted} zł
                    </span>
                </ToolTipWrapper>
            </>
        } else {
            return <>
                <ToolTipWrapper notification={text} >
                    <span className="py-3 px-4 fs-7 text-gray-700">{value.totalSpendingsFormatted} zł</span>
                </ToolTipWrapper>
            </>
        }
    }

    return (
        <>
            {/*begin::Table*/}
            <div className="card card-flush mt-6 mt-xl-9">
                {/*begin::Card header*/}
                <div className="card-header mt-5">
                    {/*begin::Card title*/}
                    <div className="card-title flex-column">
                        <h3 className="fw-bolder mb-1">{renderTitle()}</h3>
                        <div className="fs-6 text-gray-400">Łącznie {pagination?.totalItems}</div>
                    </div>
                    {/*begin::Card title*/}
                    {/*begin::Card toolbar*/}
                    <div className="card-toolbar my-1">
                        {/*begin::Select*/}
                        <div className="me-6 my-1">
                            <select name="sort" onChange={handleSortChange} className="w-275px form-select form-select-solid form-select-sm">
                                <option value="All" >Sortuj: Status</option>
                                <option value="activity-desc">Aktywność: od najaktywniejszych</option>
                                <option value="activity-asc">Aktywność: od najmniej aktywnych</option>
                                <option value="orders-desc">Zamówień: najwięcej</option>
                                <option value="orders-asc">Zamówień: najmniej </option>
                                <option value="spent-desc">Wydanych pieniędzy: najwięcej</option>
                                <option value="spent-asc">Wydanych pieniędzy: najmniej</option>
                                <option value="lastname-asc">Po nazwisku: A-Z</option>
                                <option value="lastname-desc">Po nazwisku: Z-A</option>
                            </select>
                        </div>
                        {/*end::Select*/}
                        {/*begin::Select*/}
                        {/* <div className="me-6 my-1">
                            <select name="from" onChange={handleFromChange} className="w-150px form-select form-select-solid form-select-sm">
                                <option value="All" >{t('--Customers.All')}</option>
                                <option value="facebook">Facebook</option>
                                <option value="tiktok">TikTok</option>
                            </select>
                        </div> */}
                        {/*end::Select*/}
                        {/*begin::Select*/}
                        {mode === CustomerMode.All &&
                            <div className="me-4 my-1">
                                <select name="filter" onChange={handleFilterChange} className="w-275px form-select form-select-solid form-select-sm">
                                    <option value="all">Filtruj: Wszyscy</option>
                                    <option value="active">Najbardziej aktywni</option>
                                    <option value="most-spending">Najwięcej wydający</option>
                                    <option value="open-parcel">Z otwartą paczką</option>
                                    <option value="parcel-to-send">Z paczką do wysłania</option>
                                    <option value="blacklist">Na czarnej liście</option>
                                </select>
                            </div>}
                        {/*end::Select*/}
                        {/*begin::Search*/}
                        <div className="d-flex align-items-center position-relative my-1 me-4">
                            {/*begin::Svg Icon | path: icons/duotone/General/Search.svg*/}
                            <span className="svg-icon svg-icon-3 position-absolute ms-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <rect x="0" y="0" width="24" height="24" />
                                        <path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                        <path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z" fill="#000000" fillRule="nonzero" />
                                    </g>
                                </svg>
                            </span>
                            {/*end::Svg Icon*/}
                            <input type="text" onChange={handleSearchChange} className="form-control form-control-solid form-select-sm w-250px ps-9" placeholder={'Szukaj (minimum 3 litery)'} />
                        </div>
                        {/*end::Search*/}
                        <div className="d-flex align-items-center position-relative my-1">
                            <button onClick={() => ShowModalDemoWarning()} type="button" className="btn btn-primary btn-sm" >
                                <span className="svg-icon svg-icon-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <rect fill="#000000" x="4" y="11" width="16" height="2" rx="1"></rect>
                                        <rect fill="#000000" opacity="0.5" transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000)" x="4" y="11" width="16" height="2" rx="1"></rect>
                                    </svg>
                                </span>
                                {t('--Customers.Add customer')}</button>
                        </div>
                    </div>
                    {/*begin::Card toolbar*/}
                </div>
                {/*end::Card header*/}
                {/*begin::Card body*/}
                <div className="card-body pt-0">
                    {/*begin::Table container*/}
                    <div className="table-responsive overflow-scroll">
                        {/*begin::Table*/}
                        <table className="table table-row-bordered table-row-dashed gy-2 align-middle fw-bolder">
                            {/*begin::Head*/}
                            <thead className="fs-7 text-gray-400 text-uppercase">
                                <tr>
                                    <th className="min-w-200px">Dane klienta</th>
                                    <th colSpan={2} className="min-w-50px">Ilość Zamówień / <br />wydanych pieniędzy</th>
                                    <th className="min-w-50px">Aktywność</th>
                                    <th className="min-w-50px">Dołączyła</th>
                                    <th className="min-w-50px">Status</th>
                                    <th className="min-w-260px text-end"></th>
                                </tr>
                            </thead>
                            {/*end::Head*/}
                            {/*begin::Body*/}
                            <tbody className="fs-6">
                                {store.items.map((i) => (
                                    <tr key={i.id}>
                                        <td>
                                            {/*begin::User*/}
                                            <div className="d-flex align-items-center">
                                                {/*begin::Info*/}
                                                <div className="position-relative ps-6 pe-3 py-2">
                                                    {i.eventStatus?.length !== 0 &&
                                                        <div className="position-absolute start-0 top-0 w-4px h-100 rounded-2 bg-success"></div>}
                                                    {i.troubleComment?.length !== 0 &&
                                                        <div className="position-absolute start-0 top-0 w-4px h-100 rounded-2 bg-danger"></div>}
                                                    {i.isBlackList &&
                                                        <div className="position-absolute start-0 top-0 w-4px h-100 rounded-2 bg-black"></div>}
                                                    <NavLink to={urls.customerDetails(i.id)} className="fs-6 text-gray-800 text-hover-primary">{i.firstName} {i.lastName}
                                                        {i.isPremium &&
                                                            <ToolTipWrapper notification={`To klient premium. Wydał u Ciebie łącznie ${i.totalSpendings} zł`}>
                                                                <i className="fa fa-star me-1 text-warning fs-5 ms-2" />
                                                            </ToolTipWrapper>}
                                                    </NavLink>
                                                    <div className="fw-bold text-gray-400">{i.phone}</div>
                                                    {/* <div className="fw-bold text-gray-400">{i.email}</div> */}
                                                </div>
                                                {/*end::Info*/}
                                            </div>
                                            {/*end::User*/}
                                        </td>
                                        <td>
                                            {i.totalOrders}<i className="fa fa-truck text-muted fs-8 p-2" />
                                        </td>
                                        <td>
                                            {renderSpending(i)}
                                        </td>

                                        <td>
                                            <CustomerActivity customer={i} />
                                        </td>
                                        <td className='fs-7 text-gray-700'>
                                            <SourceToLogoImage source={i.source} />
                                            <span className=''>{i.joined}</span>
                                        </td>
                                        <td>
                                            <CustomerEventStatus customer={i} isLeft />
                                            <CustomerProblematic customer={i} isLeft/>
                                        </td>
                                        <td className="text-end">
                                            <NavLink to={urls.customerDetails(i.id)} className="btn btn-light btn-sm me-3">Szczegóły</NavLink>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                            {/*end::Body*/}
                        </table>
                        {/*end::Table*/}
                    </div>
                    {/*end::Table container*/}
                    {pagination &&
                        <PaginationBar pagination={pagination!} handleGetNext={handleGetNext} handleGetPrev={handleGetPrev} />}
                </div>
                {/*end::Card body*/}
            </div>
            {/*end::Card*/}
        </>
    )
})