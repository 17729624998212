import React from 'react';
import { observer } from 'mobx-react-lite';

import HomePage from '../../features/home/HomePage';
import CalendarTest from '../../features/playground/CalendarTest';
import ChartTest from '../../features/playground/ChartTest';
import ChartTest2 from '../../features/playground/ChartTest2';
import ChartTest3 from '../../features/playground/ChartTest3';
import ChartTest4 from '../../features/playground/ChartTest4';
import DashDelivery from '../../features/playground/DashDelivery';
import DashEcommerce from '../../features/playground/DashEcommerce';
import DashStoreAnalytics from '../../features/playground/DashStoreAnalytics';

export default observer(function AllDash() {
    return (
        <>
            <HomePage />
            <ChartTest />
            <ChartTest2 />
            <ChartTest3 />
            <ChartTest4 />
            <DashDelivery />
            <DashEcommerce />
            <DashStoreAnalytics />
            <DashDelivery />
            <CalendarTest />
        </>
    )
})