import React from 'react';
import { observer } from 'mobx-react-lite';
import { Basket } from '../../../app/models/models';
import SourceToLogoImage from '../../../app/elements/SourceToLogoImage';
import ToolTipWrapper from '../../../app/elements/ToolTipWrapper';
import { NavLink } from 'react-router-dom';
import urls from '../../../app/common/urls';
import { useStore } from '../../../app/stores/store';

interface Props {
    basket: Basket
}

export default observer(function BasketDetailsData({ basket }: Props) {
    const { modalStore: {show: ShowModalDemoWarning} } = useStore();
    
    return (
        <>
            {/*begin::Card*/}
            <div className="card mb-5 mb-xl-8">
                {/*begin::Card body*/}
                <div className="card-body pt-15">
                    {/*begin::Summary*/}
                    <div className="d-flex flex-center flex-column mb-5">
                        {/* <div className="fs-5 fw-bold text-muted mb-3"><CustomerProblematic customer={order.number} /></div> */}
                        <span className="fs-3 text-gray-800 fw-bolder mb-1"><SourceToLogoImage className='me-2' source={basket.source} />Koszyk</span>
                        {/*end::Name*/}
                        {/*begin::Position*/}
                        <div className="fs-5 fw-bold text-muted mb-5">Numer koszyka: #{basket.number}</div>
                        {/*end::Position*/}
                        <ToolTipWrapper notification='Ten koszyk ma już ponad pięć dni.'>
                            <span className="badge py-3 px-4 fs-7 badge-danger">Koszyk zalegający {basket.createdDaysAgo} dni
                            </span>
                        </ToolTipWrapper>
                    </div>
                    {/*end::Summary*/}
                    {/*begin::Details toggle*/}
                    <div className="d-flex flex-stack fs-4 py-3">
                        <div className="fw-bolder">Szczegóły
                        </div>
                        <span data-bs-toggle="tooltip" data-bs-trigger="hover" title="Edit customer details">
                            <button onClick={() => ShowModalDemoWarning()} className="btn btn-sm btn-light-primary">Edytuj</button>
                        </span>
                    </div>
                    {/*end::Details toggle*/}
                    <div className="separator separator-dashed my-3"></div>
                    {/*begin::Details content*/}
                    <div>
                        <div className="pb-0 fs-6">
                            {/*begin::Details item*/}
                            <div className='row'>
                                <div className='col-6'>
                                    <div className="fw-bolder mt-5">Wartość koszyka</div>
                                    <div className="text-gray-600">{basket.totalValue} zł</div>
                                </div>
                                <div className='col-6'>
                                    <div className="fw-bolder mt-5">Ilość przedmiotów</div>
                                    <div className="text-gray-600">{basket.totalItems} szt.</div>
                                </div>
                            </div>
                            {/*begin::Details item*/}
                            <div className='row'>
                                <div className='col-6'>
                                    <div className="fw-bolder mt-5">Utworzono dnia</div>
                                    <div className="text-gray-600">{basket.createdAt}</div>
                                </div>
                                <div className='col-6'>
                                    <div className="fw-bolder mt-5">Utworzono na</div>
                                    <div className="text-gray-600">{basket.source}</div>
                                </div>
                            </div>
                            {/*begin::Details item*/}
                            {/*begin::Details item*/}
                            <div className="fw-bolder mt-5">Dane klienta</div>
                            <NavLink to={urls.customerDetails(basket.customerId)}>
                                <div className="text-gray-600 text-hover-primary">{basket.customerFullName}
                                </div>
                            </NavLink>
                            {/*begin::Details item*/}
                            <button onClick={() => ShowModalDemoWarning()} className="btn btn-danger w-100 mb-0 mt-5"><i className="fa fa-trash-alt me-1 text-white fs-5"></i> Usuń koszyk</button>
                        </div>
                    </div>
                    {/*end::Details content*/}
                </div>
                {/*end::Card body*/}
            </div>
            {/*end::Card*/}
        </>
    )
})
