import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { loadScript, unloadScript } from '../../app/common/helpers';
export default observer(function PurchasesTable() {
    useEffect(() => {
        const script0 = loadScript('/assets/js/scripts.bundle.js');
        const script1 = loadScript('/partner-custom/demo/purchasesTable.js');
        return () => {
            unloadScript(script0);
            unloadScript(script1);
        }
    }, []);
    return (
        <>
            <div className="card card-xl-stretch mb-xl-8" id="shc_purchases">
                {/*begin::Header*/}
                <div className="card-header border-0 pt-5" id="shc_header">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bolder fs-3 mb-1">Zakupy na live <span className="badge badge-danger badge-circle w-10px h-10px me-1 animation-blink"></span></span>
                        <span className="text-muted mt-1 fw-bold fs-7">Tutaj dostarczamy informacje na bieżąco o zakupach podczas live. Zwróć uwagę, że jeśli jakiegoś towaru nie ma, a ktoś chce go kupić będzie to specjalnie oznaczone. Teraz oczywiście widzisz demo :)</span>
                    </h3>
                </div>
                {/*end::Header*/}
                {/*begin::Body*/}
                <div className="card-body py-3" id="shc_purchases_body">
                    <div className="tab-content">
                        {/*begin::Tap pane*/}
                        <div className="tab-pane fade show active" id="kt_table_widget_5_tab_1">
                            {/*begin::Table container*/}
                            <div className="table-responsive scroll-y me-n5 pe-5 h-300px h-lg-auto " data-kt-element="messages" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer" data-kt-scroll-wrappers="#kt_content, #kt_chat_messenger_body" data-kt-scroll-offset="5px">
                                {/*begin::Table*/}
                                <table className="table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4" >
                                    {/*begin::Table head*/}
                                    <thead>
                                        <tr className="border-0">
                                            <th className="p-0 w-50px"></th>
                                            <th className="p-0 min-w-150px"></th>
                                            <th className="p-0 min-w-140px"></th>
                                            <th className="p-0 min-w-110px"></th>
                                        </tr>
                                    </thead>
                                    {/*end::Table head*/}
                                    {/*begin::Table body*/}
                                    <tbody className="">
                                        <tr className='d-none' data-kt-element="template-out">
                                            <td className='pe-2 pb-3'>
                                                <span className="text-muted fw-bold d-block" data-kt-element="message-time">Teraz</span>
                                            </td>
                                            <td className='pe-2 min-w-175px pb-3'>
                                                <span className="text-dark fw-bolder mb-1 fs-6" data-kt-element="message-name">Alina Bukowska</span><br />
                                                <span className="text-dark fw-bolder mb-1 fs-6" data-kt-element="message-surname">Alina Bukowska</span>
                                            </td>
                                            <td className='pe-2 w-200px pb-3'>
                                                <span className="text-dark fw-bolder mb-1 fs-6" data-kt-element="message-text">Czarna bluzka Motyl</span>
                                                <span className="text-muted fw-bold d-block" data-kt-element="message-code">6384h</span>
                                            </td>
                                            <td className="text-end pb-3">
                                                <div className="d-none badge badge-light-success ms-1" data-kt-element="message-inbasket-badge">dodano do koszyka</div>
                                                <div className="d-none badge badge-light-danger ms-1" data-kt-element="message-notinstock-badge">brak towaru</div>
                                                <div className="d-none badge badge-light-warning ms-1" data-kt-element="message-last-badge">ostatnia sztuka</div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    {/*end::Table body*/}
                                </table>
                            </div>
                            {/*end::Table*/}
                        </div>
                        {/*end::Tap pane*/}
                    </div>
                </div>
                {/*end::Body*/}
            </div>
        </>
    )
})
