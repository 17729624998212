import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Reservation, ReservationAdd } from "../models/models";
import FilteringAndPaginationController, { StoreWithLoadItems } from "../common/filteringAndPaginationController";

export default class ReservationStore implements StoreWithLoadItems{
    registry = new Map<string, Reservation>();
    selectedItem: Reservation | undefined = undefined;
    filteringAndPaginationController = new FilteringAndPaginationController(this);

    constructor() {
        makeAutoObservable(this);
    }

    loadItems = async () => {
        const result = await agent.Reservations.list(this.filteringAndPaginationController.axiosParams);
        this.filteringAndPaginationController.setPagination(result.pagination);
        runInAction(() => {
            this.registry.clear();
            result.data.forEach(b => {
                this.registry.set(b.id, b);
            });
        })
    }

    loadItem = async (id: string) => {
        this.selectedItem = await agent.Reservations.details(id);
        return this.selectedItem;
    }

    removeItem = async (id: string) => {
        await agent.Reservations.delete(id);
    }

    createItem = async (reservation: ReservationAdd) =>{
        await agent.Reservations.create(reservation);
    }

    get items() {
        return Array.from(this.registry.values());
    }
}