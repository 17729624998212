import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';

export default observer(function EmailMessageWidget() {
    const { modalStore: {show: ShowModalDemoWarning} } = useStore();
    return (
        <>
            {/*begin::Card*/}
            <div className="card mt-10">
                <div className="card-header border-0">
                    <div className="card-title m-0">
                        <h3 className="fw-bolder m-0">Wyślij powiadomienie sms</h3>
                    </div>
                </div>
                <div className="card-body p-0">
                    <div className="fv-row">
                        <div className="col-12 px-10 py-5">
                            {/*begin::Heading*/}
                            <div className="mb-3">
                                {/*begin::Label*/}
                                <label className="d-flex align-items-center fs-5 fw-bold">
                                    <span>Wybierz ilość klientów (1 sms = 20gr brutto)</span>
                                </label>
                                {/*end::Label*/}
                                {/*begin::Description*/}
                                <div className="fs-7 fw-bold text-muted py-5">Smsy zostaną wysłane do klientów, którzy najczęściej u Ciebie kupowali w ciągu ostatnich 12 miesięcy
                                    (największa liczba zrealizowanych zamówień)
                                </div>
                                {/*end::Description*/}
                            </div>
                            {/*end::Heading*/}
                            {/*begin::Row*/}
                            <div className="fv-row pb-5">
                                {/*begin::Radio group*/}
                                <div className="btn-group w-100">
                                    {/*begin::Radio*/}
                                    <label className="btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-primary" data-kt-button="true" onClick={() => ShowModalDemoWarning()}>
                                        {/*begin::Input*/}
                                        <input className="btn-check" type="radio" name="method" defaultValue="1" />
                                        {/*end::Input*/}
                                        20 sms <br /> koszt 4zł</label>
                                    {/*end::Radio*/}
                                    {/*begin::Radio*/}
                                    <label className="btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-primary active" data-kt-button="true" onClick={() => ShowModalDemoWarning()}>
                                        {/*begin::Input*/}
                                        <input className="btn-check" type="radio" name="method" defaultValue="2" />
                                        {/*end::Input*/}
                                        50 sms <br /> koszt 10zł</label>
                                    {/*end::Radio*/}
                                    {/*begin::Radio*/}
                                    <label className="btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-primary" data-kt-button="true" onClick={() => ShowModalDemoWarning()}>
                                        {/*begin::Input*/}
                                        <input className="btn-check" type="radio" name="method" defaultValue="3" />
                                        {/*end::Input*/}
                                        100 sms <br /> koszt 20zł</label>
                                    {/*end::Radio*/}
                                    {/*begin::Radio*/}
                                    <label className="btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-primary" data-kt-button="true" onClick={() => ShowModalDemoWarning()}>
                                        {/*begin::Input*/}
                                        <input className="btn-check" type="radio" name="method" defaultValue="4" />
                                        {/*end::Input*/}
                                        200 sms <br /> koszt 40zł</label>
                                    {/*end::Radio*/}
                                </div>
                                {/*end::Radio group*/}
                            </div>
                            {/*end::Row*/}
                        </div>
                        {/*end::Input group*/}
                    </div>
                    {/*begin::Form*/}
                    <form id="kt_inbox_compose_form">
                        {/*begin::Body*/}
                        <div className="d-block">
                            {/*begin::To*/}
                            <div className="d-flex align-items-center border-bottom px-8 min-h-50px">
                                {/*begin::Label*/}
                                <div className="text-dark fw-bolder w-60px">Do:</div>
                                {/*end::Label*/}
                                {/*begin::Input*/}
                                <input type="text" className="form-control form-control-transparent border-0" value='50 najczęściej kupujących klientek ze zgodą sms' />
                                {/*end::Input*/}
                            </div>
                            {/*begin::Message*/}
                            <div className="bg-transparent border-0 h-320px px-8 py-8">
                                <textarea className='w-100 h-100px p-2' value={'Najlepszy Butik Świata zaprasza na live jutro o 18:00 Rabaty do -50%. Konkurs dla udostępniających! Zapraszamy!'} onChange={() => ShowModalDemoWarning()}>
                                    
                                </textarea>

                            </div>
                            {/*end::Message*/}
                        </div>
                        {/*end::Body*/}
                        {/*begin::Footer*/}
                        <div className="py-5 ps-8 pe-5 border-top">
                            {/*begin::Actions*/}
                            <div className="me-3 text-end">
                                {/*begin::Send*/}
                                <span className='pe-3' >znaków: 112/160</span>
                                <div className="btn-group me-4">
                                    {/*begin::Submit*/}
                                    <span className="btn btn-primary fs-bold px-6 w-250px" onClick={() => ShowModalDemoWarning()}>
                                        <span className="indicator-label">Wyślij</span>
                                    </span>
                                    {/*end::Submit*/}
                                </div>
                                {/*end::Send*/}
                            </div>
                            {/*end::Actions*/}
                        </div>
                        {/*end::Footer*/}
                    </form>
                    {/*end::Form*/}
                </div>
            </div>
            {/*end::Card*/}
        </>
    )
})
