const urls = {
    root: () => '/',
    forgotPassword: () => '/forgot-password',
    resetPassword: () => '/reset-password/:uid/:token',

    dashboard: () => '/dashboard',

    orders: () => '/orders',
    orderDetails: (id: string) => `${urls.orders()}/${id}`,
    orderTemplate: () => urls.orderDetails(':id'),

    customers: () => '/customers',
    customerDetails: (id: string) => `${urls.customers()}/${id}`,
    customerTemplate: () => urls.customerDetails(':id'),

    products: () => '/products',
    productDetails: (id: string) => `${urls.products()}/${id}`,
    productTemplate: () => urls.productDetails(':id'),

    baskets: () => '/baskets',
    basketDetails: (id: string) => `${urls.baskets()}/${id}`,
    basketTemplate: () => urls.basketDetails(':id'),

    history: () => '/history',
    historyDetails: (id: string) => `${urls.history()}/${id}`,
    historyTemplate: () => urls.historyDetails(':id'),

    openParcels: () => '/open-parcels',
    parcelsToSend: () => '/parcels-to-send',
    pickups: () => '/pickups',
    blackList: () => '/black-list',
    buildYourReach: () => '/build-your-reach',
    statistics: () => '/statistics',
    settings: () => '/settings',
    liveView: () => '/live-view',
}

export enum RedirectTargets {
    channel = 'channel',
    terms = 'terms'
}

export default urls;