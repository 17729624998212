import React from 'react';
import { observer } from 'mobx-react-lite';
import { Product } from '../../../app/models/models';
import ProgressBar from '../../../app/elements/ProgressBar';
import ToolTipWrapper from '../../../app/elements/ToolTipWrapper';
import { useStore } from '../../../app/stores/store';

interface Props {
    product: Product
}

export default observer(function ProductDetailsData({ product }: Props) {
    const { modalStore: {show: ShowModalDemoWarning} } = useStore();
    return (
        <>
            {/*begin::Card*/}
            <div className="card mb-5 mb-xl-8">
                {/*begin::Card body*/}
                <div className="card-body pt-15">
                    {/*begin::Summary*/}
                    <div className="d-flex flex-center flex-column mb-5">
                        {/* <div className="fs-5 fw-bold text-muted mb-3"><CustomerProblematic customer={order.number} /></div> */}
                        <span className="fs-3 text-gray-800 fw-bolder mb-1">{product.name}
                            {product.isHot &&
                                <ToolTipWrapper notification={product.isHotReason} >
                                    <i className="fa fa-fire-alt me-1 text-danger fs-3 ps-3 pe-0" ></i>
                                </ToolTipWrapper>
                            }
                        </span>
                        {/*end::Name*/}
                        {/*begin::Position*/}
                        <div className="fs-5 fw-bold text-muted mb-5">Kod: {product.code}</div>
                        {/* <div className="fs-5 fw-bold text-muted mb-3"><OrderStatus order={order} /></div> */}
                        {/*end::Position*/}
                    </div>
                    {/*end::Summary*/}
                    {/*begin::Details toggle*/}
                    <div className="d-flex flex-stack fs-4 py-3">
                        <div className="fw-bolder">Szczegóły
                        </div>
                        <span data-bs-toggle="tooltip" data-bs-trigger="hover" title="Edit customer details">
                            <button onClick={() => ShowModalDemoWarning()} className="btn btn-sm btn-light-primary">Edytuj</button>
                        </span>
                    </div>
                    {/*end::Details toggle*/}
                    <div className="separator separator-dashed my-3"></div>
                    {/*begin::Details content*/}
                    <div>
                        <div className="pb-5 fs-6">
                            {/*begin::Details item*/}
                            <div className='row'>
                                <div className='col-6'>
                                    <div className="fw-bolder mt-5">Cena</div>
                                    <div className="text-gray-600">{product.priceReadable} zł</div>
                                </div>
                                <div className='col-6'>
                                    <div className="fw-bolder mt-5">Dostępnych</div>
                                    <div className="text-gray-600">{product.available} szt.</div>
                                </div>
                            </div>
                            {/*begin::Details item*/}
                            <div className="fw-bolder mt-5">Wyprzedano</div>
                            <div className="text-gray-600"><ProgressBar percentage={product.soldPercentage} /></div>
                            {/*begin::Details item*/}
                            <div className='row'>
                                <div className='col-6'>
                                    <div className="fw-bolder mt-5">Sprzedanych</div>
                                    <div className="text-gray-600">{product.sold} szt.</div>
                                </div>
                                <div className='col-6'>
                                    <div className="fw-bolder mt-5">W koszykach</div>
                                    <div className="text-gray-600">{product.inBaskets} szt.</div>
                                </div>
                            </div>
                            {/*begin::Details item*/}
                            <div className='row'>
                                <div className='col-6'>
                                    <div className="fw-bolder mt-5">Dodano dnia</div>
                                    <div className="text-gray-600">{product.createdAtReadable}</div>
                                </div>
                                <div className='col-6'>
                                    <div className="fw-bolder mt-5">Przychód całkowity</div>
                                    <div className="text-gray-600">{product.totalRevenueReadable} zł</div>
                                </div>
                            </div>
                            {/*begin::Details item*/}
                            {/*begin::Details item*/}
                            <div className="fw-bolder mt-5">Opis</div>
                            <div className="text-gray-600">{product.description}</div>
                            {/*begin::Details item*/}
                            <button onClick={() => ShowModalDemoWarning()} className="btn btn-warning w-100 mb-0 mt-5"><i className="fa fa-pause-circle me-1 text-white fs-5"></i> Wstrzymaj sprzedaż</button>
                        </div>
                    </div>
                    {/*end::Details content*/}
                </div>
                {/*end::Card body*/}
            </div>
            {/*end::Card*/}
        </>
    )
})
