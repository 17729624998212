import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/layout/App';
import reportWebVitals from './reportWebVitals';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { I18nextProvider } from 'react-i18next';
import common_pl from "./translations/pl-PL/common.json";
import common_en from "./translations/en-US/common.json";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import ScrollToTop from './app/layout/ScrollToTop';

export const history = createBrowserHistory()

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    interpolation: { escapeValue: false },  // React already does escaping
    resources: {
      en: {
        common: common_en               // 'common' is our custom namespace
      },
      pl: {
        common: common_pl
      },
    },
  });

ReactDOM.render(
  <Router history={history}>
    <I18nextProvider i18n={i18next}>
      <ScrollToTop />
      <App />
    </I18nextProvider>
  </Router>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
