import { makeAutoObservable, runInAction } from "mobx";

export default class ModalStore {
    visible: boolean = false;
    reloadVisible: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    show = () => {
        runInAction(() => {
            this.visible = true;
        })
    }

    hide = () => {
        runInAction(() => {
            this.visible = false;
        })
    }

    showReload = () => {
        runInAction(() => {
            this.reloadVisible = true;
        })
    }

    hideReload = () => {
        runInAction(() => {
            this.reloadVisible = false;
        })
    }
}