import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import urls from './urls';
import ShipmentProviderLogo from '../elements/ShipmentProviderLogo';
import ToolTipWrapper from '../elements/ToolTipWrapper';
import PaginationBar from '../layout/PaginationBar';
import { Order } from '../models/models';
import { PagingParams } from '../models/pagination';
import { useStore } from '../stores/store';
import NavButton from '../elements/NavButton';
import OrderStatus from '../elements/OrderStatus';
import SourceToLogoImage from '../elements/SourceToLogoImage';

export enum OrdersMode {
    All,
    OpenParcels,
    ParcelsToSend,
    Pickups,
    CustomerWidget,
    ProductWidget,
}

interface Props {
    mode?: OrdersMode
    parentObjectId?: string[]
}

export default observer(function OrdersListWidget({ mode, parentObjectId = undefined }: Props) {
    const { orderStore: store } = useStore();
    const { loadItems } = store;
    const { setPredicate, setPagingParams, pagination, clearPredicateAndPaging } = store.filteringAndPaginationController;

    function handleGetNext() {
        setPagingParams(new PagingParams(pagination!.currentPage + 1));
        loadItems();
    }

    function handleGetPrev() {
        setPagingParams(new PagingParams(pagination!.currentPage - 1));
        loadItems();
    }

    const handleFilterChange = (event: any) => {
        setPredicate('filter', event.target.value);
    }

    const handleSortChange = (event: any) => {
        setPredicate('sort', event.target.value);
    }

    const handleSearchChange = (event: any) => {
        const text = event.target.value;
        if (text === '') {
            setPredicate('search', event.target.value)
        }
        else if (text.length >= 3) {
            setPredicate('search', event.target.value)
        }
    }

    useEffect(() => {
        clearPredicateAndPaging();
        switch (mode) {
            case OrdersMode.All: setPredicate('filter', 'all'); break;
            case OrdersMode.OpenParcels: setPredicate('filter', 'open-parcel'); break;
            case OrdersMode.ParcelsToSend: setPredicate('filter', 'to-ship'); break;
            case OrdersMode.Pickups: setPredicate('filter', 'pickup'); break;
            case OrdersMode.CustomerWidget: setPredicate(parentObjectId![0], parentObjectId![1]); break;
            case OrdersMode.ProductWidget: setPredicate(parentObjectId![0], parentObjectId![1]); break;
        }

        store.loadItems();
    }, [store, clearPredicateAndPaging, mode, setPredicate, parentObjectId])

    const renderTitle = () => {
        switch (mode) {
            case OrdersMode.OpenParcels: return 'Otwarte Paczki'
            case OrdersMode.ParcelsToSend: return 'Paczki do wysłania'
            case OrdersMode.Pickups: return 'Odbiory osobiste'
            case OrdersMode.ProductWidget: return 'W zamówieniach'
        }
        return 'Zamówienia'
    }

    const renderTime = (value: Order) => {
        if (value.createdWarning) {
            return <>
                <ToolTipWrapper notification='Ta paczka czeka na wysłanie ponad trzy dni.'>
                    <span className="badge py-3 px-4 fs-7 badge-danger">{value.createdAt}
                    </span>
                </ToolTipWrapper>
            </>
        } else {
            return <>
                <span className="py-3 px-4 fs-7 text-gray-700">{value.createdAt}</span>
            </>
        }
    }

    return (
        <>
            {/*begin::Table*/}
            <div className={`card card-flush ${mode === OrdersMode.CustomerWidget || mode === OrdersMode.ProductWidget ? '' : 'mt-15'} `}>
                {/*begin::Card header*/}
                <div className="card-header mt-5">
                    {/*begin::Card title*/}
                    <div className="card-title flex-column">
                        <h3 className="fw-bolder mb-1">{renderTitle()}</h3>
                        <div className="fs-6 text-gray-400">Łącznie {pagination?.totalItems}</div>
                    </div>
                    {/*begin::Card title*/}
                    {/*begin::Card toolbar*/}
                    <div className="card-toolbar my-1">
                        {/*begin::Select*/}
                        <div className="me-6 my-1">
                            <select name="sort" onChange={handleSortChange} className="w-275px form-select form-select-solid form-select-sm">
                                <option value="All" >Sortuj: Po dacie</option>
                                <option value="customer-asc">Po nazwisku: od A do Z</option>
                                <option value="customer-desc">Po nazwisku: od Z do A</option>
                                <option value="value-desc">Wartość zamówienia: od najdroższych</option>
                                <option value="value-asc">Wartość zamówienia: od najtańszych</option>
                                <option value="created-desc">Data złożenia: od najnowszych</option>
                                <option value="created-asc">Data złożenia: od najstarszych</option>
                            </select>
                        </div>
                        {/*end::Select*/}
                        {/*begin::Select*/}
                        {/* <div className="me-6 my-1">
                            <select name="from" onChange={handleFromChange} className="w-150px form-select form-select-solid form-select-sm">
                                <option value="All" >{t('--Customers.All')}</option>
                                <option value="facebook">Facebook</option>
                                <option value="tiktok">TikTok</option>
                            </select>
                        </div> */}
                        {/*end::Select*/}
                        {/*begin::Select*/}
                        {mode === OrdersMode.All &&
                            <div className="me-4 my-1">
                                <select name="filter" onChange={handleFilterChange} className="w-275px form-select form-select-solid form-select-sm">
                                    <option value="all">Filtruj: Wszyscy</option>
                                    <option value="finished">Zrealizowano</option>
                                    <option value="open-parcel">Otwarta Paczka</option>
                                    <option value="to-ship">Do wysłania</option>
                                    <option value="to-pay">Czekamy na płatność</option>
                                    <option value="shipped">Paczka wysłana</option>
                                    <option value="pickup">Odbiór osobisty</option>
                                </select>
                            </div>}
                        {/*end::Select*/}
                        {/*begin::Search*/}
                        <div className="d-flex align-items-center position-relative my-1 me-4">
                            {/*begin::Svg Icon | path: icons/duotone/General/Search.svg*/}
                            <span className="svg-icon svg-icon-3 position-absolute ms-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <rect x="0" y="0" width="24" height="24" />
                                        <path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                        <path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z" fill="#000000" fillRule="nonzero" />
                                    </g>
                                </svg>
                            </span>
                            {/*end::Svg Icon*/}
                            <input type="text" onChange={handleSearchChange} className="form-control form-control-solid form-select-sm w-250px ps-9" placeholder={'Szukaj (minimum 3 litery)'} />
                        </div>
                        {/*end::Search*/}
                        {/* <div className="d-flex align-items-center position-relative my-1">
                            <button type="button" className="btn btn-primary btn-sm" onClick={() => handleNewCustomer()}>
                                <span className="svg-icon svg-icon-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <rect fill="#000000" x="4" y="11" width="16" height="2" rx="1"></rect>
                                        <rect fill="#000000" opacity="0.5" transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000)" x="4" y="11" width="16" height="2" rx="1"></rect>
                                    </svg>
                                </span>
                                {t('--Customers.Add customer')}</button>
                        </div> */}
                    </div>
                    {/*begin::Card toolbar*/}
                </div>
                {/*end::Card header*/}
                {/*begin::Card body*/}
                <div className="card-body pt-0">
                    {/*begin::Table container*/}
                    <div className="table-responsive">
                        {/*begin::Table*/}
                        <table className="table table-row-bordered table-row-dashed gy-2 align-middle fw-bolder">
                            {/*begin::Head*/}
                            <thead className="fs-7 text-gray-400 text-uppercase">
                                <tr>
                                    {mode !== OrdersMode.ProductWidget &&
                                        <th className="min-w-50px"></th>}
                                    {mode !== OrdersMode.CustomerWidget &&
                                        <>
                                            <th className="min-w-50px">Dane klienta</th>
                                            {mode !== OrdersMode.ProductWidget &&
                                                <th className="min-w-50px">Adres wysyłki</th>}
                                        </>}
                                    <th className="min-w-50px">Zamówienie złożone</th>
                                    <th className="min-w-50px">Status</th>
                                    <th className="min-w-260px">Wartość<br /> paczki</th>
                                </tr>
                            </thead>
                            {/*end::Head*/}
                            {/*begin::Body*/}
                            <tbody className="fs-6">
                                {store.items.map((i) => (
                                    <tr key={i.id}>
                                        {mode !== OrdersMode.ProductWidget &&
                                            <td className='text-center'>
                                                <ShipmentProviderLogo name={i.shippmentProvider} />
                                            </td>}
                                        {mode !== OrdersMode.CustomerWidget &&
                                            <>
                                                <td>
                                                    {/*begin::User*/}
                                                    <div className="d-flex align-items-center">
                                                        {/*begin::Info*/}
                                                        {i.customerIsPremium &&
                                                            <ToolTipWrapper notification={i.customerPremiumComment}>
                                                                <div className="position-relative pe-3 py-2">
                                                                    <NavLink to={urls.customerDetails(i.customerId)} className="fs-6 text-gray-800 text-hover-primary">
                                                                        {i.customerFullName}
                                                                        <i className="fa fa-star me-1 text-warning fs-5 ms-2" />
                                                                    </NavLink>
                                                                    <div className="fw-bold text-gray-400">{i.customerPhone}</div>
                                                                </div>
                                                            </ToolTipWrapper>}
                                                        {!i.customerIsPremium &&
                                                            <div className="position-relative pe-3 py-2">
                                                                <NavLink to={urls.customerDetails(i.customerId)} className="fs-6 text-gray-800 text-hover-primary">
                                                                    {i.customerFullName}
                                                                </NavLink>
                                                                <div className="fw-bold text-gray-400">{i.customerPhone}</div>
                                                            </div>}
                                                        {/*end::Info*/}
                                                    </div>
                                                    {/*end::User*/}
                                                </td>
                                                {mode !== OrdersMode.ProductWidget &&
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            {/*begin::Info*/}
                                                            <div className="position-relative pe-3 py-2">
                                                                <span className="fs-6 text-gray-800">{i.deliveryAddressLine1}</span><br />
                                                                <span className="fs-6 text-gray-800">{i.deliveryAddressLine2}</span>
                                                                {/* <div className="fw-bold text-gray-400">{i.email}</div> */}
                                                            </div>
                                                            {/*end::Info*/}
                                                        </div>
                                                    </td>}
                                            </>}
                                        <td>
                                            <SourceToLogoImage source={i.source} />
                                            {renderTime(i)}
                                        </td>
                                        <td>
                                            <OrderStatus order={i} />
                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                {/*begin::Info*/}
                                                <div className="position-relative pe-3 py-2">
                                                    <ToolTipWrapper notification={`Wartość zamówienia to ${i.totalValue} zł. Liczba przedmiotów w zamówieniu: ${i.totalItems}`} isLeft>
                                                        <span className="fs-6 text-gray-800">{i.totalValue} zł</span>
                                                        <div className="fw-bold fs-7 text-gray-400">{i.totalItems} <i className="fa fa-arrow-right me-1 text-muted fs-8" /><i className="fa fa-box-open me-1 text-muted fs-7" /></div>
                                                        {/* <div className="fw-bold text-gray-400">{i.email}</div> */}
                                                    </ToolTipWrapper>
                                                </div>
                                                {/*end::Info*/}
                                            </div>
                                        </td>
                                        <td className="text-end">
                                            <NavButton link={urls.orderDetails(i.id)} caption='Szczegóły' isSmall={mode === OrdersMode.CustomerWidget || mode === OrdersMode.ProductWidget} />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                            {/*end::Body*/}
                        </table>
                        {/*end::Table*/}
                    </div>
                    {/*end::Table container*/}
                    {pagination &&
                        <PaginationBar pagination={pagination!} handleGetNext={handleGetNext} handleGetPrev={handleGetPrev} />}
                </div>
                {/*end::Card body*/}
            </div>
            {/*end::Card*/}
        </>
    )
})