import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import ToolTipWrapper, { ToolTipWrapperMode } from '../../app/elements/ToolTipWrapper';
import ParcelsToShipWidget from './ParcelsToShipWidget';
import OldBasketsWidget from './OldBasketsWidget';
import PickupsWidget from './PickupsWidget';
import { formatMoney, loadScript, unloadScript } from '../../app/common/helpers';
import { useStore } from '../../app/stores/store';
import { runInAction } from 'mobx';


export default observer(function HomePage() {
    const { statisticsStore } = useStore();

    useEffect(() => {
        var script1: HTMLScriptElement;
        var script2: HTMLScriptElement;
        var script3: HTMLScriptElement;

        runInAction(() => {
            statisticsStore.loadStatistics().then(() => {
                script1 = loadScript('/partner-custom/widgets/SalesLastMonth.js');
                script2 = loadScript('/partner-custom/widgets/CommentsLastXTransmissions.js');
                script3 = loadScript('/partner-custom/widgets/SalesChannelsSummary.js');
            });
        })

        return () => {
            unloadScript(script1);
            unloadScript(script2);
            unloadScript(script3);
        }
    }, [statisticsStore]);

    const changeBadge = (n: number) => {
        if (n < 0) return (
            <>
                <span className={`badge badge-danger fs-6 lh-1 py-1 px-2 d-flex flex-center`} style={{ height: '22px' }}>
                    {/*begin::Svg Icon | path: icons/duotune/arrows/arr067.svg*/}
                    <span className="svg-icon svg-icon-7 svg-icon-white ms-n1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path opacity="0.5" d="M13 14.4V3.00003C13 2.40003 12.6 2.00003 12 2.00003C11.4 2.00003 11 2.40003 11 3.00003V14.4H13Z" fill="black"></path>
                            <path d="M5.7071 16.1071C5.07714 15.4771 5.52331 14.4 6.41421 14.4H17.5858C18.4767 14.4 18.9229 15.4771 18.2929 16.1071L12.7 21.7C12.3 22.1 11.7 22.1 11.3 21.7L5.7071 16.1071Z" fill="black"></path>
                        </svg>
                    </span>
                    {/*end::Svg Icon*/}{n}%</span>
            </>
        )
        return (
            <>
                <span className={`badge badge-success fs-6 lh-1 py-1 px-2 d-flex flex-center`} style={{ height: '22px' }}>
                    {/*begin::Svg Icon | path: icons/duotune/arrows/arr067.svg*/}
                    <span className="svg-icon svg-icon-7 svg-icon-white ms-n1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path opacity="0.5" d="M13 9.59998V21C13 21.6 12.6 22 12 22C11.4 22 11 21.6 11 21V9.59998H13Z" fill="black" />
                            <path d="M5.7071 7.89291C5.07714 8.52288 5.52331 9.60002 6.41421 9.60002H17.5858C18.4767 9.60002 18.9229 8.52288 18.2929 7.89291L12.7 2.3C12.3 1.9 11.7 1.9 11.3 2.3L5.7071 7.89291Z" fill="black" />
                        </svg>
                    </span>
                    {/*end::Svg Icon*/}{n}%</span>
            </>
        )
    }

    return (
        <>
            {/*begin::Row*/}
            <div className="row g-5 g-xl-10 mb-xl-10" style={{ paddingTop: '50px' }}>
                <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10">
                    {/*begin::Card widget 4*/}
                    <div className="card card-flush h-md-50 mb-5 mb-xl-10">
                        {/*begin::Header*/}
                        <div className="card-header pt-5">
                            {/*begin::Title*/}
                            <div className="card-title d-flex flex-column">
                                {/*begin::Info*/}
                                <div className="d-flex align-items-center">
                                    {/*begin::Currency*/}
                                    <span className="fs-4 fw-bold text-gray-400 me-1 align-self-start">zł</span>
                                    {/*end::Currency*/}
                                    {/*begin::Amount*/}
                                    <span className="fs-2hx fw-bolder text-dark me-2 lh-1">{formatMoney(statisticsStore.getTotalIncome())}</span>
                                    {/*end::Amount*/}
                                    {/*begin::Badge*/}
                                    <span className="badge badge-success fs-6 lh-1 py-1 px-2 d-flex flex-center" style={{ height: '22px' }}>
                                        {/*begin::Svg Icon | path: icons/duotune/arrows/arr067.svg*/}
                                        <span className="svg-icon svg-icon-7 svg-icon-white ms-n1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path opacity="0.5" d="M13 9.59998V21C13 21.6 12.6 22 12 22C11.4 22 11 21.6 11 21V9.59998H13Z" fill="black" />
                                                <path d="M5.7071 7.89291C5.07714 8.52288 5.52331 9.60002 6.41421 9.60002H17.5858C18.4767 9.60002 18.9229 8.52288 18.2929 7.89291L12.7 2.3C12.3 1.9 11.7 1.9 11.3 2.3L5.7071 7.89291Z" fill="black" />
                                            </svg>
                                        </span>
                                        {/*end::Svg Icon*/}2.2%</span>
                                    {/*end::Badge*/}
                                    <div>
                                        <ToolTipWrapper notification='objaśnienie widgeta' mode={ToolTipWrapperMode.Large}>
                                            <span className="btn btn-sm btn-icon bg-color-muted">
                                                <i className="fa fa-question-circle me-1 text-muted text-hover-primary fs-5 ms-2" />
                                            </span>
                                        </ToolTipWrapper>
                                    </div>
                                </div>
                                {/*end::Info*/}
                                {/*begin::Subtitle*/}
                                <span className="text-gray-400 pt-1 fw-bold fs-6">Sprzedaż - ostatnie 365 dni</span>
                                {/*end::Subtitle*/}
                            </div>
                            {/*end::Title*/}
                        </div>
                        {/*end::Header*/}
                        {/*begin::Card body*/}
                        <div className="card-body pt-2 pb-4 d-flex align-items-center">
                            {/*begin::Chart*/}
                            <div className="d-flex flex-center me-5 pt-2">
                                <div id="shckt_card_widget_4_chart" style={{ minWidth: '70px', minHeight: '70px' }} data-kt-size="70" data-kt-line="11"></div>
                            </div>
                            {/*end::Chart*/}
                            {/*begin::Labels*/}
                            <div className="d-flex flex-column content-justify-center w-100">
                                {/*begin::Label*/}
                                <div className="d-flex fs-6 fw-bold align-items-center">
                                    {/*begin::Bullet*/}
                                    <div className="bullet w-8px h-6px rounded-2 bg-primary me-1"></div>
                                    {/*end::Bullet*/}
                                    {/*begin::Label*/}
                                    <div className="text-gray-500 flex-grow-1 me-1">Facebook</div>
                                    {/*end::Label*/}
                                    {/*begin::Stats*/}
                                    <div className="fw-boldest text-gray-700 text-xxl-end">{formatMoney(statisticsStore.getFacebookForYear())}zł</div>
                                    {/*end::Stats*/}
                                </div>
                                {/*end::Label*/}
                                {/*begin::Label*/}
                                <div className="d-flex fs-6 fw-bold align-items-center my-3">
                                    {/*begin::Bullet*/}
                                    <div className="bullet w-8px h-6px rounded-2 bg-danger me-1"></div>
                                    {/*end::Bullet*/}
                                    {/*begin::Label*/}
                                    <div className="text-gray-500 flex-grow-1 me-1">Instagram</div>
                                    {/*end::Label*/}
                                    {/*begin::Stats*/}
                                    <div className="fw-boldest text-gray-700 text-xxl-end">{formatMoney(statisticsStore.getInstagramForYear())}zł</div>
                                    {/*end::Stats*/}
                                </div>
                                {/*end::Label*/}
                                {/*begin::Label*/}
                                <div className="d-flex fs-6 fw-bold align-items-center">
                                    {/*begin::Bullet*/}
                                    <div className="bullet w-8px h-6px rounded-2 me-1" style={{ backgroundColor: '#E4E6EF' }}></div>
                                    {/*end::Bullet*/}
                                    {/*begin::Label*/}
                                    <div className="text-gray-500 flex-grow-1 me-1">Tiktok</div>
                                    {/*end::Label*/}
                                    {/*begin::Stats*/}
                                    <div className="fw-boldest text-gray-700 text-xxl-end">{formatMoney(statisticsStore.getTikTokForYear())}zł</div>
                                    {/*end::Stats*/}
                                </div>
                                {/*end::Label*/}
                            </div>
                            {/*end::Labels*/}
                        </div>
                        {/*end::Card body*/}
                    </div>
                    {/*end::Card widget 4*/}
                    {/*begin::Card widget 5*/}
                    <div className="card card-flush h-md-50 mb-xl-10">
                        {/*begin::Header*/}
                        <div className="card-header pt-5">
                            {/*begin::Title*/}
                            <div className="card-title d-flex flex-column">
                                {/*begin::Info*/}
                                <div className="d-flex align-items-center">
                                    {/*begin::Amount*/}
                                    <span className="fs-2hx fw-bolder text-dark me-2 lh-1">{statisticsStore.statistics?.oldBaskets}</span>
                                    <div>
                                        <ToolTipWrapper notification='objaśnienie widgeta'>
                                            <span className="btn btn-sm btn-icon bg-color-muted">
                                                <i className="fa fa-question-circle me-1 text-muted text-hover-primary fs-5 ms-2" />
                                            </span>
                                        </ToolTipWrapper>
                                    </div>
                                </div>
                                {/*end::Info*/}
                                {/*begin::Subtitle*/}
                                <span className="text-gray-400 pt-1 fw-bold fs-6">Starych koszyków</span>
                                {/*end::Subtitle*/}
                            </div>
                            {/*end::Title*/}

                        </div>
                        {/*end::Header*/}
                        {/*begin::Card body*/}
                        <div className="card-body d-flex align-items-end pt-0">
                            {/*begin::Progress*/}
                            <div className="d-flex align-items-center flex-column mt-3 w-100">
                                <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                                    <span className="fw-boldest fs-6 text-dark">Zrealizowanych koszyków</span>
                                    <span className="fw-bolder fs-6 text-gray-400">{statisticsStore.statistics?.oldBasketsPercentage}%</span>
                                </div>
                                <div className="h-8px mx-3 w-100 bg-light-success rounded">
                                    <div className="bg-success rounded h-8px" role="progressbar" style={{ width: `${statisticsStore.statistics?.oldBasketsPercentage}%` }} ></div>
                                </div>
                            </div>
                            {/*end::Progress*/}
                        </div>
                        {/*end::Card body*/}
                    </div>
                    {/*end::Card widget 5*/}
                </div>
                {statisticsStore.statistics &&
                    <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10">
                        {/*begin::Card widget 9*/}
                        <div className="card h-md-50 mb-5 mb-lg-10">
                            {/*begin::Card body*/}
                            <div className="card-body d-flex justify-content-between flex-column px-0 pb-0">
                                {/*begin::Statistics*/}
                                <div className="mb-4 px-9">
                                    {/*begin::Statistics*/}
                                    <div className="d-flex align-items-center mb-2">
                                        {/*begin::Currency*/}
                                        <span className="fs-4 fw-bold text-gray-400 align-self-start me-1&gt;">zł</span>
                                        {/*end::Currency*/}
                                        {/*begin::Value*/}
                                        <span className="fs-2hx fw-bolder text-gray-800 me-2 lh-1">{formatMoney(statisticsStore.statistics!.salesLastTotal5)}</span>
                                        {/*end::Value*/}
                                        {changeBadge(statisticsStore.statistics!.salesLastChange5)}
                                        <div className="card-toolbar">
                                            <ToolTipWrapper notification='objaśnienie widgeta'>
                                                <span className="btn btn-sm btn-icon bg-color-muted">
                                                    <i className="fa fa-question-circle me-1 text-muted text-hover-primary fs-5 ms-2" />
                                                </span>
                                            </ToolTipWrapper>
                                        </div>
                                    </div>
                                    {/*end::Statistics*/}
                                    {/*begin::Description*/}
                                    <span className="fs-6 fw-bold text-gray-400">Sprzedaż (ostatnich 5 live)</span>
                                    {/*end::Description*/}
                                </div>
                                {/*end::Statistics*/}
                                {/*begin::Chart*/}
                                <div id="shc_kt_card_widget_9_chart" className="min-h-auto" style={{ height: '125px' }} data-attr={`[${statisticsStore.statistics!.salesLast5.join(',')}]`} data-labels={`["${statisticsStore.statistics!.salesLastLabels5.join('","')}"]`} ></div>
                                {/*end::Chart*/}
                            </div>
                            {/*end::Card body*/}
                        </div>
                        {/*end::Card widget 9*/}
                        {/*begin::Card widget 6*/}
                        <div className="card card-flush h-md-50 mb-5 mb-xl-10">
                            {/*begin::Header*/}
                            <div className="card-header pt-5">
                                {/*begin::Title*/}
                                <div className="card-title d-flex flex-column">
                                    {/*begin::Info*/}
                                    <div className="d-flex align-items-center">
                                        {/*begin::Currency*/}
                                        <span className="fs-4 fw-bold text-gray-400 me-1 align-self-start"><i className="fa fa-comment-dots me-1 fs-5" /></span>
                                        {/*end::Currency*/}
                                        {/*begin::Amount*/}
                                        <span className="fs-2hx fw-bolder text-dark me-2 lh-1">{formatMoney(statisticsStore.statistics!.commentsLastTotal5)}</span>
                                        {/*end::Amount*/}
                                        {/*begin::Badge*/}
                                        {changeBadge(statisticsStore.statistics!.commentsLastChange5)}
                                        {/*end::Badge*/}
                                        <div>
                                            <ToolTipWrapper notification='objaśnienie widgeta'>
                                                <span className="btn btn-sm btn-icon bg-color-muted">
                                                    <i className="fa fa-question-circle me-1 text-muted text-hover-primary fs-5 ms-2" />
                                                </span>
                                            </ToolTipWrapper>
                                        </div>
                                    </div>
                                    {/*end::Info*/}
                                    {/*begin::Subtitle*/}
                                    <span className="text-gray-400 pt-1 fw-bold fs-6">Popularność (ostatnich 5 live)</span>
                                    {/*end::Subtitle*/}
                                </div>
                                {/*end::Title*/}
                            </div>
                            {/*end::Header*/}
                            {/*begin::Card body*/}
                            <div className="card-body d-flex align-items-end px-0 pb-0">
                                {/*begin::Chart*/}
                                <div id="shckt_card_widget_6_chart" data-attr={`[${statisticsStore.statistics!.commentsLast5.join(',')}]`} data-labels={`["${statisticsStore.statistics!.commentsLastLabels5.join('","')}"]`} className="w-100" style={{ height: '80px' }}></div>
                                {/*end::Chart*/}
                            </div>
                            {/*end::Card body*/}
                        </div>
                        {/*end::Card widget 6*/}
                    </div>}
                {/*begin::Col*/}
                <div className="col-lg-12 col-xl-12 col-xxl-6 mb-5 mb-xl-0">
                    <ParcelsToShipWidget />
                </div>
                {/*end::Col*/}
            </div>
            {/*end::Row*/}
            <div className="row g-5 g-xl-10 mb-xl-10">
                <div className="col-lg-12 col-xl-12 col-xxl-7 mb-5 mb-xl-0">
                    <OldBasketsWidget />
                </div>
                <div className="col-lg-12 col-xl-12 col-xxl-5 mb-5 mb-xl-0">
                    <PickupsWidget />
                </div>
            </div>
        </>
    )
})