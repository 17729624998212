import { Pagination, PagingParams } from "../models/pagination";

export interface StoreWithLoadItems {
    loadItems(): any;
}

export default class FilteringAndPaginationController {
    predicate = new Map().set('filter', 'all');
    pagination: Pagination | null = null;
    pagingParams = new PagingParams();
    store: StoreWithLoadItems;

    constructor(store: StoreWithLoadItems) {
        this.store = store;
    }

    get axiosParams() {
        const searchParams = new URLSearchParams();
        searchParams.append('pageNumber', this.pagingParams.pageNumber.toString());
        searchParams.append('pageSize', this.pagingParams.pageSize.toString());
        this.predicate.forEach((v, k) => {
            searchParams.append(k, v);
        })
        return searchParams;
    }

    setPredicate = (key: string, value: string, pageSize: number = 8) => {
        this.predicate.set(key, value);
        this.setPagingParams(new PagingParams(1, pageSize));
        this.store.loadItems();
    }

    setPredicates = (predicates: string[][]) => {
        predicates.forEach((v)=>{
            this.predicate.set(v[0], v[1]);
        })
        this.setPagingParams(new PagingParams(1));
        this.store.loadItems();
    }

    setPagingParams = (pagingParams: PagingParams) => {
        this.pagingParams = pagingParams;
    }

    setPagination = (pagination: Pagination) => {
        this.pagination = pagination;
    }

    clearPredicateAndPaging = () => {
        this.predicate = new Map().set('filter', 'all');
        this.pagination = null;
        this.pagingParams = new PagingParams();
    }

    clearPredicateAndPagingToGetAll = () => {
        this.predicate = new Map().set('filter', 'all');
        this.pagination = null;
        this.pagingParams = new PagingParams(1, 10000);
    }
}