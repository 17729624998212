import React from 'react';
import { observer } from 'mobx-react-lite';
import MarketingDiscounts from './MarketingDiscounts';
import ContestWidget from './ContestWidget';
import EmailMessageWidget from './EmailMessageWidget';
import SmsMessageWidget from './SmsMessageWidget';

export default observer(function BuildYourReach() {
    return (
        <>
            <div className="row g-5 g-xl-10 mb-xl-10" style={{ paddingTop: '50px' }}>
                <div className="col-lg-12 col-xl-6 col-xxl-6 mb-5 mb-xl-0">
                    <MarketingDiscounts />
                    <EmailMessageWidget />
                    <SmsMessageWidget />
                </div>
                <div className="col-lg-12 col-xl-6 col-xxl-6 mb-5 mb-xl-0">
                    <ContestWidget />
                </div>
            </div>
        </>
    )
})