import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
export default observer(function BlackListWidget() {
    const { modalStore: {show: ShowModalDemoWarning} } = useStore();
    return (
        <>
            {/*begin::Connected Accounts*/}
            <div className="card card-flush h-xl-100">
                {/*begin::Card header*/}
                <div className="card-header border-0">
                    <div className="card-title m-0">
                        <h3 className="fw-bolder m-0">Automatyczna czarna lista</h3>
                    </div>
                </div>
                {/*end::Card header*/}
                {/*begin::Content*/}
                <div>
                    {/*begin::Card body*/}
                    <div className="card-body border-top p-9">
                        <div className="fv-row">
                            {/*begin::Option*/}
                            <label className="btn btn-outline btn-outline-dashed btn-outline-default d-flex text-start p-6 mb-6 active">
                                {/*begin::Input*/}
                                <input className="btn-check" type="radio" name="offer_type" defaultValue="1" />
                                {/*end::Input*/}
                                {/*begin::Label*/}
                                <span className="d-flex">
                                    {/*begin::Icon*/}
                                    {/*begin::Svg Icon | path: icons/duotune/communication/com006.svg*/}
                                    <span className="svg-icon svg-icon-3hx">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path opacity="0.3" d="M5 22H19C19.6 22 20 21.6 20 21V8L14 2H5C4.4 2 4 2.4 4 3V21C4 21.6 4.4 22 5 22Z" fill="black" />
                                            <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="black" />
                                            <rect x="7.55518" y="16.7585" width="10.144" height="2" rx="1" transform="rotate(-45 7.55518 16.7585)" fill="black" />
                                            <rect x="9.0174" y="9.60327" width="10.0952" height="2" rx="1" transform="rotate(45 9.0174 9.60327)" fill="black" />
                                        </svg>
                                    </span>
                                    {/*end::Svg Icon*/}
                                    {/*end::Icon*/}
                                    {/*begin::Info*/}
                                    <span className="ms-4">
                                        <span className="fs-5 fw-bolder text-dark mb-2 d-block">Wyłączona</span>
                                        <span className="fw-bold fs-6 text-gray-400">Użytkownik może trafić na czarną listę tylko jeśli go ręcznie na nią dodasz</span>
                                    </span>
                                    {/*end::Info*/}
                                </span>
                                {/*end::Label*/}
                            </label>
                            {/*end::Option*/}
                            {/*begin::Option*/}
                            <label className="btn btn-outline btn-outline-dashed btn-outline-default d-flex text-start p-6 mb-6" onClick={() => ShowModalDemoWarning()}>
                                {/*begin::Input*/}
                                <input className="btn-check" type="radio" name="offer_type" defaultValue="2" />
                                {/*end::Input*/}
                                {/*begin::Label*/}
                                <span className="d-flex">
                                    {/*begin::Icon*/}
                                    {/*begin::Svg Icon | path: icons/duotune/general/gen025.svg*/}
                                    <span className="svg-icon svg-icon-3hx">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="black" />
                                            <path d="M12.0006 11.1542C13.1434 11.1542 14.0777 10.22 14.0777 9.0771C14.0777 7.93424 13.1434 7 12.0006 7C10.8577 7 9.92348 7.93424 9.92348 9.0771C9.92348 10.22 10.8577 11.1542 12.0006 11.1542Z" fill="black" />
                                            <path d="M15.5652 13.814C15.5108 13.6779 15.4382 13.551 15.3566 13.4331C14.9393 12.8163 14.2954 12.4081 13.5697 12.3083C13.479 12.2993 13.3793 12.3174 13.3067 12.3718C12.9257 12.653 12.4722 12.7981 12.0006 12.7981C11.5289 12.7981 11.0754 12.653 10.6944 12.3718C10.6219 12.3174 10.5221 12.2902 10.4314 12.3083C9.70578 12.4081 9.05272 12.8163 8.64456 13.4331C8.56293 13.551 8.49036 13.687 8.43595 13.814C8.40875 13.8684 8.41781 13.9319 8.44502 13.9864C8.51759 14.1133 8.60828 14.2403 8.68991 14.3492C8.81689 14.5215 8.95295 14.6757 9.10715 14.8208C9.23413 14.9478 9.37925 15.0657 9.52439 15.1836C10.2409 15.7188 11.1026 15.9999 11.9915 15.9999C12.8804 15.9999 13.7421 15.7188 14.4586 15.1836C14.6038 15.0748 14.7489 14.9478 14.8759 14.8208C15.021 14.6757 15.1661 14.5215 15.2931 14.3492C15.3838 14.2312 15.4655 14.1133 15.538 13.9864C15.5833 13.9319 15.5924 13.8684 15.5652 13.814Z" fill="black" />
                                        </svg>
                                    </span>
                                    {/*end::Svg Icon*/}
                                    {/*end::Icon*/}
                                    {/*begin::Info*/}
                                    <span className="ms-4">
                                        <span className="fs-5 fw-bolder text-dark mb-2 d-block">Ścisła</span>
                                        <span className="fw-bold fs-6 text-gray-400">Użytkownik trafi na czarną listę automatycznie jeśli nie odbierze jakiegoś zamówenia lub koszyka</span>
                                    </span>
                                    {/*end::Info*/}
                                </span>
                                {/*end::Label*/}
                            </label>
                            {/*end::Option*/}
                            {/*begin::Option*/}
                            <label className="btn btn-outline btn-outline-dashed btn-outline-default d-flex text-start p-6" onClick={() => ShowModalDemoWarning()}>
                                {/*begin::Input*/}
                                <input className="btn-check" type="radio" name="offer_type" defaultValue="2" />
                                {/*end::Input*/}
                                {/*begin::Label*/}
                                <span className="d-flex">
                                    {/*begin::Icon*/}
                                    {/*begin::Svg Icon | path: icons/duotune/general/gen025.svg*/}
                                    <span className="svg-icon svg-icon-3hx">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M21.7 18.9L18.6 15.8C17.9 16.9 16.9 17.9 15.8 18.6L18.9 21.7C19.3 22.1 19.9 22.1 20.3 21.7L21.7 20.3C22.1 19.9 22.1 19.3 21.7 18.9Z" fill="black" />
                                            <path opacity="0.3" d="M11 20C6 20 2 16 2 11C2 6 6 2 11 2C16 2 20 6 20 11C20 16 16 20 11 20ZM11 4C7.1 4 4 7.1 4 11C4 14.9 7.1 18 11 18C14.9 18 18 14.9 18 11C18 7.1 14.9 4 11 4ZM8 11C8 9.3 9.3 8 11 8C11.6 8 12 7.6 12 7C12 6.4 11.6 6 11 6C8.2 6 6 8.2 6 11C6 11.6 6.4 12 7 12C7.6 12 8 11.6 8 11Z" fill="black" />
                                        </svg>
                                    </span>
                                    {/*end::Svg Icon*/}
                                    {/*end::Icon*/}
                                    {/*begin::Info*/}
                                    <span className="ms-4">
                                        <span className="fs-5 fw-bolder text-dark mb-2 d-block">Luźna</span>
                                        <span className="fw-bold fs-6 text-gray-400">Użytkownik trafi na czarną listę automatycznie jeśli nie odbierze 3 zamówień lub koszyków</span>
                                    </span>
                                    {/*end::Info*/}
                                </span>
                                {/*end::Label*/}
                            </label>
                            {/*end::Option*/}
                        </div>
                    </div>
                    {/*end::Card body*/}
                    {/*begin::Card footer*/}
                    {/* <div className="card-footer d-flex justify-content-end py-6 px-9">
                        <button className="btn btn-light btn-active-light-primary me-2">Discard</button>
                        <button className="btn btn-primary">Save Changes</button>
                    </div> */}
                    {/*end::Card footer*/}
                </div>
                {/*end::Content*/}
            </div>
            {/*end::Connected Accounts*/}
        </>
    )
})
