import { makeAutoObservable, runInAction } from "mobx";
import { history } from "../..";
import agent from "../api/agent";
import urls from "../common/urls";
import { PasswordSet, PasswordUpdate, User, UserFormValues } from "../models/models";
import { store } from "./store";

export default class UserStore {
    user: User | null = null;
    constructor() {
        makeAutoObservable(this);
    }

    get isLoggedIn() {
        return !!this.user;
    }

    login = async (creds: UserFormValues) => {
        try {
            const user = await agent.Account.login(creds);
            store.commonStore.setToken(user.token);
            runInAction(() => this.user = user);
            history.push(urls.dashboard());
        } catch (error) {
            throw error;
        }
    }

    logout = async () => {
        store.commonStore.deleteToken();
        window.localStorage.removeItem('jwt');
        this.user = null;
        history.push(urls.root());
    }

    changePassword = async (password: PasswordUpdate) => {
        return agent.Account.changePassword(password);
    }

    setNewPassword = async (password: PasswordSet) => {
        return agent.Account.setNewPassword(password);
    }

    resetPassword = async (email: string) => {
        return agent.Account.resetPassword({ email: email });
    }

    getUser = async () => {
        try {
            const user = await agent.Account.current();
            runInAction(() => this.user = user);
        } catch (error) {
            console.log(error);
        }
    }

    isAdmin = () => {
        return this.user?.role === 'Administrator';
    }
}
