import React from 'react';
import { observer } from 'mobx-react-lite';
export default observer(function ChartTest2(){
    return(
        <>
    {/*begin::Row*/}
								<div className="row gy-5 g-xl-8">
									{/*begin::Col*/}
									<div className="col-xl-4">
										{/*begin::List Widget 3*/}
										<div className="card card-xl-stretch mb-xl-8">
											{/*begin::Header*/}
											<div className="card-header border-0">
												<h3 className="card-title fw-bolder text-dark">Todo</h3>
												<div className="card-toolbar">
													{/*begin::Menu*/}
													<button type="button" className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
														{/*begin::Svg Icon | path: icons/duotune/general/gen024.svg*/}
														<span className="svg-icon svg-icon-2">
															<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
																<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
																	<rect x="5" y="5" width="5" height="5" rx="1" fill="#000000" />
																	<rect x="14" y="5" width="5" height="5" rx="1" fill="#000000" opacity="0.3" />
																	<rect x="5" y="14" width="5" height="5" rx="1" fill="#000000" opacity="0.3" />
																	<rect x="14" y="14" width="5" height="5" rx="1" fill="#000000" opacity="0.3" />
																</g>
															</svg>
														</span>
														{/*end::Svg Icon*/}
													</button>
													{/*begin::Menu 3*/}
													<div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3" data-kt-menu="true">
														{/*begin::Heading*/}
														<div className="menu-item px-3">
															<div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">Payments</div>
														</div>
														{/*end::Heading*/}
														{/*begin::Menu item*/}
														<div className="menu-item px-3">
															<a href="\" className="menu-link px-3">Create Invoice</a>
														</div>
														{/*end::Menu item*/}
														{/*begin::Menu item*/}
														<div className="menu-item px-3">
															<a href="\" className="menu-link flex-stack px-3">Create Payment
															<i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Specify a target name for future usage and reference"></i></a>
														</div>
														{/*end::Menu item*/}
														{/*begin::Menu item*/}
														<div className="menu-item px-3">
															<a href="\" className="menu-link px-3">Generate Bill</a>
														</div>
														{/*end::Menu item*/}
														{/*begin::Menu item*/}
														<div className="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-end">
															<a href="\" className="menu-link px-3">
																<span className="menu-title">Subscription</span>
																<span className="menu-arrow"></span>
															</a>
															{/*begin::Menu sub*/}
															<div className="menu-sub menu-sub-dropdown w-175px py-4">
																{/*begin::Menu item*/}
																<div className="menu-item px-3">
																	<a href="\" className="menu-link px-3">Plans</a>
																</div>
																{/*end::Menu item*/}
																{/*begin::Menu item*/}
																<div className="menu-item px-3">
																	<a href="\" className="menu-link px-3">Billing</a>
																</div>
																{/*end::Menu item*/}
																{/*begin::Menu item*/}
																<div className="menu-item px-3">
																	<a href="\" className="menu-link px-3">Statements</a>
																</div>
																{/*end::Menu item*/}
																{/*begin::Menu separator*/}
																<div className="separator my-2"></div>
																{/*end::Menu separator*/}
																{/*begin::Menu item*/}
																<div className="menu-item px-3">
																	<div className="menu-content px-3">
																		{/*begin::Switch*/}
																		<label className="form-check form-switch form-check-custom form-check-solid">
																			{/*begin::Input*/}
																			<input className="form-check-input w-30px h-20px" type="checkbox" defaultValue="1"  name="notifications" />
																			{/*end::Input*/}
																			{/*end::Label*/}
																			<span className="form-check-label text-muted fs-6">Recuring</span>
																			{/*end::Label*/}
																		</label>
																		{/*end::Switch*/}
																	</div>
																</div>
																{/*end::Menu item*/}
															</div>
															{/*end::Menu sub*/}
														</div>
														{/*end::Menu item*/}
														{/*begin::Menu item*/}
														<div className="menu-item px-3 my-1">
															<a href="\" className="menu-link px-3">Settings</a>
														</div>
														{/*end::Menu item*/}
													</div>
													{/*end::Menu 3*/}
													{/*end::Menu*/}
												</div>
											</div>
											{/*end::Header*/}
											{/*begin::Body*/}
											<div className="card-body pt-2">
												{/*begin::Item*/}
												<div className="d-flex align-items-center mb-8">
													{/*begin::Bullet*/}
													<span className="bullet bullet-vertical h-40px bg-success"></span>
													{/*end::Bullet*/}
													{/*begin::Checkbox*/}
													<div className="form-check form-check-custom form-check-solid mx-5">
														<input className="form-check-input" type="checkbox" defaultValue="" />
													</div>
													{/*end::Checkbox*/}
													{/*begin::Description*/}
													<div className="flex-grow-1">
														<a href="\" className="text-gray-800 text-hover-primary fw-bolder fs-6">Create FireStone Logo</a>
														<span className="text-muted fw-bold d-block">Due in 2 Days</span>
													</div>
													{/*end::Description*/}
													<span className="badge badge-light-success fs-8 fw-bolder">New</span>
												</div>
												{/*end:Item*/}
												{/*begin::Item*/}
												<div className="d-flex align-items-center mb-8">
													{/*begin::Bullet*/}
													<span className="bullet bullet-vertical h-40px bg-primary"></span>
													{/*end::Bullet*/}
													{/*begin::Checkbox*/}
													<div className="form-check form-check-custom form-check-solid mx-5">
														<input className="form-check-input" type="checkbox" defaultValue="" />
													</div>
													{/*end::Checkbox*/}
													{/*begin::Description*/}
													<div className="flex-grow-1">
														<a href="\" className="text-gray-800 text-hover-primary fw-bolder fs-6">Stakeholder Meeting</a>
														<span className="text-muted fw-bold d-block">Due in 3 Days</span>
													</div>
													{/*end::Description*/}
													<span className="badge badge-light-primary fs-8 fw-bolder">New</span>
												</div>
												{/*end:Item*/}
												{/*begin::Item*/}
												<div className="d-flex align-items-center mb-8">
													{/*begin::Bullet*/}
													<span className="bullet bullet-vertical h-40px bg-warning"></span>
													{/*end::Bullet*/}
													{/*begin::Checkbox*/}
													<div className="form-check form-check-custom form-check-solid mx-5">
														<input className="form-check-input" type="checkbox" defaultValue="" />
													</div>
													{/*end::Checkbox*/}
													{/*begin::Description*/}
													<div className="flex-grow-1">
														<a href="\" className="text-gray-800 text-hover-primary fw-bolder fs-6">Scoping &amp; Estimations</a>
														<span className="text-muted fw-bold d-block">Due in 5 Days</span>
													</div>
													{/*end::Description*/}
													<span className="badge badge-light-warning fs-8 fw-bolder">New</span>
												</div>
												{/*end:Item*/}
												{/*begin::Item*/}
												<div className="d-flex align-items-center mb-8">
													{/*begin::Bullet*/}
													<span className="bullet bullet-vertical h-40px bg-primary"></span>
													{/*end::Bullet*/}
													{/*begin::Checkbox*/}
													<div className="form-check form-check-custom form-check-solid mx-5">
														<input className="form-check-input" type="checkbox" defaultValue="" />
													</div>
													{/*end::Checkbox*/}
													{/*begin::Description*/}
													<div className="flex-grow-1">
														<a href="\" className="text-gray-800 text-hover-primary fw-bolder fs-6">KPI App Showcase</a>
														<span className="text-muted fw-bold d-block">Due in 2 Days</span>
													</div>
													{/*end::Description*/}
													<span className="badge badge-light-primary fs-8 fw-bolder">New</span>
												</div>
												{/*end:Item*/}
												{/*begin::Item*/}
												<div className="d-flex align-items-center mb-8">
													{/*begin::Bullet*/}
													<span className="bullet bullet-vertical h-40px bg-danger"></span>
													{/*end::Bullet*/}
													{/*begin::Checkbox*/}
													<div className="form-check form-check-custom form-check-solid mx-5">
														<input className="form-check-input" type="checkbox" defaultValue="" />
													</div>
													{/*end::Checkbox*/}
													{/*begin::Description*/}
													<div className="flex-grow-1">
														<a href="\" className="text-gray-800 text-hover-primary fw-bolder fs-6">Project Meeting</a>
														<span className="text-muted fw-bold d-block">Due in 12 Days</span>
													</div>
													{/*end::Description*/}
													<span className="badge badge-light-danger fs-8 fw-bolder">New</span>
												</div>
												{/*end:Item*/}
												{/*begin::Item*/}
												<div className="d-flex align-items-center">
													{/*begin::Bullet*/}
													<span className="bullet bullet-vertical h-40px bg-success"></span>
													{/*end::Bullet*/}
													{/*begin::Checkbox*/}
													<div className="form-check form-check-custom form-check-solid mx-5">
														<input className="form-check-input" type="checkbox" defaultValue="" />
													</div>
													{/*end::Checkbox*/}
													{/*begin::Description*/}
													<div className="flex-grow-1">
														<a href="\" className="text-gray-800 text-hover-primary fw-bolder fs-6">Customers Update</a>
														<span className="text-muted fw-bold d-block">Due in 1 week</span>
													</div>
													{/*end::Description*/}
													<span className="badge badge-light-success fs-8 fw-bolder">New</span>
												</div>
												{/*end:Item*/}
											</div>
											{/*end::Body*/}
										</div>
										{/*end:List Widget 3*/}
									</div>
									{/*end::Col*/}
									{/*begin::Col*/}
									<div className="col-xl-8">
										{/*begin::Tables Widget 9*/}
										<div className="card card-xl-stretch mb-5 mb-xl-8">
											{/*begin::Header*/}
											<div className="card-header border-0 pt-5">
												<h3 className="card-title align-items-start flex-column">
													<span className="card-label fw-bolder fs-3 mb-1">Members Statistics</span>
													<span className="text-muted mt-1 fw-bold fs-7">Over 500 members</span>
												</h3>
												<div className="card-toolbar">
													<a href="\" className="btn btn-sm btn-light btn-active-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_invite_friends">
													{/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
													<span className="svg-icon svg-icon-3">
														<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
															<rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="black" />
															<rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="black" />
														</svg>
													</span>
													{/*end::Svg Icon*/}New Member</a>
												</div>
											</div>
											{/*end::Header*/}
											{/*begin::Body*/}
											<div className="card-body py-3">
												{/*begin::Table container*/}
												<div className="table-responsive">
													{/*begin::Table*/}
													<table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
														{/*begin::Table head*/}
														<thead>
															<tr className="fw-bolder text-muted">
																<th className="w-25px">
																	<div className="form-check form-check-sm form-check-custom form-check-solid">
																		<input className="form-check-input" type="checkbox" defaultValue="1" data-kt-check="true" data-kt-check-target=".widget-9-check" />
																	</div>
																</th>
																<th className="min-w-150px">Authors</th>
																<th className="min-w-140px">Company</th>
																<th className="min-w-120px">Progress</th>
																<th className="min-w-100px text-end">Actions</th>
															</tr>
														</thead>
														{/*end::Table head*/}
														{/*begin::Table body*/}
														<tbody>
															<tr>
																<td>
																	<div className="form-check form-check-sm form-check-custom form-check-solid">
																		<input className="form-check-input widget-9-check" type="checkbox" defaultValue="1" />
																	</div>
																</td>
																<td>
																	<div className="d-flex align-items-center">
																		<div className="symbol symbol-45px me-5">
																			<img src="assets/media/avatars/300-14.jpg" alt="" />
																		</div>
																		<div className="d-flex justify-content-start flex-column">
																			<a href="\" className="text-dark fw-bolder text-hover-primary fs-6">Ana Simmons</a>
																			<span className="text-muted fw-bold text-muted d-block fs-7">HTML, JS, ReactJS</span>
																		</div>
																	</div>
																</td>
																<td>
																	<a href="\" className="text-dark fw-bolder text-hover-primary d-block fs-6">Intertico</a>
																	<span className="text-muted fw-bold text-muted d-block fs-7">Web, UI/UX Design</span>
																</td>
																<td className="text-end">
																	<div className="d-flex flex-column w-100 me-2">
																		<div className="d-flex flex-stack mb-2">
																			<span className="text-muted me-2 fs-7 fw-bold">50%</span>
																		</div>
																		<div className="progress h-6px w-100">
																			<div className="progress-bar bg-primary" role="progressbar" style={{height: '50%'}}  aria-valuenow={50} aria-valuemin={0} aria-valuemax={100}></div>
																		</div>
																	</div>
																</td>
																<td>
																	<div className="d-flex justify-content-end flex-shrink-0">
																		<a href="\" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
																			{/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
																			<span className="svg-icon svg-icon-3">
																				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																					<path d="M17.5 11H6.5C4 11 2 9 2 6.5C2 4 4 2 6.5 2H17.5C20 2 22 4 22 6.5C22 9 20 11 17.5 11ZM15 6.5C15 7.9 16.1 9 17.5 9C18.9 9 20 7.9 20 6.5C20 5.1 18.9 4 17.5 4C16.1 4 15 5.1 15 6.5Z" fill="black" />
																					<path opacity="0.3" d="M17.5 22H6.5C4 22 2 20 2 17.5C2 15 4 13 6.5 13H17.5C20 13 22 15 22 17.5C22 20 20 22 17.5 22ZM4 17.5C4 18.9 5.1 20 6.5 20C7.9 20 9 18.9 9 17.5C9 16.1 7.9 15 6.5 15C5.1 15 4 16.1 4 17.5Z" fill="black" />
																				</svg>
																			</span>
																			{/*end::Svg Icon*/}
																		</a>
																		<a href="\" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
																			{/*begin::Svg Icon | path: icons/duotune/art/art005.svg*/}
																			<span className="svg-icon svg-icon-3">
																				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																					<path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="black" />
																					<path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="black" />
																				</svg>
																			</span>
																			{/*end::Svg Icon*/}
																		</a>
																		<a href="\" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
																			{/*begin::Svg Icon | path: icons/duotune/general/gen027.svg*/}
																			<span className="svg-icon svg-icon-3">
																				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																					<path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="black" />
																					<path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="black" />
																					<path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="black" />
																				</svg>
																			</span>
																			{/*end::Svg Icon*/}
																		</a>
																	</div>
																</td>
															</tr>
															<tr>
																<td>
																	<div className="form-check form-check-sm form-check-custom form-check-solid">
																		<input className="form-check-input widget-9-check" type="checkbox" defaultValue="1" />
																	</div>
																</td>
																<td>
																	<div className="d-flex align-items-center">
																		<div className="symbol symbol-45px me-5">
																			<img src="assets/media/avatars/300-2.jpg" alt="" />
																		</div>
																		<div className="d-flex justify-content-start flex-column">
																			<a href="\" className="text-dark fw-bolder text-hover-primary fs-6">Jessie Clarcson</a>
																			<span className="text-muted fw-bold text-muted d-block fs-7">C#, ASP.NET, MS SQL</span>
																		</div>
																	</div>
																</td>
																<td>
																	<a href="\" className="text-dark fw-bolder text-hover-primary d-block fs-6">Agoda</a>
																	<span className="text-muted fw-bold text-muted d-block fs-7">Houses &amp; Hotels</span>
																</td>
																<td className="text-end">
																	<div className="d-flex flex-column w-100 me-2">
																		<div className="d-flex flex-stack mb-2">
																			<span className="text-muted me-2 fs-7 fw-bold">70%</span>
																		</div>
																		<div className="progress h-6px w-100">
																			<div className="progress-bar bg-danger" role="progressbar" style={{height: '70%'}}  aria-valuenow={50} aria-valuemin={0} aria-valuemax={100}></div>
																		</div>
																	</div>
																</td>
																<td>
																	<div className="d-flex justify-content-end flex-shrink-0">
																		<a href="\" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
																			{/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
																			<span className="svg-icon svg-icon-3">
																				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																					<path d="M17.5 11H6.5C4 11 2 9 2 6.5C2 4 4 2 6.5 2H17.5C20 2 22 4 22 6.5C22 9 20 11 17.5 11ZM15 6.5C15 7.9 16.1 9 17.5 9C18.9 9 20 7.9 20 6.5C20 5.1 18.9 4 17.5 4C16.1 4 15 5.1 15 6.5Z" fill="black" />
																					<path opacity="0.3" d="M17.5 22H6.5C4 22 2 20 2 17.5C2 15 4 13 6.5 13H17.5C20 13 22 15 22 17.5C22 20 20 22 17.5 22ZM4 17.5C4 18.9 5.1 20 6.5 20C7.9 20 9 18.9 9 17.5C9 16.1 7.9 15 6.5 15C5.1 15 4 16.1 4 17.5Z" fill="black" />
																				</svg>
																			</span>
																			{/*end::Svg Icon*/}
																		</a>
																		<a href="\" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
																			{/*begin::Svg Icon | path: icons/duotune/art/art005.svg*/}
																			<span className="svg-icon svg-icon-3">
																				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																					<path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="black" />
																					<path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="black" />
																				</svg>
																			</span>
																			{/*end::Svg Icon*/}
																		</a>
																		<a href="\" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
																			{/*begin::Svg Icon | path: icons/duotune/general/gen027.svg*/}
																			<span className="svg-icon svg-icon-3">
																				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																					<path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="black" />
																					<path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="black" />
																					<path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="black" />
																				</svg>
																			</span>
																			{/*end::Svg Icon*/}
																		</a>
																	</div>
																</td>
															</tr>
															<tr>
																<td>
																	<div className="form-check form-check-sm form-check-custom form-check-solid">
																		<input className="form-check-input widget-9-check" type="checkbox" defaultValue="1" />
																	</div>
																</td>
																<td>
																	<div className="d-flex align-items-center">
																		<div className="symbol symbol-45px me-5">
																			<img src="assets/media/avatars/300-5.jpg" alt="" />
																		</div>
																		<div className="d-flex justify-content-start flex-column">
																			<a href="\" className="text-dark fw-bolder text-hover-primary fs-6">Lebron Wayde</a>
																			<span className="text-muted fw-bold text-muted d-block fs-7">PHP, Laravel, VueJS</span>
																		</div>
																	</div>
																</td>
																<td>
																	<a href="\" className="text-dark fw-bolder text-hover-primary d-block fs-6">RoadGee</a>
																	<span className="text-muted fw-bold text-muted d-block fs-7">Transportation</span>
																</td>
																<td className="text-end">
																	<div className="d-flex flex-column w-100 me-2">
																		<div className="d-flex flex-stack mb-2">
																			<span className="text-muted me-2 fs-7 fw-bold">60%</span>
																		</div>
																		<div className="progress h-6px w-100">
																			<div className="progress-bar bg-success" role="progressbar" style={{height: '60%'}}  aria-valuenow={50} aria-valuemin={0} aria-valuemax={100}></div>
																		</div>
																	</div>
																</td>
																<td>
																	<div className="d-flex justify-content-end flex-shrink-0">
																		<a href="\" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
																			{/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
																			<span className="svg-icon svg-icon-3">
																				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																					<path d="M17.5 11H6.5C4 11 2 9 2 6.5C2 4 4 2 6.5 2H17.5C20 2 22 4 22 6.5C22 9 20 11 17.5 11ZM15 6.5C15 7.9 16.1 9 17.5 9C18.9 9 20 7.9 20 6.5C20 5.1 18.9 4 17.5 4C16.1 4 15 5.1 15 6.5Z" fill="black" />
																					<path opacity="0.3" d="M17.5 22H6.5C4 22 2 20 2 17.5C2 15 4 13 6.5 13H17.5C20 13 22 15 22 17.5C22 20 20 22 17.5 22ZM4 17.5C4 18.9 5.1 20 6.5 20C7.9 20 9 18.9 9 17.5C9 16.1 7.9 15 6.5 15C5.1 15 4 16.1 4 17.5Z" fill="black" />
																				</svg>
																			</span>
																			{/*end::Svg Icon*/}
																		</a>
																		<a href="\" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
																			{/*begin::Svg Icon | path: icons/duotune/art/art005.svg*/}
																			<span className="svg-icon svg-icon-3">
																				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																					<path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="black" />
																					<path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="black" />
																				</svg>
																			</span>
																			{/*end::Svg Icon*/}
																		</a>
																		<a href="\" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
																			{/*begin::Svg Icon | path: icons/duotune/general/gen027.svg*/}
																			<span className="svg-icon svg-icon-3">
																				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																					<path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="black" />
																					<path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="black" />
																					<path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="black" />
																				</svg>
																			</span>
																			{/*end::Svg Icon*/}
																		</a>
																	</div>
																</td>
															</tr>
															<tr>
																<td>
																	<div className="form-check form-check-sm form-check-custom form-check-solid">
																		<input className="form-check-input widget-9-check" type="checkbox" defaultValue="1" />
																	</div>
																</td>
																<td>
																	<div className="d-flex align-items-center">
																		<div className="symbol symbol-45px me-5">
																			<img src="assets/media/avatars/300-20.jpg" alt="" />
																		</div>
																		<div className="d-flex justify-content-start flex-column">
																			<a href="\" className="text-dark fw-bolder text-hover-primary fs-6">Natali Goodwin</a>
																			<span className="text-muted fw-bold text-muted d-block fs-7">Python, PostgreSQL, ReactJS</span>
																		</div>
																	</div>
																</td>
																<td>
																	<a href="\" className="text-dark fw-bolder text-hover-primary d-block fs-6">The Hill</a>
																	<span className="text-muted fw-bold text-muted d-block fs-7">Insurance</span>
																</td>
																<td className="text-end">
																	<div className="d-flex flex-column w-100 me-2">
																		<div className="d-flex flex-stack mb-2">
																			<span className="text-muted me-2 fs-7 fw-bold">50%</span>
																		</div>
																		<div className="progress h-6px w-100">
																			<div className="progress-bar bg-warning" role="progressbar" style={{height: '50%'}}  aria-valuenow={50} aria-valuemin={0} aria-valuemax={100}></div>
																		</div>
																	</div>
																</td>
																<td>
																	<div className="d-flex justify-content-end flex-shrink-0">
																		<a href="\" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
																			{/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
																			<span className="svg-icon svg-icon-3">
																				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																					<path d="M17.5 11H6.5C4 11 2 9 2 6.5C2 4 4 2 6.5 2H17.5C20 2 22 4 22 6.5C22 9 20 11 17.5 11ZM15 6.5C15 7.9 16.1 9 17.5 9C18.9 9 20 7.9 20 6.5C20 5.1 18.9 4 17.5 4C16.1 4 15 5.1 15 6.5Z" fill="black" />
																					<path opacity="0.3" d="M17.5 22H6.5C4 22 2 20 2 17.5C2 15 4 13 6.5 13H17.5C20 13 22 15 22 17.5C22 20 20 22 17.5 22ZM4 17.5C4 18.9 5.1 20 6.5 20C7.9 20 9 18.9 9 17.5C9 16.1 7.9 15 6.5 15C5.1 15 4 16.1 4 17.5Z" fill="black" />
																				</svg>
																			</span>
																			{/*end::Svg Icon*/}
																		</a>
																		<a href="\" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
																			{/*begin::Svg Icon | path: icons/duotune/art/art005.svg*/}
																			<span className="svg-icon svg-icon-3">
																				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																					<path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="black" />
																					<path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="black" />
																				</svg>
																			</span>
																			{/*end::Svg Icon*/}
																		</a>
																		<a href="\" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
																			{/*begin::Svg Icon | path: icons/duotune/general/gen027.svg*/}
																			<span className="svg-icon svg-icon-3">
																				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																					<path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="black" />
																					<path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="black" />
																					<path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="black" />
																				</svg>
																			</span>
																			{/*end::Svg Icon*/}
																		</a>
																	</div>
																</td>
															</tr>
															<tr>
																<td>
																	<div className="form-check form-check-sm form-check-custom form-check-solid">
																		<input className="form-check-input widget-9-check" type="checkbox" defaultValue="1" />
																	</div>
																</td>
																<td>
																	<div className="d-flex align-items-center">
																		<div className="symbol symbol-45px me-5">
																			<img src="assets/media/avatars/300-23.jpg" alt="" />
																		</div>
																		<div className="d-flex justify-content-start flex-column">
																			<a href="\" className="text-dark fw-bolder text-hover-primary fs-6">Kevin Leonard</a>
																			<span className="text-muted fw-bold text-muted d-block fs-7">HTML, JS, ReactJS</span>
																		</div>
																	</div>
																</td>
																<td>
																	<a href="\" className="text-dark fw-bolder text-hover-primary d-block fs-6">RoadGee</a>
																	<span className="text-muted fw-bold text-muted d-block fs-7">Art Director</span>
																</td>
																<td className="text-end">
																	<div className="d-flex flex-column w-100 me-2">
																		<div className="d-flex flex-stack mb-2">
																			<span className="text-muted me-2 fs-7 fw-bold">90%</span>
																		</div>
																		<div className="progress h-6px w-100">
																			<div className="progress-bar bg-info" role="progressbar" style={{height: '90%'}}  aria-valuenow={50} aria-valuemin={0} aria-valuemax={100}></div>
																		</div>
																	</div>
																</td>
																<td>
																	<div className="d-flex justify-content-end flex-shrink-0">
																		<a href="\" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
																			{/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
																			<span className="svg-icon svg-icon-3">
																				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																					<path d="M17.5 11H6.5C4 11 2 9 2 6.5C2 4 4 2 6.5 2H17.5C20 2 22 4 22 6.5C22 9 20 11 17.5 11ZM15 6.5C15 7.9 16.1 9 17.5 9C18.9 9 20 7.9 20 6.5C20 5.1 18.9 4 17.5 4C16.1 4 15 5.1 15 6.5Z" fill="black" />
																					<path opacity="0.3" d="M17.5 22H6.5C4 22 2 20 2 17.5C2 15 4 13 6.5 13H17.5C20 13 22 15 22 17.5C22 20 20 22 17.5 22ZM4 17.5C4 18.9 5.1 20 6.5 20C7.9 20 9 18.9 9 17.5C9 16.1 7.9 15 6.5 15C5.1 15 4 16.1 4 17.5Z" fill="black" />
																				</svg>
																			</span>
																			{/*end::Svg Icon*/}
																		</a>
																		<a href="\" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
																			{/*begin::Svg Icon | path: icons/duotune/art/art005.svg*/}
																			<span className="svg-icon svg-icon-3">
																				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																					<path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="black" />
																					<path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="black" />
																				</svg>
																			</span>
																			{/*end::Svg Icon*/}
																		</a>
																		<a href="\" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
																			{/*begin::Svg Icon | path: icons/duotune/general/gen027.svg*/}
																			<span className="svg-icon svg-icon-3">
																				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																					<path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="black" />
																					<path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="black" />
																					<path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="black" />
																				</svg>
																			</span>
																			{/*end::Svg Icon*/}
																		</a>
																	</div>
																</td>
															</tr>
														</tbody>
														{/*end::Table body*/}
													</table>
													{/*end::Table*/}
												</div>
												{/*end::Table container*/}
											</div>
											{/*begin::Body*/}
										</div>
										{/*end::Tables Widget 9*/}
									</div>
									{/*end::Col*/}
								</div>
								{/*end::Row*/}
        </>
    )
})
