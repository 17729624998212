import React from 'react';
import { observer } from 'mobx-react-lite';
export default observer(function ChartTest3(){
    return(
        <>
    								{/*begin::Row*/}
								<div className="row gy-5 g-xl-8">
									{/*begin::Col*/}
									<div className="col-xl-4">
										{/*begin::List Widget 2*/}
										<div className="card card-xl-stretch mb-xl-8">
											{/*begin::Header*/}
											<div className="card-header border-0">
												<h3 className="card-title fw-bolder text-dark">Authors</h3>
												<div className="card-toolbar">
													{/*begin::Menu*/}
													<button type="button" className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
														{/*begin::Svg Icon | path: icons/duotune/general/gen024.svg*/}
														<span className="svg-icon svg-icon-2">
															<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
																<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
																	<rect x="5" y="5" width="5" height="5" rx="1" fill="#000000" />
																	<rect x="14" y="5" width="5" height="5" rx="1" fill="#000000" opacity="0.3" />
																	<rect x="5" y="14" width="5" height="5" rx="1" fill="#000000" opacity="0.3" />
																	<rect x="14" y="14" width="5" height="5" rx="1" fill="#000000" opacity="0.3" />
																</g>
															</svg>
														</span>
														{/*end::Svg Icon*/}
													</button>
													{/*begin::Menu 2*/}
													<div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px" data-kt-menu="true">
														{/*begin::Menu item*/}
														<div className="menu-item px-3">
															<div className="menu-content fs-6 text-dark fw-bolder px-3 py-4">Quick Actions</div>
														</div>
														{/*end::Menu item*/}
														{/*begin::Menu separator*/}
														<div className="separator mb-3 opacity-75"></div>
														{/*end::Menu separator*/}
														{/*begin::Menu item*/}
														<div className="menu-item px-3">
															<a href="\" className="menu-link px-3">New Ticket</a>
														</div>
														{/*end::Menu item*/}
														{/*begin::Menu item*/}
														<div className="menu-item px-3">
															<a href="\" className="menu-link px-3">New Customer</a>
														</div>
														{/*end::Menu item*/}
														{/*begin::Menu item*/}
														<div className="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-start">
															{/*begin::Menu item*/}
															<a href="\" className="menu-link px-3">
																<span className="menu-title">New Group</span>
																<span className="menu-arrow"></span>
															</a>
															{/*end::Menu item*/}
															{/*begin::Menu sub*/}
															<div className="menu-sub menu-sub-dropdown w-175px py-4">
																{/*begin::Menu item*/}
																<div className="menu-item px-3">
																	<a href="\" className="menu-link px-3">Admin Group</a>
																</div>
																{/*end::Menu item*/}
																{/*begin::Menu item*/}
																<div className="menu-item px-3">
																	<a href="\" className="menu-link px-3">Staff Group</a>
																</div>
																{/*end::Menu item*/}
																{/*begin::Menu item*/}
																<div className="menu-item px-3">
																	<a href="\" className="menu-link px-3">Member Group</a>
																</div>
																{/*end::Menu item*/}
															</div>
															{/*end::Menu sub*/}
														</div>
														{/*end::Menu item*/}
														{/*begin::Menu item*/}
														<div className="menu-item px-3">
															<a href="\" className="menu-link px-3">New Contact</a>
														</div>
														{/*end::Menu item*/}
														{/*begin::Menu separator*/}
														<div className="separator mt-3 opacity-75"></div>
														{/*end::Menu separator*/}
														{/*begin::Menu item*/}
														<div className="menu-item px-3">
															<div className="menu-content px-3 py-3">
																<a className="btn btn-primary btn-sm px-4" href="\">Generate Reports</a>
															</div>
														</div>
														{/*end::Menu item*/}
													</div>
													{/*end::Menu 2*/}
													{/*end::Menu*/}
												</div>
											</div>
											{/*end::Header*/}
											{/*begin::Body*/}
											<div className="card-body pt-2">
												{/*begin::Item*/}
												<div className="d-flex align-items-center mb-7">
													{/*begin::Avatar*/}
													<div className="symbol symbol-50px me-5">
														<img src="assets/media/avatars/300-6.jpg" className="" alt="" />
													</div>
													{/*end::Avatar*/}
													{/*begin::Text*/}
													<div className="flex-grow-1">
														<a href="\" className="text-dark fw-bolder text-hover-primary fs-6">Emma Smith</a>
														<span className="text-muted d-block fw-bold">Project Manager</span>
													</div>
													{/*end::Text*/}
												</div>
												{/*end::Item*/}
												{/*begin::Item*/}
												<div className="d-flex align-items-center mb-7">
													{/*begin::Avatar*/}
													<div className="symbol symbol-50px me-5">
														<img src="assets/media/avatars/300-5.jpg" className="" alt="" />
													</div>
													{/*end::Avatar*/}
													{/*begin::Text*/}
													<div className="flex-grow-1">
														<a href="\" className="text-dark fw-bolder text-hover-primary fs-6">Sean Bean</a>
														<span className="text-muted d-block fw-bold">PHP, SQLite, Artisan CLI</span>
													</div>
													{/*end::Text*/}
												</div>
												{/*end::Item*/}
												{/*begin::Item*/}
												<div className="d-flex align-items-center mb-7">
													{/*begin::Avatar*/}
													<div className="symbol symbol-50px me-5">
														<img src="assets/media/avatars/300-11.jpg" className="" alt="" />
													</div>
													{/*end::Avatar*/}
													{/*begin::Text*/}
													<div className="flex-grow-1">
														<a href="\" className="text-dark fw-bolder text-hover-primary fs-6">Brian Cox</a>
														<span className="text-muted d-block fw-bold">PHP, SQLite, Artisan CLI</span>
													</div>
													{/*end::Text*/}
												</div>
												{/*end::Item*/}
												{/*begin::Item*/}
												<div className="d-flex align-items-center mb-7">
													{/*begin::Avatar*/}
													<div className="symbol symbol-50px me-5">
														<img src="assets/media/avatars/300-9.jpg" className="" alt="" />
													</div>
													{/*end::Avatar*/}
													{/*begin::Text*/}
													<div className="flex-grow-1">
														<a href="\" className="text-dark fw-bolder text-hover-primary fs-6">Francis Mitcham</a>
														<span className="text-muted d-block fw-bold">PHP, SQLite, Artisan CLI</span>
													</div>
													{/*end::Text*/}
												</div>
												{/*end::Item*/}
												{/*begin::Item*/}
												<div className="d-flex align-items-center">
													{/*begin::Avatar*/}
													<div className="symbol symbol-50px me-5">
														<img src="assets/media/avatars/300-23.jpg" className="" alt="" />
													</div>
													{/*end::Avatar*/}
													{/*begin::Text*/}
													<div className="flex-grow-1">
														<a href="\" className="text-dark fw-bolder text-hover-primary fs-6">Dan Wilson</a>
														<span className="text-muted d-block fw-bold">PHP, SQLite, Artisan CLI</span>
													</div>
													{/*end::Text*/}
												</div>
												{/*end::Item*/}
											</div>
											{/*end::Body*/}
										</div>
										{/*end::List Widget 2*/}
									</div>
									{/*end::Col*/}
									{/*begin::Col*/}
									<div className="col-xl-4">
										{/*begin::List Widget 6*/}
										<div className="card card-xl-stretch mb-xl-8">
											{/*begin::Header*/}
											<div className="card-header border-0">
												<h3 className="card-title fw-bolder text-dark">Notifications</h3>
												<div className="card-toolbar">
													{/*begin::Menu*/}
													<button type="button" className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
														{/*begin::Svg Icon | path: icons/duotune/general/gen024.svg*/}
														<span className="svg-icon svg-icon-2">
															<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
																<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
																	<rect x="5" y="5" width="5" height="5" rx="1" fill="#000000" />
																	<rect x="14" y="5" width="5" height="5" rx="1" fill="#000000" opacity="0.3" />
																	<rect x="5" y="14" width="5" height="5" rx="1" fill="#000000" opacity="0.3" />
																	<rect x="14" y="14" width="5" height="5" rx="1" fill="#000000" opacity="0.3" />
																</g>
															</svg>
														</span>
														{/*end::Svg Icon*/}
													</button>
													{/*begin::Menu 3*/}
													<div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3" data-kt-menu="true">
														{/*begin::Heading*/}
														<div className="menu-item px-3">
															<div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">Payments</div>
														</div>
														{/*end::Heading*/}
														{/*begin::Menu item*/}
														<div className="menu-item px-3">
															<a href="\" className="menu-link px-3">Create Invoice</a>
														</div>
														{/*end::Menu item*/}
														{/*begin::Menu item*/}
														<div className="menu-item px-3">
															<a href="\" className="menu-link flex-stack px-3">Create Payment
															<i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Specify a target name for future usage and reference"></i></a>
														</div>
														{/*end::Menu item*/}
														{/*begin::Menu item*/}
														<div className="menu-item px-3">
															<a href="\" className="menu-link px-3">Generate Bill</a>
														</div>
														{/*end::Menu item*/}
														{/*begin::Menu item*/}
														<div className="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-end">
															<a href="\" className="menu-link px-3">
																<span className="menu-title">Subscription</span>
																<span className="menu-arrow"></span>
															</a>
															{/*begin::Menu sub*/}
															<div className="menu-sub menu-sub-dropdown w-175px py-4">
																{/*begin::Menu item*/}
																<div className="menu-item px-3">
																	<a href="\" className="menu-link px-3">Plans</a>
																</div>
																{/*end::Menu item*/}
																{/*begin::Menu item*/}
																<div className="menu-item px-3">
																	<a href="\" className="menu-link px-3">Billing</a>
																</div>
																{/*end::Menu item*/}
																{/*begin::Menu item*/}
																<div className="menu-item px-3">
																	<a href="\" className="menu-link px-3">Statements</a>
																</div>
																{/*end::Menu item*/}
																{/*begin::Menu separator*/}
																<div className="separator my-2"></div>
																{/*end::Menu separator*/}
																{/*begin::Menu item*/}
																<div className="menu-item px-3">
																	<div className="menu-content px-3">
																		{/*begin::Switch*/}
																		<label className="form-check form-switch form-check-custom form-check-solid">
																			{/*begin::Input*/}
																			<input className="form-check-input w-30px h-20px" type="checkbox" defaultValue="1"  name="notifications" />
																			{/*end::Input*/}
																			{/*end::Label*/}
																			<span className="form-check-label text-muted fs-6">Recuring</span>
																			{/*end::Label*/}
																		</label>
																		{/*end::Switch*/}
																	</div>
																</div>
																{/*end::Menu item*/}
															</div>
															{/*end::Menu sub*/}
														</div>
														{/*end::Menu item*/}
														{/*begin::Menu item*/}
														<div className="menu-item px-3 my-1">
															<a href="\" className="menu-link px-3">Settings</a>
														</div>
														{/*end::Menu item*/}
													</div>
													{/*end::Menu 3*/}
													{/*end::Menu*/}
												</div>
											</div>
											{/*end::Header*/}
											{/*begin::Body*/}
											<div className="card-body pt-0">
												{/*begin::Item*/}
												<div className="d-flex align-items-center bg-light-warning rounded p-5 mb-7">
													{/*begin::Icon*/}
													<span className="svg-icon svg-icon-warning me-5">
														{/*begin::Svg Icon | path: icons/duotune/abstract/abs027.svg*/}
														<span className="svg-icon svg-icon-1">
															<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																<path opacity="0.3" d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z" fill="black" />
																<path d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z" fill="black" />
															</svg>
														</span>
														{/*end::Svg Icon*/}
													</span>
													{/*end::Icon*/}
													{/*begin::Title*/}
													<div className="flex-grow-1 me-2">
														<a href="\" className="fw-bolder text-gray-800 text-hover-primary fs-6">Group lunch celebration</a>
														<span className="text-muted fw-bold d-block">Due in 2 Days</span>
													</div>
													{/*end::Title*/}
													{/*begin::Lable*/}
													<span className="fw-bolder text-warning py-1">+28%</span>
													{/*end::Lable*/}
												</div>
												{/*end::Item*/}
												{/*begin::Item*/}
												<div className="d-flex align-items-center bg-light-success rounded p-5 mb-7">
													{/*begin::Icon*/}
													<span className="svg-icon svg-icon-success me-5">
														{/*begin::Svg Icon | path: icons/duotune/abstract/abs027.svg*/}
														<span className="svg-icon svg-icon-1">
															<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																<path opacity="0.3" d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z" fill="black" />
																<path d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z" fill="black" />
															</svg>
														</span>
														{/*end::Svg Icon*/}
													</span>
													{/*end::Icon*/}
													{/*begin::Title*/}
													<div className="flex-grow-1 me-2">
														<a href="\" className="fw-bolder text-gray-800 text-hover-primary fs-6">Navigation optimization</a>
														<span className="text-muted fw-bold d-block">Due in 2 Days</span>
													</div>
													{/*end::Title*/}
													{/*begin::Lable*/}
													<span className="fw-bolder text-success py-1">+50%</span>
													{/*end::Lable*/}
												</div>
												{/*end::Item*/}
												{/*begin::Item*/}
												<div className="d-flex align-items-center bg-light-danger rounded p-5 mb-7">
													{/*begin::Icon*/}
													<span className="svg-icon svg-icon-danger me-5">
														{/*begin::Svg Icon | path: icons/duotune/abstract/abs027.svg*/}
														<span className="svg-icon svg-icon-1">
															<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																<path opacity="0.3" d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z" fill="black" />
																<path d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z" fill="black" />
															</svg>
														</span>
														{/*end::Svg Icon*/}
													</span>
													{/*end::Icon*/}
													{/*begin::Title*/}
													<div className="flex-grow-1 me-2">
														<a href="\" className="fw-bolder text-gray-800 text-hover-primary fs-6">Rebrand strategy planning</a>
														<span className="text-muted fw-bold d-block">Due in 5 Days</span>
													</div>
													{/*end::Title*/}
													{/*begin::Lable*/}
													<span className="fw-bolder text-danger py-1">-27%</span>
													{/*end::Lable*/}
												</div>
												{/*end::Item*/}
												{/*begin::Item*/}
												<div className="d-flex align-items-center bg-light-info rounded p-5">
													{/*begin::Icon*/}
													<span className="svg-icon svg-icon-info me-5">
														{/*begin::Svg Icon | path: icons/duotune/abstract/abs027.svg*/}
														<span className="svg-icon svg-icon-1">
															<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																<path opacity="0.3" d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z" fill="black" />
																<path d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z" fill="black" />
															</svg>
														</span>
														{/*end::Svg Icon*/}
													</span>
													{/*end::Icon*/}
													{/*begin::Title*/}
													<div className="flex-grow-1 me-2">
														<a href="\" className="fw-bolder text-gray-800 text-hover-primary fs-6">Product goals strategy</a>
														<span className="text-muted fw-bold d-block">Due in 7 Days</span>
													</div>
													{/*end::Title*/}
													{/*begin::Lable*/}
													<span className="fw-bolder text-info py-1">+8%</span>
													{/*end::Lable*/}
												</div>
												{/*end::Item*/}
											</div>
											{/*end::Body*/}
										</div>
										{/*end::List Widget 6*/}
									</div>
									{/*end::Col*/}
									{/*begin::Col*/}
									<div className="col-xl-4">
										{/*begin::List Widget 4*/}
										<div className="card card-xl-stretch mb-5 mb-xl-8">
											{/*begin::Header*/}
											<div className="card-header border-0 pt-5">
												<h3 className="card-title align-items-start flex-column">
													<span className="card-label fw-bolder text-dark">Trends</span>
													<span className="text-muted mt-1 fw-bold fs-7">Latest tech trends</span>
												</h3>
												<div className="card-toolbar">
													{/*begin::Menu*/}
													<button type="button" className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
														{/*begin::Svg Icon | path: icons/duotune/general/gen024.svg*/}
														<span className="svg-icon svg-icon-2">
															<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
																<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
																	<rect x="5" y="5" width="5" height="5" rx="1" fill="#000000" />
																	<rect x="14" y="5" width="5" height="5" rx="1" fill="#000000" opacity="0.3" />
																	<rect x="5" y="14" width="5" height="5" rx="1" fill="#000000" opacity="0.3" />
																	<rect x="14" y="14" width="5" height="5" rx="1" fill="#000000" opacity="0.3" />
																</g>
															</svg>
														</span>
														{/*end::Svg Icon*/}
													</button>
													{/*begin::Menu 3*/}
													<div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3" data-kt-menu="true">
														{/*begin::Heading*/}
														<div className="menu-item px-3">
															<div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">Payments</div>
														</div>
														{/*end::Heading*/}
														{/*begin::Menu item*/}
														<div className="menu-item px-3">
															<a href="\" className="menu-link px-3">Create Invoice</a>
														</div>
														{/*end::Menu item*/}
														{/*begin::Menu item*/}
														<div className="menu-item px-3">
															<a href="\" className="menu-link flex-stack px-3">Create Payment
															<i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Specify a target name for future usage and reference"></i></a>
														</div>
														{/*end::Menu item*/}
														{/*begin::Menu item*/}
														<div className="menu-item px-3">
															<a href="\" className="menu-link px-3">Generate Bill</a>
														</div>
														{/*end::Menu item*/}
														{/*begin::Menu item*/}
														<div className="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-end">
															<a href="\" className="menu-link px-3">
																<span className="menu-title">Subscription</span>
																<span className="menu-arrow"></span>
															</a>
															{/*begin::Menu sub*/}
															<div className="menu-sub menu-sub-dropdown w-175px py-4">
																{/*begin::Menu item*/}
																<div className="menu-item px-3">
																	<a href="\" className="menu-link px-3">Plans</a>
																</div>
																{/*end::Menu item*/}
																{/*begin::Menu item*/}
																<div className="menu-item px-3">
																	<a href="\" className="menu-link px-3">Billing</a>
																</div>
																{/*end::Menu item*/}
																{/*begin::Menu item*/}
																<div className="menu-item px-3">
																	<a href="\" className="menu-link px-3">Statements</a>
																</div>
																{/*end::Menu item*/}
																{/*begin::Menu separator*/}
																<div className="separator my-2"></div>
																{/*end::Menu separator*/}
																{/*begin::Menu item*/}
																<div className="menu-item px-3">
																	<div className="menu-content px-3">
																		{/*begin::Switch*/}
																		<label className="form-check form-switch form-check-custom form-check-solid">
																			{/*begin::Input*/}
																			<input className="form-check-input w-30px h-20px" type="checkbox" defaultValue="1"  name="notifications" />
																			{/*end::Input*/}
																			{/*end::Label*/}
																			<span className="form-check-label text-muted fs-6">Recuring</span>
																			{/*end::Label*/}
																		</label>
																		{/*end::Switch*/}
																	</div>
																</div>
																{/*end::Menu item*/}
															</div>
															{/*end::Menu sub*/}
														</div>
														{/*end::Menu item*/}
														{/*begin::Menu item*/}
														<div className="menu-item px-3 my-1">
															<a href="\" className="menu-link px-3">Settings</a>
														</div>
														{/*end::Menu item*/}
													</div>
													{/*end::Menu 3*/}
													{/*end::Menu*/}
												</div>
											</div>
											{/*end::Header*/}
											{/*begin::Body*/}
											<div className="card-body pt-5">
												{/*begin::Item*/}
												<div className="d-flex align-items-sm-center mb-7">
													{/*begin::Symbol*/}
													<div className="symbol symbol-50px me-5">
														<span className="symbol-label">
															<img src="assets/media/svg/brand-logos/plurk.svg" className="h-50 align-self-center" alt="" />
														</span>
													</div>
													{/*end::Symbol*/}
													{/*begin::Section*/}
													<div className="d-flex align-items-center flex-row-fluid flex-wrap">
														<div className="flex-grow-1 me-2">
															<a href="\" className="text-gray-800 text-hover-primary fs-6 fw-bolder">Top Authors</a>
															<span className="text-muted fw-bold d-block fs-7">Mark, Rowling, Esther</span>
														</div>
														<span className="badge badge-light fw-bolder my-2">+82$</span>
													</div>
													{/*end::Section*/}
												</div>
												{/*end::Item*/}
												{/*begin::Item*/}
												<div className="d-flex align-items-sm-center mb-7">
													{/*begin::Symbol*/}
													<div className="symbol symbol-50px me-5">
														<span className="symbol-label">
															<img src="assets/media/svg/brand-logos/telegram.svg" className="h-50 align-self-center" alt="" />
														</span>
													</div>
													{/*end::Symbol*/}
													{/*begin::Section*/}
													<div className="d-flex align-items-center flex-row-fluid flex-wrap">
														<div className="flex-grow-1 me-2">
															<a href="\" className="text-gray-800 text-hover-primary fs-6 fw-bolder">Popular Authors</a>
															<span className="text-muted fw-bold d-block fs-7">Randy, Steve, Mike</span>
														</div>
														<span className="badge badge-light fw-bolder my-2">+280$</span>
													</div>
													{/*end::Section*/}
												</div>
												{/*end::Item*/}
												{/*begin::Item*/}
												<div className="d-flex align-items-sm-center mb-7">
													{/*begin::Symbol*/}
													<div className="symbol symbol-50px me-5">
														<span className="symbol-label">
															<img src="assets/media/svg/brand-logos/vimeo.svg" className="h-50 align-self-center" alt="" />
														</span>
													</div>
													{/*end::Symbol*/}
													{/*begin::Section*/}
													<div className="d-flex align-items-center flex-row-fluid flex-wrap">
														<div className="flex-grow-1 me-2">
															<a href="\" className="text-gray-800 text-hover-primary fs-6 fw-bolder">New Users</a>
															<span className="text-muted fw-bold d-block fs-7">John, Pat, Jimmy</span>
														</div>
														<span className="badge badge-light fw-bolder my-2">+4500$</span>
													</div>
													{/*end::Section*/}
												</div>
												{/*end::Item*/}
												{/*begin::Item*/}
												<div className="d-flex align-items-sm-center mb-7">
													{/*begin::Symbol*/}
													<div className="symbol symbol-50px me-5">
														<span className="symbol-label">
															<img src="assets/media/svg/brand-logos/bebo.svg" className="h-50 align-self-center" alt="" />
														</span>
													</div>
													{/*end::Symbol*/}
													{/*begin::Section*/}
													<div className="d-flex align-items-center flex-row-fluid flex-wrap">
														<div className="flex-grow-1 me-2">
															<a href="\" className="text-gray-800 text-hover-primary fs-6 fw-bolder">Active Customers</a>
															<span className="text-muted fw-bold d-block fs-7">Mark, Rowling, Esther</span>
														</div>
														<span className="badge badge-light fw-bolder my-2">+686$</span>
													</div>
													{/*end::Section*/}
												</div>
												{/*end::Item*/}
												{/*begin::Item*/}
												<div className="d-flex align-items-sm-center mb-7">
													{/*begin::Symbol*/}
													<div className="symbol symbol-50px me-5">
														<span className="symbol-label">
															<img src="assets/media/svg/brand-logos/kickstarter.svg" className="h-50 align-self-center" alt="" />
														</span>
													</div>
													{/*end::Symbol*/}
													{/*begin::Section*/}
													<div className="d-flex align-items-center flex-row-fluid flex-wrap">
														<div className="flex-grow-1 me-2">
															<a href="\" className="text-gray-800 text-hover-primary fs-6 fw-bolder">Bestseller Theme</a>
															<span className="text-muted fw-bold d-block fs-7">Disco, Retro, Sports</span>
														</div>
														<span className="badge badge-light fw-bolder my-2">+726$</span>
													</div>
													{/*end::Section*/}
												</div>
												{/*end::Item*/}
											</div>
											{/*end::Body*/}
										</div>
										{/*end::List Widget 4*/}
									</div>
									{/*end::Col*/}
								</div>
								{/*end::Row*/}
        </>
    )
})
