import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { DiscountPlan, DiscountPlanCreateOrUpdate } from "../models/models";
import FilteringAndPaginationController, { StoreWithLoadItems } from "../common/filteringAndPaginationController";

export default class DiscountPlansStore implements StoreWithLoadItems {
    registry = new Map<string, DiscountPlan>();
    selectedItem: DiscountPlan | undefined = undefined;
    filteringAndPaginationController = new FilteringAndPaginationController(this);

    constructor() {
        makeAutoObservable(this);
    }

    loadItems = async () => {
        const result = await agent.DiscountPlans.list(this.filteringAndPaginationController.axiosParams);
        this.filteringAndPaginationController.setPagination(result.pagination);
        runInAction(() => {
            this.registry.clear();
            result.data.forEach(b => {
                this.registry.set(b.id, b);
            });
        })
    }

    loadItem = async (id: string) => {
        this.selectedItem = await agent.DiscountPlans.details(id);
        return this.selectedItem;
    }

    createOrUpdateItem = async (discountPlan: DiscountPlanCreateOrUpdate) => {
        if (discountPlan.id) {
            return agent.DiscountPlans.update(discountPlan);
        } else {
            return agent.DiscountPlans.create(discountPlan);
        }
    }

    activate = async (id: string) => {
        await agent.DiscountPlans.activate(id);
    }

    deactivate = async (id: string) => {
        await agent.DiscountPlans.deactivate(id);
    }

    get items() {
        return Array.from(this.registry.values());
    }

    get activeItems() {
        return Array.from(this.registry.values()).filter((i) => !i.isDeacivated);
    }
}