import React from 'react';
import { observer } from 'mobx-react-lite';
export default observer(function CalendarTest() {
    return (
        <>
            <div className="card">
                {/*begin::Card header*/}
                <div className="card-header">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bolder text-dark">My Calendar</span>
                        <span className="text-muted mt-1 fw-bold fs-7">Preview monthly events</span>
                    </h3>
                    <div className="card-toolbar">
                        <a href="../../demo1/dist/apps/calendar.html" className="btn btn-primary">Manage Calendar</a>
                    </div>
                </div>
                {/*end::Card header*/}
                {/*begin::Card body*/}
                <div className="card-body">
                    {/*begin::Calendar*/}
                    <div id="kt_calendar_widget_1"></div>
                    {/*end::Calendar*/}
                </div>
                {/*end::Card body*/}
            </div>
        </>
    )
})
