import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import urls from '../../app/common/urls';
import { NavLink } from 'react-router-dom';
import ToolTipWrapper, { ToolTipWrapperMode } from '../../app/elements/ToolTipWrapper';
import { IconShop } from '../../app/layout/Icons';
import { useStore } from '../../app/stores/store';
import { PagingParams } from '../../app/models/pagination';
import { runInAction } from 'mobx';
import PaginationBar from '../../app/layout/PaginationBar';
import SourceToLogoImage from '../../app/elements/SourceToLogoImage';


export default observer(function PickupsWidget() {
    const { orderStore: store } = useStore();
    const { setPredicate, setPagingParams, pagination, clearPredicateAndPaging } = store.filteringAndPaginationController;

    useEffect(() => {
        clearPredicateAndPaging();
        setPredicate('filter', 'pickup', 5);
        runInAction(() => {
            store.loadItems();
        })
    }, [store, clearPredicateAndPaging, setPredicate])

    function handleGetNext() {
        setPagingParams(new PagingParams(pagination!.currentPage + 1, 5));
        store.loadItems();
    }

    function handleGetPrev() {
        setPagingParams(new PagingParams(pagination!.currentPage - 1, 5));
        store.loadItems();
    }

    return (
        <>
            {/*begin::Tables Widget 9*/}
            <div className="card card-flush h-md-100">
                {/*begin::Header*/}
                <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bolder fs-3 mb-1">Odbiory osobiste</span>
                        <span className="text-muted mt-1 fw-bold fs-7">Łącznie {pagination?.totalItems}</span>
                    </h3>
                    <div className="card-toolbar">
                        <NavLink to={urls.pickups()} className="btn btn-sm btn-light btn-active-primary" >
                            {/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
                            <IconShop size="1" />
                            {/*end::Svg Icon*/}Zobacz wszystkie</NavLink>
                    </div>
                </div>
                {/*end::Header*/}
                {/*begin::Body*/}
                <div className="card-body py-3">
                    {/*begin::Table container*/}
                    <div className="table-responsive">
                        {/*begin::Table*/}
                        <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2 mb-0">
                            {/*begin::Table head*/}
                            {/*end::Table head*/}
                            {/*begin::Table body*/}
                            <tbody>
                                {store.items.map((i) => (
                                    <tr key={i.id}>
                                        <td>
                                            {/*begin::User*/}
                                            <div className="d-flex align-items-center">
                                                {/*begin::Info*/}
                                                {i.customerIsPremium &&
                                                    <ToolTipWrapper notification={i.customerPremiumComment}>
                                                        <div className="position-relative pe-3 py-2">
                                                            <NavLink to={urls.customerDetails(i.customerId)} className="text-dark fw-bolder text-hover-primary fs-6">
                                                                {i.customerFirstName}<i className="fa fa-star me-1 text-warning fs-5 ms-2" /><br />{i.customerLastName}
                                                            </NavLink>
                                                            <div className="text-muted fw-bold text-muted d-block fs-7">{i.customerPhone}</div>
                                                        </div>
                                                    </ToolTipWrapper>}
                                                {!i.customerIsPremium &&
                                                    <div className="position-relative pe-3 py-2">
                                                        <NavLink to={urls.customerDetails(i.customerId)} className="text-dark fw-bolder text-hover-primary fs-6">
                                                            {i.customerFirstName}<br />{i.customerLastName}
                                                        </NavLink>
                                                        <div className="text-muted fw-bold text-muted d-block fs-7">{i.customerPhone}</div>
                                                    </div>}
                                                {/*end::Info*/}
                                            </div>
                                            {/*end::User*/}
                                        </td>
                                        <td>
                                            <SourceToLogoImage source={i.source} />
                                            {`${2} d. temu`}
                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                {/*begin::Info*/}
                                                <div className="position-relative pe-0 py-2">
                                                    <ToolTipWrapper notification={`Wartość zamówienia to ${i.totalValue} zł. Liczba przedmiotów w zamówieniu: ${i.totalItems}`} mode={ToolTipWrapperMode.Medium} isLeft>
                                                        <span className="text-dark fw-bolder fs-6">{i.totalValue} zł</span>
                                                        <div className="fw-bold fs-7 text-gray-400">{i.totalItems} <i className="fa fa-arrow-right me-1 text-muted fs-8" /><i className="fa fa-box-open me-1 text-muted fs-7" /></div>
                                                        {/* <div className="fw-bold text-gray-400">{i.email}</div> */}
                                                    </ToolTipWrapper>
                                                </div>
                                                {/*end::Info*/}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="">
                                                <NavLink to={urls.orderDetails(i.id)} className="btn btn-icon btn-bg-light btn-active-color-primary text-hover-primary btn-sm">
                                                    <i className="fa fa-arrow-right me-1 text-muted fs-8 text-hover-primary" />
                                                </NavLink>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                            {/*end::Table body*/}
                        </table>
                        {/*end::Table*/}
                        {pagination &&
                            <PaginationBar pagination={pagination!} handleGetNext={handleGetNext} handleGetPrev={handleGetPrev} />}
                    </div>
                    {/*end::Table container*/}
                </div>
                {/*begin::Body*/}
            </div>
            {/*end::Tables Widget 9*/}
        </>
    )
})