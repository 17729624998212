import React from 'react';
import { observer } from 'mobx-react-lite';
import { Customer } from '../models/models';
import ToolTipWrapper from './ToolTipWrapper';

interface Props {
    customer: Customer,
    inline?: boolean
}

export default observer(function CustomerActivity({ customer, inline = false }: Props) {
    const renderRating = (value: number) => {
        return (<>
            <div className="rating">
                <div className={`rating-label me-1 ${value >= 1 ? 'checked' : ''}`}>
                    <i className="bi bi-star-fill fs-6s"></i>
                </div>
                <div className={`rating-label me-1 ${value >= 2 ? 'checked' : ''}`}>
                    <i className="bi bi-star-fill fs-6s"></i>
                </div>
                <div className={`rating-label me-1 ${value >= 3 ? 'checked' : ''}`}>
                    <i className="bi bi-star-fill fs-6s"></i>
                </div>
                <div className={`rating-label me-1 ${value >= 4 ? 'checked' : ''}`}>
                    <i className="bi bi-star-fill fs-6s"></i>
                </div>
                <div className={`rating-label me-1 ${value >= 5 ? 'checked' : ''}`}>
                    <i className="bi bi-star-fill fs-6s"></i>
                </div>
                {inline &&
                    <span className="text-muted fw-bold text-muted d-block fs-7 mt-1 ps-3">{customer.comments} <i className="fa fa-comment-dots me-1 text-primary fs-5" /> {customer.shares} <i className="fa fa-share me-1 text-primary fs-5" /></span>}
            </div>
        </>)
    }

    return (
        <>
            <ToolTipWrapper notification={`Ten klient dodał na Twoim kanale ${customer.comments} komentarzy oraz ${customer.shares} razy udostępnił Twój live`}>
                {renderRating(customer.rating)}
                {!inline &&
                    <span className="text-muted fw-bold text-muted d-block fs-7 mt-1">{customer.comments} <i className="fa fa-comment-dots me-1 text-primary fs-5" /> {customer.shares} <i className="fa fa-share me-1 text-primary fs-5" /></span>}
            </ToolTipWrapper>
        </>
    )
})


