import React from 'react';
import { observer } from 'mobx-react-lite';
import ToolTipWrapper from './ToolTipWrapper';
import { Customer } from '../models/models';

interface Props {
    customer: Customer,
    isLeft?: boolean
}

export default observer(function CustomerEventStatus({ customer, isLeft = false }: Props) {

    if (customer.isBlackList) {
        return (
            <ToolTipWrapper notification={`Ten klient jest na czarnej liście i nie może dokonywać zakupów.`} isLeft={isLeft} >
                <span className="badge badge-dark fw-bolder px-4 py-3">Czarna lista</span>
            </ToolTipWrapper>
        );
    }
    switch (customer.eventStatus) {
        case 'open-parcel': return (<><span className="badge badge-light-success fw-bolder px-4 py-3">Otwarta Paczka</span></>);
        case 'parcel-to-send': return (<><span className="badge badge-light-success fw-bolder px-4 py-3">Paczka do wysłania</span></>);
        default: return (<></>)
    }


})