import { observer } from 'mobx-react-lite';
import React from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import OrdersListWidget, { OrdersMode } from '../../../app/common/OrdersListWidget';
import { useStore } from '../../../app/stores/store';
import HistoryDetailsData from './HistoryDetailsData';

export default observer(function HistoryDetailsPage() {
    var { historyStore } = useStore();
    const { id } = useParams<{ id: string }>();
    const { selectedItem } = historyStore;

    useEffect(() => {
        historyStore.loadItem(id);
    }, [id, historyStore.loadItem, historyStore])

    return (
        <>
            {selectedItem &&
                <>
                    <div className="row g-5 g-xl-10 mb-xl-10" style={{ paddingTop: '50px' }}>
                        <div className="col-lg-12 col-xl-6 col-xxl-4 mb-5 mb-xl-0">
                            <HistoryDetailsData historyItem={selectedItem!} />
                        </div>
                        <div className="col-lg-12 col-xl-6 col-xxl-8 mb-5 mb-xl-0">
                            <OrdersListWidget mode={OrdersMode.CustomerWidget} parentObjectId={['historyItemId', id]} />
                        </div>
                    </div>
                    <div className="row g-5 g-xl-10 mb-xl-10">
                        <div className="col-lg-12 col-xl-6 col-xxl-6 mb-5 mb-xl-0">
                        </div>
                        <div className="col-lg-12 col-xl-6 col-xxl-6 mb-5 mb-xl-0">
                        </div>
                    </div>
                    <div className="row g-5 g-xl-10 mb-xl-10">
                        <div className="col-lg-12 col-xl-6 col-xxl-6 mb-5 mb-xl-0">
                        </div>
                        <div className="col-lg-12 col-xl-6 col-xxl-8 mb-5 mb-xl-0">
                        </div>
                    </div>
                </>
            }
        </>
    )
})
