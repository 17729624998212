import React from 'react';
import { observer } from 'mobx-react-lite';

interface Props {
    percentage: number;
    simple?: boolean;
}

export default observer(function ProgressBar({ percentage, simple = false }: Props) {

    const percentageToColor = (p: number) => {
        if (p <= 33) return 'success';
        if (p <= 66) return 'warning';
        return 'danger';
    }

    if (percentage === 100)
        return (
            <div className="d-flex flex-column w-100 me-2">
                {!simple && <div className="d-flex flex-stack mb-2">
                    <span className="text-muted me-2 fs-7 fw-bold">100 %</span>
                </div>}
                <div className="progress h-6px w-100">
                    <div className={`progress-bar bg-light-success`} role="progressbar" style={{ width: `100%` }}></div>
                </div>
            </div>)

    if (percentage === -1)
        return (
            <div className="d-flex flex-column w-100 me-2">
                {!simple && <div className="d-flex flex-stack mb-2">
                    <span className="text-muted me-2 fs-7 fw-bold">0 %</span>
                </div>}
                <div className="progress h-6px w-100">
                    <div className={`progress-bar bg-primary`} role="progressbar" style={{ width: '0%' }}></div>
                </div>
            </div>)

    return (
        <div className="d-flex flex-column w-100 me-2">
            {!simple &&
                <div className="d-flex flex-stack mb-2">
                    <span className="text-muted me-2 fs-7 fw-bold">{percentage} %</span>
                </div>}
            <div className="progress h-6px w-100">
                <div className={`progress-bar bg-${percentageToColor(percentage)}`} role="progressbar" style={{ width: `${percentage}%` }}></div>
            </div>
        </div>)
})