import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Contract, TypeOfPayment } from "../models/models";
import FilteringAndPaginationController, { StoreWithLoadItems } from "../common/filteringAndPaginationController";

export default class ContractStore implements StoreWithLoadItems {
    registry = new Map<string, Contract>();
    selectedItem: Contract | undefined = undefined;
    filteringAndPaginationController = new FilteringAndPaginationController(this);

    constructor() {
        makeAutoObservable(this);
    }

    loadItems = async () => {
        const result = await agent.Contracts.list(this.filteringAndPaginationController.axiosParams);
        this.filteringAndPaginationController.setPagination(result.pagination);
        runInAction(() => {
            this.registry.clear();
            result.data.forEach(b => {
                this.registry.set(b.id, b);
            });
        })
    }

    loadItem = async (id: string) => {
        this.selectedItem = await agent.Contracts.details(id);
        return this.selectedItem;
    }

    invoiceEntireRemainingPeriod = async(contractId: string, typeOfPayment: TypeOfPayment) => {
        await agent.Contracts.invoiceEntireRemainingPeriod(contractId, typeOfPayment);
    }

    invoiceNextUninvoicedPeriod = async(contractId: string, typeOfPayment: TypeOfPayment) => {
        await agent.Contracts.invoiceNextUninvoicedPeriod(contractId, typeOfPayment);
    }

    delete = async(contractId: string) => {
        await agent.Contracts.delete(contractId);
    }

    finish = async(contractId: string) => {
        await agent.Contracts.finish(contractId);
    }

    get items() {
        return Array.from(this.registry.values());
    }
}