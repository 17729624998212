import { createContext, useContext } from "react";
import BasketStore from "./basketStore";
import { BoxStore } from "./boxStore";
import CommonStore from "./commonStore";
import ContractStore from "./contractStore";
import CustomerStore from "./customerStore";
import DiscountPlansStore from "./discountPlansStore";
import HistoryStore from "./historyStore";
import InvoiceStore from "./invoiceStore";
import ModalStore from "./modalStore";
import OrderStore from "./orderStore";
import ProductStore from "./productStore";
import ReservationStore from "./reservationStore";
import StatisticsStore from "./statisticsStore";
import UserStore from "./userStore";

interface Store {
    boxStore: BoxStore;
    contractStore: ContractStore;
    invoiceStore: InvoiceStore;
    customerStore: CustomerStore;
    orderStore: OrderStore;
    homeOrderStore: OrderStore;
    headerOrderStore: OrderStore;
    basketStore: BasketStore;
    productStore: ProductStore;
    historyStore: HistoryStore;
    reservationStore: ReservationStore;
    statisticsStore: StatisticsStore;
    userStore: UserStore;
    commonStore: CommonStore;
    discountPlansStore: DiscountPlansStore;
    modalStore: ModalStore;
}

export const store: Store = {
    boxStore: new BoxStore(),
    contractStore: new ContractStore(),
    invoiceStore: new InvoiceStore(),
    customerStore: new CustomerStore(),
    orderStore: new OrderStore(),
    homeOrderStore: new OrderStore(),
    headerOrderStore: new OrderStore(),
    basketStore: new BasketStore(),
    productStore: new ProductStore(),
    historyStore: new HistoryStore(),
    reservationStore: new ReservationStore(),
    statisticsStore: new StatisticsStore(),
    userStore: new UserStore(),
    commonStore: new CommonStore(),
    discountPlansStore: new DiscountPlansStore(),
    modalStore: new ModalStore()
}

export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext);
}