import React from 'react';
import { observer } from 'mobx-react-lite';
import { Order } from '../../../app/models/models';
import SourceToLogoImage from '../../../app/elements/SourceToLogoImage';
import OrderStatus from '../../../app/elements/OrderStatus';
import ToolTipWrapper from '../../../app/elements/ToolTipWrapper';
import { NavLink } from 'react-router-dom';
import urls from '../../../app/common/urls';
import { useStore } from '../../../app/stores/store';
import { paymentMethodReadable, paymentStatusReadable } from '../../../app/common/helpers';
interface Props {
    order: Order
}
export default observer(function OrderDetailsData({ order }: Props) {
    const { modalStore: { show: ShowModalDemoWarning } } = useStore();
    return (
        <>
            {/*begin::Card*/}
            <div className="card mb-5 mb-xl-8">
                {/*begin::Card body*/}
                <div className="card-body pt-15">
                    {/*begin::Summary*/}
                    <div className="d-flex flex-center flex-column mb-5">
                        {/* <div className="fs-5 fw-bold text-muted mb-3"><CustomerProblematic customer={order.number} /></div> */}
                        <span className="fs-3 text-gray-800 fw-bolder mb-1"><SourceToLogoImage className='me-2' source={order.source} />Zamówienie</span>
                        {/*end::Name*/}
                        {/*begin::Position*/}
                        <div className="fs-5 fw-bold text-muted mb-5">Numer zamówienia: #{order.number}</div>
                        <div className="fs-5 fw-bold text-muted mb-3"><OrderStatus order={order} /></div>
                        {/*end::Position*/}
                    </div>
                    {/*end::Summary*/}
                    {/*begin::Details toggle*/}
                    <div className="d-flex flex-stack fs-4 py-3">
                        <div className="fw-bolder">Szczegóły
                        </div>
                        <span data-bs-toggle="tooltip" data-bs-trigger="hover" title="Edit customer details">
                            <button onClick={() => ShowModalDemoWarning()} className="btn btn-sm btn-light-primary">Edytuj</button>
                        </span>
                    </div>
                    {/*end::Details toggle*/}
                    <div className="separator separator-dashed my-3"></div>
                    {/*begin::Details content*/}
                    <div>
                        <div className="pb-5 fs-6">
                            {/*begin::Details item*/}
                            <div className='row'>
                                <div className='col-6'>
                                    <div className="fw-bolder mt-5">Wartość zamówienia</div>
                                    <div className="text-gray-600">{order.totalValue} zł</div>
                                </div>
                                <div className='col-6'>
                                    <div className="fw-bolder mt-5">Ilość przedmiotów</div>
                                    <div className="text-gray-600">{order.totalItems} szt.</div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-6'>
                                    <div className="fw-bolder mt-5">Cena wysyłki</div>
                                    <div className="text-gray-600">{order.deliveryCostReadable} zł ({order.shippmentProvider})</div>
                                </div>
                                <div className='col-6'>
                                    <div className="fw-bolder mt-5">Łącznie do zapłaty</div>
                                    <div className="text-gray-600">{order.totalToPayReadable} zł.</div>
                                </div>
                            </div>
                            {/*begin::Details item*/}
                            <div className='row'>
                                <div className='col-6'>
                                    <div className="fw-bolder mt-5">Złożono dnia</div>
                                    <div className="text-gray-600">{order.createdAt}</div>
                                </div>
                                <div className='col-6'>
                                    <div className="fw-bolder mt-5">Złożono na</div>
                                    <div className="text-gray-600">{order.source}</div>
                                </div>
                            </div>
                            {/*begin::Details item*/}
                            {/*begin::Details item*/}
                            <div className='row'>
                                <div className='col-6'>
                                    <div className="fw-bolder mt-5">Forma płatności</div>
                                    <div className="text-gray-600">{paymentMethodReadable(order.paymentProvider)}</div>
                                </div>
                                <div className='col-6'>
                                    <div className="fw-bolder mt-5">Status płatności</div>
                                    <div className="text-gray-600">{paymentStatusReadable(order.paymentStatus)}</div>
                                </div>
                            </div>
                            {/*begin::Details item*/}
                            {/*begin::Details item*/}
                            <div className="fw-bolder mt-5">Dane klienta</div>
                            <NavLink to={urls.customerDetails(order.customerId)}>
                                <div className="text-gray-600 text-hover-primary">{order.customerFullName}
                                    {order.customerIsPremium && <ToolTipWrapper notification={order.customerPremiumComment}><i className="fa fa-star me-1 text-warning fs-5 ms-2" /></ToolTipWrapper>}
                                </div>
                            </NavLink>
                            {/*begin::Details item*/}
                            {/*begin::Details item*/}
                            <div className="fw-bolder mt-5">Kontakt</div>
                            <div className="text-gray-600">
                                {order.customerEmail}
                                <br />
                                {order.customerPhone}
                            </div>
                            {/*begin::Details item*/}
                            {/*begin::Details item*/}
                            <div className="fw-bolder mt-5">Adres wysyłki</div>
                            <div className="text-gray-600">
                                {order.deliveryAddressLine1}
                                <br />{order.deliveryAddressLine2}
                                <br />Polska</div>
                            {/*begin::Details item*/}
                            <button onClick={() => ShowModalDemoWarning()} className="btn btn-success w-100 mb-0 mt-5"><i className="fa fa-truck me-1 text-white fs-5"></i> Zmień status zamówienia</button>
                            {order.paymentStatus === 'to-pay' &&
                                <button onClick={() => ShowModalDemoWarning()} className="btn btn-success w-100 mb-0 mt-5"><i className="fas fa-hand-holding-usd me-1 text-white fs-5"></i> Oznacz jako opłacone</button>}
                            {order.paymentStatus === 'to-pay' &&
                                <button onClick={() => ShowModalDemoWarning()} className="btn btn-danger w-100 mb-0 mt-5"><i className="fas fa-ban me-1 text-white fs-5"></i> Oznacz jako nieodebrane</button>}
                            {order.status === 'finished' &&
                                <button onClick={() => ShowModalDemoWarning()} className="btn btn-warning w-100 mb-0 mt-5"><i className="fa fa-undo me-1 text-white fs-5"></i> Edytuj zwrot towaru<br/> (lub jego części)</button>}
                        </div>
                    </div>
                    {/*end::Details content*/}
                </div>
                {/*end::Card body*/}
            </div>
            {/*end::Card*/}
        </>
    )
})
