import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
export default observer(function ContestWidget() {
    const { modalStore: {show: ShowModalDemoWarning} } = useStore();
    return (
        <>
            {/*begin::Connected Accounts*/}
            <div className="card">
                {/*begin::Card header*/}
                <div className="card-header border-0">
                    <div className="card-title m-0">
                        <h3 className="fw-bolder m-0">Buduj zaangażowanie</h3>
                    </div>
                </div>
                {/*end::Card header*/}
                {/*begin::Content*/}
                <div>
                    {/*begin::Card body*/}
                    <div className="card-body border-top p-9">
                        <div className="fv-row">
                            <div className="col-12">
                                {/*begin::Heading*/}
                                <div className="mb-3">
                                    {/*begin::Label*/}
                                    <label className="d-flex align-items-center fs-5 fw-bold">
                                        <span>Konkursy w trakcie live</span>
                                    </label>
                                    {/*end::Label*/}
                                    {/*begin::Description*/}
                                    <div className='py-5'>
                                        <div className="timeline-label">
                                            {/*begin::Item*/}
                                            <div className="timeline-item">
                                                {/*begin::Label*/}
                                                {/*end::Label*/}
                                                {/*begin::Badge*/}
                                                <div className="timeline-badge">
                                                    <i className="fa fa-genderless text-warning fs-1"></i>
                                                </div>
                                                {/*end::Badge*/}
                                                {/*begin::Text*/}
                                                <div className="timeline-content fw-bolder text-gray-800 ps-3">Algorytmy w mediach społecznościowych wykrywają „nakłanianie” do lajkowania i komentowania.</div>
                                                {/*end::Text*/}
                                            </div>
                                            {/*end::Item*/}
                                            {/*begin::Item*/}
                                            <div className="timeline-item">
                                                {/*begin::Label*/}
                                                {/*end::Label*/}
                                                {/*begin::Badge*/}
                                                <div className="timeline-badge">
                                                    <i className="fa fa-genderless text-danger fs-1"></i>
                                                </div>
                                                {/*end::Badge*/}
                                                {/*begin::Content*/}
                                                <div className="timeline-content d-flex">
                                                    <span className="timeline-content fw-bolder text-gray-800 ps-3">Jeśli piszesz lub mówisz wprost „lajkujcie, komentujcie” - facebook, instagram czy tiktok mogą <strong className='text-danger'> obciąć Twoje zasięgi.</strong></span>
                                                </div>
                                                {/*end::Content*/}
                                            </div>
                                            {/*end::Item*/}
                                            {/*begin::Item*/}
                                            <div className="timeline-item">
                                                {/*begin::Label*/}
                                                {/*end::Label*/}
                                                {/*begin::Badge*/}
                                                <div className="timeline-badge">
                                                    <i className="fa fa-genderless text-success fs-1"></i>
                                                </div>
                                                {/*end::Badge*/}
                                                {/*begin::Desc*/}
                                                <div className="timeline-content fw-bolder text-gray-800 ps-3">Zamiast „prosić o lajki” lepiej jest robić konkursy.</div>
                                                {/*end::Desc*/}
                                            </div>
                                            {/*end::Item*/}
                                        </div>
                                    </div>
                                    {/*end::Description*/}
                                </div>
                                {/*end::Heading*/}
                                {/*begin::Row*/}
                                <div className="fv-row">
                                    {/*begin::Radio group*/}
                                    <div className="btn-group w-100">
                                        {/*begin::Radio*/}
                                        <label className="btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-danger" data-kt-button="true" onClick={() => ShowModalDemoWarning()}>
                                            {/*begin::Input*/}
                                            <input className="btn-check" type="radio" name="method" defaultValue="1" />
                                            {/*end::Input*/}
                                            Konkursy wyłączone</label>
                                        {/*end::Radio*/}
                                        {/*begin::Radio*/}
                                        <label className="btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-primary active" data-kt-button="true" onClick={() => ShowModalDemoWarning()}>
                                            {/*begin::Input*/}
                                            <input className="btn-check" type="radio" name="method" defaultValue="2" />
                                            {/*end::Input*/}
                                            Konkursy włączone</label>
                                    </div>
                                    {/*end::Radio group*/}
                                </div>
                                {/*end::Row*/}
                            </div>
                            <div className='col-12 pt-7'>
                                <div className="table-responsive">
                                    <table className="table table-row-dashed border-gray-300 align-middle gy-4">
                                        <tbody className="fw-bolder">
                                            {/*begin::Table row*/}
                                            <tr>
                                                <td className="min-w-250px fs-4 fw-bold">Dostępne pytania:</td>
                                                <td className="w-100px ps-100">
                                                </td>
                                            </tr>
                                            {/*begin::Table row*/}
                                            {/*begin::Table row*/}
                                            <tr>
                                                <td>Co dziś zaprezentowaliśmy jako pierwsze na live?</td>
                                                <td className='text-end'>
                                                    <div className="form-check form-check-solid">
                                                        <input className="form-check-input" type="checkbox" checked onClick={() => ShowModalDemoWarning()}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            {/*begin::Table row*/}
                                            {/*begin::Table row*/}
                                            <tr>
                                                <td>Ile kosztowała pierwsza rzecz na dzisiejszym live (można pomylić się o 5zł)?</td>
                                                <td className='text-end'>
                                                    <div className="form-check form-check-solid">
                                                        <input className="form-check-input" type="checkbox" checked onClick={() => ShowModalDemoWarning()}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            {/*begin::Table row*/}
                                            {/*begin::Table row*/}
                                            <tr>
                                                <td>Co pokazaliśmy na dzisiejszym live jako ostatnie?</td>
                                                <td className='text-end'>
                                                    <div className="form-check form-check-solid">
                                                        <input className="form-check-input" type="checkbox" checked onClick={() => ShowModalDemoWarning()}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            {/*begin::Table row*/}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='col-12 text-end pe-5'>
                                <button onClick={() => ShowModalDemoWarning()} type="button" className="btn btn-primary btn-sm" >
                                    <span className="svg-icon svg-icon-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <rect fill="#000000" x="4" y="11" width="16" height="2" rx="1"></rect>
                                            <rect fill="#000000" opacity="0.5" transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000)" x="4" y="11" width="16" height="2" rx="1"></rect>
                                        </svg>
                                    </span>
                                    {'Dodaj swoje pytania'}</button>
                            </div>
                            {/*end::Input group*/}
                        </div>
                        <div className='fv-row pt-5'>
                            <label className="d-flex align-items-center fs-5 fw-bold">
                                <span>Jak to działa?</span>
                            </label>
                            {/*end::Label*/}
                            {/*begin::Description*/}
                            <div className="fs-7 fw-bold text-muted">
                                <div className='py-5'>
                                        <div className="timeline-label">
                                            {/*begin::Item*/}
                                            <div className="timeline-item">
                                                {/*begin::Label*/}
                                                {/*end::Label*/}
                                                {/*begin::Badge*/}
                                                <div className="timeline-badge">
                                                    <i className="fa fa-genderless text-success fs-1"></i>
                                                </div>
                                                {/*end::Badge*/}
                                                {/*begin::Text*/}
                                                <div className="timeline-content fw-bolder text-gray-800 ps-3">1. Dodajesz powyżej swoje pytania.</div>
                                                {/*end::Text*/}
                                            </div>
                                            {/*end::Item*/}
                                            {/*begin::Item*/}
                                            <div className="timeline-item">
                                                {/*begin::Label*/}
                                                {/*end::Label*/}
                                                {/*begin::Badge*/}
                                                <div className="timeline-badge">
                                                    <i className="fa fa-genderless text-success fs-1"></i>
                                                </div>
                                                {/*end::Badge*/}
                                                {/*begin::Content*/}
                                                <div className="timeline-content d-flex">
                                                    <span className="timeline-content fw-bolder text-gray-800 ps-3">2. Na początku live ogłaszasz konkurs dla udostępniających.</span>
                                                </div>
                                                {/*end::Content*/}
                                            </div>
                                            {/*end::Item*/}
                                            {/*begin::Item*/}
                                            <div className="timeline-item">
                                                {/*begin::Label*/}
                                                {/*end::Label*/}
                                                {/*begin::Badge*/}
                                                <div className="timeline-badge">
                                                    <i className="fa fa-genderless text-success fs-1"></i>
                                                </div>
                                                {/*end::Badge*/}
                                                {/*begin::Desc*/}
                                                <div className="timeline-content fw-bolder text-gray-800 ps-3">3. W wybranym momencie (najlepiej pod koniec live) ogłaszasz pytanie i klikasz je tutaj w zakładce „Twój obecny live”.</div>
                                                {/*end::Desc*/}
                                            </div>
                                            {/*end::Item*/}
                                             {/*begin::Item*/}
                                             <div className="timeline-item">
                                                {/*begin::Label*/}
                                                {/*end::Label*/}
                                                {/*begin::Badge*/}
                                                <div className="timeline-badge">
                                                    <i className="fa fa-genderless text-success fs-1"></i>
                                                </div>
                                                {/*end::Badge*/}
                                                {/*begin::Desc*/}
                                                <div className="timeline-content fw-bolder text-gray-800 ps-3">4. Bot wpisuje na Twój czat to pytanie i klientki mogą pod nim odpowiadać.</div>
                                                {/*end::Desc*/}
                                            </div>
                                            {/*end::Item*/}
                                             {/*begin::Item*/}
                                             <div className="timeline-item">
                                                {/*begin::Label*/}
                                                {/*end::Label*/}
                                                {/*begin::Badge*/}
                                                <div className="timeline-badge">
                                                    <i className="fa fa-genderless text-success fs-1"></i>
                                                </div>
                                                {/*end::Badge*/}
                                                {/*begin::Desc*/}
                                                <div className="timeline-content fw-bolder text-gray-800 ps-3">5. Wybierasz najszybszą poprawną odpowiedź.</div>
                                                {/*end::Desc*/}
                                            </div>
                                            {/*end::Item*/}
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                    {/*end::Card body*/}
                    {/*begin::Card footer*/}
                    {/* <div className="card-footer d-flex justify-content-end py-6 px-9">
                        <button className="btn btn-light btn-active-light-primary me-2">Discard</button>
                        <button className="btn btn-primary">Save Changes</button>
                    </div> */}
                    {/*end::Card footer*/}
                </div>
                {/*end::Content*/}
            </div>
            {/*end::Connected Accounts*/}
        </>
    )
})
