import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { formatMoney, loadScript, unloadScript } from '../../app/common/helpers';
import { useStore } from '../../app/stores/store';

export default observer(function AverageSalesByDayWidget() {
    const { statisticsStore } = useStore();
    console.log('detected ' + statisticsStore.storeType);

    useEffect(() => {
        const script1 = loadScript('/partner-custom/widgets/AverageSalesByDayWidget.js');

        return () => {
            unloadScript(script1);
        }
    }, []);


    return (
        <>
            {/*begin::Charts Widget 1*/}
            <div className="card card-flush h-xl-100">
                {/*begin::Header*/}
                <div className="card-header border-0 pt-5">
                    {/*begin::Title*/}
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bolder fs-3 mb-1">Średnia sprzedaż / dzień tygodnia</span>
                        <span className="text-gray-400 mt-1 fw-bold fs-6">Ostatni kwartał (na podstawie {formatMoney(statisticsStore.getTransactionsForQuarter())} zamówień)</span>
                    </h3>
                    {/*end::Title*/}
                </div>
                {/*end::Header*/}
                {/*begin::Body*/}
                <div className="card-body">
                    {/*begin::Chart*/}
                    <div id="AverageSalesByDayWidget" data-attr={statisticsStore.getAverageSalesByDayWidget()} style={{ height: '330px' }}></div>
                    {/*end::Chart*/}
                </div>
                {/*end::Body*/}
            </div>
            {/*end::Charts Widget 1*/}
        </>
    )
})
