import React from 'react';
import { NavLink } from 'react-router-dom';
import urls from '../common/urls';
import MenuItem from '../elements/MenuItem';
import { 
    IconBaskets, IconBlacklist, IconBuildYourReach, IconCustomers, IconDashboard, 
    IconOpenParcels, IconOrders, IconParcelsToSend, IconPlay, IconProducts, 
    IconSalesChannels, IconSettings, IconShop, IconStatistics } from './Icons';

export default function Aside() {
    return (
        <>
            <div id="kt_aside" className="aside aside-dark aside-hoverable" data-kt-drawer="true"
                data-kt-drawer-name="aside" data-kt-drawer-activate="{default: true, lg: false}"
                data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'200px', '300px': '250px'}"
                data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_aside_mobile_toggle">
                <div className="aside-logo flex-column-auto" id="kt_aside_logo">
                    <NavLink to={urls.dashboard()}>
                        <img alt="Logo" src="/partner-custom/logo.svg" className="h-40px logo" />
                    </NavLink>
                </div>
                <div className="aside-menu flex-column-fluid">
                    <div>
                        <div className="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500">
                            <MenuItem url={urls.dashboard()} caption='Start'>
                                <IconDashboard size="1" />
                            </MenuItem>
                            <MenuItem url={urls.customers()} caption='Klienci'>
                                <IconCustomers size="1" />
                            </MenuItem>
                            <MenuItem url={urls.blackList()} caption='Czarna lista' isSub>
                                <IconBlacklist size="1" />
                            </MenuItem>
                            <MenuItem url={urls.liveView()} caption='Live View Demo'>
                                <IconPlay size="1" />
                            </MenuItem>
                            <MenuItem url={urls.orders()} caption='Zamówienia'>
                                <IconOrders size="1" />
                            </MenuItem>
                            <MenuItem url={urls.openParcels()} caption='Otwarte paczki' isSub>
                                <IconOpenParcels size="1" />
                            </MenuItem>
                            <MenuItem url={urls.parcelsToSend()} caption='Paczki do wysłania' isSub>
                                <IconParcelsToSend size="1" />
                            </MenuItem>
                            <MenuItem url={urls.pickups()} caption='Odbiory osobiste' isSub>
                                <IconShop size="1" />
                            </MenuItem>
                            <MenuItem url={urls.products()} caption='Produkty'>
                                <IconProducts size="1" />
                            </MenuItem>
                            <MenuItem url={urls.baskets()} caption='Koszyki'>
                                <IconBaskets size="1" />
                            </MenuItem>
                            <MenuItem url={urls.history()} caption='Historia'>
                                <IconSalesChannels size="1" />
                            </MenuItem>
                            <MenuItem url={urls.statistics()} caption='Ty w liczbach'>
                                <IconStatistics size="1" />
                            </MenuItem>
                            <MenuItem url={urls.settings()} caption='Ustawienia'>
                                <IconSettings size="1" />
                            </MenuItem>
                            <MenuItem url={urls.buildYourReach()} caption='Buduj zasięgi'>
                                <IconBuildYourReach size="1" />
                            </MenuItem>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}