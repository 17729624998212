import React from 'react';
import { observer } from 'mobx-react-lite';

interface Props {
    source: string
    className?: string
    big?: boolean
}

export default observer(function SourceToLogoImage({ source, className = '', big = false }: Props) {
    const sizeClass = big?'w-30px': 'w-20px';
    
    return (
        <>
            {source === 'facebook' &&
                <img src="/assets/media/svg/brand-logos/facebook-4.svg" className={`${sizeClass} me-2 ${className}`} alt="" />}
            {source === 'instagram' &&
                <img src="/assets/media/svg/brand-logos/instagram-2-1.svg" className={`${sizeClass} me-2 ${className}`} alt="" />}
            {source === 'tiktok' &&
                <img src="/partner-custom/logos/tiktok_icon.svg" className={`${sizeClass} me-2 ${className}`} alt=""/>}

        </>
    )
})