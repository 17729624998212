import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { formatMoney, loadScript, unloadScript } from '../../app/common/helpers';
import { useStore } from '../../app/stores/store';


export default observer(function SalesInDaysWidget() {
    const { statisticsStore } = useStore();
    useEffect(() => {
        const script1 = loadScript('/partner-custom/widgets/SalesInDaysWidget.js');
        return () => {
            unloadScript(script1);
        }
    }, []);
    return (
        <>
            <div className="card card-flush h-xl-100">
                {/*begin::Header*/}
                <div className="card-header py-5">
                    {/*begin::Title*/}
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bolder text-dark">Sprzedaż - ostatnie 12 miesięcy</span>
                        <span className="text-gray-400 mt-1 fw-bold fs-6">Wszystkie kanały i formy</span>
                    </h3>
                    {/*end::Title*/}
                    {/*begin::Toolbar*/}
                    <div className="card-toolbar">
                        {/* <a href="../../demo1/dist/apps/ecommerce/catalog/products.html" className="btn btn-sm btn-light-primary fw-bolder">Launch eCommerce App</a> */}
                    </div>
                    {/*end::Toolbar*/}
                </div>
                {/*end::Header*/}
                {/*begin::Card body*/}
                <div className="card-body d-flex justify-content-between flex-column pb-1 px-0">
                    {/*begin::Statistics*/}
                    <div className="px-9 mb-5">
                        {/*begin::Statistics*/}
                        <div className="d-flex mb-2">
                            <span className="fs-4 fw-bold text-gray-400 me-1">zł</span>
                            <span className="fs-2hx fw-bolder text-gray-800 me-2 lh-1">{formatMoney(statisticsStore.getTotalIncome())}</span>
                        </div>
                        {/*end::Statistics*/}
                        {/*begin::Description*/}
                        <span className="fs-6 fw-bold text-gray-400">Łącznie {formatMoney(statisticsStore.getTotalTransactions())} zamówień</span>
                        {/*end::Description*/}
                    </div>
                    {/*end::Statistics*/}
                    {/*begin::Chart*/}
                    <div id="SalesInDaysWidget" className="min-h-auto ps-4 pe-6" style={{ height: '280px' }} data-attr={statisticsStore.getSalesInDaysWidget()}></div>
                    {/*end::Chart*/}
                </div>
                {/*end::Card body*/}
            </div>
        </>
    )
})
