import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { formatMoney, loadScript, unloadScript } from '../../app/common/helpers';
import { useStore } from '../../app/stores/store';

export default observer(function TopPaymentsWidget() {
    const { statisticsStore } = useStore();
    
    useEffect(() => {
        const script1 = loadScript('/partner-custom/widgets/TopPaymentsWidget.js');

        return () => {
            unloadScript(script1);
        }
    }, []);

    return (
        <>
            {/*begin::Chart widget 6*/}
            <div className="card card-flush h-lg-100">
                {/*begin::Header*/}
                <div className="card-header py-7 mb-3">
                    {/*begin::Title*/}
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bolder text-gray-800">Popularność metod płatności</span>
                        <span className="text-gray-400 mt-1 fw-bold fs-6">Ostatni kwartał (łącznie {formatMoney(statisticsStore.getTransactionsForQuarter())} transakcji)</span>
                    </h3>
                    {/*end::Title*/}
                    {/*begin::Toolbar*/}
                    <div className="card-toolbar">
                        {/* <a href="../../demo1/dist/apps/ecommerce/sales/listing.html" className="btn btn-sm btn-light">View All</a> */}
                    </div>
                    {/*end::Toolbar*/}
                </div>
                {/*end::Header*/}
                {/*begin::Body*/}
                <div className="card-body py-0 ps-6 mt-n12">
                    <div id="TopPaymentsWidget" data-attr={statisticsStore.getPaymentsChannelForQuarter()}></div>
                </div>
                {/*end::Body*/}
            </div>
            {/*end::Chart widget 6*/}
        </>
    )
})
