import React from 'react';
import { observer } from 'mobx-react-lite';

export default observer(function Footer() {
    return (
        <>
            {/*begin::Footer*/}
            <div className="footer py-4 d-flex flex-lg-column" id="kt_footer" >
                {/*begin::Container*/}
                <div className="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">
                    {/*begin::Copyright*/}
                    <div className="text-dark order-2 order-md-1">
                        <span className="text-muted fw-bold me-1">2022©</span>
                        <a href="https://www.basketbot.live" target="_blank" rel="noreferrer" className="text-light-800 text-hover-primary">BasketBot.live </a>
                        | powered by <a href="https://www.skyhighcode.com" target="_blank" rel="noreferrer" className="text-light-800 text-hover-primary">Sky High Code</a>

                    </div>
                    {/*end::Copyright*/}
                    {/*begin::Menu*/}
                    <ul className="menu menu-gray-600 menu-hover-primary fw-bold order-1">
                        <li className="menu-item">
                            <a href="https://www.basketbot.live" target="_blank" rel='noreferrer' className="menu-link px-2">Strona oficjalna</a>
                        </li>
                    </ul>
                    {/*end::Menu*/}
                </div>
                {/*end::Container*/}
            </div>
            {/*end::Footer*/}
        </>
    )
})
