import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { HistoryItem } from "../models/models";
import FilteringAndPaginationController, { StoreWithLoadItems } from "../common/filteringAndPaginationController";

export default class HistoryStore implements StoreWithLoadItems {
    registry = new Map<string, HistoryItem>();
    selectedItem: HistoryItem | undefined = undefined;
    filteringAndPaginationController = new FilteringAndPaginationController(this);

    constructor() {
        makeAutoObservable(this);
    }

    loadItems = async () => {
        const result = await agent.History.list(this.filteringAndPaginationController.axiosParams);
        this.filteringAndPaginationController.setPagination(result.pagination);
        runInAction(() => {
            this.registry.clear();
            result.data.forEach(b => {
                this.registry.set(b.id, b);
            });
        })
    }

    loadItem = async (id: string) => {
        const result = await agent.History.details(id);
        runInAction(() => {
            this.selectedItem = result;
            return this.selectedItem;
        })
    }

    // createOrUpdateItem = async (customer: CustomerAddOrUpdate) => {
    //     if(customer.id){
    //         //return agent.Customers.update(customer);
    //     }else{
    //         //return agent.Customers.create(customer);
    //     }
    // }

    get items() {
        return Array.from(this.registry.values());
    }
}